import orderBudgetGroupingFlightEntity from './order-budget-grouping-flight'
import { userHasFunction } from '@/helpers/auth'

const entity = {
  ...orderBudgetGroupingFlightEntity,
  absoluteKey: 'ControlOrderBudgetGroupingFlight',
  list: {
    ...orderBudgetGroupingFlightEntity.list,
    headers: [
      {
        key: 'Select',
        pages: [1, 2, 3],
        heading: null,
        componentList: 'ListCellSelect',
        size: '2rem',
        classCell: 'tw-h-20 tablet:tw-h-18',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-2',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-20 tablet:tw-h-18 tw-bg-alto-light tw-border-l tw-border-border',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Status',
        key: 'flightOrderStatusType',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderStatus',
        componentList: 'ListCellTypeIcon',
        icon: 'plane',
        size: 'calc(90px - 1rem)',
        classCell:
          'tw-h-20 tablet:tw-h-18 tw-border-l tw-border-border tw-justify-end tw-pr-4',
        classHeader: '',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEntityName',
        size: 'minmax(100px, 1fr)',
        classCell: 'tw-h-20 tablet:tw-h-18',
      },
      {
        label: 'Start date',
        key: 'startDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'End date',
        key: 'endDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Ordered',
        key: 'budget.impressionsGoal',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellEmpty',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Gross CPM',
        key: 'budget.grossCpm',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellEmpty',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Bonus Impressions',
        key: 'bonusImpressions',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: 'calc(80px - 1rem)',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-border-r tw-border-border',
        classHeader: 'tw-col-span-2',
      },
      {
        key: 'gap-3',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-20 tablet:tw-h-18 tw-bg-alto-light tw-border-r tw-border-border',
        classHeader: 'tw-hidden',
      },
    ],
    rowOptions: (item) => {
      const options = [
        ...orderBudgetGroupingFlightEntity.list.rowOptions(item),
        ...(userHasFunction([
          'Sales',
          'SalesAssistant',
          'SalesSenior',
          'SalesTeamManager',
        ]) && ['Converted', 'AppliedRevision'].includes(item[entity.statusKey])
          ? [
              {
                key: 'address-revision',
                label: 'Address a revision',
                icon: 'revision',
              },
            ]
          : []),
        ...(userHasFunction([
          'AdTrafficker',
          'AdTraffickerSenior',
          'MasterAdTrafficker',
        ]) && ['PendingRevision'].includes(item[entity.statusKey])
          ? [
              {
                key: 'check-revision',
                label: 'Check all revisions',
                icon: 'revision',
              },
            ]
          : []),
      ]

      return options
    },
  },
}

export default entity
