import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig.js'
import { searchList } from '@/helpers/search'

const transformCreativeToForm = (initialCreative) => {
  // Transform the form to include a `creativeTriggerRequest` instead of a `creativeTriggerResponse`
  const { creativeTriggerResponse, ...creative } = initialCreative

  return {
    ...creative,
    creativeTriggerRequest: {
      creativeId: creative.id,
      rangedTriggers:
        creativeTriggerResponse?.rangedTriggers
          .filter((rangeTrigger) => rangeTrigger !== null)
          .map((rangeTrigger) => ({
            triggerTypeId: rangeTrigger.triggerType.id,
            from: rangeTrigger.range.from,
            to: rangeTrigger.range.to,
          })) || [],
      segmentedTriggers:
        creativeTriggerResponse?.segmentedTrigger
          .filter((segmentTrigger) => segmentTrigger !== null)
          .map((segmentTrigger) => ({
            triggerTypeId: segmentTrigger.triggerType.id,
            segmentId: segmentTrigger.segmentId,
          })) || [],
    },
  }
}

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
    macroList: [],
    parent: null,
  },

  mutations: {
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
      state.list = []
      state.macroList = []
      state.macroValues = []
      state.parent = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_MACRO_LIST(state, macroList) {
      state.macroList = macroList
    },
    SET_MACRO_VALUES(state, values) {
      state.macroValues = values
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async fetchConfigView({ commit, state }, { campaignId } = {}) {
      if (state.configView) {
        return Promise.resolve()
      }

      try {
        const response = await HTTP.get(
          `Creative/Configview/Campaign/${campaignId}`
        )

        commit('SET_CONFIG_VIEW', parseConfig(response.data.CreativeXml))

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit }, { id }) {
      try {
        const response = await HTTP.get(`Creative/${id}`)

        commit('SET_CURRENT', response.data.datas)

        commit('SET_FORM', transformCreativeToForm(response.data.datas))

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(_, { id }) {
      try {
        const response = await HTTP.delete(`Creative/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create(_, form) {
      try {
        const response = await HTTP.post('Creative', form)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }) {
      try {
        const response = await HTTP.put('Creative', state.form)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updateWeight(_, form) {
      try {
        const response = await HTTP.put(
          'Creative',
          transformCreativeToForm(form)
        )

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, state }, { id, force = true }) {
      if (!force && state.list.length) {
        return Promise.resolve(state.list)
      }

      try {
        const listResponse = await HTTP.get(`Creative/campaign/list/${id}`)

        const list = listResponse.data.datas

        commit('SET_LIST', list)

        return Promise.resolve({ data: { datas: list } })
      } catch (error) {
        commit('SET_LIST', [])

        console.error(error)
        return Promise.reject(error)
      }
    },

    async search({ dispatch }, { search }) {
      try {
        const response = await dispatch('list')

        response.data.datas = searchList(response.data.datas, 'name', search)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async listMacro({ commit, state }, { force = true } = {}) {
      if (!force && state.macroList.length) {
        return Promise.resolve(state.macroList)
      }

      try {
        const response = await HTTP.get(`Creative/Macro`)

        const list = response.data.datas

        commit('SET_MACRO_LIST', list)

        return Promise.resolve({ data: { datas: list } })
      } catch (error) {
        commit('SET_MACRO_LIST', [])

        return Promise.reject(error)
      }
    },

    async valuesMacro({ commit }) {
      try {
        const response = await HTTP.get(`parameters/pixel-macros`)

        const values = response.data.flatMap((item) => item.macro)

        commit('SET_MACRO_VALUES', values)
      } catch (error) {
        commit('SET_MACRO_VALUES', [])

        return Promise.reject(error)
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
