<template>
  <div
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-text-grey-medium hover:tw-text-blackest"
  >
    <div class="tw-truncate tw-text-xs">
      <Tooltip
        dynamic
        tag="span"
        :title="item.name"
      >
        {{ item.name }}
      </Tooltip>
      <div v-if="item.fileFormat">
        <div
          class="tw-inline-block tw-rounded tw-bg-pill-bg tw-px-1 tw-py-px tw-text-2xs tw-text-black"
        >
          {{ item.fileFormat }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Tooltip from '@/components/Tooltip.vue'

export default {
  name: 'ListCellCreativeName',
  extends: ListCellDefault,
  components: {
    Tooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
