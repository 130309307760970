<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useInventoryFilters, FiltersKeys } from '@/hooks/useInventory'
import SearchInput from '@/components/Input/SearchInput.vue'
import PassportButton from '@/components/PassportButton.vue'

const emit = defineEmits(['@close', '@download'])

const store = useStore()
const { filters, setFilter } = useInventoryFilters()
const error = ref<null | string>(null)
const isLoading = ref(false)

const selectedPublisher = computed({
  get: () => filters.value[FiltersKeys.PUBLISHER] || undefined,
  set: (value) => {
    if (!value) return
    setFilter({ key: FiltersKeys.PUBLISHER, value })
  },
})

const isInvalid = computed(() => Boolean(selectedPublisher.value) === false)

const listPublishers = computed(() => {
  return store.getters['inventory/menuPublishers']
})

const handleSubmit = (): void => {
  emit('@close')
  emit('@download')
}
</script>

<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-center tw-text-xl tw-font-bold tw-text-black">
        Generate Report
      </h2>
    </div>
    <div class="tw-space-y-4 tw-border-b tw-border-border-light tw-p-6">
      <p v-if="isInvalid">
        Please select a publisher before generating a report.
      </p>
      <SearchInput
        v-model="selectedPublisher"
        :options="listPublishers"
        label="Select a publisher"
      />
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="emit('@close')"
      />
      <div
        :key="String(isInvalid)"
        v-tooltip="{
          placement: 'top',
          content: isInvalid ? `Please, select a publisher first` : '',
        }"
      >
        <PassportButton
          :busy="isLoading"
          variant="primary"
          :disabled="isInvalid"
          :label="error ? 'Retry' : 'Generate the Report'"
          type="button"
          @@click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>
