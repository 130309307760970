<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-text-sm tw-tabular-nums tw-text-grey-medium hover:tw-text-blackest"
  >
    {{ $filters.formatCurrency(safeGet(item, headerKey), currencyCode) }}
  </router-link>
</template>
<script>
import _get from 'lodash/get'
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellCurrency',
  extends: ListCellDefault,
  props: {
    currencyKey: {
      type: String,
      default: 'currencyCode',
    },
  },
  data() {
    return {
      currencyCode: _get(this.item, this.currencyKey),
    }
  },
}
</script>
