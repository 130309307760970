/* eslint-disabled */
const CLEAR_MODAL = 'CLEAR_MODAL'
const DISPLAY_MODAL = 'DISPLAY_MODAL'

const initialState = () => ({
  modalContent: {
    isModalShown: false,
    modalTitle: '',
    modalText: '',
    confirmAction: () => ({}),
    confirmText: '',
    cancelText: '',
    cancelAction: () => ({}),
    childComponent: '',
    width: '',
    customData: null,
  },
})

export const modal = {
  state: initialState,

  mutations: {
    [CLEAR_MODAL](state) {
      state.modalContent = {
        isModalShown: false,
        modalTitle: '',
        modalText: '',
        cancelText: '',
        confirmAction: () => ({}),
        cancelAction: () => ({}),
        childComponent: '',
        width: '',
        customData: null,
      }
    },

    [DISPLAY_MODAL](
      state,
      {
        modalTitle,
        modalText,
        confirmAction,
        confirmText,
        cancelText,
        cancelAction,
        childComponent,
        width,
        customData,
      }
    ) {
      state.modalContent.isModalShown = true
      state.modalContent.modalTitle = modalTitle ? modalTitle : ''
      state.modalContent.modalText = modalText ? modalText : ''
      state.modalContent.confirmAction = confirmAction
      state.modalContent.confirmText = confirmText ? confirmText : 'Confirm'
      state.modalContent.cancelText = cancelText ? confirmText : 'Cancel'
      state.modalContent.cancelAction = cancelAction
      state.modalContent.childComponent = childComponent
      state.modalContent.width = width
      state.modalContent.customData = customData
    },
  },

  actions: {
    displayModal(
      { commit },
      {
        modalTitle = undefined,
        modalText = undefined,
        confirmAction,
        confirmText = 'Confirm',
        cancelText = 'Cancel',
        cancelAction,
        childComponent,
        width = '',
        customData,
        ...props
      }
    ) {
      commit(DISPLAY_MODAL, {
        modalTitle,
        modalText,
        confirmAction,
        confirmText,
        cancelText,
        cancelAction,
        childComponent,
        width,
        customData,
        ...props,
      })
    },
  },

  getters: {},
}
