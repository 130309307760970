<script setup lang="ts">
import { useSlots, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'
import Loader from '@/components/Loader.vue'

const slots = useSlots()

const emit = defineEmits<{ (e: '@click'): void }>()
const props = defineProps({
  busy: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String as PropType<'button' | 'submit' | 'reset' | 'link'>,
    default: 'button',
  },
  size: {
    type: String as PropType<'base' | 'small'>,
    default: 'base',
  },
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: null,
  },
  variant: {
    type: String as PropType<'stroke' | 'primary' | 'basic'>,
    required: true,
  },
})
</script>

<template>
  <component
    :is="props.type == 'link' ? 'router-link' : 'button'"
    :to="props.type == 'link' && props.to ? props.to : null"
    class="tw-relative tw-inline-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-2 tw-whitespace-nowrap tw-rounded-full tw-border tw-font-semibold tw-uppercase tw-leading-6 tw-outline-none tw-ring-primary tw-ring-offset-2 tw-transition tw-duration-200 focus-visible:tw-ring-2 disabled:tw-cursor-not-allowed"
    :class="{
      'tw-border-transparent tw-bg-primary tw-text-white tw-shadow-sm hover:tw-bg-primary-dark active:tw-scale-90 disabled:tw-bg-alto hover:disabled:tw-bg-alto':
        props.variant === 'primary',
      ' dark:tw-text-text-tertiary-dark tw-border-primary tw-text-text-active hover:tw-bg-primary hover:tw-text-white active:tw-scale-90 disabled:tw-border-alto disabled:tw-text-alto hover:disabled:tw-bg-transparent':
        props.variant === 'stroke',
      'tw-text-text-tertiary dark:tw-text-text-tertiary-dark tw-border-transparent tw-shadow-none hover:tw-text-text-1 disabled:tw-text-alto':
        props.variant === 'basic',
      'tw-h-10 tw-px-5 tw-text-sm': props.size === 'base',
      'tw-h-8 tw-px-4 tw-text-xs': props.size === 'small',
    }"
    :disabled="props.busy || props.disabled"
    :type="props.type"
    @click.stop="() => emit('@click')"
  >
    <Loader
      v-if="props.busy"
      class="tw-text-lg"
      :class="{
        'tw-text-white': props.variant === 'primary',
        'tw-text-text-1':
          props.variant === 'stroke' || props.variant === 'basic',
      }"
    />
    <slot name="before" />
    <template v-if="slots.default">
      <span class="tw-sr-only">{{ props.label }}</span>
    </template>
    <slot>
      <span>{{ props.label }}</span>
    </slot>
    <slot name="after" />
  </component>
</template>
