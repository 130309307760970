<template>
  <div
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-bg-white tw-text-grey-medium hover:tw-text-blackest"
  >
    <button
      v-if="!item.isVastDastUrl"
      type="button"
      class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-grey-light hover:tw-text-grey-medium"
      @click.stop="
        $emit('click', { key: isPlaying ? 'playerPause' : 'playerPlay', item })
      "
    >
      <Icon
        v-if="!isPlaying"
        name="control-play"
        class="tw-text-xl"
      />
      <Icon
        v-else
        name="control-pause"
        class="tw-text-xl"
      />
    </button>
    <div
      v-else
      class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-xl tw-text-grey-light"
    >
      <Icon name="vast-daast" />
    </div>
  </div>
</template>
<script>
import Icon from '@/components/Icon.vue'
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellCreativePlay',
  emits: ['click'],
  extends: ListCellDefault,
  components: {
    Icon,
  },
  props: {
    headerKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    isPlaying() {
      return (
        this.entity.player.id === this.item[this.headerKey] &&
        this.entity.player.isPlaying
      )
    },
  },
}
</script>
