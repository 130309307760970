import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import _get from 'lodash/get'
import router from '@/router/index.ts'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig.js'
import { searchList } from '@/helpers/search'
import { useStoreForecastEstimates } from '@/stores'

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
  },

  mutations: {
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_BUDGET(state, budget) {
      state.current.budget = budget
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async fetchConfigView({ commit }, { orderId } = {}) {
      try {
        const response = await HTTP.get(
          `FlightOrder/Configview/CampaignOrder/${orderId}`
        )
        const parsedConfig = parseConfig(
          response.data.FlightOrderXml,
          'FlightOrderTypeId'
        )

        commit('SET_CONFIG_VIEW', parsedConfig)

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit, state }, { id, reload = false }) {
      try {
        const response = await HTTP.get(`FlightOrder/${id}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        if (reload || !state.form) {
          commit('SET_FORM', response.data.datas)
        }

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.dataCostsCpm =
          response.data.datas.budget.dataCostsCpm

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`FlightOrder/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post('FlightOrder', state.form)

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { isInBudgetGrouping, options } = {}) {
      try {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { budget, ...formData } = state.form // Do not send the budget for BG Flights
        const response = await HTTP.put(
          'FlightOrder',
          router.currentRoute.value.params.orderBudget || isInBudgetGrouping
            ? formData
            : state.form,
          options
        )

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.dataCostsCpm =
          response.data.datas.budget.dataCostsCpm

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async updateDataCost({ commit, state }, { value }) {
      try {
        const response = await HTTP.put('FlightOrder/UpdateDataCostsCpm', {
          flightOrderId: state.current.id,
          dataCostsCpm: value,
        })

        commit('SET_CURRENT', {
          ...state.current,
          budget: {
            ...state.current.budget,
            ecpm: response.data.datas.budget.ecpm,
            dataCostsCpm: response.data.datas.budget.dataCostsCpm,
          },
        })
        commit('SET_FORM', {
          ...state.form,
          budget: {
            ...state.form.budget,
            ecpm: response.data.datas.budget.ecpm,
            dataCostsCpm: response.data.datas.budget.dataCostsCpm,
          },
        })

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.dataCostsCpm =
          response.data.datas.budget.dataCostsCpm

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async duplicate(context, { id, startDate, endDate }) {
      try {
        const response = await HTTP.post(`FlightOrder/duplicate`, {
          flightOrderId: id,
          startDate,
          endDate,
        })

        return Promise.resolve(response?.data?.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, state }, { force = true, id }) {
      if (!force && state.list.length) {
        return Promise.resolve(state.list)
      }

      try {
        const response = await HTTP.get(`FlightOrder/full/${id}`)

        // Flatten the flights that are not parts of budget groupings
        const list = response.data.datas.notBudgetGroupings.map(
          (notBudgetGrouping) => {
            return {
              ...notBudgetGrouping,
              flights: [notBudgetGrouping],
            }
          }
        )

        commit('SET_LIST', list)
        commit(
          'orderBudgetGrouping/SET_LIST',
          response.data.datas.budgetGroupings.map(
            ({ flightOrderList = [], ...budgetGrouping }) => {
              return {
                ...budgetGrouping,
                flights: flightOrderList,
              }
            }
          ),
          { root: true }
        )

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    async search({ dispatch }, { search }) {
      try {
        const response = await dispatch('list')

        response.data.datas = searchList(response.data.datas, 'name', search)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async requestConversion(_, { id }) {
      try {
        const response = await HTTP.put(`FlightOrder/convert/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async confirmConversion(_, { id, type = 'flight' }) {
      try {
        const response = await HTTP.put(`FlightOrder/to-${type}/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    async setDefaultForm({ commit, rootState, state }, type = 'flight') {
      try {
        const languageCodesList = _get(
          state.configView,
          'fields.FlightLanguageCode.DataList.DataList' || []
        )

        const channelsTypesList = _cloneDeep(
          _get(state.configView, 'fields.ChannelTypes.DataList.DataList') || []
        )

        const languageCode = Array.isArray(languageCodesList)
          ? languageCodesList[0]?.Language?.toUpperCase()
          : languageCodesList?.Language?.toUpperCase()

        if (type === 'flight') {
          const form = await import('@/forms/order-flight.js')
          const defaultForm = _cloneDeep(form.default)

          commit('SET_FORM', {
            ...defaultForm,
            budget: {
              ...defaultForm.budget,
              hardCostsCurrencyCode: rootState.order.current.currencyCode,
            },
            campaignOrderId: rootState.order.current.id,
            countryCode: rootState.order.current.countryCode,
            currencyCode: rootState.order.current.currencyCode,
            languageCode,
            channelTypes: channelsTypesList
              .filter(({ IsDefault }) => IsDefault)
              .map(({ Id }) => Id),
          })
        }

        if (type === 'budget-grouping-flight') {
          const form = await import('@/forms/order-budget-grouping-flight.js')
          const defaultForm = _cloneDeep(form.default)

          commit('SET_FORM', {
            ...defaultForm,
            budgetId: rootState.orderBudgetGrouping.current.id,
            campaignOrderId: rootState.order.current.id,
            languageCode,
            channelTypes: channelsTypesList
              .filter(({ IsDefault }) => IsDefault)
              .map(({ Id }) => Id),
          })
        }
      } catch (e) {
        console.error(e)
      }
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    setParentId({ commit }, { id }) {
      commit('SET_PARENT_ID', id)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
