<template>
  <Popup
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Change your password
      </h2>
    </div>

    <form
      id="password-form"
      class="tw-px-10 tw-pb-8 tw-pt-4"
      @submit.prevent="handleSubmit"
    >
      <div class="tw-space-y-4">
        <TextInput
          v-model="form.password"
          label="New password"
          type="password"
          required
        />
        <TextInput
          v-model="form.passwordConfirmation"
          type="password"
          label="Confirm new password"
          required
        />
      </div>
      <div
        v-show="errorMessage !== null"
        class="tw-mt-4 tw-text-red"
      >
        {{ errorMessage }}
      </div>
    </form>
    <div
      class="tw-mt-auto tw-flex tw-items-center tw-justify-between tw-border-t tw-p-6"
    >
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="handleClose"
      />
      <PassportButton
        type="submit"
        variant="primary"
        label="Change Password"
        form="password-form"
      />
    </div>
  </Popup>
</template>
<script>
import PassportButton from '@/components/PassportButton.vue'
import Popup from '@/components/Popup.vue'
import TextInput from '@/components/Input/TextInput.vue'

export default {
  components: {
    PassportButton,
    Popup,
    TextInput,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update:modelValue'],
  data() {
    return {
      errorMessage: null,
      form: {
        password: '',
        passwordConfirmation: '',
      },
    }
  },
  computed: {
    isPasswordConfirmed() {
      return this.form.password === this.form.passwordConfirmation
    },
  },
  methods: {
    async handleSubmit() {
      if (this.isPasswordConfirmed) {
        try {
          await this.$store.dispatch('profile/updatePassword', {
            password: this.form.password,
          })

          this.$store.dispatch('addToast', {
            type: 'info',
            value: `Your password has been changed correctly`,
          })

          this.handleClose()
        } catch (error) {
          try {
            this.errorMessage = error.response.data.errors[0].message
          } catch (error) {
            this.errorMessage = 'Password could not be updated.'
          }
        }
      } else {
        this.errorMessage = `Passwords do not match`
      }
    },
    handleClose() {
      this.errorMessage = null
      this.form.password = ''
      this.form.passwordConfirmation = ''

      this.$emit('close')
    },
  },
}
</script>
