<script setup lang="ts">
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'

type PropsModelValue = string | number | unknown[] | boolean

const emit = defineEmits<{
  (e: 'update:modelValue', value: PropsModelValue): void
  (e: 'change', event: Event): void
}>()

const props = withDefaults(
  defineProps<{
    label?: string
    value: unknown
    disabled?: boolean
    // checked?: boolean
    modelValue: PropsModelValue
    hideLabel?: boolean
    type?: 'checkbox' | 'radio'
    inverted?: boolean
    indeterminate?: boolean
  }>(),
  {
    disabled: false,
    hideLabel: false,
    inverted: false,
    indeterminate: false,
  }
)

const valueProxy = computed<PropsModelValue>({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

const isMultiple = computed(() => Array.isArray(props.modelValue))
</script>

<template>
  <div
    class="tw-relative tw-inline-block"
    :class="
      isMultiple || props.type == 'checkbox' ? 'tw-rounded' : 'tw-rounded-full'
    "
  >
    <label class="tw-flex tw-flex-nowrap tw-items-center tw-gap-2">
      <div
        class="tw-bg-white"
        :class="{
          ' tw-rounded-full': props.type != 'checkbox' && isMultiple == false,
          'tw-rounded': props.type == 'checkbox' || isMultiple == true,
        }"
      >
        <input
          v-if="isMultiple || props.type == 'checkbox'"
          v-model="valueProxy"
          :value="props.value"
          :disabled="props.disabled"
          inder
          type="checkbox"
          class="tw-peer tw-absolute tw-h-4 tw-w-4 tw-appearance-none tw-border-transparent tw-opacity-0"
          :indeterminate.prop="props.indeterminate"
          @change="emit('change', $event)"
        />
        <input
          v-else
          v-model="valueProxy"
          :value="props.value"
          :disabled="props.disabled"
          type="radio"
          class="tw-peer tw-absolute tw-h-4 tw-w-4 tw-appearance-none tw-border-transparent tw-opacity-0"
          @change="emit('change', $event)"
        />
        <div
          class="tw-relative tw-h-4 tw-w-4 tw-border tw-ring-primary tw-ring-offset-2 peer-checked:tw-border-transparent peer-focus-visible:tw-ring-1"
          :class="[
            {
              'parent:tw-rounded-full tw-rounded-full peer-disabled:[&_>*]:tw-bg-primary/40':
                props.type != 'checkbox' && isMultiple == false,
              'tw-rounded': props.type == 'checkbox' || isMultiple == true,
            },
            '[&_>*]:tw-hidden peer-checked:[&_>*]:tw-inline-block peer-disabled:[&_>*]:tw-text-white',
            'tw-cursor-pointer tw-border-text-3 peer-disabled:tw-cursor-not-allowed peer-disabled:tw-border-text-4',
            'peer-checked:tw-bg-primary/60 peer-disabled:peer-checked:tw-bg-primary/30 dark:peer-checked:tw-bg-primary/90 dark:peer-disabled:peer-checked:tw-bg-primary/60',
          ]"
        >
          <template v-if="isMultiple || props.type === 'checkbox'">
            <Icon
              v-if="props.indeterminate == true"
              name="minus"
              class="tw-absolute tw-right-1/2 tw-top-1/2 tw--translate-y-1/2 tw-translate-x-1/2 tw-text-2xs tw-text-text-1"
            />
            <Icon
              v-else
              name="check"
              class="tw-absolute tw-right-1/2 tw-top-1/2 tw--translate-y-1/2 tw-translate-x-1/2 tw-text-2xs tw-text-text-1"
            />
          </template>
          <template v-else>
            <div
              class="tw-absolute tw-right-1/2 tw-top-1/2 tw-h-2 tw-w-2 tw--translate-y-1/2 tw-translate-x-1/2 tw-rounded-full tw-bg-primary tw-p-0.5 tw-ring-1 tw-ring-white"
            />
          </template>
        </div>
      </div>
      <slot>
        <span
          v-if="props.label"
          class="tw-flex-1 tw-break-words tw-text-sm tw-text-text-2 peer-disabled:tw-text-text-4"
          :class="{
            'tw-sr-only': hideLabel == true,
            'tw-order-first': props.inverted,
            'hover:tw-text-text-3': props.disabled == false,
          }"
        >
          {{ props.label }}
        </span>
      </slot>
    </label>
  </div>
</template>
