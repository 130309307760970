/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'
import { toRoute } from '@/helpers/route'
import Auth from '@/router/auth'
import Billing from '@/router/billing'
import Dashboard from '@/router/dashboard'
import Campaigns from '@/router/campaigns'
import Deals from '@/router/deals'
import Forecasts from '@/router/forecasts'
import Inventory from '@/router/inventory'
import MagicTokenReport from '@/router/magic-token-report'
import MagicTokens from '@/router/magic-tokens'
import Orders from '@/router/orders'
import People from '@/router/people'
import Profile from '@/router/profile'

const ignoreScrollBehaviorPaths = [
  'Users',
  'Agencies',
  'Advertisers',
  'Inventory',
  'CampaignReport',
  'CampaignReportMagicToken',
]

let routes: RouteRecordRaw[] = []

routes = routes.concat(
  [
    {
      path: '/',
      redirect: () => {
        if (store.state.auth.authenticated) {
          return toRoute(store.getters['auth/defaultRoute'])
        }

        return toRoute({ name: 'DashBoard' })
      },
      meta: {
        name: 'Dashboard',
        layout: 'LayoutAuthenticated',
        requiresAuth: true,
      },
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import('@/views/maintenance.vue'),
      meta: {
        title: 'Passport | Maintenance',
        layout: 'LayoutGuest',
        maintenance: true,
      },
    },
    {
      path: '/:organization?/release-notes',
      name: 'ReleaseNotes',
      component: () => import('@/views/release-notes.vue'),
      meta: {
        layout: 'LayoutAuthenticated',
        title: 'Passport | Release Notes',
      },
    },
    {
      path: '/:organization?/legal/terms-and-conditions',
      name: 'LegalTermsAndConditions',
      component: () => import('@/views/TermsAndConditions.vue'),
      meta: {
        layout: 'LayoutGuest',
        title: 'Passport | Terms And Conditions',
      },
    },
    {
      path: '/:organization?/legal/privacy-policy',
      name: 'LegalPrivacyPolicy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      meta: {
        layout: 'LayoutGuest',
        title: 'Passport | Privacy Policy',
      },
    },
    {
      path: '/:organization?/terms-and-conditions',
      name: 'TermsAndConditions',
      component: () => import('@/views/TermsAndConditions.vue'),
      meta: {
        requiresAuth: true,
        layout: 'LayoutAuthenticated',
        title: 'Passport | Terms And Conditions',
      },
      props: true,
    },
    {
      path: '/:organization?/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        layout: 'LayoutAuthenticated',
        title: 'Passport | Privacy Policy',
      },
    },
  ],
  Auth,
  Billing,
  Dashboard,
  Campaigns,
  Deals,
  Forecasts,
  Inventory,
  MagicTokenReport,
  MagicTokens,
  Orders,
  Profile,
  People,
  [
    {
      path: '/:organization/orders/:order/edit-order-flight/:orderFlight/:tab?',
      redirect: { name: 'EditOrderFlight' },
    },
    {
      path: '/:organization?/programmatic/edit/:deal/:tab?',
      redirect: { name: 'EditDeal' },
    },
    {
      path: '/403',
      name: 'ErrorForbidden',
      component: () => import('@/views/403.vue'),
      meta: {
        layout: 'LayoutAuthenticated',
      },
      props: {
        description:
          'It seems that you do not have any access. Please, contact Passport support',
      },
    },
    {
      path: '/404',
      name: 'ErrorNotFound',
      component: () => import('@/views/404.vue'),
      meta: {
        layout: 'LayoutGuest',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/404.vue'),
      meta: {
        layout: 'LayoutGuest',
      },
    },
  ]
)

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  scrollBehavior(to, _from, savedPosition) {
    if (
      ignoreScrollBehaviorPaths.some(
        (ignoredPath) =>
          typeof to.name === 'string' && to.name.includes(ignoredPath)
      )
    ) {
      return savedPosition || false
    }

    return { top: 0 }
  },
  routes,
})

router.beforeEach(function (to, from, next) {
  // ❗️ Avoid an infinite redirect
  if (
    typeof to?.name === 'string' &&
    ['Login', 'ErrorForbidden', 'ErrorNotFound'].includes(to.name)
  )
    return next()

  const isConnected =
    store.state.auth.authenticated === true &&
    store.state.auth.authenticating === false

  if (isConnected) {
    const currentOrganization = store.getters['auth/currentOrganization']

    if (
      store.getters['auth/isInSimplifiedOrganization'] &&
      store.state.auth.gcuAccepted === false &&
      ['SingleFlight'].includes(currentOrganization.campaignFlag)
    ) {
      // ❗️ Avoid an infinite redirect
      if (
        typeof to?.name === 'string' &&
        ['LegalTermsAndConditions', 'LegalPrivacyPolicy'].includes(to.name)
      ) {
        return next()
      }

      return next({
        name: 'LegalTermsAndConditions',
        query: {
          redirect: to?.path || '/',
        },
        params: {
          organization: store.getters['auth/organizationId'],
        },
      })
    } else if (
      store.state.auth.gcuAccepted === true &&
      typeof to?.name === 'string' &&
      ['LegalTermsAndConditions', 'LegalPrivacyPolicy'].includes(to.name)
    ) {
      return next(toRoute(store.getters['auth/defaultRoute']))
    }

    if (
      to.params?.organization &&
      Number(to.params?.organization) !==
        store.getters['auth/currentOrganization']?.id
    ) {
      if (
        typeof to?.name === 'string' &&
        ['SwitchOrganization'].includes(to.name)
      )
        return next()

      return next({
        name: 'SwitchOrganization',
        params: {
          orgFrom: store.getters['auth/currentOrganization']?.id,
          orgTo: to.params.organization,
        },
        query: {
          redirect: to?.path || '/',
        },
      })
    }

    if (store.getters['auth/currentOrganization'] === null) {
      // ❗️ Avoid an infinite redirect
      if (
        typeof to?.name === 'string' &&
        ['SwitchOrganization'].includes(to.name)
      )
        return next()

      const organizationList = store.state.auth?.user?.organizationList || []
      const [{ id = null } = {}] = organizationList

      if (id) {
        return next({
          name: 'SwitchOrganization',
          params: {
            orgFrom: store.getters['auth/currentOrganization']?.id,
            orgTo: id,
          },
        })
      }

      return next({ name: 'ErrorForbidden' })
    }
  }

  if (
    store.state.auth.authenticated &&
    to.matched.some((record) => record.meta.requiresGuest)
  ) {
    return next({ name: 'DashBoard' })
  }

  if (to.name !== from.name) {
    store.dispatch('requests/cancelPendingRequests')
  }

  const maintenance = {
    from: new Date('2021-03-20T06:00:00+00:00'),
    to: new Date('2021-03-22T07:00:00+00:00'),
  }
  const now = new Date()
  const isInMaintenance =
    now.getTime() > maintenance.from.getTime() &&
    now.getTime() < maintenance.to.getTime()

  if (isInMaintenance) {
    if (to.matched.some((record) => record.meta.inMaintenance)) {
      return next({ name: 'Maintenance' })
    }
  }

  if (!isConnected && to.matched.some((record) => record.meta.requiresAuth)) {
    return next({ name: 'Login', query: { redirect: to.fullPath } })
  }

  next()
})

router.afterEach(async (to) => {
  if (store.state!.auth.authenticated || store.state.auth.authenticating) {
    return
  }

  try {
    await store.dispatch('auth/sync', { background: true })

    if (to.query.organizationId && store.state.auth?.user) {
      try {
        const newOrganizationId = Number(to.query.organizationId)

        if (
          newOrganizationId === store.getters['auth/currentOrganization']?.id
        ) {
          return
        }

        store.dispatch('popup/show', {
          name: 'PopupChangeOrganization',
          props: {
            oldOrganizationId: store.getters['auth/currentOrganization']?.id,
            newOrganizationId,
            resolve: () => ({}),
          },
        })
      } catch (error) {
        console.error('Could not change organization')
      }
    }
  } catch (error) {
    // Maybe Unauthenticated
  }
})

export default router
