<template>
  <Popup
    :model-value="modelValue"
    popup-width="tw-w-modal-lg"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div v-if="modelValue">
      <div
        class="tw-my-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <Icon
          name="wand"
          class="tw-text-5xl tw-text-alto"
        />
        <h2 class="mt-8 tw-text-xl tw-text-black">
          Update a Campaign Report Magic Token
        </h2>
      </div>
      <div>
        <div class="tw-mb-4 tw-px-6">
          <div
            class="tw-flex tw-h-12 tw-max-w-full tw-items-center tw-truncate tw-rounded tw-border tw-border-border-light"
          >
            <div
              class="tw-grid tw-h-full tw-w-12 tw-place-content-center tw-border-r tw-border-border-light"
            >
              <Icon
                name="stats"
                class="tw-flex-shrink-0 tw-text-grey-light"
              />
            </div>
            <div class="tw-flex tw-items-center tw-gap-2 tw-truncate tw-px-6">
              <div class="tw-flex-none tw-font-semibold tw-text-grey-light">
                id: {{ magicToken.campaignId }}
              </div>
              <h3 class="tw-truncate tw-font-semibold">
                {{ magicToken.parentName }}
              </h3>
            </div>
          </div>
        </div>
        <div class="tw-max-h-64 tw-overflow-y-scroll lg:tw-max-h-80">
          <div
            v-if="false"
            class="tw-mb-8 tw-px-6"
          >
            <h4
              class="tw-text-md tw-border-b tw-border-black tw-font-bold tw-leading-8 tw-text-black"
            >
              Expiration
            </h4>
            <p class="tw-mt-4 tw-text-sm tw-text-grey">
              Date for this link to expire
            </p>
            <div
              class="tw-ml-4 tw-flex tw-items-start tw-justify-between tw-pt-5"
            >
              <InputSelector
                v-for="preset of optionsPresets"
                :key="preset.value"
                v-model="form.preset"
                :value="preset.value"
                :label="preset.label"
                name="preset"
              />
              <div class="tw-flex tw-items-center tw-gap-2">
                <InputSelector
                  v-model="form.preset"
                  :value="'CUSTOM'"
                  name="preset"
                />
                <DateInput
                  v-model="form.customDate"
                  :min-date="new Date().toISOString()"
                  label="Expiration on"
                  class="-tw-mt-6 tw-w-64"
                  @update:model-value="
                    ($event) =>
                      $event ? (form.preset = 'CUSTOM') : (form.preset = null)
                  "
                />
              </div>
            </div>
          </div>
          <div class="tw-mb-8 tw-px-6">
            <h4
              class="tw-text-md tw-border-b tw-border-black tw-font-bold tw-leading-8 tw-text-black"
            >
              Type of report
            </h4>
            <p class="tw-mt-4 tw-text-sm tw-text-grey">
              Type of report to share
            </p>
            <div
              class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-pl-4 tw-pt-5"
            >
              <InputSelector
                v-model="form.withPublisherData"
                label="Limited report (without publishers report)"
                :value="false"
              />
              <InputSelector
                v-model="form.withPublisherData"
                label="Full report"
                :value="true"
              />
            </div>
          </div>
          <div class="tw-mb-8 tw-px-6">
            <div
              class="tw-text-md tw-flex tw-justify-between tw-border-b tw-border-black tw-font-bold tw-text-black"
            >
              <h4 class="tw-leading-8">Budget Data</h4>
              <div class="tw-mb-1.5 tw-self-end">
                <SwitchInput v-model="form.withBudgetData" />
              </div>
            </div>
            <p class="tw-mt-4 tw-text-sm tw-text-grey">
              Hide/Show Amount, CPM, Discount
            </p>
          </div>
          <div
            v-for="section of listSections"
            :key="section.formKey"
            class="tw-mb-8 tw-px-6"
          >
            <label
              class="tw-text-md tw-flex tw-justify-between tw-border-b tw-border-black tw-font-bold tw-text-black"
            >
              <h4 class="tw-leading-8">{{ section.name }}</h4>
              <div class="tw-mb-1.5 tw-self-end">
                <SwitchInput v-model="form[section.formKey]" />
              </div>
            </label>
            <p class="tw-mt-4 tw-text-sm tw-text-grey">
              Hide/show {{ section.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-border-light tw-p-6"
      >
        <PassportButton
          label="Cancel"
          variant="basic"
          size="small"
          @@click="$emit('update:modelValue', false)"
        />
        <PassportButton
          :busy="isLoading"
          size="small"
          variant="primary"
          type="button"
          :label="shouldRetry ? 'Retry' : 'Update'"
          @@click="handleSubmit"
        />
      </div>
    </div>
  </Popup>
</template>
<script>
import { addDays, getYear, format, isDate, parseISO } from 'date-fns'
import { formats } from '@/helpers/dateFns'
import DateInput from '@/components/Input/DateInput.vue'
import InputSelector from '@/components/InputSelector.vue'
import PassportButton from '@/components/PassportButton.vue'
import Popup from '@/components/Popup.vue'
import SwitchInput from '@/components/Input/SwitchInput.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'PopupMagicTokenCampaignReportEdit',
  components: {
    DateInput,
    Icon,
    InputSelector,
    PassportButton,
    Popup,
    SwitchInput,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    magicToken: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'update:modelValue', 'refresh'],
  data() {
    return {
      copied: false,
      form: {
        customDate: null,
        preset: null,
        withAdPlacementData: true,
        withBudgetData: true,
        withCarrierData: true,
        withChannelData: true,
        withCreativeData: true,
        withDeviceData: true,
        withFlightTypeData: true,
        withFormatData: true,
        withGenderReport: true,
        withGeoData: true,
        withImpressionData: true,
        withKVPData: true,
        withPublisherData: true,
        withRelatedFlightAndBudgetGroupingData: true,
      },
      isLoading: false,
      optionsPresets: [
        { value: null, label: 'Never' },
        { value: 7, label: '7 days' },
        { value: 30, label: '30 days' },
      ],
      shouldRetry: false,
      step: 'settings',
    }
  },
  computed: {
    listSections() {
      return [
        { formKey: 'withAdPlacementData', name: 'Ad Placement data' },
        // { formKey: 'withBudgetData', name: 'Budget' },
        { formKey: 'withCarrierData', name: 'Carrier data' },
        { formKey: 'withChannelData', name: 'Channels Types data' },
        { formKey: 'withCreativeData', name: 'Creatives data' },
        { formKey: 'withDeviceData', name: 'Devices data' },
        // { formKey: 'withFlightTypeData', name: 'Flight Types data' },
        { formKey: 'withImpressionData', name: 'Impressions data' },
        { formKey: 'withFormatData', name: 'Format data' },
        { formKey: 'withGenderReport', name: 'Gender/Age report' },
        { formKey: 'withGeoData', name: 'Geo-localized Distribution data' },
        { formKey: 'withKVPData', name: 'KVP data' },
        { formKey: 'withPublisherData', name: 'Publisher data' },
        {
          formKey: 'withRelatedFlightAndBudgetGroupingData',
          name: 'Related Flights and Budget Groupings',
        },
      ]
    },
  },
  mounted() {
    const expiryDate = parseISO(this.magicToken.expiryDate)
    const isNeverExpired = isDate(expiryDate) && getYear(expiryDate) === 9999

    Object.assign(this.form, {
      preset: isNeverExpired ? null : 'CUSTOM',
      customDate: isNeverExpired ? null : this.magicToken.expiryDate,
      ...this.magicToken,
    })
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      this.shouldRetry = false

      let expiryDate = null

      if (this.form.preset === 7) {
        expiryDate = format(addDays(new Date(), 7), formats.ISO.date)
      } else if (this.form.preset === 30) {
        expiryDate = format(addDays(new Date(), 30), formats.ISO.date)
      } else if (this.form.customDate) {
        expiryDate = this.form.customDate
      } else {
        expiryDate = '9999-12-31'
      }

      try {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { customDate, preset, ...configSections } = this.form
        const payload = {
          expiryDate,
          campaignId: this.magicToken.campaignId,
          ...configSections,
        }

        await this.$store.dispatch(
          'magicToken/updateCampaignReportMagicToken',
          {
            payload,
          }
        )

        this.$emit('refresh')
        this.$emit('update:modelValue', false)
      } catch (error) {
        this.shouldRetry = true
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
