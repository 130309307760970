<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-bg-white tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
  >
    <span v-if="headerKey === 'isDisabled'">
      {{ safeGet(item, headerKey) ? 'Disabled' : 'Active' }}
    </span>
    <span v-else>
      {{ safeGet(item, headerKey) ? 'Show' : 'Hide' }}
    </span>
  </router-link>
</template>

<script>
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellMagicTokenVisibility',
  extends: ListCellDefault,
}
</script>
