<template>
  <div class="tw-w-modal tw-divide-y tw-divide-border-light">
    <div class="tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        {{ content.title }}
      </h2>
    </div>
    <div
      v-if="content.description"
      class="tw-p-6"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="content.description" />
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        variant="basic"
        label="Cancel"
        @@click="$emit('close')"
      />
      <PassportButton
        variant="primary"
        :busy="isLoading"
        :label="shouldRetry ? 'Retry' : content.button"
        type="button"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>
<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  components: { PassportButton },
  name: 'PopupEntityAction',
  props: {
    content: {
      type: Object,
      required: true,
    },
    resolve: {
      type: Function,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      error: '',
      isLoading: false,
      shouldRetry: false,
    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      this.shouldRetry = false

      try {
        await this.resolve()

        this.$emit('close')
      } catch (error) {
        this.error = this.handleError(error)

        this.shouldRetry = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
