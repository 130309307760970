import HTTP from '@/services/http'
import { format, subDays, startOfDay } from 'date-fns'
import { formats } from '@/helpers/dateFns'
import dashboardWidgetsTemplates from '@/constants/DashboardWidgetsTemplates.js'

const ADD_DASHBOARD = 'ADD_DASHBOARD'
const REFRESH_ACTIVE_DASHBOARD = 'REFRESH_ACTIVE_DASHBOARD'
const SET_DASHBOARD = 'SET_DASHBOARD'
const SET_DASHBOARD_DIRTY = 'SET_DASHBOARD_DIRTY'
const SET_ACTIVE_DASHBOARD = 'SET_ACTIVE_DASHBOARD'
const SET_DASHBOARDS = 'SET_DASHBOARDS'
const SET_SHARED_DASHBOARDS = 'SET_SHARED_DASHBOARDS'
const REMOVE_DASHBOARD = 'REMOVE_DASHBOARD'
const UPDATE_ACTIVE_DASHBOARD = 'UPDATE_ACTIVE_DASHBOARD'
const ADD_WIDGET = 'ADD_WIDGET'
const REMOVE_WIDGET = 'REMOVE_WIDGET'
const UPDATE_WIDGET_OPTIONS = 'UPDATE_WIDGET_OPTIONS'
const SET_WIDGETS_FETCH_DATE_FROM = 'SET_WIDGETS_FETCH_DATE_FROM'
const SET_WIDGETS_FETCH_DATE_TO = 'SET_WIDGETS_FETCH_DATE_TO'
const SET_ACCESSIBLE_WIDGETS = 'SET_ACCESSIBLE_WIDGETS'
const RESET_DASHBOARD = 'RESET_DASHBOARD'

const initialState = () => ({
  accessibleWidgets: [],
  activeDashboard: {},
  allDashboards: [],
  dashboardIsDirty: false,
  defaultDashboard: {
    isShared: false,
    name: 'Dashboard by Default',
    jsonDesign: [],
  },
  sharedDashboards: [],
  widgetsFetchDateFrom: null,
  widgetsFetchDateTo: null,
})

export const dashboard = {
  state: initialState,
  mutations: {
    [ADD_DASHBOARD](state, payload) {
      const dashboard = payload

      if (dashboard.jsonDesign.length === 0) {
        dashboard.jsonDesign = []
      } else if (typeof dashboard.jsonDesign === 'string') {
        dashboard.jsonDesign = JSON.parse(dashboard.jsonDesign)
      }

      state.allDashboards.push(dashboard)
    },
    [SET_ACCESSIBLE_WIDGETS](state, payload) {
      state.accessibleWidgets = payload
    },
    [REFRESH_ACTIVE_DASHBOARD](state) {
      if (typeof state.activeDashboard.id !== 'undefined') {
        const refreshedActiveDashboards = state.allDashboards.filter(
          (dashboard) => dashboard.id === state.activeDashboard.id
        )

        if (refreshedActiveDashboards.length) {
          state.activeDashboard = refreshedActiveDashboards[0]
        } else {
          state.activeDashboard = state.defaultDashboard
        }
      }
    },
    [SET_DASHBOARD_DIRTY](state, value) {
      state.dashboardIsDirty = value
    },
    [SET_DASHBOARD](state, payload) {
      const dashboard = payload

      if (dashboard.jsonDesign.length === 0) {
        dashboard.jsonDesign = []
      } else if (typeof dashboard.jsonDesign === 'string') {
        if (dashboard.jsonDesign === 'string') {
          // cannot parse a string so return a empty array
          dashboard.jsonDesign = []
        } else {
          dashboard.jsonDesign = JSON.parse(dashboard.jsonDesign)
        }
      }

      state.activeDashboard = dashboard

      // Replace dashboard in list
      const dashboardIndex = state.allDashboards.findIndex(
        (d) => d.id === dashboard.id
      )

      if (dashboardIndex >= 0) {
        state.allDashboards[dashboardIndex] = dashboard
      }
    },
    [SET_ACTIVE_DASHBOARD](state, id) {
      if (id === null) {
        state.activeDashboard = state.defaultDashboard
      } else {
        const dashboards = state.allDashboards.concat(state.sharedDashboards)
        const dashboard = dashboards.find((dashboard) => dashboard.id === id)

        if (dashboard) {
          state.activeDashboard = dashboard
        } else {
          state.activeDashboard = state.defaultDashboard
        }
      }
    },
    [UPDATE_ACTIVE_DASHBOARD](state, options) {
      state.activeDashboard = {
        ...state.activeDashboard,
        ...options,
      }
    },
    [REMOVE_DASHBOARD](state, id) {
      state.allDashboards = state.allDashboards.filter(
        (dashboard) => dashboard.id !== id
      )
    },
    [SET_DASHBOARDS](state, dashboards) {
      state.allDashboards = dashboards.map((dashboard) => {
        return {
          ...dashboard,
          jsonDesign:
            typeof dashboard.jsonDesign === 'string'
              ? JSON.parse(dashboard.jsonDesign)
              : dashboard.jsonDesign,
        }
      })
    },
    [SET_SHARED_DASHBOARDS](state, dashboards) {
      state.sharedDashboards = dashboards.map((dashboard) => {
        return {
          ...dashboard,
          jsonDesign:
            typeof dashboard.jsonDesign === 'string'
              ? JSON.parse(dashboard.jsonDesign)
              : dashboard.jsonDesign,
        }
      })
    },
    [ADD_WIDGET](state, widget) {
      state.activeDashboard.jsonDesign.push(widget)
    },
    [REMOVE_WIDGET](state, widget) {
      const widgetIndex = state.activeDashboard.jsonDesign.findIndex(
        (w) => w.i === widget.i
      )

      state.activeDashboard.jsonDesign.splice(widgetIndex, 1)
    },
    [UPDATE_WIDGET_OPTIONS](state, { widgetIndex, options }) {
      state.activeDashboard.jsonDesign[widgetIndex].options = {
        ...state.activeDashboard.jsonDesign[widgetIndex].options,
        ...options,
      }
    },
    [SET_WIDGETS_FETCH_DATE_FROM](state, value) {
      state.widgetsFetchDateFrom = value
    },
    [SET_WIDGETS_FETCH_DATE_TO](state, value) {
      state.widgetsFetchDateTo = value
    },
    [RESET_DASHBOARD](state) {
      Object.assign(state, initialState())
    },
  },
  actions: {
    /**
     * Action for fetching the widgets for an organization.
     *
     * @param {function} commit
     * @param {object} rootState
     * @returns {Promise<void>}
     */
    async fetchAccessibleWidgets({ commit, rootGetters }) {
      try {
        const response = await HTTP.get(
          `Widget/OrganizationTypeAccess/${rootGetters['auth/currentOrganization']?.id}`
        )
        const widgetNotFetchedButAvailableLocally = [
          'PeriodRevenue',
          'GlobalRevenue',
        ]
        const finalAccessibleWidget = [
          ...response.data.datas,
          ...widgetNotFetchedButAvailableLocally,
        ]

        commit(SET_ACCESSIBLE_WIDGETS, finalAccessibleWidget)
      } catch (error) {
        commit(SET_ACCESSIBLE_WIDGETS, [])
      }
    },

    /**
     * Action for fetching the active dashboard.
     *
     * @param {function} commit
     * @param {object} state
     * @returns {Promise<void>}
     */
    fetchActiveDashboard({ commit, state }) {
      // Default Dashboard
      let dashboard = state.defaultDashboard

      return HTTP.get(`Dashboard/Active`)
        .then((response) => {
          if (typeof response.data.datas === 'object') {
            dashboard = {
              ...response.data.datas,
            }

            if (dashboard.id === 0) {
              dashboard.name = state.defaultDashboard.name
            }
          }
        })
        .catch((error) => {
          console.error(error)

          if (state.allDashboards.length) {
            dashboard = state.allDashboards[0]
          }
        })
        .finally(() => {
          commit(SET_DASHBOARD, dashboard)
          commit(SET_DASHBOARD_DIRTY, false)
        })
    },

    /**
     * Action for fetching the dashboards the user own.
     *
     * @param {function} commit
     * @param {object} state
     * @returns {Promise<void>}
     */
    async fetchOwnedDashboards({ commit }) {
      let ownedDashboards = []

      try {
        const response = await HTTP.get(`Dashboard/Owns`)

        if (Array.isArray(response.data.datas)) {
          ownedDashboards = response.data.datas
        }
      } catch (error) {
        console.error(error)
      } finally {
        commit(SET_DASHBOARDS, ownedDashboards)
      }
    },

    /**
     * Action for fetching all the shared dashboards.
     *
     * @param {function} commit
     * @param {object} state
     * @returns {Promise<void>}
     */
    async fetchSharedDashboards({ commit }) {
      commit(SET_SHARED_DASHBOARDS, [])

      try {
        const response = await HTTP.get(`Dashboard/Shared`)

        commit(SET_SHARED_DASHBOARDS, response.data.datas)
      } catch (error) {
        console.error(error)
      }
    },

    /**
     * Action for setting the active dashboard based on the id in the payload.
     */
    async setActiveDashboard({ commit }, payload) {
      const id = payload !== null ? payload.id : 0

      return HTTP.put(`Dashboard/ChangeActive/${id}`)
        .then((response) => {
          commit(SET_ACTIVE_DASHBOARD, response.data.datas.id)
          commit(SET_DASHBOARD, response.data.datas)
        })
        .catch(() => {
          commit(SET_DASHBOARD, null)
        })
        .finally(() => {
          commit(SET_DASHBOARD_DIRTY, false)
        })
    },

    /**
     * Action for updating the dashboards provided on the parameters.
     *
     * @param {function} commit
     * @param {function} dispatch
     * @param {object} state
     * @param {array} dashboards
     * @returns {Promise<void>}
     */
    async updateDashboards({ commit, dispatch }, dashboards) {
      for (const dashboard of dashboards) {
        const payload = dashboard

        payload.jsonDesign = JSON.stringify(payload.jsonDesign)

        try {
          await HTTP.put(`Dashboard/Save`, payload)
        } catch (error) {
          console.error(error)
        }
      }

      commit(SET_DASHBOARDS, dashboards)
      commit(REFRESH_ACTIVE_DASHBOARD)
      dispatch('persistDashboardsLocally')
    },

    /**
     * Action for saving a dashboard based on a form object in parameters.
     *
     * @param {function} commit
     * @param {function} dispatch
     * @param {object} state
     * @param {object} form
     * @returns {Promise<void>}
     */
    async saveDashboard({ commit, dispatch, state }, form) {
      const updatedDashboard = {
        ...state.activeDashboard,
        ...form,
      }

      updatedDashboard.jsonDesign = JSON.stringify(updatedDashboard.jsonDesign)

      try {
        const response = await HTTP.put(`Dashboard/Save`, updatedDashboard)

        if (typeof response.data.datas === 'object') {
          commit(SET_DASHBOARD, response.data.datas)
        }
      } catch (error) {
        //
      } finally {
        dispatch('persistDashboardsLocally')
        commit(SET_DASHBOARD_DIRTY, false)
      }
    },

    /**
     * Action for persisting a dashboard locally for future reuse.
     *
     * @param {object} state
     * @returns {Promise<void>}
     */
    persistDashboardsLocally({ state }) {
      window.localStorage.setItem(
        'dashboards',
        JSON.stringify(state.allDashboards)
      )
    },

    /**
     * Action for saving the dashboard "as" with a given name.
     *
     * @param {function} commit
     * @param {function} dispatch
     * @param {object} state
     * @param {string} name
     * @param {boolean} isShared
     * @param {object} jsonDesign
     * @returns {Promise<void>}
     */
    async saveDashboardAs(
      { commit, dispatch },
      { name, isShared, jsonDesign }
    ) {
      const dashboardData = {
        isShared: isShared,
        name: name,
        jsonDesign: JSON.stringify(jsonDesign),
      }

      try {
        const response = await HTTP.post(`Dashboard/SaveAs`, dashboardData)

        commit(ADD_DASHBOARD, response.data.datas)
        commit(SET_ACTIVE_DASHBOARD, response.data.datas.id)
      } catch (error) {
        // Alert error
      } finally {
        dispatch('persistDashboardsLocally')
        commit(SET_DASHBOARD_DIRTY, false)
      }
    },

    async removeDashboard({ commit, dispatch, state }, dashboard) {
      try {
        await HTTP.delete(`Dashboard`, {
          params: {
            dashboardId: dashboard.id,
          },
        })
      } catch (error) {
        console.error(error)
      } finally {
        commit(REMOVE_DASHBOARD, dashboard.id)
      }

      if (state.allDashboards.length === 0) {
        commit(SET_DASHBOARD, state.defaultDashboard)
      }

      dispatch('persistDashboardsLocally')
      commit(SET_DASHBOARD_DIRTY, false)
    },

    async getDashboardWidget({ rootGetters, state }, widget) {
      const widgetTemplate = dashboardWidgetsTemplates[widget.type]

      if (!widgetTemplate) return

      const params = {
        organizationId: rootGetters['auth/currentOrganization']?.id,
        country: widget.options.country,
        currencyCode: widget.options.currency,
        dateFrom: format(state.widgetsFetchDateFrom, formats.ISO.date),
        dateTo: format(state.widgetsFetchDateTo, formats.ISO.date),
      }

      try {
        if (Array.isArray(widgetTemplate.endpoint)) {
          const promises = widgetTemplate.endpoint.map((endpoint) =>
            HTTP.get(`${endpoint}`, {
              params,
            })
          )

          const response = []

          await Promise.allSettled(promises).then((result) => {
            response.push(
              ...result.map(({ value }) => value && value?.data?.datas)
            )
          })

          return response
        }

        const response = await HTTP.get(`${widgetTemplate.endpoint}`, {
          params,
        })

        return response.data.datas
      } catch (error) {
        console.error(error)
      }
    },

    async setDashboardWidgets({ commit }, widgets) {
      // Update current dashboard widgets
      commit(UPDATE_ACTIVE_DASHBOARD, { jsonDesign: widgets })
    },

    updateDashboardDirty({ commit }, value) {
      commit(SET_DASHBOARD_DIRTY, value)
    },

    async setupDashboardPeriod({ commit }) {
      let widgetsFetchDateFrom = window.localStorage.getItem(
        'widgetsFetchDateFrom'
      )

      if (widgetsFetchDateFrom === null) {
        // Setup default value
        widgetsFetchDateFrom = subDays(startOfDay(new Date()), 30)
      } else {
        widgetsFetchDateFrom = new Date(widgetsFetchDateFrom)
      }
      commit(SET_WIDGETS_FETCH_DATE_FROM, widgetsFetchDateFrom)

      let widgetsFetchDateTo = window.localStorage.getItem('widgetsFetchDateTo')

      if (widgetsFetchDateTo === null) {
        // Setup default value
        widgetsFetchDateTo = startOfDay(new Date())
      } else {
        widgetsFetchDateTo = new Date(widgetsFetchDateTo)
      }
      commit(SET_WIDGETS_FETCH_DATE_TO, widgetsFetchDateTo)
    },

    selectDashboardPeriod({ commit }, { start, end }) {
      if (start instanceof Date) {
        commit(SET_WIDGETS_FETCH_DATE_FROM, start)
        window.localStorage.setItem('widgetsFetchDateFrom', start)
      } else {
        commit(SET_WIDGETS_FETCH_DATE_FROM, null)
        window.localStorage.removeItem('widgetsFetchDateFrom')
      }

      if (end instanceof Date) {
        commit(SET_WIDGETS_FETCH_DATE_TO, end)
        window.localStorage.setItem('widgetsFetchDateTo', end)
      } else {
        commit(SET_WIDGETS_FETCH_DATE_TO, null)
        window.localStorage.removeItem('widgetsFetchDateTo')
      }
    },
  },
  getters: {},
}
