<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import type { RouteLocationRaw } from 'vue-router'
import { toRoute } from '@/helpers/route'
import { userCan } from '@/helpers/auth'
import InputOpenWatcher from '@/components/InputOpenWatcher.vue'
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'

interface Item {
  key: string | number
  to?: RouteLocationRaw
  href?: string
  selected?: boolean
  permission?: string | Array<string>
  attrs?: ItemAttributes
  divider?: boolean
  icon?: string
  label: string
}

interface ItemAttributes {
  to?: RouteLocationRaw
  href?: string
  class?: string | object | Array<string>
  onClick?: (e: Event) => void
}

interface MenuItemScope {
  active?: boolean
  disabled?: boolean
}

type MenuPosition = 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'

const emit = defineEmits<{
  (e: 'click', item: Item): void
  (e: 'toggle', toggle: boolean): void
}>()

const props = defineProps({
  items: {
    type: Array as () => Array<Item>,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  position: {
    type: String as () => MenuPosition,
    default: 'bottom-right',
  },
})

const filteredItems = computed(() => {
  return props.items.filter((item) => userCan(item.permission))
})

const positionClass = computed(() => {
  if (['top', 'right'].every((position) => props.position.includes(position))) {
    return 'tw-right-0 tw-origin-bottom-right'
  }
  if (
    ['bottom', 'left'].every((position) => props.position.includes(position))
  ) {
    return 'tw-left-0 tw-origin-top-left'
  }
  if (['top', 'left'].every((position) => props.position.includes(position))) {
    return 'tw-left-0 tw-origin-bottom-left'
  }

  return 'tw-right-0 tw-origin-top-right'
})

const handleToggle = (value: boolean): void => {
  emit('toggle', value)
}

const menuItemAttributes = (
  item: Item,
  scope: MenuItemScope = {}
): ItemAttributes => {
  const attributes = {
    class:
      'tw-flex tw-w-full tw-px-4 tw-py-2 tw-text-left tw-uppercase tw-text-xs tw-font-medium hover:tw-bg-alto-light focus:tw-bg-alto-light focus-visible:tw-bg-alto-light focus-within:tw-bg-alto-light',
    ...item.attrs,
  }

  if (scope.active) {
    attributes.class += ' tw-bg-alto-light'
  }

  if (item.selected) {
    attributes.class += ' tw-bg-alto-light tw-text-primary'
  } else {
    attributes.class += ' tw-text-grey-medium'
  }

  if (item.to) {
    attributes.to = toRoute(item.to)
  } else if (item.href) {
    attributes.href = item.href
  } else {
    attributes.onClick = () => emit('click', item)
  }

  return attributes
}
</script>

<template>
  <Menu
    v-slot="{ open }"
    as="div"
    class="tw-relative tw-inline-block tw-text-left"
  >
    <slot
      name="button"
      v-bind="{ open }"
    >
      <MenuButton v-slot="{ open }">
        <InputOpenWatcher
          :is-open="open"
          @toggle="handleToggle"
        />
        <slot v-bind="{ open }">{{ props.label }}</slot>
      </MenuButton>
    </slot>
    <transition
      enter-active-class="tw-transition tw-duration-200 tw-ease-out"
      enter-from-class="tw-translate-y-1 tw-opacity-0"
      enter-to-class="tw-translate-y-0 tw-opacity-100"
      leave-active-class="tw-transition tw-duration-150 tw-ease-in"
      leave-from-class="tw-translate-y-0 tw-opacity-100"
      leave-to-class="tw-translate-y-1 tw-opacity-0"
    >
      <MenuItems
        class="tw-absolute tw-z-10 tw-w-56 tw-transform tw-divide-gray-100 tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
        :class="[positionClass]"
      >
        <slot
          name="prepend"
          v-bind="{ open }"
        />
        <div class="tw-max-h-60 tw-overflow-auto tw-py-1">
          <slot name="items">
            <template
              v-for="item in filteredItems"
              :key="`${item.key}`"
            >
              <slot
                name="item"
                v-bind="{ item }"
              >
                <MenuItem
                  v-slot="scope"
                  as="template"
                >
                  <template v-if="item.divider">
                    <div
                      class="tw-h-px tw-w-full tw-border-b tw-border-border-light"
                    ></div>
                  </template>
                  <component
                    :is="item.to ? 'RouterLink' : item.href ? 'a' : 'button'"
                    v-else
                    v-bind="{ ...menuItemAttributes(item, scope) }"
                  >
                    <div>{{ item.label }}</div>
                    <Icon
                      v-if="item.icon"
                      :name="item.icon"
                      class="tw-ml-auto"
                    />
                  </component>
                </MenuItem>
              </slot>
            </template>
            <template v-if="items.length === 0">
              <MenuItem
                as="div"
                disabled
              >
                <div
                  class="tw-w-full tw-px-4 tw-py-2 tw-text-center tw-text-xs tw-font-medium tw-uppercase tw-text-grey-medium tw-opacity-50"
                >
                  No Results
                </div>
              </MenuItem>
            </template>
          </slot>
        </div>
        <slot name="append" />
      </MenuItems>
    </transition>
  </Menu>
</template>
