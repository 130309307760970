import _debounce from 'lodash/debounce'
import { sortList } from '@/helpers/sort'
import { searchList } from '@/helpers/search'

import advertiserEntity from '@/entity/advertiser.js'
import agencyEntity from '@/entity/agency.js'
import campaignEntity from '@/entity/campaign.js'
import campaignFlightEntity from '@/entity/campaign-flight.js'
import campaignBudgetGroupingEntity from '@/entity/campaign-budget-grouping.js'
import creativeLibraryEntity from '@/entity/creative-library'
import campaignWithFlightEntity from '@/entity/campaign-with-flight.js'
import dealEntity from '@/entity/deal.js'
import magicTokenForecastRow from '@/entity/magic-token-forecast-row.js'
import magicTokenCampaignReportRow from '@/entity/magic-token-campaign-report-row.js'
import orderEntity from '@/entity/order.js'
import orderFlightEntity from '@/entity/order-flight.js'
import orderBudgetGroupingEntity from '@/entity/order-budget-grouping.js'
import userEntity from '@/entity/user.js'

import ListingHeader from '@/components/list/header.vue'
import ListingRow from '@/components/list/row.vue'
import LoadMore from '@/components/list/load-more.vue'

export default {
  components: {
    ListingHeader,
    ListingRow,
    LoadMore,
  },

  data() {
    return {
      searchFields: ['id', 'name'],
      isHeaderFixed: false,
      highlightedItems: [],
      openedItems: [],
      searchedItems: [],
    }
  },

  computed: {
    /** Applies filters & sorting to set of items */
    filteredItems() {
      let items = this.items.slice()

      this.entity.list.filters.map(({ key, value }) => {
        if (Object.keys(this.entity.list.filtersCallbacks).includes(key)) {
          items = items.filter((item) =>
            this.entity.list.filtersCallbacks[key](item, value)
          )
        }
      })

      return sortList(
        items,
        this.entity.list.sortKey,
        this.entity.list.sortOrder
      )
    },

    paginatedList() {
      if (!('pagination' in this.entity.list) || !this.filteredItems.length)
        return this.filteredItems

      return this.filteredItems.slice(
        0,
        this.entity.list.pagination.perPage * this.entity.list.pagination.page
      )
    },

    /** Generates Grid Style specific to a given entity */
    gridStyle() {
      const pageCount = this.entity.list.headers.reduce((maxPage, header) => {
        return Math.max(maxPage, Math.max(...header.pages))
      }, 0)

      const gridStyle = {}

      for (let page = 1; page <= pageCount; page++) {
        gridStyle[`--grid-template-columns-page-${page}`] =
          this.entity.list.headers
            .filter((header) => header.pages.includes(page))
            .map((header) => header.size)
            .join(' ')
      }

      gridStyle['--grid-template-columns-desktop'] = this.entity.list.headers
        .map((header) => header.size)
        .join(' ')

      return gridStyle
    },

    /** Returns the items stored in the state */
    items() {
      return this.$store.state[this.entity.module].list
    },

    userCanViewEntity() {
      return this.userCan(`View${this.entity.permissionKey}`)
    },

    userCanCreateEntity() {
      return this.userCan(`Create${this.entity.permissionKey}`)
    },

    userCanUpdateEntity() {
      return this.userCan(`Edit${this.entity.permissionKey}`)
    },
  },

  methods: {
    /** Debounce the fetch data method */
    debouncedFetchData: _debounce(function (payload) {
      this.fetchData(payload)
    }, 500),

    getItemEntity(item) {
      switch (item.entity) {
        case 'Advertiser':
          return advertiserEntity
        case 'Agency':
          return agencyEntity
        case 'Campaign':
          return campaignEntity
        case 'CampaignFlight':
        case 'ControlFlight':
          return campaignFlightEntity
        case 'CampaignBudgetGrouping':
        case 'ControlBudgetGrouping':
          return campaignBudgetGroupingEntity
        case 'CampaignBudgetGroupingFlight':
        case 'ControlBudgetGroupingFlight':
          return campaignFlightEntity
        case 'CampaignWithFlight':
          return campaignWithFlightEntity
        case 'CreativeLibrary':
          return creativeLibraryEntity

        case 'Deal':
          return dealEntity
        case 'Order':
          return orderEntity
        case 'OrderFlight':
        case 'ControlOrderFlight':
          return orderFlightEntity
        case 'OrderBudgetGrouping':
        case 'ControlOrderBudgetGrouping':
          return orderBudgetGroupingEntity
        case 'OrderBudgetGroupingFlight':
        case 'ControlOrderBudgetGroupingFlight':
          return orderFlightEntity
        case 'MagicTokenCampaignReportRow':
          return magicTokenCampaignReportRow
        case 'MagicTokenForecastRow':
          return magicTokenForecastRow
        case 'User':
          return userEntity
        default:
          return
      }
    },

    /** Fetch the data from the store */
    fetchData({ load = true } = {}) {
      if (load) {
        this.entity.list.loading = true
      }
      this.entity.list.shouldRetry = false
      this.openedItems = []

      let action = [`${this.entity.module}/list`]

      const search = this.entity.list.search.trim()

      if (
        (search && search.length > 2) ||
        (search.length === 2 && Number(search))
      ) {
        action = [
          `${this.entity.module}/search`,
          { search, fields: this.searchFields },
        ]

        this.findHighlightedItems()
      }

      return this.$store
        .dispatch(...action)
        .catch(() => {
          this.entity.list.shoudRetry = true
        })
        .finally(() => {
          this.entity.list.loading = false
          this.findHighlightedItems()
        })
    },

    /** Apply status from header */
    handleApplyStatus(selected) {
      this.handleFilter({ key: 'status', value: selected })
    },

    /** Clears a given filter */
    handleClearFilter(key) {
      const filterIndex = this.entity.list.filters.findIndex(
        (filter) => filter.key === key
      )

      this.entity.list.filters.splice(filterIndex, 1)

      localStorage.setItem(
        `${this.entity.module}-list-filters`,
        JSON.stringify(this.entity.list.filters)
      )
    },

    /** Handle a Click Event on a Row */
    handleClick({ item, key, refresh }) {
      if (!key) {
        return
      }

      try {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
        const methodName = `handleClick${capitalizedKey}`

        this[methodName](item, refresh)
      } catch (error) {
        // this.$bugsnag.notify(error, function (event) {
        //   event.addMetaData('item', item)
        //   event.addMetaData('key', key)
        //   event.addMetaData('method', methodName)
        // })
      }
    },

    handleClickArchive(item, refresh) {
      const entity = this.getItemEntity(item)

      this.$store.dispatch('popup/show', {
        name: 'PopupEntityAction',
        props: {
          content: {
            button: 'Archive',
            description: entity.getDescription(item),
            title: `Are you sure you want to archive this ${entity.singular}?`,
          },
          resolve: () => {
            return this.$store
              .dispatch(`${entity.module}/archive`, { id: item.id })
              .then(() => {
                this.handleRefreshData({ item, refresh })
              })
              .then(() => {
                this.fetchData({ load: false })
                this.$store.dispatch('addToast', {
                  type: 'info',
                  value: `${entity.singular} ${item.id} successfully archived`,
                })
              })
              .catch(() => {
                throw Error(`${entity.singular} could not be archived.`)
              })
          },
        },
      })
    },

    handleClickCopyWorkId(item) {
      this.handleClipboardCopy(item.workId)
    },

    handleClickDelete(item, refresh) {
      const entity = this.getItemEntity(item)

      this.$store.dispatch('popup/show', {
        name: 'PopupEntityAction',
        props: {
          content: {
            button: 'Delete',
            description: entity.getDescription(item),
            title: `Are you sure you want to delete this ${entity.singular}?`,
          },
          resolve: async () => {
            try {
              await this.$store.dispatch(`${entity.module}/delete`, {
                id: item.id,
              })

              this.handleRefreshData({ item, refresh })

              this.$store.dispatch('addToast', {
                type: 'info',
                value: `${entity.singular} ${item.id} successfully deleted`,
              })
            } catch (e) {
              throw Error(`${entity.singular} could not be deleted.`)
            }
          },
        },
      })
    },

    /** Handle entity duplication */
    handleClickDuplicate(item, refresh) {
      const entity = this.getItemEntity(item)

      if (entity.singular === 'Flight') {
        this.$store.dispatch('popup/show', {
          name: 'PopupDuplicateFlights',
          props: {
            entity,
            flight: item,
            resolve: async () => {
              try {
                this.handleRefreshData({ item, refresh })
              } catch (e) {
                throw Error(`${entity.singular} could not be duplicated.`)
              }
            },
          },
        })
      } else {
        this.$store.dispatch('popup/show', {
          name: 'PopupEntityAction',
          props: {
            content: {
              button: 'Duplicate',
              description: entity.getDescription(item),
              title: `Are you sure you want to duplicate this ${entity.singular}?`,
            },
            resolve: async () => {
              try {
                const response = await this.$store.dispatch(
                  `${entity.module}/duplicate`,
                  {
                    id: item.id,
                  }
                )

                if (response) {
                  switch (entity.module) {
                    case 'campaign':
                      this.$router.push(
                        this.toRoute({
                          name: 'EditCampaign',
                          params: {
                            campaign: response.id,
                          },
                        })
                      )
                      break
                    case 'order':
                      this.$router.push(
                        this.toRoute({
                          name: 'EditOrder',
                          params: {
                            order: response.id,
                          },
                        })
                      )
                      break
                    case 'campaignBudgetGrouping':
                      this.$router.push(
                        this.toRoute({
                          name: 'EditCampaignBudgetGrouping',
                          params: {
                            campaign: response.baseCampaignId,
                            campaignBudget: response.id,
                          },
                        })
                      )
                      break
                    case 'orderBudgetGrouping':
                      this.$router.push(
                        this.toRoute({
                          name: 'EditOrderBudgetGrouping',
                          params: {
                            order: response.baseCampaignId,
                            orderBudget: response.id,
                          },
                        })
                      )

                      break
                    default:
                      this.handleRefreshData({ item, refresh })
                  }
                }

                this.$store.dispatch('addToast', {
                  type: 'info',
                  value: `${entity.singular} ${item.id} successfully duplicated`,
                })
              } catch (e) {
                throw Error(`${entity.singular} could not be duplicated.`)
              }
            },
          },
        })
      }
    },

    /** Handle entity quick edit */
    handleClickEdit(item) {
      this.slideOvers[item.entity] = {
        ...this.slideOvers[item.entity],
        currentId: item.id,
        isClosed: false,
        isVisible: true,
      }
    },

    handleClickPause(item, refresh) {
      const entity = this.getItemEntity(item)

      this.$store.dispatch('popup/show', {
        name: 'PopupEntityAction',
        props: {
          content: {
            button: 'Pause',
            description: entity.getDescription(item),
            title: `Are you sure you want to pause this ${entity.singular}?`,
          },
          resolve: async () => {
            try {
              await this.$store.dispatch(`${entity.module}/pause`, {
                id: item.id,
              })

              this.handleRefreshData({ item, refresh })

              this.$store.dispatch('addToast', {
                type: 'info',
                value: `${entity.singular} ${item.id} successfully paused`,
              })
            } catch (e) {
              throw Error(`${entity.singular} could not be paused.`)
            }
          },
        },
      })
    },

    handleClickResume(item, refresh) {
      const entity = this.getItemEntity(item)

      this.$store.dispatch('popup/show', {
        name: 'PopupEntityAction',
        props: {
          content: {
            button: 'Resume',
            description: entity.getDescription(item),
            title: `Are you sure you want to resume this ${entity.singular}?`,
          },
          resolve: async () => {
            try {
              await this.$store.dispatch(`${entity.module}/resume`, {
                id: item.id,
              })

              this.handleRefreshData({ item, refresh })

              this.$store.dispatch('addToast', {
                type: 'info',
                value: `${entity.singular} ${item.id} successfully resumed`,
              })
            } catch (e) {
              throw Error(`${entity.singular} could not be resumed.`)
            }
          },
        },
      })
    },

    handleClickToggle(item) {
      const index = this.openedItems.indexOf(item.id)

      if (index >= 0) {
        this.openedItems.splice(index, 1)
      } else {
        this.openedItems.push(item.id)
      }
    },

    /** Handle Filter from the header */
    handleFilter({ key, direction, value }) {
      if (direction) {
        this.handleSort({ key, direction })
      }

      const filterIndex = this.entity.list.filters.findIndex(
        (filter) => filter.key === key
      )

      if (value !== null) {
        if (filterIndex >= 0) {
          const filters = this.entity.list.filters

          filters[filterIndex].value = value

          this.entity.list = {
            ...this.entity.list,
            filters,
          }
        } else {
          this.entity.list.filters.push({ key, value })
        }
      } else if (filterIndex >= 0) {
        this.entity.list.filters.splice(filterIndex, 1)
      }

      localStorage.setItem(
        `${this.entity.module}-list-filters`,
        JSON.stringify(this.entity.list.filters)
      )
    },

    handleClearFilters() {
      this.entity.list.filters = []

      localStorage.setItem(
        `${this.entity.module}-list-filters`,
        JSON.stringify(this.entity.list.filters)
      )
    },

    async handleRefreshData({ item, refresh }) {
      if (
        [
          'CampaignFlight',
          'CampaignBudgetGrouping',
          'CampaignBudgetGroupingFlight',
          'OrderFlight',
          'OrderBudgetGrouping',
          'OrderBudgetGroupingFlight',
        ].includes(item.entity)
      ) {
        await refresh()
      } else {
        await this.fetchData({ load: false })
      }
    },

    /** Handle new Search input */
    handleSearch(value) {
      this.entity.list.search = value
      this.debouncedFetchData()
    },

    findHighlightedItems() {
      const search = this.entity.list.search.trim()

      search.split(' ').map((searchTerm) => {
        return searchTerm
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      })

      this.highlightedItems = []

      if (['Campaign'].includes(this.entity.key) && this.listSearch) {
        const listItems = this.items.flatMap(
          ({ id, notBudgetGrouping = [], budgetGrouping = [] }) => {
            return [...budgetGrouping, ...notBudgetGrouping].flatMap((item) => {
              const { flights = [] } = item || {}

              return [
                ...flights.map((item) =>
                  Object.assign(item, { campaignId: id })
                ),
                item,
              ]
            })
          }
        )

        const _searchedItems = searchList(
          listItems,
          ['name', 'id'],
          this.listSearch
        )

        _searchedItems.forEach(({ campaignId, id }) => {
          if (this.openedItems.includes(campaignId) === false) {
            this.openedItems.push(campaignId)
            this.searchedItems.push(campaignId)
          }
          this.highlightedItems.push(id)
        })
      }
    },

    /** Applies Sorting to an entity */
    handleSort({ key }) {
      if (this.entity.list.sortKey === key) {
        switch (this.entity.list.sortOrder) {
          case null:
            this.entity.list.sortOrder = 'desc'
            break
          case 'asc':
            this.entity.list.sortOrder = null
            this.entity.list.sortKey = null
            break
          case 'desc':
            this.entity.list.sortOrder = 'asc'
            break
        }
      } else {
        this.entity.list.sortKey = key
        this.entity.list.sortOrder = 'desc'
      }

      if (['pacing', 'progression'].includes(key)) {
        const headerKey = this.entity.list.headers.findIndex(
          (header) => header.key === 'pacingOrProgression'
        )

        if (headerKey >= 0) {
          this.entity.list.headers[headerKey].view = key
        }
      }

      localStorage.setItem(
        `${this.entity.module}-sort`,
        JSON.stringify({
          key: this.entity.list.sortKey,
          order: this.entity.list.sortOrder,
        })
      )
    },
  },
}
