import { h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { RouterView } from 'vue-router'
import campaignBudgetGroupingFlights from './campaign-budget-grouping-flights'
import campaignBudgetGroupings from './campaign-budget-groupings'
import campaignFlights from './campaign-flights'

export default [
  {
    path: '/:organization?/campaigns',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'Campaigns',
        component: () => import('@/views/campaign/index.vue'),
        meta: {
          title: 'Passport | List of Campaigns',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'create',
        name: 'CreateCampaign',
        component: () => import('@/views/campaign/edit.vue'),
        meta: {
          title: 'Passport | Create Campaign',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: ':campaign/control',
        name: 'CampaignControl',
        component: () => import('@/views/campaign/control.vue'),
        meta: {
          title: 'Passport | Campaign Flights & Budget Groupings List',
          layout: 'LayoutAuthenticated',
          redirectPath: '/campaigns',
          requiresAuth: true,
        },
      },
      {
        path: ':campaign/list-creatives',
        name: 'CampaignCreatives',
        component: () => import('@/views/campaign/creatives.vue'),
        meta: {
          title: 'Passport | Campaign Creatives List',
          layout: 'LayoutAuthenticated',
          redirectPath: '/campaigns',
          requiresAuth: true,
        },
      },
      {
        path: ':campaign/report',
        name: 'CampaignReport',
        component: () => import('@/views/report/index.vue'),
        meta: {
          title: 'Passport | Campaign Report',
          layout: 'LayoutAuthenticated',
          redirectPath: '/campaigns',
          requiresAuth: true,
        },
      },
      {
        path: ':campaign/creatives/:campaignCreative',
        name: 'EditCampaignCreative',
        component: () => import('@/views/campaign/edit.vue'),
        meta: {
          title: 'Passport | Edit Campaign Creative',
          layout: 'LayoutAuthenticated',
          redirectPath: '/campaigns',
          requiresAuth: true,
        },
        props: () => ({ tabSelected: 'creatives' }),
      },
      {
        path: ':campaign/:tab?',
        name: 'EditCampaign',
        component: () => import('@/views/campaign/edit.vue'),
        meta: {
          title: 'Passport | Edit Campaign',
          layout: 'LayoutAuthenticated',
          redirectPath: '/campaigns',
          requiresAuth: true,
        },
        props: (route) => ({ tabSelected: route.params.tab || 'info' }),
      },
      ...campaignBudgetGroupingFlights,
      ...campaignBudgetGroupings,
      ...campaignFlights,
    ],
  },
] as RouteRecordRaw[]
