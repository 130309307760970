<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Do you want to convert this order budget?
      </h2>
    </div>
    <div class="tw-border-b tw-border-border-light tw-p-6">
      This action will convert this order budget. Are you sure?
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-border-light tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        v-if="!error"
        :busy="isLoading"
        label="Yes, Convert"
        type="button"
        variant="primary"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  name: 'PopupOrderBudgetConfirmConversion',
  components: { PassportButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],

  data() {
    return {
      isLoading: false,
      shouldRetry: false,
      error: '',
    }
  },

  methods: {
    async handleSubmit() {
      if (this.error) {
        this.$emit('close')
        return
      }

      this.error = ''
      this.isLoading = true

      try {
        const response = await this.$store.dispatch(
          'orderBudgetGrouping/confirmConversion',
          { id: this.item.id }
        )

        this.$router.push(
          this.toRoute({
            name: 'EditCampaignBudgetGrouping',
            params: {
              campaign: response.data.datas.baseCampaignId,
              campaignBudget: response.data.datas.id,
            },
          })
        )

        this.$store.dispatch('addToast', {
          type: 'info',
          value: 'Order successfully converted',
        })

        this.$emit('close')
      } catch (error) {
        if (error.response?.data?.errors?.length) {
          this.error = error.response.data.errors[0].message
        } else if (error.message) {
          this.error = error.message
        } else {
          this.error =
            "An error occurred, we couldn't proceed with your conversion confirmation. Please try again later. (Only an AdTrafficker person is allowed to do so)"
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
