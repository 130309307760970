import store from '@/store/index'
import { formatDate } from '@/helpers/date'
import { formatCurrency, formatNumber } from '@/helpers/number'
import { canSeeElk, elkFlightUrl } from '@/helpers/elk'

const entity = {
  absoluteKey: 'CampaignFlight',
  module: 'campaignFlight',
  parentModule: 'campaign',
  key: 'CampaignFlight',
  permissionKey: 'Campaign',
  productType: 'Flight',
  route: {
    index: 'Campaigns',
    create: 'CreateCampaignFlight',
    edit: 'EditCampaignFlight',
  },
  param: 'campaignFlight',
  singular: 'Flight',
  plural: 'Flights',
  parentSingular: 'Campaign',
  parentPlural: 'Campaigns',
  statusKey: 'flightStatusType',
  statusList: ['Draft', 'Pending', 'Archived', 'Paused', 'Converted'],
  mutationsToWatch: [],
  list: {
    filters: [],
    loading: true,
    pacingOrProgression: 'pacing',
    page: 1,
    search: '',
    shouldRetry: false,
    sortKey: '',
    sortOrder: 'asc',
    statusKey: 'flightStatusType',
    headers: [
      {
        key: 'gap-1',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-20 tablet:tw-h-16 tw-bg-alto-light tw-border-l tw-border-border',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-2',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Status',
        key: 'flightStatusType',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderStatus',
        componentList: 'ListCellTypeIcon',
        icon: 'plane',
        size: '90px',
        classCell:
          'tw-h-20 tablet:tw-h-16 tw-col-span-2 tw-border-l tw-border-border tw-justify-end tw-pr-4',
        classHeader: 'tw-col-span-2',
      },
      {
        label: 'Pacing',
        key: 'pacingOrProgression',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPacingOrProgression',
        componentList: 'ListCellPacingProgression',
        view: 'pacing',
        size: 'minmax(90px, min-content)',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEntityName',
        classCell: 'tw-h-20 tablet:tw-h-16',
        size: 'minmax(100px, 1fr)',
      },
      {
        label: 'Start date',
        key: 'startDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'End date',
        key: 'endDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Delivered',
        key: 'deliveredImpressions',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Ordered',
        key: 'impressionsGoal',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Gross CPM',
        key: 'grossCpm',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellCurrency',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Budget Spread',
        key: 'budget.budgetSpreadType',
        pages: [3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellBudgetSpread',
        size: '140px',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-pl-4 tw-pr-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Creatives',
        key: 'creativesCount',
        pages: [1, 2, 3],
        heading: null,
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellCreatives',
        size: '60px',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-border-l',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell:
          'tw-h-20 tablet:tw-h-16 tw-col-span-2 tw-border-r tw-border-border',
        classHeader: 'tw-col-span-3',
      },
      {
        key: 'gap-3',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-h-20 tablet:tw-h-16 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-4',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-20 tablet:tw-h-16 tw-bg-alto-light tw-border-r tw-border-border',
        classHeader: 'tw-hidden',
      },
    ],
    filtersCallbacks: {
      status(item, selectedStatus) {
        return (
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.flightStatusType)
        )
      },
      deliveredImpressions(item, { min, max }) {
        return (
          (min !== null ? item.deliveredImpressions >= min : true) &&
          (max !== null ? item.deliveredImpressions <= max : true)
        )
      },
      impressionsGoal(item, { min, max }) {
        return (
          (min !== null ? item.impressionsGoal >= min : true) &&
          (max !== null ? item.impressionsGoal <= max : true)
        )
      },
      grossCpm(item, { min, max }) {
        return (
          (min !== null ? item.grossCpm >= min : true) &&
          (max !== null ? item.grossCpm <= max : true)
        )
      },
    },
    rowOptions: (item) => {
      const options = [
        {
          key: 'copyWorkId',
          label: `Copy Work ID (${item.workId})`,
          iconLabel: `${item.workId}`,
          text: item.workId,
          icon: 'copy',
        },
        {
          key: 'show-report',
          label: 'Show Report',
          to: {
            name: 'CampaignReport',
            params: { campaign: item.campaignId },
            query: { 'filters[flightIds]': item.id },
          },
          icon: 'report',
        },
        ...(!['Draft'].includes(item.flightStatusType) && canSeeElk()
          ? [
              {
                key: 'kibana',
                label: 'Go To Kibana',
                href: elkFlightUrl(item),
                icon: 'kibana',
                attrs: {
                  target: '_blank',
                },
              },
            ]
          : []),
        ...(item.campaignFlag !== 'SingleFlight'
          ? [
              ...(['Ready', 'Running', 'Warning', 'Delivering'].includes(
                item.flightStatusType
              )
                ? [
                    {
                      key: 'pause',
                      label: 'Pause',
                      icon: 'pause',
                    },
                  ]
                : []),
              ...(['Paused'].includes(item.flightStatusType)
                ? [
                    {
                      key: 'resume',
                      label: 'Resume',
                      icon: 'play',
                    },
                  ]
                : []),
              {
                key: 'duplicate',
                label: 'Duplicate',
                icon: 'copy',
              },
              ...(['Draft', 'Error'].includes(item.flightStatusType)
                ? [
                    {
                      key: 'delete',
                      label: 'Delete',
                      icon: 'trash',
                    },
                  ]
                : []),
              ...(!['Archived', 'Draft'].includes(item.flightStatusType)
                ? [
                    {
                      key: 'archive',
                      label: 'Archive',
                      icon: 'archive',
                    },
                  ]
                : []),
            ]
          : []),
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
        },
      ]

      return options
    },
  },
  moreActions: (item, claims) => {
    if (!item) {
      return []
    }

    const campaign = store.state.campaign.current
    const bundles = store.state.bundles.list.filter(
      (bundle) => bundle.countryCode === campaign.countryCode
    )

    if (!campaign) {
      return []
    }

    const actions = [
      {
        key: 'show-report',
        label: 'Show Report',
        to: {
          name: 'CampaignReport',
          params: { campaign: item.campaignId },
          query: { 'filters[flightIds]': item.id },
        },
        icon: 'report',
      },
      ...(!['Draft'].includes(item.flightStatusType) && canSeeElk()
        ? [
            {
              key: 'kibana',
              label: 'Go To Kibana',
              href: elkFlightUrl(item),
              icon: 'kibana',
              attrs: {
                target: '_blank',
              },
            },
          ]
        : []),
      {
        key: 'export-forecast',
        label: `Export Forecast`,
        icon: 'download',
      },
      {
        key: 'divider-2',
        divider: true,
      },
      ...(campaign.campaignFlag !== 'SingleFlight'
        ? [
            {
              key: 'duplicate',
              label: `Duplicate`,
              icon: 'copy',
            },
            ...(['Ready', 'Running', 'Warning'].includes(item.flightStatusType)
              ? [
                  {
                    key: 'pause',
                    label: `Pause`,
                    icon: 'pause',
                  },
                ]
              : []),
            ...(['Paused'].includes(item.flightStatusType)
              ? [
                  {
                    key: 'resume',
                    label: `Resume`,
                    icon: 'play',
                  },
                ]
              : []),
            ...(!['Archived', 'Draft'].includes(item.flightStatusType)
              ? [
                  {
                    key: 'archive',
                    label: `Archive`,
                    icon: 'archive',
                  },
                ]
              : []),
            {
              key: 'divider-2',
              divider: true,
            },
          ]
        : []),
      {
        key: 'load-bundle',
        label: 'Load Bundle',
        children: bundles.length
          ? bundles.map((bundle) => {
              return {
                key: 'load-bundle',
                label: bundle.name,
                id: bundle.id,
                targetingFilterId: item.targetingFilterId,
                icon: bundle.isShared ? 'gender-both' : null,
                iconTooltip: 'Shared',
              }
            })
          : [{ key: 'no-bundle', label: 'No Bundle available' }],
      },
      ...(claims.indexOf('CreateBundle') >= 0
        ? [
            {
              key: 'save-bundle',
              label: 'Save As Bundle',
            },
            {
              key: 'manage-bundles',
              label: 'Manage Bundles',
            },
          ]
        : []),
      ...(campaign.campaignFlag !== 'SingleFlight'
        ? [
            ...(['Draft', 'Error'].includes(item.flightStatusType)
              ? [
                  {
                    key: 'divider-3',
                    divider: true,
                  },
                  {
                    key: 'delete',
                    label: `Delete`,
                    icon: 'trash',
                    class: 'hover:tw-text-red',
                  },
                ]
              : []),
          ]
        : []),
    ]

    return actions
  },
  handleStoreInput(module, path, value, valueKey = 'form') {
    store.dispatch(`${module}/handleInput`, { path, value, valueKey })
  },
  getDescription(item) {
    return `<div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Name</div>
        <div class="tw-text-sm">${item.name}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Dates</div>
        <div class="tw-text-sm">${formatDate(item.startDate)} - ${
      item.endDate ? formatDate(item.endDate) : '-'
    }</div>
      </div>
      <div class="tw-grid tw-grid-cols-12 tw-gap-6">
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Ordered Impressions</div>
            <div class="tw-text-sm">${formatNumber(
              item.orderedImpressions
            )}</div>
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Gross CPM</div>
            <div class="tw-text-sm">${formatCurrency(
              item.grossCpm,
              item.currencyCode
            )}</div>
          </div>
        </div>
      </div>
    </div>`
  },
  shouldUpdateDmps(form, item) {
    if (form.startDate !== item.startDate || form.endDate !== item.endDate) {
      return true
    }

    return false
  },
}

export default entity
