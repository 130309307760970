import { defineStore } from 'pinia'
import HTTP from '@/services/http'
import { computed, onMounted, ref } from 'vue'
import { utcToZonedTime } from 'date-fns-tz'
import { useStore } from 'vuex'

interface Timezone {
  countryCode: string
  name: string
  timeZoneId: string
  gmtOffset: number
}

export const useStoreDate = defineStore('date', () => {
  const store = useStore()
  const timeZone = ref<Timezone>()
  const currentDate = ref<Date | null>(null)
  const currentCountryCode = ref<string | null>(null)

  onMounted(() => setCurrentDate)

  const dateFormat = computed<'EU' | 'US'>(() => {
    return (
      store.state.auth.user?.userPreference?.dateI18n?.toUpperCase() || 'EU'
    )
  })

  const fetchTimezone = async ({
    countryCode,
  }: {
    countryCode: string
  }): Promise<unknown> => {
    if (currentCountryCode.value === countryCode && timeZone.value) return

    currentDate.value = null
    currentCountryCode.value = countryCode

    const response = await HTTP.get<{
      name: string
      timeZones: [
        { countryCode: string; timeZoneId: string; gmtOffset: number }
      ]
    }>(`parameters/Countries/${countryCode}`)

    timeZone.value = Object.assign(
      {},
      {
        name: response.data?.name,
        ...response.data?.timeZones[0],
      }
    )

    setCurrentDate()

    return Promise.resolve(response.data)
  }

  const setCurrentDate = (): void => {
    if (timeZone.value?.timeZoneId) {
      currentDate.value = utcToZonedTime(new Date(), timeZone.value.timeZoneId)
    } else {
      currentDate.value = null
    }
  }

  setInterval(setCurrentDate, 60000)

  return {
    dateFormat,
    currentDate,
    fetchTimezone,
    timeZone,
  }
})
