import { defineStore } from 'pinia'
import HTTP from '@/services/http'
import type {
  APIPagination,
  APIResponse,
  Notification,
  NotificationStatus,
} from '@/types'
import { reactive, ref } from 'vue'

interface State {
  listSearch: string
  list: Notification[]
  pagination: APIPagination
  countUnread: number
}

export const useStoreNotifications = defineStore('notifications', () => {
  const countUnread = ref(0)
  const pagination = reactive({
    index: 0,
    size: 10,
    skip: 0,
    total: 0,
  })
  const list = ref<Notification[]>([])
  const listSearch = ref('')

  const fetchCountUnread = async (): Promise<void> => {
    const response = await HTTP.get<APIResponse<number>>(
      'notifications/count/unread',
      {
        executeInBackground: true,
      }
    )

    countUnread.value = response.data.datas
  }

  const fetchList = async (): Promise<void> => {
    const response = await HTTP.get<
      APIResponse<{
        list: State['list']
        paging: State['pagination']
      }>
    >(`notifications/list/${pagination.index}`, {
      executeInBackground: true,
    })

    const { list: notifications = [], paging } = response.data.datas

    list.value = notifications
    Object.assign(pagination, paging)
  }

  const loadMore = (): void => {
    Object.assign(pagination, { index: pagination.index + 1 })

    fetchList()
  }

  const setStatus = async ({
    status,
    notificationsIds,
  }: {
    status: NotificationStatus
    notificationsIds: Notification['id'][]
  }): Promise<void> => {
    const response = await HTTP.put<APIResponse<Notification[]>>(
      `notifications/${status}/`,
      notificationsIds,
      {
        executeInBackground: true,
      }
    )

    if (status === 'dismissed') {
      list.value = list.value.filter(({ id }) => !notificationsIds.includes(id))
    } else {
      response.data.datas.forEach(updateNotification)
    }

    fetchCountUnread()
  }

  const setAllStatus = async ({
    status,
  }: {
    status: NotificationStatus
  }): Promise<void> => {
    const response = await HTTP.put<APIResponse<Notification[]>>(
      `notifications/${status}/all`,
      null,
      {
        executeInBackground: true,
      }
    )

    if (status === 'dismissed') {
      list.value = []
    } else {
      response.data.datas.forEach(updateNotification)
    }

    fetchCountUnread()
  }

  const setUnread = async ({ id }: Notification): Promise<void> => {
    const response = await HTTP.put<APIResponse<Notification[]>>(
      `notifications/unread/${id}`,
      null,
      {
        executeInBackground: true,
      }
    )

    response.data.datas.forEach(updateNotification)
  }

  const toggleReadStatus = async (
    notification: Notification
  ): Promise<void> => {
    if (notification.readAt) {
      setUnread(notification)
    } else {
      setStatus({
        status: 'read',
        notificationsIds: [notification.id],
      })
    }

    fetchCountUnread()
  }

  const updateNotification = (notification: Notification): void => {
    const notificationIndex = list.value.findIndex(
      ({ id }) => notification.id === id
    )

    if (!notificationIndex) throw new Error('Notification not found')

    list.value[notificationIndex] = notification
  }

  return {
    pagination,
    list,
    listSearch,
    countUnread,

    fetchCountUnread,
    fetchList,
    loadMore,
    setStatus,
    setAllStatus,
    toggleReadStatus,
  }
})
