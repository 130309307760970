<script setup lang="ts">
import { handleClipboardCopy } from '@/helpers/clipboard'
import { computed } from 'vue'
import { useStore } from 'vuex'
import Icon from '@/components/Icon.vue'

const store = useStore()
const toasts = computed(() => store.state.toasts.list)

const removeToast = (id: number): void => {
  store.dispatch('removeToast', id)
}

const handleClick = (toast: string): void => {
  handleClipboardCopy(toast, false)
}
</script>

<template>
  <transition-group
    v-show="toasts.length"
    name="notification"
    class="disable-scrollbars tw-fixed tw-bottom-0 tw-left-0 tw-flex tw-max-h-screen tw-w-64 tw-flex-col tw-overflow-y-auto tw-overscroll-contain tw-text-white"
    style="z-index: 12"
    tag="ul"
  >
    <li
      v-for="toast in toasts"
      :key="toast.id"
      class="notification tw-mb-1 tw-ml-1 tw-rounded tw-p-4 tw-text-sm"
      :class="{
        'tw-bg-red': toast.type === 'error',
        'tw-bg-blue': toast.type === 'info',
        'tw-bg-orange': toast.type === 'warning',
      }"
    >
      <button
        type="button"
        class="tw-float-right tw-fill-current tw-text-white tw-outline-none hover:tw-text-gray-500"
        @click.stop="removeToast(toast.id)"
      >
        <Icon
          name="delete"
          class="tw-text-xs"
        />
      </button>
      <div
        class="tw-cursor-pointer tw-text-left tw-text-white hover:tw-text-gray-200"
        @click.stop="handleClick(toast.value)"
        @dblclick.stop="removeToast(toast.id)"
      >
        {{ toast.value }}
      </div>
    </li>
  </transition-group>
</template>
