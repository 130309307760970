<template>
  <div class="tw-w-modal-lg">
    <div v-if="magicToken">
      <div class="tw-my-6 tw-flex tw-flex-col tw-items-center">
        <Icon
          name="wand"
          class="tw-text-4xl tw-text-alto"
        />
        <h2 class="mt-8 tw-text-xl tw-text-black">
          Congratulations, the link has been generated
        </h2>
      </div>

      <div class="tw-mb-8 tw-px-6">
        <div class="tw-relative tw-flex tw-h-16 tw-max-w-full tw-items-center">
          <input
            ref="copyInput"
            :value="magicTokenLink"
            readonly
            class="tw-flex tw-h-16 tw-w-full tw-rounded-l tw-border tw-border-r-0 tw-border-border-light tw-pl-6 tw-outline-none"
            @focus="handleCopy"
          />
          <button
            type="button"
            class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-r tw-bg-primary hover:tw-bg-opacity-75"
            @click="handleCopy"
          >
            <Icon
              v-if="copied"
              name="check"
              class="tw-text-white"
            />
            <Icon
              v-else
              name="copy"
              class="tw-text-white"
            />
          </button>
        </div>
      </div>

      <div class="tw-mb-8 tw-px-6">
        <p
          v-if="magicToken.expiryDate.includes('9999-12-31')"
          class="tw-mt-4 tw-text-sm tw-text-grey"
        >
          Anyone with this link can view the report.
        </p>
        <p
          v-else
          class="tw-mt-4 tw-text-sm tw-text-grey"
        >
          Anyone with this link can view the forecast until:
          <span class="tw-font-semibold">
            {{ $filters.dateWithDay(magicToken.expiryDate) }}
          </span>
        </p>
      </div>
    </div>
    <div v-else>
      <div class="tw-my-6 tw-flex tw-flex-col tw-items-center">
        <Icon
          name="wand"
          class="tw-text-4xl tw-text-alto"
        />
        <h2 class="mt-8 tw-text-xl tw-text-black">
          Generate a Forecast Magic Token
        </h2>
      </div>

      <div class="tw-mb-8 tw-px-6">
        <div
          class="tw-flex tw-h-16 tw-max-w-full tw-items-center tw-truncate tw-rounded tw-border tw-border-border-light"
        >
          <div class="tw-border-r tw-border-border-light tw-p-5">
            <Icon
              name="stats"
              class="tw-flex-shrink-0 tw-text-xl tw-text-grey-light"
            />
          </div>
          <div class="tw-flex tw-items-center tw-px-6">
            <div class="tw-mr-2 tw-font-semibold tw-text-grey-light">
              id: {{ forecast.id }}
            </div>
            <h3 class="tw-font-semibold">
              {{ forecast.name }}
            </h3>
          </div>
        </div>
      </div>

      <div class="tw-mb-8 tw-px-6">
        <h4
          class="tw-text-md tw-border-b tw-border-black tw-font-bold tw-leading-8 tw-text-black"
        >
          Expiration
        </h4>
        <p class="tw-mt-4 tw-text-sm tw-text-grey">
          Date for this link to expire
        </p>
        <div class="tw-flex tw-items-center tw-justify-between tw-pt-5">
          <InputSelector
            v-for="preset in optionsPresets"
            :key="preset.value"
            v-model="form.preset"
            :value="preset.value"
            :label="preset.label"
            name="preset"
          />
          <div class="tw-flex tw-items-center tw-gap-2">
            <InputSelector
              v-model="form.preset"
              value="CUSTOM"
              name="preset"
            />
            <DateInput
              v-model="form.customDate"
              :with-button-apply="true"
              :min-date="new Date().toISOString()"
              label="Expiration on"
              class="-tw-mt-6 tw-w-64"
              :mode="EnumDateInputModes.DATE"
              @update:model-value="
                ($event) =>
                  $event ? (form.preset = 'CUSTOM') : (form.preset = null)
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-border-light tw-p-6"
    >
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        v-if="magicToken"
        :to="toRoute({ name: 'MagicTokens', params: { tab: 'forecast' } })"
        type="link"
        variant="stroke"
        size="small"
        label="Show Links List"
        @@click="$emit('close')"
      />
      <PassportButton
        v-else
        :busy="isLoading"
        size="small"
        variant="primary"
        type="button"
        :label="shouldRetry ? 'Retry' : 'Generate Link'"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>
<script>
import { format, addDays, parseISO } from 'date-fns'
import { formats } from '@/helpers/dateFns'
import DateInput from '@/components/Input/DateInput.vue'
import Icon from '@/components/Icon.vue'
import InputSelector from '@/components/InputSelector.vue'
import PassportButton from '@/components/PassportButton.vue'
import { EnumDateInputModes } from '@/constants/components'

export default {
  name: 'PopupMagicTokenForecast',
  components: {
    DateInput,
    Icon,
    InputSelector,
    PassportButton,
  },
  props: {
    forecast: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      copied: false,
      EnumDateInputModes,
      form: {
        customDate: null,
        preset: null,
      },
      magicToken: null,
      isLoading: false,
      optionsPresets: [
        { value: null, label: 'Never' },
        { value: 7, label: '7 days' },
        { value: 30, label: '30 days' },
      ],
      shouldRetry: false,
      step: 'settings',
    }
  },
  computed: {
    magicTokenLink() {
      if (!this.magicToken) {
        return null
      }
      return (
        window.location.origin +
        this.$router.resolve(
          this.toRoute({
            name: 'ForecastMagicToken',
            params: { uid: this.magicToken.uid },
          })
        ).href
      )
    },
  },
  methods: {
    handleCopy() {
      this.$refs.copyInput?.select()
      document.execCommand('copy')
      this.copied = true
      this.$refs.copyInput?.blur()

      setTimeout(() => {
        this.copied = false
      }, 2000)
    },
    async handleSubmit() {
      this.isLoading = true
      this.shouldRetry = false

      let expiryDate = null

      if (this.form.preset === 7) {
        expiryDate = format(addDays(new Date(), 7), formats.ISO.date)
      } else if (this.form.preset === 30) {
        expiryDate = format(addDays(new Date(), 30), formats.ISO.date)
      } else if (this.form.customDate) {
        expiryDate = format(parseISO(this.form.customDate), formats.ISO.date)
      } else {
        expiryDate = '9999-12-31'
      }

      try {
        const response = await this.$store.dispatch(
          'magicToken/createForecastMagicToken',
          { id: this.forecast.id, expiryDate }
        )

        this.magicToken = response.data.datas
      } catch (error) {
        this.shouldRetry = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
