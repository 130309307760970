<script setup lang="ts">
import type { PropType } from 'vue'
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue'

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  title: {
    type: [String, Object, Boolean, Number] as PropType<
      string | object | boolean | null | undefined | number
    >,
    default: null,
  },
})

const listener = ref<null | void>(null)
const tooltipVisibility = ref(false)
const element = ref<HTMLElement | null>(null)

onMounted(() => {
  nextTick(() => {
    detectOverflow()

    listener.value = window.addEventListener('resize', detectOverflow)
  })
})

onBeforeUnmount(() => {
  if (listener.value) window.removeEventListener('resize', listener.value)
})

const detectOverflow = (): void => {
  if (!element.value) return

  const elComputedStyle = window.getComputedStyle(element.value as Element)
  const computedWidth = Number.isNaN(
    Math.round(parseFloat(elComputedStyle.width))
  )
    ? element.value.offsetWidth
    : Math.round(parseFloat(elComputedStyle.width))

  tooltipVisibility.value = element.value.scrollWidth > computedWidth
}
</script>

<template>
  <component
    :is="props.tag"
    v-tooltip="{
      placement: 'top-start',
      content: tooltipVisibility ? props.title : null,
    }"
    ref="element"
    class="tw-overflow-hidden tw-overflow-ellipsis"
  >
    <slot />
  </component>
</template>
