import HTTP from '@/services/http'
import { searchList } from '@/helpers/search'

const getDefaultState = () => ({
  configView: null,
  list: [],
})

export default {
  namespaced: true,

  state: getDefaultState,

  mutations: {
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    async delete(_, { id, isCampaign }) {
      try {
        const url = isCampaign
          ? `MagicToken/CampaignReport/${id}`
          : `MagicToken/FlightForecast/${id}`
        const response = await HTTP.delete(url)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async list({ commit }) {
      try {
        const response = await HTTP.get(`MagicToken/List`)

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },
    async search({ commit }, search) {
      try {
        const response = await HTTP.get(`MagicToken/List`)

        for (const key in response.data.datas) {
          response.data.datas[key] = searchList(
            response.data.datas[key],
            'name',
            search
          )
        }

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },
    async changeTokenStatus(_, { id, isCampaign, isDisabled }) {
      const url = isCampaign
        ? `MagicToken/CampaignReport/EnableDisable`
        : `MagicToken/FlightForecast/EnableDisable`

      try {
        const response = await HTTP.put(url, {
          id,
          isDisabled,
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createCampaignReportMagicToken(_, { payload }) {
      try {
        const response = await HTTP.post('MagicToken/CampaignReport', payload)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateCampaignReportMagicToken(_, { payload }) {
      try {
        const response = await HTTP.put(`MagicToken/CampaignReport`, payload)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createForecastMagicToken(_, { id, expiryDate }) {
      try {
        const response = await HTTP.post('MagicToken/FlightForecast', {
          flightForecastId: id,
          expiryDate,
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
