import { canSeeElk, elkCampaignUrl } from '@/helpers/elk'
import { formatDate, isDateInPast } from '@/helpers/date'
import { formatCurrency, formatNumber } from '@/helpers/number'
import store from '@/store/index'

const listFilters = localStorage.getItem('campaign-list-filters')
const sort = localStorage.getItem('campaign-sort')

const entity = {
  absoluteKey: 'Campaign',
  module: 'campaign',
  key: 'Campaign',
  permissionKey: 'Campaign',
  productType: 'Flight',
  route: {
    index: 'Campaigns',
    create: 'CreateCampaign',
    edit: 'EditCampaign',
  },
  statusList: [
    'Archived',
    'Completed',
    'Draft',
    'Paused',
    'Pending',
    'Running',
    'Ready',
  ],
  param: 'campaign',
  singular: 'Campaign',
  plural: 'Campaigns',
  statusKey: 'campaignStatusType',
  list: {
    module: 'campaign',
    filters: listFilters ? JSON.parse(listFilters) : [],
    page: 1,
    pagination: {
      perPage: import.meta.env.DEV ? 10 : 50,
      page: 1,
    },
    loading: true,
    pacingOrProgression: 'pacing',
    search: '',
    shouldRetry: false,
    sortKey: sort ? JSON.parse(sort).key : '',
    sortOrder: sort ? JSON.parse(sort).order : 'asc',
    statusKey: 'campaignStatusType',
    headers: [
      {
        key: 'gap-1',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-2',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Status',
        key: 'campaignStatusType',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderStatusFromStore',
        componentList: 'ListCellCampaignToggle',
        size: '60px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-col-span-3 tw-rounded-tl',
        classHeader: 'tw-col-span-3',
      },
      {
        label: 'Pacing',
        key: 'pacingOrProgression',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPacingOrProgression',
        componentList: 'ListCellPacingProgression',
        size: 'minmax(90px, min-content)',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEntityName',
        size: 'minmax(100px, 1fr)',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24',
      },
      {
        label: 'Start date',
        key: 'startDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'End date',
        key: 'endDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Delivered',
        key: 'deliveredImpressions',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Ordered',
        key: 'orderedImpressions',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Gross CPM',
        key: 'grossCpm',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellCurrency',
        size: '120px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Budget Spread',
        key: 'budgetSpreadType',
        pages: [3],
        componentHeader: 'ListHeaderLabel',
        componentList: 'ListCellBudgetSpread',
        size: '140px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-pl-4 tw-pr-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Creatives',
        key: 'creativesCount',
        pages: [1, 2, 3],
        heading: null,
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellCreatives',
        size: '60px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-border-l',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-col-span-3 tw-border-r',
        classHeader: 'tw-col-span-4',
      },
      {
        key: 'gap-3',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-4',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
    ],
    filtersCallbacks: {
      accountManagerId(item, accountManagerId) {
        return (
          accountManagerId === null ||
          item.campaignAccountManager?.userId === accountManagerId
        )
      },
      adopsResponsibleId(item, adopsResponsibleId) {
        return (
          adopsResponsibleId === null ||
          item.campaignAdopsResponsible?.userId === adopsResponsibleId
        )
      },
      status(item, selectedStatus) {
        return (
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.campaignStatusType)
        )
      },
      saleUserId(item, saleUserId) {
        return (
          saleUserId === null ||
          (item.campaignSaleUsersList || []).some(
            (saleUserList) => saleUserList.userId === saleUserId
          )
        )
      },
      deliveredImpressions(item, { min, max }) {
        return (
          (min !== null
            ? parseInt(item.deliveredImpressions) >= parseInt(min)
            : true) &&
          (max !== null
            ? parseInt(item.deliveredImpressions) <= parseInt(max)
            : true)
        )
      },
      orderedImpressions(item, { min, max }) {
        return (
          (min !== null
            ? parseInt(item.orderedImpressions) >= parseInt(min)
            : true) &&
          (max !== null
            ? parseInt(item.orderedImpressions) <= parseInt(max)
            : true)
        )
      },
      grossCpm(item, { min, max }) {
        return (
          (min !== null
            ? parseFloat(item.grossCpm) >= parseFloat(min)
            : true) &&
          (max !== null ? parseFloat(item.grossCpm) <= parseFloat(max) : true)
        )
      },
    },
    rowOptions: (item) => {
      if (!item) return []

      const options = [
        {
          key: 'copyWorkId',
          label: `Copy Work ID (${item.workId})`,
          iconLabel: `${item.workId}`,
          icon: 'copy',
        },
      ]

      if (
        item.campaignFlag !== 'SingleFlight' &&
        !isDateInPast(item.endDate, item.timezones?.[0]?.timeZoneId)
      ) {
        options.push(
          {
            key: 'plane',
            label: 'Add Flight',
            to: {
              name: 'CreateCampaignFlight',
              params: { campaign: item.id },
            },
            icon: 'plane',
          },
          {
            key: 'add-budget-grouping',
            label: 'Add Budget Grouping',
            to: {
              name: 'CreateCampaignBudgetGrouping',
              params: { campaign: item.id },
            },
            icon: 'budget-grouping',
          }
        )
      }

      options.push({
        key: 'show-report',
        label: 'Show Report',
        to: { name: 'CampaignReport', params: { campaign: item.id } },
        icon: 'report',
      })

      if (canSeeElk()) {
        options.push({
          key: 'kibana',
          label: 'Go To Kibana',
          href: elkCampaignUrl(item),
          icon: 'kibana',
          attrs: {
            target: '_blank',
          },
        })
      }

      if (['Ready', 'Running', 'Warning'].includes(item.campaignStatusType)) {
        options.push({
          key: 'pause',
          label: 'Pause',
          icon: 'pause',
        })
      }

      if (['Paused'].includes(item.campaignStatusType)) {
        options.push({
          key: 'resume',
          label: 'Resume',
          icon: 'play',
        })
      }

      options.push({
        key: 'duplicate',
        label: 'Duplicate',
        icon: 'copy',
      })

      if (['Draft'].includes(item.campaignStatusType)) {
        options.push({
          key: 'delete',
          label: 'Delete',
          icon: 'trash',
        })
      }

      if (!['Archived', 'Draft'].includes(item.campaignStatusType)) {
        options.push({
          key: 'archive',
          label: 'Archive',
          icon: 'archive',
        })
      }

      options.push({
        key: 'edit',
        label: 'Edit',
        icon: 'edit',
      })

      return options
    },
  },
  mutationsToWatch: [],
  moreActions: (item) => {
    if (!item) return []

    const actions = []

    if (
      item.campaignFlag !== 'SingleFlight' &&
      !isDateInPast(item.endDate, item.timezones?.[0]?.timeZoneId)
    ) {
      actions.push(
        {
          key: 'create-flight',
          label: 'Create New Flight',
          to: { name: 'CreateCampaignFlight', id: item.id },
          icon: 'plane',
        },
        {
          key: 'create-budget-grouping',
          label: 'Create New Budget Grouping',
          to: {
            name: 'CreateCampaignBudgetGrouping',
            params: { campaign: item.id },
          },
          icon: 'budget-grouping',
        },
        {
          key: 'divider-1',
          divider: true,
        }
      )
    }

    actions.push(
      {
        key: 'show-report',
        label: 'Show Report',
        to: { name: 'CampaignReport', params: { campaign: item.id } },
        icon: 'report',
      },
      {
        key: 'export',
        label: 'Export Setup XLS',
        icon: 'download',
      },
      {
        key: 'download-logs',
        label: 'Download logs',
        icon: 'download',
      }
    )

    if (canSeeElk()) {
      actions.push({
        key: 'kibana',
        label: 'Go To Kibana',
        href: elkCampaignUrl(item),
        icon: 'kibana',
        attrs: {
          target: '_blank',
        },
      })
    }

    actions.push(
      {
        key: 'divider-2',
        divider: true,
      },
      {
        key: 'duplicate',
        label: `Duplicate`,
        icon: 'copy',
      }
    )

    if (['Ready', 'Running', 'Warning'].includes(item.campaignStatusType)) {
      actions.push({
        key: 'pause',
        label: `Pause`,
        icon: 'pause',
      })
    }

    if (['Paused'].includes(item.campaignStatusType)) {
      actions.push({
        key: 'resume',
        label: `Resume`,
        icon: 'play',
      })
    }

    if (!['Archived', 'Draft'].includes(item.campaignStatusType)) {
      actions.push({
        key: 'archive',
        label: `Archive`,
        icon: 'archive',
      })
    }

    if (['Draft'].includes(item.campaignStatusType)) {
      actions.push(
        {
          key: 'divider-2',
          divider: true,
        },
        {
          key: 'delete',
          label: `Delete`,
          icon: 'trash',
          class: 'hover:tw-text-red',
        }
      )
    }

    return actions
  },
  handleStoreInput(module, path, value, valueKey = 'form') {
    store.dispatch(`${module}/handleInput`, { path, value, valueKey })
  },
  getDescription(item) {
    return `<div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Name</div>
        <div class="tw-text-sm">${item.name}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Dates</div>
        <div class="tw-text-sm">${formatDate(item.startDate)} - ${
      item.endDate ? formatDate(item.endDate) : '-'
    }</div>
      </div>
      <div class="tw-grid tw-grid-cols-12 tw-gap-6">
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Ordered Impressions</div>
            <div class="tw-text-sm">${formatNumber(
              item.orderedImpressions
            )}</div>
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Gross CPM</div>
            <div class="tw-text-sm">${formatCurrency(
              item.grossCpm,
              item.currencyCode
            )}</div>
          </div>
        </div>
      </div>
    </div>`
  },
}

export default entity
