<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  height: {
    type: String,
    default: '1em',
  },
  width: {
    type: String,
    default: '1em',
  },
  name: {
    type: String,
    required: true,
  },
})

// eslint-disable-next-line no-undef
const version = ref(__APP_VERSION__)
</script>

<template>
  <svg
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <use
      v-if="props.name.startsWith('flag-')"
      v-bind="{
        'xlink:href': `/sprite-flags.svg?version=${version || 1}#icon-${
          props.name
        }`,
      }"
    />
    <use
      v-else
      v-bind="{
        'xlink:href': `/sprite.svg?version=${version || 1}#icon-${props.name}`,
      }"
    />
  </svg>
</template>
