export default {
  computed: {
    isPrinting() {
      return (
        (this.$route && this.$route.meta && this.$route.meta.print === true) ||
        (this.$store && this.$store.state.report.isPrinting === true)
      )
    },
  },
}
