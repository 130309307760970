export default function parseConfig(config, productTypeKey = 'FlightTypeId') {
  const fields =
    config?.FieldConfigs?.FieldConfig.reduce((obj, item) => {
      obj[item.Code] = item
      return obj
    }, {}) ?? {}

  const sections =
    config?.Sections?.Sections.reduce((obj, item) => {
      obj[item.Code] = item
      return obj
    }, {}) ?? {}

  const productNameKey = productTypeKey.replace('Id', 'Name')

  return {
    fields,
    sections,
    [productTypeKey]: config[productTypeKey],
    name: config[productNameKey],
  }
}
