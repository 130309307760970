import axios from 'axios'

const HTTP = axios.create({
  baseURL: import.meta.env.VITE_TARGETSPOT_API_BASE_URL,
})

const accessToken = localStorage.getItem('accessToken')

if (accessToken) {
  HTTP.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

export default HTTP

export const paramsSerializer = (params: {
  [key: string]: string | string[]
}): string => {
  const results = []

  for (const param in params) {
    if (Array.isArray(params[param])) {
      results.push(
        ...(params[param] as string[]).map(
          (value) => new URLSearchParams({ [param]: value })
        )
      )
    } else {
      results.push(new URLSearchParams({ [param]: params[param] as string }))
    }
  }

  return results.join('&')
}
