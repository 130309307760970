<template>
  <svg
    :style="{ width: height + 'px', height: height + 'px' }"
    viewBox="0 0 36 36"
    class="tw-block"
  >
    <path
      class="tw-fill-none tw-stroke-surface-3 tw-stroke-2"
      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path
      :stroke-dasharray="`${percentFilled}, 100`"
      :stroke="whichColor"
      style="stroke-linecap: round"
      class="circle tw-fill-none tw-stroke-[2.8]"
      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <g
      v-if="percentPacing > 0"
      :transform="`translate(${coord.x}, ${coord.y})`"
      class="tw-fill-surface-5 tw-stroke-white tw-stroke-1 tw-transition-transform tw-duration-100 tw-ease-linear"
    >
      <circle
        cx="2"
        cy="2"
        r="2.6"
      />
    </g>
    <text
      v-if="showPercentage"
      x="18.5"
      y="21"
      class="tw-text-2xs tw-font-semibold tw-text-text-1"
      style="text-anchor: middle"
    >
      {{ ~~graphView }}%
    </text>
  </svg>
</template>
<script>
/*
    radian = -angleInDegrees * Math.PI / 180.0;

    //get the cartesian x coordinate (centerX = x coordinate of the center of the circle == 36 in our case)
    x = (diameter - radius) * Math.sin(angleInRadians) * radius;

    //get the cartesian y coordinate (centerY = y coordinate of the center of the circle == 36 in our case)
    y = radius * Math.cos(angleInRadians) * radius;
  */

import { ColorMapping } from '@/constants/CampaignColors'

export default {
  name: 'GraphPacing',
  props: {
    percentFilled: {
      type: [Number, String],
      required: true,
    },
    percentPacing: {
      type: [Number, String],
      required: true,
    },
    width: {
      type: Number,
      default: 60,
    },
    height: {
      type: Number,
      default: 60,
    },
    // This property overrides the color mapping
    // useful when a campaign is paused or anything but running
    // so we don't bother computing the color
    colorOverride: {
      type: String,
      default: '',
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
    parentView: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      centerX: 15.9155, // center X of viewBox
      centerY: 15.9155, // center Y of viewBox
      radius: 15.9155, // radius of circle without border
      diameter: 31.831,
      currentView: '',
    }
  },
  computed: {
    relativePacing() {
      return (this.percentFilled * this.percentPacing) / 100
    },
    computeDegre() {
      return (Math.min(100, this.relativePacing) * 360) / 100
    },
    computeRadian() {
      return parseFloat(((-this.computeDegre * Math.PI) / 180).toFixed(4))
    },
    computeX() {
      return (
        this.diameter - this.radius - Math.sin(this.computeRadian) * this.radius
      )
    },
    computeY() {
      return this.radius - Math.cos(this.computeRadian) * this.radius
    },
    coord() {
      return { x: this.computeX, y: this.computeY }
    },
    whichColor() {
      // Color override (Campaign might be paused)
      if (this.colorOverride) {
        return this.colorOverride
      }

      // Compute color according on threshold mapping
      const pacing = parseFloat(this.percentPacing)
      let color = null

      for (const threshold in ColorMapping) {
        if (pacing >= threshold) {
          color = ColorMapping[threshold]
        }
      }

      return color
    },
    graphView() {
      return this.parentView === 'progression'
        ? this.percentFilled
        : this.percentPacing
    },
  },
}
</script>
<style lang="postcss" scoped>
.circle {
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
</style>
