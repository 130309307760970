import HTTP from '@/services/http'
import safeGet from '@/helpers/safeGet.js'
import { sortList } from '@/helpers/sort'

const GET_MAGIC_TOKENS = 'GET_MAGIC_TOKENS'
const SORT_TOKENS = 'SORT_TOKENS'
const CHANGE_TOKEN_STATUS = 'CHANGE_TOKEN_STATUS'
const DELETE_TOKEN = 'DELETE_TOKEN'
const CREATE_TOKEN = 'CREATE_TOKEN'
const EDIT_TOKEN = 'EDIT_TOKEN'
const VERIFY_FORECAST_TOKEN = 'VERIFY_FORECAST_TOKEN'
const GET_CAMPAIGN_REPORT_MAGIC_TOKEN_INFO =
  'GET_CAMPAIGN_REPORT_MAGIC_TOKEN_INFO'

const initialState = () => ({
  currentSort: undefined,
  currentSortDir: 'asc',
  magicTokenList: null,
  isGettingMagicTokens: false,
  isChangingTokenStatus: false,
  isCreatingToken: false,
  isEditingToken: false,
  isVerifyingMagicToken: false,
  forecastForm: null,
  campaignReportMagicTokenInfo: null,
  isGettingCampaignReportMagicTokenInfo: false,
})

export default {
  state: initialState,

  getters: {},

  mutations: {
    [GET_MAGIC_TOKENS](state, { data, loading }) {
      if (data) {
        state.magicTokenList = data
      }
      state.isGettingMagicTokens = loading
    },

    /* Sets current column filter to ASCENDING */
    [SORT_TOKENS](state, { table, key, dir }) {
      state.currentSortDir = dir
      state.currentSort = key
      if (table === 'campaign') {
        const campaignList = safeGet(
          state.fakeData,
          'magicTokenCampaignReportsListResponses'
        )

        campaignList &&
          campaignList.length &&
          campaignList.map((campaign) =>
            sortList(campaign.magicTokenCampaignReportResponses, key, dir)
          )
      } else {
        const forecastList = safeGet(
          state.fakeData,
          'magicTokenFlightForecastsListResponses'
        )

        forecastList &&
          forecastList.length &&
          forecastList.map((forecast) => {
            sortList(forecast.magicTokenFlightForecastResponses, key, dir)
          })
      }
    },

    [CHANGE_TOKEN_STATUS](state, { loading }) {
      state.isChangingTokenStatus = loading
    },

    [DELETE_TOKEN](state, { loading }) {
      state.isDeletingToken = loading
    },

    [CREATE_TOKEN](state, { loading }) {
      state.isCreatingToken = loading
    },

    [EDIT_TOKEN](state, { loading }) {
      state.isEditingToken = loading
    },

    [VERIFY_FORECAST_TOKEN](state, { data, loading }) {
      if (data) {
        state.forecastForm = data
      }
      state.isVerifyingMagicToken = loading
    },

    [GET_CAMPAIGN_REPORT_MAGIC_TOKEN_INFO](state, { data, loading }) {
      if (data) {
        state.campaignReportMagicTokenInfo = data
      }
      state.isGettingCampaignReportMagicTokenInfo = loading
    },
  },

  actions: {
    async getMagicTokens({ commit }) {
      commit(GET_MAGIC_TOKENS, { loading: true })
      try {
        const response = await HTTP.get(`MagicToken/List`)

        commit(GET_MAGIC_TOKENS, { data: response.data.datas, loading: false })
        return response
      } catch (error) {
        commit(GET_MAGIC_TOKENS, { loading: false })
      }
    },

    async createToken({ commit, dispatch }, { data, isCampaign }) {
      commit(CREATE_TOKEN, { loading: true })
      const url = isCampaign
        ? `MagicToken/CampaignReport`
        : `MagicToken/FlightForecast`

      try {
        const response = await HTTP.post(url, data)

        dispatch('getMagicTokens')
        commit(CREATE_TOKEN, { loading: false })
        return response
      } catch (error) {
        commit(CREATE_TOKEN, { loading: false })
      }
    },

    async editToken({ commit, dispatch }, { data, isCampaign }) {
      commit(EDIT_TOKEN, { loading: true })
      const url = isCampaign
        ? `MagicToken/CampaignReport`
        : `MagicToken/FlightForecast`

      try {
        const response = await HTTP.put(url, data)

        dispatch('getMagicTokens')
        commit(EDIT_TOKEN, { loading: false })
        return response
      } catch (error) {
        commit(EDIT_TOKEN, { loading: false })
      }
    },

    async changeTokenStatus(
      { commit, dispatch },
      { tokenId, isCampaign, isDisabled }
    ) {
      commit(CHANGE_TOKEN_STATUS, { loading: true })
      const url = isCampaign
        ? `MagicToken/CampaignReport/EnableDisable`
        : `MagicToken/FlightForecast/EnableDisable`

      try {
        const response = await HTTP.put(url, {
          id: tokenId,
          isDisabled,
        })

        dispatch('getMagicTokens')
        commit(CHANGE_TOKEN_STATUS, { loading: false })
        return response
      } catch (error) {
        commit(CHANGE_TOKEN_STATUS, { loading: false })
      }
    },

    async deleteToken({ commit, dispatch }, { tokenId, isCampaign }) {
      commit(DELETE_TOKEN, { loading: true })
      const url = isCampaign
        ? `MagicToken/CampaignReport/${tokenId}`
        : `MagicToken/FlightForecast/${tokenId}`

      try {
        const response = await HTTP.delete(url)

        dispatch('getMagicTokens')
        commit(DELETE_TOKEN, { loading: false })
        return response
      } catch (error) {
        commit(DELETE_TOKEN, { loading: false })
      }
    },

    async verifyForecastMagicToken({ commit }, { uid }) {
      commit(VERIFY_FORECAST_TOKEN, { loading: true })
      try {
        const response = await HTTP.get(`Share/FlightForecast/${uid}`)

        commit(VERIFY_FORECAST_TOKEN, {
          data: response.data.datas,
          loading: false,
        })
        return response
      } catch (error) {
        commit(VERIFY_FORECAST_TOKEN, { loading: false })
      }
    },

    async getCampaignReportMagicTokenInfo({ commit }, { uid }) {
      commit(GET_CAMPAIGN_REPORT_MAGIC_TOKEN_INFO, { loading: true })
      try {
        const response = await HTTP.get(`Share/CampaignReport/${uid}`)

        commit(GET_CAMPAIGN_REPORT_MAGIC_TOKEN_INFO, {
          data: response.data.datas,
          loading: false,
        })
        return response
      } catch (error) {
        commit(GET_CAMPAIGN_REPORT_MAGIC_TOKEN_INFO, { loading: false })
      }
    },
  },
}
