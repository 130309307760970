<template>
  <div class="tw-w-modal tw-divide-y tw-divide-border-light">
    <div class="tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">Are you sure?</h2>
    </div>
    <div class="tw-p-6">
      Are you sure you want to switch to the simple targeting? Your current
      selection will be lost if you continue.
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        type="button"
        @@click="handleCancel"
      />
      <PassportButton
        label="Yes, Switch to Simple"
        variant="primary"
        type="button"
        @@click="handleContinue"
      />
    </div>
  </div>
</template>
<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  component: {
    PassportButton,
  },
  name: 'PopupAdvancedTargetingSwitch',
  props: {
    discard: {
      type: Function,
      default: null,
    },
    resolve: {
      type: Function,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      error: '',
    }
  },
  methods: {
    async handleContinue() {
      try {
        if (this.resolve) {
          await this.resolve()
        }

        this.$emit('close')
      } catch (error) {
        this.error = this.handleError(error)
      }
    },
    async handleCancel() {
      try {
        if (this.discard) {
          await this.discard()
        }

        this.$emit('close')
      } catch (error) {
        this.error = this.handleError(error)
      }
    },
  },
}
</script>
