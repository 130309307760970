export default {
  methods: {
    getConfigField(module, field) {
      return this.safeGet(
        this.$store.state[module].configView,
        `fields.${field}`
      )
    },
    getConfigSection(module, section) {
      return this.safeGet(
        this.$store.state[module].configView,
        `sections.${section}`
      )
    },
    getFieldValidations(module, field) {
      const configField = this.getConfigField(module, field)

      return (configField?.ValidationsList?.Validations || []).reduce(
        (rules, { FirstValue, ValidationType }) => {
          rules[ValidationType.toLowerCase()] = FirstValue

          return rules
        },
        {}
      )
    },
    isFieldRequired(module, field) {
      const configField = this.getConfigField(module, field)

      return configField?.IsRequire === true ?? false
    },
    isFieldVisible(module, field) {
      const configField = this.getConfigField(module, field)

      return configField?.IsVisible === true ?? false
    },
    isSectionVisible(module, section) {
      const configSection = this.getConfigSection(module, section)

      return configSection?.IsVisible === true ?? false
    },
    configView(module, path) {
      return this.safeGet(this.$store.state[module].configView, path)
    },
  },
}
