export default (buffer, fileName, type = 'application/pdf') => {
  const blob = new Blob([buffer], { type })
  const link = document.createElement('a')

  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.click()

  window.URL.revokeObjectURL(link.href)
}
