import { computed, ref, watch } from 'vue'
import HTTP from '@/services/http'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import _debounce from 'lodash/debounce'
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns'
import { formats } from '@/helpers/dateFns'
import type {
  InventoryPeriod,
  FilterValue,
  InventoryFiltersState,
  InventoryFilters,
} from '@/types'

export enum FiltersKeys {
  AD_TRAFFICKING = 'adTraffickingId',
  AD_PLACEMENT = 'adPlacementList',
  // GEO_ITEM = 'geoItemId',
  ORGANIZATION = 'organizationId',
  CAMPAIGN_TYPE = 'campaignTypeList',
  CARRIER = 'carrierList',
  CHANNEL = 'channelId',
  CHANNEL_TYPE = 'channelTypeList',
  COUNTRY = 'countryCodeList',
  GENDER = 'genderId',
  DEVICE = 'deviceList',
  PUBLISHER = 'publisherId',
  PUBLISHER_GROUP = 'publisherGroupId',
}

const initialState: InventoryFiltersState = {
  adPlacementList: [],
  adTraffickingId: null,
  campaignTypeList: [],
  carrierList: [],
  channelId: null,
  channelTypeList: [],
  countryCodeList: [],
  deviceList: [],
  genderId: null,
  organizationId: [],
  productType: null,
  publisherGroupId: null,
  publisherId: null,
}

const filtersNumber: string[] = [
  FiltersKeys.AD_TRAFFICKING,
  FiltersKeys.AD_PLACEMENT,
  FiltersKeys.ORGANIZATION,
  FiltersKeys.CAMPAIGN_TYPE,
  FiltersKeys.CARRIER,
  FiltersKeys.CHANNEL,
  FiltersKeys.CHANNEL_TYPE,
  FiltersKeys.GENDER,
  FiltersKeys.DEVICE,
  FiltersKeys.PUBLISHER,
  FiltersKeys.PUBLISHER_GROUP,
]

const selectedFilters = ref<InventoryFiltersState>(_cloneDeep(initialState))
const selectedPeriod = ref<InventoryPeriod>({ from: null, to: null })

export const useInventoryFilters = ({
  initialFilters,
}: { initialFilters?: InventoryFilters } = {}) => {
  const setFilter = ({
    key,
    value,
  }: {
    key: keyof InventoryFiltersState
    value: FilterValue
  }): void => {
    ;(selectedFilters.value[key] as FilterValue) = value
  }

  const removeFilter = ({
    key,
  }: {
    key: keyof InventoryFiltersState
  }): void => {
    ;(selectedFilters.value[key] as FilterValue) = _cloneDeep(initialState[key])
  }

  const removeAllFilters = (): void => {
    selectedFilters.value = _cloneDeep(initialState)
  }

  const toggleFilter = ({
    key,
    value,
  }: {
    key: keyof InventoryFiltersState
    value: FilterValue
  }): void => {
    const filter = selectedFilters.value[key]

    if (Array.isArray(filter)) {
      let newValue = [...filter] as string[]

      if (filter.some((item) => String(item) === String(value))) {
        newValue = newValue.filter((item) => String(item) !== String(value))
      } else {
        newValue.push(value as string)
      }

      setFilter({ key, value: newValue })
    } else {
      const newValue = value

      if (
        isFilterSet({ key, value } as {
          key: keyof InventoryFiltersState
          value: string | number
        })
      ) {
        setFilter({ key, value: initialState[key] })
      } else {
        setFilter({ key, value: newValue })
      }
    }
  }

  const isFiltered = ({
    key,
  }: {
    key: keyof InventoryFiltersState
  }): boolean => {
    const filter = selectedFilters.value[key]

    return !_isEqual(filter, initialState[key])
  }

  const isFilterSet = ({
    key,
    value,
  }: {
    key: keyof InventoryFiltersState
    value: number | string
  }): boolean => {
    const filter = selectedFilters.value[key]

    if (Array.isArray(filter)) {
      return filter.some((v) => String(v) === String(value))
    }

    return String(filter) === String(value)
  }

  const isFilterActive = ({
    key,
    value,
  }: {
    key: keyof InventoryFiltersState
    value: number | string
  }): boolean => {
    if (!isFiltered({ key })) return true

    return isFilterSet({ key, value })
  }

  const setPeriod = ({
    from,
    to,
  }: {
    from: string | Date | null
    to: string | Date | null
  }): void => {
    selectedPeriod.value = {
      from:
        typeof from === 'string'
          ? parseISO(from)
          : from
          ? from
          : startOfMonth(new Date()),
      to:
        typeof to === 'string'
          ? parseISO(to)
          : to
          ? to
          : endOfMonth(new Date()),
    }
  }

  if (initialFilters) {
    const { from = startOfMonth(new Date()), to = endOfMonth(new Date()) } =
      initialFilters

    setPeriod({ from, to })

    for (const [key, value] of Object.entries(initialFilters)) {
      if (!(key in initialState)) continue

      const _key = key as keyof InventoryFiltersState
      let _value = value

      if (!Array.isArray(_value) && Array.isArray(initialState[_key])) {
        _value = [_value] as string | number[]
      }

      if (filtersNumber.includes(_key)) {
        if (Array.isArray(_value)) {
          _value = _value.map((v) => (isNaN(v) ? v : Number(v))) as
            | string
            | number[]
        } else {
          _value = isNaN(_value) ? _value : Number(_value)
        }
      }

      setFilter({ key: _key, value: _value })
    }
  }

  const filters = computed(() => selectedFilters.value)
  const period = computed<InventoryPeriod>(() => selectedPeriod.value)

  return {
    filters,
    isFiltered,
    isFilterSet,
    isFilterActive,
    period,
    setPeriod,
    setFilter,
    toggleFilter,
    removeFilter,
    removeAllFilters,
  }
}

type Endpoint =
  | 'booking'
  | `${'booking' | 'daily' | 'filter' | 'general' | 'listing'}/${string}`

export const useInventory = <T>({
  endpoint,
  payloadOverride = {},
  preventWatch = false,
}: {
  endpoint: Endpoint
  payloadOverride?: { [key: string]: unknown }
  preventWatch?: boolean
}) => {
  const data = ref<T>()
  const isLoading = ref(true)
  const shouldRetry = ref(false)
  const _preventWatch = ref(preventWatch)

  const inventoryFilters = computed(() => selectedFilters.value)
  const inventoryPeriod = computed<InventoryPeriod>(() => {
    return selectedPeriod.value
  })

  const inventoryParams = computed(() => ({
    ...inventoryFilters.value,
    from: format(
      inventoryPeriod.value.from || startOfMonth(new Date()),
      formats.ISO.date
    ),
    to: format(
      inventoryPeriod.value.to || endOfMonth(new Date()),
      formats.ISO.date
    ),
    ...payloadOverride,
  }))

  const fetchData = _debounce(() => {
    isLoading.value = true
    shouldRetry.value = false

    HTTP.post(`inventory/${endpoint}`, inventoryParams.value, {
      executeInBackground: true,
    })
      .then((response) => {
        data.value = response.data
      })
      .catch((e) => {
        console.error(e)
        shouldRetry.value = true
      })
      .finally(() => {
        isLoading.value = false
      })
  }, 500)

  const startWatching = (): void => {
    _preventWatch.value = false

    fetchData()
  }

  const stopWatching = (): void => {
    _preventWatch.value = true
  }

  watch(
    () => inventoryParams.value,
    (newValue, oldValue) => {
      if (!_isEqual(newValue, oldValue) && !_preventWatch.value) {
        fetchData()
      }
    },
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    data,
    fetchInventoryData: fetchData,
    isLoading,
    shouldRetry,
    startWatching,
    stopWatching,
  }
}
