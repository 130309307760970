import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: ':campaign/budget-groupings/:campaignBudget/flights/create',
    name: 'CreateCampaignBudgetGroupingFlight',
    component: () => import('@/views/campaign-flight/edit.vue'),
    meta: {
      title: 'Passport | Create Campaign Budget Grouping Flight',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
  {
    path: ':campaign/budget-groupings/:campaignBudget/flights/:campaignFlight/creatives/:campaignCreative',
    name: 'EditCampaignBudgetGroupingFlightCreative',
    component: () => import('@/views/campaign-flight/edit.vue'),
    meta: {
      title: 'Passport | Edit Campaign Budget Grouping Flight Creative',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
    props: () => ({ tabSelected: 'creatives' }),
  },
  {
    path: ':campaign/budget-groupings/:campaignBudget/flights/:campaignFlight/:tab?',
    name: 'EditCampaignBudgetGroupingFlight',
    component: () => import('@/views/campaign-flight/edit.vue'),
    meta: {
      title: 'Passport | Edit Campaign Budget Grouping Flight',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
] as RouteRecordRaw[]
