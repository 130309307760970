import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/:organization?/magic-tokens/:tab?',
    name: 'MagicTokens',
    component: () => import('@/views/magic-token/index.vue'),
    meta: {
      title: 'Passport | Magic Tokens',
      layout: 'LayoutAuthenticated',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'campaign-report' }),
  },
] as RouteRecordRaw[]
