import HTTP from '@/services/http'
import store from '@/store/index'

const createHttpAuthenticateInterceptor = function () {
  HTTP.interceptors.request.use((config) => {
    if (store.state.auth.token) {
      config.headers.Authorization = `Bearer ${store.state.auth.token}`
    }

    return config
  })
}

export default createHttpAuthenticateInterceptor
