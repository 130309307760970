<template>
  <div>
    <div
      class="tw-flex tw-h-full tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
    >
      <div class="tw-relative tw-text-grey-light">
        <Icon
          name="creative"
          class="tw-h-8 tw-flex-shrink-0 tw-text-lg"
        />
        <span
          class="tw-absolute tw-bottom-0 tw-right-0 tw--mb-1 tw--mr-2 tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-text-2xs tw-font-medium"
          :class="
            countCreatives > 0
              ? 'tw-bg-red tw-text-white'
              : 'tw-bg-pill-bg tw-text-grey-dark'
          "
        >
          {{ countCreatives }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'ListCellCreatives',
  extends: ListCellDefault,
  components: {
    Icon,
  },
  computed: {
    countCreatives() {
      if (
        ['CampaignBudgetGrouping', 'ControlBudgetGrouping'].includes(
          this.item.entity
        ) &&
        this.item?.flights?.length
      ) {
        return this.item.flights.reduce(
          (count, flight) => (count += flight.creativesCount),
          0
        )
      }

      return this.item[this.headerKey]
    },
  },
}
</script>
