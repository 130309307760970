import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: ':order/budget-groupings/create/:tab?',
    name: 'CreateOrderBudgetGrouping',
    component: () => import('@/views/order-budget/edit.vue'),
    meta: {
      title: 'Passport | Create Order Budget Grouping',
      layout: 'LayoutAuthenticated',
      redirectPath: '/orders',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
  {
    path: ':order/budget-groupings/:orderBudget/:tab?',
    name: 'EditOrderBudgetGrouping',
    component: () => import('@/views/order-budget/edit.vue'),
    meta: {
      title: 'Passport | Edit Order Budget Grouping',
      layout: 'LayoutAuthenticated',
      redirectPath: '/orders',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
] as RouteRecordRaw[]
