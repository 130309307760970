import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import store from '../store/index'
import jsonPackage from '../../package.json'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: jsonPackage.version,
  plugins: [new BugsnagPluginVue()],
  releaseStage: import.meta.env.VITE_BUGSNAG_STAGE,
  enabledReleaseStages: [
    'production',
    'preproduction',
    'staging',
    'development',
  ],
  onError: function (event) {
    if (store.state.auth.accessToken && store.state.auth.user) {
      event.setUser(
        store.state.auth.user.id,
        store.state.auth.user.email,
        [store.state.auth.user.firstName, store.state.auth.user.lastName].join(
          ' '
        )
      )
      event.addMetadata('account', store.state.auth.user)
    }
  },
})

export default () => {
  return Bugsnag.getPlugin('vue')
}
