import HTTP from '@/services/http'

const initialState = () => ({
  list: [],
  listFlight: [],
  listMine: [],
})

export default {
  namespaced: true,

  state: initialState,

  mutations: {
    SET_BUNDLES(state, bundles) {
      state.list = bundles
    },
    SET_BUNDLES_FLIGHT(state, bundles) {
      state.listFlight = bundles
    },
    SET_MINE_BUNDLES(state, listMine) {
      state.listMine = listMine
    },
  },

  actions: {
    async fetch({ commit }) {
      try {
        const sharedResponse = await HTTP.get('Bundle/SharedByOrganization')
        const mineResponse = await HTTP.get('Bundle/Mine')

        const sharedBundles = sharedResponse.data.datas.map((bundle) => ({
          ...bundle,
          canDelete: false,
        }))

        const mineBundles = mineResponse.data.datas
          .map((bundle) => ({ ...bundle, canDelete: true }))
          .filter((bundle) => !bundle.isShared)

        const bundles = [...sharedBundles, ...mineBundles]

        commit('SET_BUNDLES', bundles)

        commit('SET_MINE_BUNDLES', mineResponse.data.datas)

        return Promise.resolve(bundles)
      } catch (error) {
        commit('SET_BUNDLES', [])
        commit('SET_MINE_BUNDLES', [])

        return Promise.reject(error)
      }
    },

    async update(context, bundle) {
      try {
        const promises = []

        promises.push(HTTP.put('Bundle', bundle))

        const responses = await Promise.allSettled(promises)

        return Promise.resolve(responses)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create(context, bundle) {
      try {
        const response = await HTTP.post('Bundle', bundle)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(context, id) {
      try {
        const response = await HTTP.delete(`Bundle/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit }) {
      try {
        const response = await HTTP.get('Bundle/list/meta')

        commit('SET_BUNDLES_FLIGHT', response.data.datas)

        return Promise.resolve(response.data.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async load(context, bundle) {
      try {
        const response = await HTTP.put('TargetingFilter/LoadBundle', bundle)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
