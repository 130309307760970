// import Bugsnag from '@bugsnag/js'
import { toRoute } from '@/helpers/route'
import clipboard from '@/mixins/clipboard'
import configView from '@/mixins/configView.js'
// import debug from '@/mixins/debug.js'
import dotNotation from '@/mixins/dotNotation.js'
import handleError from '@/mixins/handleError.js'
import handleInput from '@/mixins/handleInput.js'
import print from '@/mixins/print.js'
import sortObjects from '@/mixins/sortObjects.js'
import userCan from '@/mixins/userCan.js'

export default (app) => {
  app.mixin(clipboard)
  app.mixin(configView)
  // if (import.meta.env.DEV) {
  //   app.mixin(debug)
  // }
  app.mixin(dotNotation)
  app.mixin(handleError)
  app.mixin(handleInput)
  app.mixin(print)
  app.mixin({
    methods: {
      toRoute,
    },
  })
  app.mixin(sortObjects)
  app.mixin(userCan)
  app.config.globalProperties.$bugsnag = {
    notify: (error) => {
      console.error(error)
    },
  }
}
