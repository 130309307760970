import { EnumLocalesIso } from '@/constants/options'

// Number => string
export function numberToI18n(value, format = 'EU', options = {}) {
  return new Intl.NumberFormat(
    EnumLocalesIso[format.toUpperCase()],
    options
  ).format(Number(value))
}

/*
 * @params
 * @value : value to convert => type: Number
 * @numberI18n : format you want (Eu, Us) => type: String
 * @currency : currency output (EUR, USD, etc.) but so far based our own mapping see userPreferences.js  => type: String
 * Example : intlFormatCurrency(5000, 'Eu', 'EURO')
 * */
export function intlFormatCurrency(value, numberI18n, currencyCode) {
  if (currencyCode) {
    try {
      return new Intl.NumberFormat(EnumLocalesIso[numberI18n.toUpperCase()], {
        style: 'currency',
        currency: currencyCode,
      }).format(value)
    } catch (e) {
      console.error(
        'intlFormatCurrency exception',
        { value, numberI18n, currencyCode },
        e.message
      )
      return null
    }
  }
}
