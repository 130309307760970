<script setup lang="ts">
import { computed, ref, type PropType } from 'vue'
import 'v-calendar/dist/style.css'
import type { DatePickerValue } from '@/types'
import { EnumDateInputModes } from '@/constants/components'
import { useStore } from 'vuex'

const emit = defineEmits<{
  (e: 'update:modelValue', value: DatePickerValue): void
}>()

const props = defineProps({
  attributes: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Number,
    default: 1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String as PropType<EnumDateInputModes>,
    default: EnumDateInputModes.DATE,
  },
  modelValue: {
    type: [String, Object, null] as PropType<DatePickerValue>,
    required: true,
  },
  minDate: {
    type: String,
    default: null,
  },
  maxDate: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  isRange: {
    type: Boolean,
    default: false,
  },
  static: {
    type: Boolean,
    default: false,
  },
  trigger: {
    type: String as PropType<'click' | 'focus' | 'hover' | 'hidden'>,
    default: 'focus',
  },
  withMinutes: {
    type: Boolean,
    default: true,
  },
})

const store = useStore()

const refDatePicker = ref()

defineExpose({
  refDatePicker,
})

const dateFormat = computed<'EU' | 'US'>(() => {
  return store.state.auth.user?.userPreference?.dateI18n?.toUpperCase() || 'EU'
})

const attributes = {
  highlight: {
    start: {
      color: 'white',
      class: 'tw-bg-primary',
      contentClass: 'tw-text-white',
    },
    base: {
      color: 'white',
      class: 'tw-bg-primary tw-opacity-70',
      contentClass: 'tw-text-white',
    },
    end: {
      color: 'white',
      class: 'tw-bg-white tw-border-primary tw-border-2',
      contentClass: 'tw-text-yellow',
    },
  },
}

const dragAttributes = {
  highlight: {
    start: {
      class: 'tw-border-2 tw-rounded-full tw-border-primary tw-bg-white',
    },
    base: {
      color: 'white',
      class: 'tw-bg-primary tw-opacity-70',
      contentClass: 'tw-text-white',
    },
    end: {
      class: 'tw-border-2 tw-rounded-full tw-border-primary tw-bg-white',
    },
  },
}

const proxyModelValue = computed<DatePickerValue>({
  get: () => props.modelValue,
  set: (value: DatePickerValue) => {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <vc-date-picker
    ref="refDatePicker"
    v-model="proxyModelValue"
    :class="[
      `date-picker-${props.mode}`,
      props.withMinutes ? '' : 'without-minutes',
    ]"
    color="blue"
    :attributes="props.attributes"
    :columns="props.columns"
    :drag-attribute="dragAttributes"
    :is24hr="dateFormat !== 'US'"
    :is-expanded="true"
    :is-range="props.isRange"
    :max-date="props.maxDate"
    :min-date="props.minDate"
    :mode="props.mode"
    :popover="{ visibility: props.trigger }"
    :select-attribute="attributes"
    :required="props.required"
  >
    <template
      v-if="props.static === false && props.mode !== EnumDateInputModes.TIME"
      #default="{ inputValue, inputEvents }"
    >
      <slot
        :input-value="inputValue"
        :input-events="inputEvents"
      />
    </template>
  </vc-date-picker>
</template>
<style lang="postcss">
.date-picker-time .vc-time-content {
  @apply tw-m-0;
}

.date-picker-time .vc-time-picker {
  @apply tw-px-0;
}

.date-picker-time .vc-time-date,
.date-picker-time .vc-time-icon {
  @apply tw-hidden;
}

.date-picker-time {
  @apply tw--mt-2 tw-border-none;
}

.without-minutes .vc-time-picker .vc-time-select span::after {
  content: '00';
  font-size: var(--text-base);
  font-weight: var(--font-medium);
  @apply tw-ml-1;
}

.without-minutes .vc-time-picker span + .vc-select {
  @apply tw-hidden;
}
.vc-container,
.vc-container.vc-green {
  --accent-600: rgb(var(--colorRGB-primary-dark));
  --accent-500: rgb(var(--colorRGB-primary));
  --accent-400: rgb(var(--colorRGB-primary-light));
}

.vc-time-picker {
  @apply tw-flex-1;
}

.vc-pane-container > div:not([class]) {
  @apply tw-flex tw-w-full tw-bg-alto-light;
}

.popover-panel .vc-time-picker.vc-bordered {
  @apply tw-border-y tw-border-border;
}

.vc-time-content {
  --gray-200: #ffffff;
}

.vc-time-picker {
  @apply tw-flex-1;
}

.popover-panel .vc-pane-container > div:not([class]) {
  @apply tw-flex tw-border-t tw-border-border;
}

.popover-panel .vc-container {
  @apply tw-w-full tw-border-none tw-shadow-none;
}
</style>
