import store from '@/store/index'

const handleClipboardCopy = (text: string, notify = true): void => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      if (notify) {
        store.dispatch('addToast', { type: 'info', value: `Copied` })
      }
    })
  } else {
    const copyElement = document.createElement('textarea')

    copyElement.value = text
    copyElement.select()

    try {
      if (document.execCommand('copy')) {
        if (notify) {
          store.dispatch('addToast', { type: 'info', value: `Copied` })
        }
      } else {
        store.dispatch('addToast', { type: 'error', value: `Copy failed` })
      }
    } catch (e) {
      store.dispatch('addToast', { type: 'error', value: `Copy failed` })
    }
  }
}

export { handleClipboardCopy }
