export default {
  namespaced: true,

  state: () => ({
    cancelTokens: [],
  }),

  mutations: {
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token)
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = []
    },
  },

  actions: {
    addCancelToken({ commit }, token) {
      commit('ADD_CANCEL_TOKEN', token)
    },
    async cancelPendingRequests({ commit, state }) {
      const promises = []

      for (const request of state.cancelTokens) {
        if (request.cancel) {
          promises.push(request.cancel())
        }
      }

      await Promise.allSettled(promises)

      commit('CLEAR_CANCEL_TOKENS')
    },
  },
}
