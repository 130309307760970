<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
  >
    <GraphPacing
      :height="48"
      :percent-filled="item.progression * 100"
      :percent-pacing="item.pacing * 100"
      :parent-view="entity.pacingOrProgression"
    />
  </router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import GraphPacing from '@/components/GraphPacing.vue'

export default {
  name: 'ListCellPacingProgression',
  components: {
    GraphPacing,
  },
  extends: ListCellDefault,
}
</script>
