export const searchList = <T extends object | string>(
  list: T[],
  keys: Array<keyof T> | keyof T | null,
  search: string
): T[] => {
  const searchTerms = search.split(' ').map((searchTerm) => {
    return searchTerm
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  })

  if (!keys) {
    return list.filter((item) => {
      return searchTerms.every((term) =>
        normalizeSearchTerm(item as string).includes(term)
      )
    })
  }

  if (!Array.isArray(keys)) {
    keys = [keys]
  }

  return list.filter((item) => {
    return searchTerms.every((term) =>
      (keys as Array<keyof T>).some(
        (key) =>
          typeof item == 'object' &&
          key in item &&
          normalizeSearchTerm(item[key] as string).includes(term)
      )
    )
  })
}

export const searchListDeep = <T extends object>(
  list: T[],
  key: keyof T,
  search: string,
  childKey: keyof T
): T[] => {
  const searchTerms = search.split(' ').map((searchTerm) => {
    return searchTerm
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  })

  return list.reduce((searchedList, item) => {
    const isMatching = searchTerms.every((term) =>
      normalizeSearchTerm(item[key] as string).includes(term)
    )
    const matchingChildren =
      item[childKey] && Array.isArray(item[childKey])
        ? searchListDeep(item[childKey] as T[], key, search, childKey)
        : []

    if (isMatching || matchingChildren.length > 0) {
      searchedList.push({
        ...item,
        [childKey]: matchingChildren,
      })
    }

    return searchedList
  }, [] as T[])
}

const normalizeSearchTerm = (searchTerm: string): string => {
  if (['string', 'number'].includes(typeof searchTerm) === false) return ''

  return searchTerm
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}
