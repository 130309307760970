import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import { searchList } from '@/helpers/search'
import { sortList } from '@/helpers/sort'

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    list: [],
    listAdops: [],
    listCampaigns: [],
    listCampaignsId: null,
  },

  getters: {
    optionsAdops(state) {
      return sortList(
        state.listAdops.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        })),
        'label',
        'asc'
      )
    },
  },

  mutations: {
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = _cloneDeep(list)
    },
    SET_LIST_ADOPS(state, users) {
      state.listAdops = users.filter((user) =>
        user.functions.some((role) => {
          return [
            'AdTrafficker',
            'AdTraffickerSenior',
            'MasterAdTrafficker',
          ].includes(role)
        })
      )
    },
    SET_LIST_CAMPAIGNS(state, listCampaigns) {
      state.listCampaigns = _cloneDeep(listCampaigns)
    },
    SET_LIST_CAMPAIGNS_ID(state, listCampaignsId) {
      state.listCampaignsId = listCampaignsId
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async create({ state }) {
      try {
        const bodyFormData = new FormData()

        for (const key in state.form) {
          if (
            state.form[key] !== '' &&
            !['functions', 'sendEmail'].includes(key)
          ) {
            bodyFormData.append(`${key}`, state.form[key])
          }
        }

        const response = await HTTP.post('User', bodyFormData)

        if (state.form.functions.length) {
          await HTTP.post('User/Functions', {
            userId: response.data.datas.id,
            functionTypeCodeList: state.form.functions,
          })
        }

        if (state.form.sendEmail) {
          HTTP.post('Account/send-set-password-mail', {
            email: state.form.email,
            baseUrl: `${window.location.origin}/set-password/`,
          })
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetch({ commit, state }, { id, force = true, reload = false }) {
      try {
        if (!force && state.current.id === id) {
          return Promise.resolve(state.current)
        }

        const response = await HTTP.get(`User/${id}`)

        commit('SET_CURRENT', response.data.datas)

        if (reload || !state.form || state.form.id !== id) {
          const {
            firstName,
            lastName,
            dateI18n,
            numberI18n,
            email,
            completePicturePath,
            functions,
            phone,
            ssn,
          } = response.data.datas

          const form = {
            firstName,
            lastName,
            email,
            picture: null,
            completePicturePath,
            numberI18n: numberI18n,
            dateI18n: dateI18n,
            functions,
            phone,
            ssn,
          }

          commit('SET_FORM', form)
        }

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async handleInput({ commit }, { path, value, valueKey = 'form' }) {
      await commit('HANDLE_INPUT', { path, value, valueKey })
    },

    async list({ commit, rootGetters }) {
      try {
        const response = await HTTP.get('User/list')

        let dataList = response.data.datas

        // Hardcodisme: Hide users whose ID is > 100 for new organizations
        if (
          [21].includes(rootGetters['auth/currentOrganization']?.id) ||
          (rootGetters['auth/currentOrganization']?.id >= 27 &&
            rootGetters['auth/currentOrganization']?.id <= 181)
        ) {
          dataList = dataList.filter((user) => user.id > 100)
        }

        commit('SET_LIST', dataList)
        commit('SET_LIST_ADOPS', dataList)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])
        commit('SET_LIST_ADOPS', [])
        return Promise.reject(error)
      }
    },

    async delete(_, { id }) {
      try {
        const response = await HTTP.delete(`User/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async listCampaigns({ commit, state }, { id, force = false } = {}) {
      if (id === null) {
        commit('SET_LIST_CAMPAIGNS_ID', id)
        commit('SET_LIST_CAMPAIGNS', [])
        return state.listCampaigns
      }

      if (
        !force &&
        id === state.listCampaignsId &&
        state.listCampaigns.length
      ) {
        return state.listCampaigns
      }

      try {
        const response = await HTTP.get(`User/${id}/campaigns`)

        commit('SET_LIST_CAMPAIGNS_ID', id)
        commit('SET_LIST_CAMPAIGNS', response.data.datas)
        return response
      } catch (e) {
        commit('SET_LIST_CAMPAIGNS', [])
        commit('SET_LIST_CAMPAIGNS_ID', null)
      }
    },

    async lostPassword({ state }, { email = null }) {
      try {
        const response = await HTTP.post('Account/lost-password', {
          email: email || state.current.email,
          baseUrl: `${document.location.protocol}//${document.location.host}`,
        })

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async search({ commit }, { search, fields }) {
      try {
        const response = await HTTP.get('User/list')
        const filteredItems = searchList(response.data.datas, fields, search)

        commit('SET_LIST', filteredItems)
        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])
        console.error(error)
        return Promise.reject(error)
      }
    },

    async update({ state }, options) {
      try {
        const id = state.current.id

        const functionsAdded = state.form.functions.filter(
          (role) => !state.current.functions.includes(role)
        )
        const functionsDeleted = state.current.functions.filter(
          (role) => !state.form.functions.includes(role)
        )

        if (functionsAdded.length) {
          await HTTP.post('User/Functions', {
            userId: id,
            functionTypeCodeList: functionsAdded,
          })
        }
        if (functionsDeleted.length) {
          await HTTP.delete('User/Functions', {
            data: {
              userId: id,
              functionTypeCodeList: functionsDeleted,
            },
          })
        }

        const bodyFormData = new FormData()

        bodyFormData.append('id', state.current.id)
        for (const key in state.form) {
          if (
            state.form[key] !== null &&
            !['pictureUrl', 'functions', 'sendEmail'].includes(key)
          ) {
            bodyFormData.append(`${key}`, state.form[key])
          }
        }
        const response = await HTTP.put('User', bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          ...options,
        })

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async setDefaultForm({ commit, rootState }) {
      const formUser = await import('@/forms/user.js')
      const defaultForm = _cloneDeep(formUser.default)

      commit('SET_FORM', {
        ...defaultForm,
        numberI18n: rootState.auth.user?.userPreference?.numberI18n || null,
        dateI18n: rootState.auth.user?.userPreference?.dateI18n || null,
      })
    },
  },
}
