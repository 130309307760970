<template>
  <div>
    <SwitchInput
      v-tooltip="canToggle ? '' : isToggled ? 'Pause' : 'Resume'"
      :modelValue="isToggled"
      :disabled="canToggle"
      @update:modelValue="
        $emit('click', { item, key: isToggled ? 'pause' : 'resume' })
      "
    />
  </div>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import SwitchInput from '@/components/Input/SwitchInput.vue'

export default {
  name: 'ListCellToggle',
  emits: ['click'],
  components: {
    SwitchInput,
  },
  extends: ListCellDefault,
  computed: {
    isToggled() {
      return ['unpaused'].includes(this.item.dealClientStatusType.toLowerCase())
    },
    canToggle() {
      return ['draft'].includes(this.item.dealStatusType.toLowerCase())
    },
  },
}
</script>
