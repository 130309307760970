import store from '@/store/index'

export function userCan(claims: string | Array<string> | undefined): boolean {
  if (!claims) {
    return true
  }

  claims = Array.isArray(claims) ? claims : [claims]

  return Boolean(
    store.state.auth.claims &&
      claims.every((claim) => store.state.auth.claims.includes(claim))
  )
}

export function userHasFunction(
  roles: string | Array<string> | undefined
): boolean {
  if (!roles) {
    return true
  }

  roles = Array.isArray(roles) ? roles : [roles]

  return Boolean(
    store.state.auth.functions &&
      roles.some((role) => store.state.auth.functions.includes(role))
  )
}

export function userHasEveryFunctions(roles: Array<string>): boolean {
  if (!roles || !roles.length) {
    return false
  }

  return Boolean(
    store.state.auth.functions &&
      roles.every((role) => store.state.auth.functions.includes(role))
  )
}
