<template>
  <router-link
    :to="to"
    class="tw-w-full tw-max-w-full tw-cursor-pointer tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
  >
    {{ safeGet(item, headerKey).join(separator) }}
  </router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellList',
  extends: ListCellDefault,
  props: {
    separator: {
      type: String,
      default: ', ',
    },
  },
}
</script>
