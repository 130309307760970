export default {
  methods: {
    handleInput(path, value, valueKey = 'value') {
      this.$emit(
        'update:modelValue',
        this.safeSet({ ...this[valueKey] }, path, value)
      )
    },
    handleStoreInput(module, path, value, valueKey = 'form') {
      this.$store.dispatch(`${module}/handleInput`, { path, value, valueKey })
    },
  },
}
