const popupsComponents = import.meta.globEager('../popups/*.vue')

export default (app) => {
  Object.entries(popupsComponents).forEach(([path, definition]) => {
    const componentName = path
      .split('/')
      .pop()
      .replace(/\.\w+$/, '')
      .split('-')
      .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
      .join('')

    app.component(`Popup${componentName}`, definition.default)
  })
}
