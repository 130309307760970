import HTTP from '@/services/http'
import { sortList } from '@/helpers/sort'
import { optionsCurrencies } from '@/constants/options'

export default {
  namespaced: true,

  state: {
    defaultCurrency: null,
    isSwitching: false,
    list: [],
    listCountries: [],
    listCurrencies: [],
  },

  getters: {
    getCountriesList: (state) => {
      const countriesList = state.listCountries.map(({ id, label }) => ({
        value: id,
        label,
      }))

      return sortList(countriesList, 'label', 'asc')
    },
    getOptionsCurrencies: (state) => {
      if (!Array.isArray(state.listCurrencies)) return []

      return state.listCurrencies.map((currencyCode) => {
        const indexCurrency = optionsCurrencies.findIndex(
          ({ value }) => value === currencyCode
        )

        if (indexCurrency >= 0) return optionsCurrencies[indexCurrency]
        else
          return {
            value: currencyCode,
            label: currencyCode,
          }
      })
    },
  },

  mutations: {
    RESET_STATE(state) {
      state.list = []
      state.listCountries = []
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_LIST_COUNTRIES(state, listCountries) {
      state.listCountries = listCountries
    },
    SET_SWITCHING(state, isSwitching) {
      state.isSwitching = isSwitching
    },
    SET_LIST_CURRENCIES(state, listCurrencies) {
      state.listCurrencies = listCurrencies
    },
    SET_DEFAULT_CURRENCY(state, defaultCurrency) {
      state.defaultCurrency = defaultCurrency
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async getOrganizationInfos({ commit, rootGetters }) {
      try {
        const { id } = rootGetters['auth/currentOrganization']

        const response = await HTTP.get(`Organization/${id}`)

        commit('SET_LIST_CURRENCIES', response.data.datas.currencies)
        commit('SET_DEFAULT_CURRENCY', response.data.datas.defaultCurrency)
        // commit('SET_DEFAULT_CURRENCY', 'CAD')

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST_CURRENCIES', [])

        Promise.reject(error)
      }
    },

    async list({ commit }) {
      try {
        const response = await HTTP.get('Account/self/organizations')

        commit('SET_LIST', response.data.datas)

        Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        Promise.reject(error)
      }
    },

    async listCountries({ commit }) {
      try {
        const response = await HTTP.get('Organization/Countries')

        commit(
          'SET_LIST_COUNTRIES',
          Object.keys(response.data.datas.countries).map((countryCode) => {
            return {
              id: countryCode,
              label: response.data.datas.countries[countryCode],
            }
          })
        )

        Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST_COUNTRIES', [])

        Promise.reject(error)
      }
    },

    async switch({ commit, dispatch }, { id } = {}) {
      try {
        const response = await HTTP.get(`Organization/Switch/${id}`)

        commit('SET_SWITCHING', true)

        await dispatch('auth/sync', { background: false }, { root: true })

        commit('SET_SWITCHING', false)

        Promise.resolve(response)
      } catch (error) {
        Promise.reject(error)
      }
    },

    async switchCountry({ commit, dispatch, rootState }, countryCode) {
      const currentCountry = rootState.auth.user.countryCode

      try {
        if (countryCode === null) {
          // TODO: Hardcode PASSPORTV2-4420
          commit(
            'auth/SET_USER',
            {
              ...rootState.auth.user,
              countryCode,
            },
            { root: true }
          )

          Promise.resolve()
        } else {
          const response = await HTTP.put(
            `Organization/Switch/country/${countryCode}`
          )

          await dispatch('auth/sync', { background: false }, { root: true })

          Promise.resolve(response)
        }
      } catch (error) {
        dispatch(
          'auth/setCountry',
          { countryCode: currentCountry },
          { root: true }
        )

        Promise.reject(error)
      }
    },

    setTheme({ rootState }) {
      const { organizationId, organizationList } = rootState.auth.user
      const currentOrganization = organizationList.find(
        (organization) => organization.id === organizationId
      )

      if (!currentOrganization) return

      const { colorFrom, colorText, colorTo, logo, name } = currentOrganization

      localStorage.setItem(
        'organizationTheme',
        JSON.stringify({
          colorFrom,
          colorText,
          colorTo,
          logo,
          name,
        })
      )
    },
  },
}
