import { h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { RouterView } from 'vue-router'

import orderBudgetGroupingFlights from '@/router/order-budget-grouping-flights'
import orderBudgetGroupings from '@/router/order-budget-groupings'
import orderFlights from '@/router/order-flights'

export default [
  {
    path: '/:organization?/orders',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'Orders',
        component: () => import('@/views/order/index.vue'),
        meta: {
          title: 'Passport | List of Orders',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'create',
        name: 'CreateOrder',
        component: () => import('@/views/order/edit.vue'),
        meta: {
          title: 'Passport | Create Order',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: ':order/control/revision/:flightId/:revisionId',
        name: 'OrderFlightRevision',
        component: () => import('@/views/order/control.vue'),
        meta: {
          title: 'Passport | Order Flights & Budget Groupings List',
          layout: 'LayoutAuthenticated',
          redirectPath: '/orders',
          requiresAuth: true,
        },
        props: {
          revisionModalOpened: true,
        },
      },
      {
        path: ':order/control',
        name: 'OrderControl',
        component: () => import('@/views/order/control.vue'),
        meta: {
          title: 'Passport | Order Flights & Budget Groupings List',
          layout: 'LayoutAuthenticated',
          redirectPath: '/orders',
          requiresAuth: true,
          revisionModalOpened: false,
        },
      },
      {
        path: ':order/:tab?',
        name: 'EditOrder',
        component: () => import('@/views/order/edit.vue'),
        meta: {
          title: 'Passport | Edit Order',
          layout: 'LayoutAuthenticated',
          redirectPath: '/orders',
          requiresAuth: true,
        },
        props: (route) => ({ tabSelected: route.params.tab || 'info' }),
      },
      ...orderBudgetGroupingFlights,
      ...orderBudgetGroupings,
      ...orderFlights,
    ],
  },
] as RouteRecordRaw[]
