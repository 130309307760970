const sort = localStorage.getItem('magicToken-forecast-sort')

import { formatDateWithDay } from '@/helpers/date'
import { isBefore, parseISO } from 'date-fns'

const entity = {
  module: 'magicToken',
  plural: 'forecasts',
  singular: 'forecast',
  list: {
    filters: [],
    page: 1,
    loading: true,
    shouldRetry: false,
    search: '',
    sortKey: sort ? JSON.parse(sort).key : '',
    sortOrder: sort ? JSON.parse(sort).order : 'asc',
    headers: [
      {
        key: 'gap-1',
        pages: [1, 2],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-12 tw-col-span-1 tw-bg-alto-light tw-border-l tw-border-border',
      },
      {
        label: 'Status',
        key: 'toggle',
        pages: [1, 2],
        componentHeader: 'ListHeaderStatus',
        componentList: 'ListCellTypeIcon',
        icon: 'wand',
        size: '70px',
        classCell:
          'tw-h-12 tw-justify-end tw-border-l tw-border-border tw-pr-8',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1, 2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEmpty',
        size: 'minmax(80px, 1fr)',
        type: 'toggle',
        classCell: 'tw-h-12',
      },
      {
        key: 'createdBy',
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        pages: [1, 2],
        label: 'Created by',
        size: '1fr',
        classCell: 'tw-h-12',
      },
      {
        label: 'Valid Until',
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellMagicTokenDate',
        key: 'expiryDate',
        pages: [1],
        size: '1fr',
        classCell: 'tw-h-12 tw-px-2',
      },
      {
        label: 'Is Disabled',
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellMagicTokenVisibility',
        pages: [2],
        key: 'isDisabled',
        size: 'minmax(90px, min-content)',
        justify: 'center',
        classCell: 'tw-h-12',
      },
      {
        label: 'Open Counts',
        componentHeader: 'ListHeaderLabel',
        componentList: 'ListCellNumber',
        key: 'countOpened',
        pages: [2],
        size: 'minmax(90px, min-content)',
        classCell: 'tw-h-12 tw-px-2 tw-justify-end',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '60px',
        classCell: 'tw-h-12 tw-border-r tw-border-border',
      },
      {
        key: 'gap-2',
        pages: [1, 2],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-12 tw-col-span-1 tw-bg-alto-light tw-border-r tw-border-border',
      },
    ],
    rowOptions(item) {
      const isExpired = isBefore(parseISO(item.expiryDate), new Date())

      const options = [
        isExpired
          ? null
          : item.isDisabled
          ? {
              key: 'activate',
              label: 'Activate Magic Token',
              icon: 'hyper-link',
            }
          : {
              key: 'link-broken',
              label: 'Disable Magic Token',
              icon: 'link-broken',
            },
        item.isDisabled || isExpired
          ? null
          : {
              key: 'show',
              label: 'Show Magic Token',
              icon: 'eye',
              to: { name: 'ForecastMagicToken', params: { uid: item.uid } },
            },
        item.isDisabled || isExpired
          ? null
          : {
              key: 'copy',
              label: 'Copy Magic Token',
              icon: 'copy',
            },
        {
          key: 'delete',
          label: 'Delete Magic Token',
          icon: 'trash',
        },
      ].filter((option) => option)

      return options
    },
  },
  getDescription(item) {
    return `
    <div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Forecast</div>
        <div class="tw-text-sm">${item.parentName}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Valid Until</div>
        <div class="tw-text-sm">${
          item.expiryDate.includes('9999-12-31')
            ? 'Always'
            : formatDateWithDay(item.expiryDate)
        }</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Created by</div>
        <div class="tw-text-sm">${item.createdBy}</div>
      </div>
    </div>`
  },
}

export default entity
