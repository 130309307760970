<script setup lang="ts">
import { computed, type PropType, ref } from 'vue'
import { formats } from '@/helpers/dateFns'
import { parseISO, format, isValid } from 'date-fns'
import TextInput from '@/components/Input/TextInput.vue'
import { EnumDateInputModes } from '@/constants/components'
import DatePicker from '@/components/DatePicker.vue'
import type { DatePickerValue } from '@/types'
import { OnClickOutside } from '@vueuse/components'
import { Popover, PopoverPanel } from '@headlessui/vue'
import PassportButton from '@/components/PassportButton.vue'
import Icon from '@/components/Icon.vue'

const emit = defineEmits<{
  (e: 'update:modelValue', date: string | null): void
  (e: 'update:shadow', date: string | null): void
}>()

const props = defineProps({
  attributes: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: 'Date',
  },
  minDate: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
  maxDate: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
  mode: {
    type: String as PropType<EnumDateInputModes>,
    default: EnumDateInputModes.DATE,
  },
  modelValue: {
    type: [String, null] as PropType<string | null>,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  state: {
    type: String,
    default: 'initial',
  },
  targetClass: {
    type: [Array, Object, String],
    default: null,
  },
  valueFormat: {
    type: String,
    default: formats.ISO.dateWithTime,
  },
  withButtonApply: {
    type: Boolean,
    default: false,
  },
})

const isOpen = ref<boolean>(false)
const date = ref<string | null>(props.modelValue)

const dateProxy = computed({
  get: () => (props.modelValue ? parseISO(props.modelValue) : null),
  set: (_date) =>
    emit('update:modelValue', _date ? format(_date, props.valueFormat) : null),
})

const datePopoverProxy = computed<DatePickerValue<'simple'>>({
  get: () =>
    typeof date.value === 'string' && isValid(parseISO(date.value))
      ? parseISO(date.value)
      : null,
  set: (_date) => {
    if (!_date) date.value = null
    else {
      date.value = format(_date, props.valueFormat)
      emit('update:shadow', date.value)
    }
  },
})

const handleChangeDate = (date: DatePickerValue): void => {
  if (date instanceof Date === false && typeof date === 'object') return

  datePopoverProxy.value = date as DatePickerValue<'simple'>
}

const handleSubmit = (): void => {
  emit('update:modelValue', date.value)
}

const handleReset = (): void => {
  date.value = props.modelValue
}
</script>
<template>
  <template v-if="props.withButtonApply">
    <DatePicker
      v-model="dateProxy"
      trigger="hidden"
      :is-range="false"
      :attributes="props.attributes"
      :mode="props.mode"
      :min-date="props.minDate || undefined"
      :max-date="props.maxDate || undefined"
      :required="required"
      :disabled="props.disabled"
      :value-format="props.valueFormat"
    >
      <template #default="{ inputValue, inputEvents }">
        <OnClickOutside
          class="tw-relative tw-w-full"
          @trigger="
            () => {
              isOpen = false
              handleReset()
            }
          "
        >
          <div class="tw-relative tw-flex-1">
            <TextInput
              class="tw-uppercase"
              :label="props.label"
              :class="
                props.mode === EnumDateInputModes.TIME
                  ? 'tw-min-w-24'
                  : 'tw-min-w-48'
              "
              :disabled="props.disabled"
              :model-value="inputValue"
              :required="props.required"
              :state="state"
              :target-class="props.targetClass"
              v-on="inputEvents"
              @focus="isOpen = true"
            />

            <Icon
              :name="
                props.mode === EnumDateInputModes.TIME ? 'clock' : 'calendar'
              "
              class="tw-absolute tw-bottom-1.5 tw-right-1.5 tw-text-gray-500"
            />
          </div>

          <Popover>
            <PopoverPanel
              v-if="isOpen === true"
              class="popover-panel tw-absolute tw-top-12 tw-z-20 tw-mt-px tw-w-full tw-rounded-b tw-border-t-2 tw-border-t-primary tw-bg-white tw-drop-shadow-lg"
              :class="
                props.mode === EnumDateInputModes.TIME
                  ? 'tw-min-w-[150px]'
                  : 'tw-min-w-[250px]'
              "
              static
            >
              <slot
                name="popover-header"
                :date="datePopoverProxy"
                :set-date="(_date: Date) => (datePopoverProxy = _date)"
              />
              <slot>
                <DatePicker
                  v-model="datePopoverProxy"
                  :is-range="false"
                  :static="true"
                  :attributes="props.attributes"
                  :columns="1"
                  :min-date="props.minDate || undefined"
                  :max-date="props.maxDate || undefined"
                  :mode="props.mode"
                  :required="required"
                  :disabled="props.disabled"
                  :value-format="props.valueFormat"
                  @update:model-value="handleChangeDate"
                />
              </slot>
              <div class="tw-flex tw-items-center tw-px-6 tw-py-2">
                <slot
                  name="popover-footer"
                  :date="datePopoverProxy"
                  :set-date="(_date: Date) => (datePopoverProxy = _date)"
                />
                <PassportButton
                  label="Apply"
                  size="small"
                  variant="primary"
                  class="tw-ml-auto"
                  type="button"
                  @@click="
                    () => {
                      isOpen = false
                      handleSubmit()
                    }
                  "
                />
              </div>
            </PopoverPanel>
          </Popover>
        </OnClickOutside>
      </template>
    </DatePicker>
  </template>
  <template v-else>
    <DatePicker
      v-bind="props"
      v-model="datePopoverProxy"
      :mode="props.mode"
      :attributes="props.attributes"
      :min-date="props.minDate || undefined"
      :max-date="props.maxDate || undefined"
      @update:model-value="handleSubmit"
    >
      <template #default="{ inputValue, inputEvents }">
        <div class="tw-relative">
          <TextInput
            :label="props.label"
            :class="
              props.mode === EnumDateInputModes.TIME
                ? 'tw-min-w-12'
                : 'tw-min-w-48'
            "
            :disabled="props.disabled"
            :model-value="inputValue"
            :required="props.required"
            :state="props.state"
            :target-class="props.targetClass"
            v-on="inputEvents"
          />
          <Icon
            :name="
              props.mode === EnumDateInputModes.TIME ? 'clock' : 'calendar'
            "
            class="tw-absolute tw-bottom-1.5 tw-right-1.5 tw-text-gray-500"
          />
        </div>
      </template>
    </DatePicker>
  </template>
</template>
