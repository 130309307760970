import _get from 'lodash/get'

export const sortArray = <T extends object>(value: T[], key: keyof T): T[] => {
  if (Array.isArray(value)) {
    return value.slice().sort((a, b) => (b[key] as number) - (a[key] as number))
  }

  return value
}

type Direction = 'asc' | 'desc'

export const sortList = <T extends object>(
  list: T[],
  key: keyof T,
  direction: Direction = 'desc'
): T[] => {
  return list.slice().sort(sortObjects(key, direction))
}

export const sortObjects = <T extends object>(
  key: keyof T,
  order: Direction = 'asc'
) => {
  return (a: T, b: T): number => {
    const aProp = _get(a, key)
    const bProp = _get(b, key)

    if (aProp === undefined || bProp === undefined) {
      // property doesn't exist on either object
      return 0
    }

    // convert to uppercase for @String type
    const varA =
      typeof aProp === 'string' ? (aProp as string).toUpperCase() : aProp
    const varB =
      typeof bProp === 'string' ? (bProp as string).toUpperCase() : bProp

    /*
    Comparison value :
    -1 => asc
    1  => desc
    0  => no change
    **/
    let comparison = 0

    if (varA === null) {
      comparison = 1
    } else if (varB === null) {
      comparison = -1
    } else if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }

    // as by default, order is ascending, multiply comparison test with -1 to have descending ordering
    return order === 'desc' ? comparison * -1 : comparison
  }
}
