import HTTP from '@/services/http'
import { version } from '../../package.json'

const cacheAvailable = 'caches' in self

// https://web.dev/cache-api-quick-guide/

async function get({ store = 'global', url, params = {}, config = {} }) {
  if (!cacheAvailable) {
    const response = await HTTP.request({
      url,
      params,
      ...config,
    })

    return response
  }

  const cache = await caches.open(store)

  // Generate Request URL
  const requestUrl = new URL(
    `${import.meta.env.VITE_TARGETSPOT_API_BASE_URL}/${url}`
  )

  Object.keys(params).forEach((key) =>
    requestUrl.searchParams.append(key, params[key])
  )

  requestUrl.searchParams.append('appVersion', version)

  const cacheRequest = new Request(requestUrl)

  // Try to fetch the response
  let cacheResponse = await cache.match(cacheRequest)

  if (cacheResponse) {
    return cacheResponse.json().then((data) => {
      return { data }
    })
  }

  // Call the Request & cache the response
  const response = await HTTP.request({
    url,
    params,
    ...config,
  }).then((response) => {
    cacheResponse = new Response(JSON.stringify(response.data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    cache.put(cacheRequest, cacheResponse)

    return response
  })

  return response
}

// Clear all caches
async function clear() {
  if (!cacheAvailable) {
    return
  }

  const cacheNames = await caches.keys()

  for (const name of cacheNames) {
    await caches.delete(name)
  }
}

// Make sure we only keep the cached request for the current version
async function sync() {
  if (!cacheAvailable) {
    return
  }

  const cacheNames = await caches.keys()

  for (const name of cacheNames) {
    const cache = await caches.open(name)

    for (const request of await cache.keys()) {
      if (!request.url.includes(`appVersion=${version}`)) {
        cache.delete(request)
      }
    }
  }
}

export default { get, clear, sync }
