<script setup lang="ts">
import { onMounted, onUnmounted, reactive } from 'vue'
import Icon from '@/components/Icon.vue'

const state = reactive({
  lastScrollPos: 0,
  isTicking: false,
  isVisible: false,
})

onMounted(() => {
  document.addEventListener('scroll', showOnScroll)
})
onUnmounted(() => {
  document.removeEventListener('scroll', showOnScroll)
})

const showOnScroll = (): void => {
  if (!state.isTicking) {
    state.lastScrollPos = window.scrollY

    window.requestAnimationFrame(() => {
      state.isVisible = state.lastScrollPos > 100
      state.isTicking = false
    })

    state.isTicking = true
  }
}
const smoothScrollTop = (): void => {
  const scrollY = document.documentElement.scrollTop || document.body.scrollTop

  if (scrollY > 0) {
    window.requestAnimationFrame(smoothScrollTop)
    window.scrollTo(0, scrollY - scrollY / 8)
  }
}
</script>

<template>
  <transition name="fade">
    <div
      v-if="state.isVisible"
      id="scroll-top-container"
      class="tw-fixed tw-bottom-0 tw-left-0 tw-z-30 tw-mb-32 tw-ml-3 tw-flex tw-h-12 tw-w-12 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded tw-border tw-border-border-alt tw-bg-pill-bg tw-text-black tw-shadow hover:tw-bg-border"
      @click="smoothScrollTop"
    >
      <Icon name="arrow-up" />
    </div>
  </transition>
</template>
