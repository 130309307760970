import HTTP from '@/services/http'

export default {
  namespaced: true,

  state: {
    list: [],
  },

  mutations: {
    RESET_STATE(state) {
      state.list = []
    },
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async import(context, { flightId, creativeLibraryIds }) {
      try {
        const response = await HTTP.post(
          `Creative/import/CreativeLibrary/${flightId}`,
          { creativeLibraryIds }
        )

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, rootState }, { advertiserId = null } = {}) {
      try {
        const { organizationId } = rootState.auth.user

        const response = await HTTP.get(
          `Creative/CreativeLibrary/${organizationId}/advertiser/`
        )
        let listCreatives = response.data.datas

        if (advertiserId) {
          listCreatives = listCreatives.filter(
            (creative) => creative.advertiserId === advertiserId
          )
        }
        commit('SET_LIST', listCreatives)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])
      }
    },

    async update(_, { id, payload }) {
      try {
        const response = await HTTP.put(
          `Creative/CreativeLibrary/${id}`,
          payload
        )

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`Creative/CreativeLibrary/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
