import { HARDCOST_TYPE_AMOUNT } from '@/constants/HardCostTypes'
import { distinctList } from '@/helpers/list'
import parseConfig from '@/helpers/parseConfig.js'
import { searchList } from '@/helpers/search'
import HTTP, { paramsSerializer } from '@/services/http'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

const cleanForm = (form) => {
  const cleanForm = _cloneDeep(form)

  cleanForm.campaignSaleUsersList = cleanForm.campaignSaleUsersList.filter(
    (campaignSaleUser) => campaignSaleUser.userId !== null
  )
  cleanForm.campaignHardCostsList = cleanForm.campaignHardCostsList.filter(
    (campaignHardCost) =>
      [
        campaignHardCost.currencyCode,
        campaignHardCost.hardCostType === HARDCOST_TYPE_AMOUNT
          ? campaignHardCost.amount
          : campaignHardCost.cpm,
        campaignHardCost.name,
      ].every((value) => value !== null)
  )

  return cleanForm
}

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
    listLoadingMore: false,
    listTotal: 0,
    listStatusCount: {},
    listPerPage: 15,
    listSearch: '',
    listFilters: {},
    listOrderBy: null,
    listOrderByDirection: null,
    listPage: 0,
    listChildren: {},
    currencyRate: 0,
  },

  mutations: {
    ADD_TO_LIST(state, list) {
      state.list = state.list.concat(list)
    },
    SET_LIST_PAGE(state, page) {
      state.listPage = page
    },
    SET_LIST_PAGING(state, paging) {
      if (!paging) return

      state.listTotal = paging.total
      state.listPerPage = paging.size
      state.listPage = paging.index
    },
    SET_LIST_STATUS_COUNT(state, statusCount) {
      state.listStatusCount = statusCount
    },
    SET_LIST_SEARCH(state, search) {
      state.listSearch = search
    },
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
    },
    RESET_CURRENT(state) {
      state.current = null
      state.form = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      if (path === null) {
        state[valueKey] = _cloneDeep(value)
      } else {
        _set(state[valueKey], path, value)
      }
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      const updatedDeals = mapDeals(current)

      state.current = { ...current, deals: updatedDeals }
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      const updatedDeals = mapDeals(form)

      state.form = {
        ...form,
        deals: updatedDeals,
      }
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_LIST_CHILDREN(state, { id, list }) {
      const listChildren = _cloneDeep(state.listChildren)

      listChildren[id] = list

      state.listChildren = listChildren
    },
    SET_LIST_FILTER(state, filter) {
      state.listFilters = Object.assign({}, state.listFilters, filter)
    },
    SET_LIST_SORT(state, orderBy) {
      state.listOrderBy = orderBy
    },
    SET_LIST_SORT_DIRECTION(state, direction) {
      state.listOrderByDirection = direction
    },
    SET_LIST_LOADING_MORE(state, loading) {
      state.listLoadingMore = loading
    },
    REMOVE_LIST_FILTER(state, key) {
      const listFilters = state.listFilters

      delete listFilters[key]
      state.listFilters = listFilters
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    async fetchConfigView(
      { commit, dispatch, state, rootGetters },
      { campaignType = null } = {}
    ) {
      try {
        const response = await HTTP.get(
          `Campaign/Configview/${campaignType ? campaignType : ''}`
        )
        const parsedConfig = parseConfig(
          response.data.CampaignXml,
          'CampaignTypeId'
        )

        commit('SET_CONFIG_VIEW', parsedConfig)

        const productTypes = Array.isArray(
          parsedConfig.fields.CampaignType.DataList.DataList
        )
          ? parsedConfig.fields.CampaignType.DataList.DataList
          : [parsedConfig.fields.CampaignType.DataList.DataList]

        if (campaignType === null) {
          if (
            productTypes.findIndex(
              (productType) => +productType.Id === +parsedConfig.CampaignTypeId
            ) >= 0
          ) {
            await dispatch('handleInput', {
              path: 'campaignType',
              value: +parsedConfig.CampaignTypeId,
              valueKey: 'form',
            })
          } else {
            const firstProductType = productTypes[0].Id

            await dispatch('fetchConfigView', {
              campaignType: +firstProductType,
            }).then(() => {
              dispatch('handleInput', {
                path: 'campaignType',
                value: +firstProductType,
                valueKey: 'form',
              })
            })
          }
        } else if (
          productTypes.findIndex(
            (productType) => +productType.Id === +campaignType
          ) < 0 &&
          productTypes.length > 0
        ) {
          const firstProductType = productTypes[0].Id

          await dispatch('handleInput', {
            path: 'campaignType',
            value: +firstProductType,
            valueKey: 'form',
          })
        }

        if (!state.form.id) {
          if (parsedConfig.fields.CampaignTraffickingFee.DefaultValue) {
            await dispatch('handleInput', {
              path: 'traffickingFeePercentage',
              value: parsedConfig.fields.CampaignTraffickingFee.DefaultValue,
              valueKey: 'form',
            })
          }

          const optionsAdops = rootGetters['user/optionsAdops'].map(
            (user) => user.value
          )
          const currentUserId = rootGetters['auth/currentUser']?.id

          if (optionsAdops.includes(currentUserId)) {
            dispatch('handleInput', {
              path: 'campaignAdopsResponsible.userId',
              value: currentUserId,
              valueKey: 'form',
            })
          }
        }

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch(
      { commit, dispatch, state },
      { id, force = true, reload = false }
    ) {
      if (!force && state.current && state.current.id === id) {
        if (!state.form || state.form.id !== id) {
          // commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(state.current)
      }

      try {
        const response = await HTTP.get(`Campaign/${id}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        if (reload || !state.form || state.form.id !== id) {
          commit('SET_FORM', response.data.datas)
        }

        if (response.data.datas.deals && response.data.datas.deals.length) {
          const { providers, ...deal } = response.data.datas.deals[0]

          deal.providersResponse = providers

          dispatch('deal/setForm', deal, { root: true })
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async export(context, { id }) {
      try {
        const response = await HTTP.get(`export/campaign/${id}/xls`, {
          executeInBackground: true,
          responseType: 'blob',
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`Campaign/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post('Campaign', cleanForm(state.form))

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { options } = {}) {
      try {
        const { deals, ...form } = state.form

        if (deals && deals.length && !form.dealIds?.length) {
          Object.assign(form, { dealIds: deals.map(({ id }) => id) })
        }

        const response = await HTTP.put('Campaign', cleanForm(form), options)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async duplicate(context, { id }) {
      try {
        const response = await HTTP.post(`Campaign/duplicate`, {
          campaignId: id,
          isFullDuplicate: true,
        })

        return Promise.resolve(response?.data?.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async archive(context, { id }) {
      try {
        const response = await HTTP.get(`Campaign/Status`, {
          params: {
            campaignId: id,
            status: 'Archived',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async pause(context, { id }) {
      try {
        const response = await HTTP.get(`Campaign/Status`, {
          params: {
            campaignId: id,
            status: 'Paused',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async resume(context, { id }) {
      try {
        const response = await HTTP.get(`Campaign/Status`, {
          params: {
            campaignId: id,
            status: 'Running',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, rootState }) {
      const countryCode = rootState.auth.user.countryCode

      return HTTP.get(
        countryCode
          ? `Campaign/list/${countryCode.toLowerCase()}`
          : `Campaign/list`
      )
        .then((response) => {
          commit('SET_LIST', response.data.datas)

          return response
        })
        .catch((error) => {
          commit('SET_LIST', [])

          return Promise.reject(error)
        })
    },

    async search({ commit, rootState }, { search, fields }) {
      try {
        const countryCode = rootState.auth.user.countryCode
        const response = await HTTP.get(
          countryCode
            ? `Campaign/list/${countryCode.toLowerCase()}`
            : `Campaign/list`
        )

        const searchTerms = search.split(' ').map((searchTerm) => {
          return searchTerm
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        })

        const matchedFlights = response.data.datas.filter(({ flightIds }) => {
          return flightIds?.some((flightId) =>
            searchTerms.some((term) => flightId.toString().includes(term))
          )
        })

        response.data.datas = distinctList([
          ...searchList(response.data.datas, fields, search),
          ...matchedFlights,
        ])

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    listChildren(_, { id }) {
      return HTTP.get(`Flight/list`, {
        params: {
          campaignId: id,
        },
      }).then((response) => {
        return response.data.datas
      })
    },

    downloadLogs(_, { id }) {
      try {
        const response = HTTP.get(`Campaign/${id}/audit/download`, {
          executeInBackground: true,
          responseType: 'blob',
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    async setDefaultForm({ commit, rootState }) {
      const form = await import('@/forms/campaign.js')
      const defaultForm = _cloneDeep(form.default)

      commit('SET_FORM', {
        ...defaultForm,
        countryCode: rootState.auth.user?.countryCode || null,
        currencyCode: rootState.organization.defaultCurrency || null,
      })
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },
    async getListCampaign(
      { commit, state, rootState },
      { initialize = true } = {}
    ) {
      try {
        if (initialize) {
          await commit('SET_LIST_PAGE', 0)
          await commit('SET_LIST', [])
        } else {
          commit('SET_LIST_LOADING_MORE', true)
        }

        const countryCode = rootState.auth.user.countryCode
        const params = { ...state.listFilters }

        delete params.country

        if (state.listOrderBy) {
          params.orderBy = `${state.listOrderBy
            .toUpperCase()
            .replace('W', '')}${
            state.listOrderByDirection === 'desc'
              ? state.listOrderByDirection.toUpperCase()
              : ''
          }`
        }

        const searchTerms = state.listSearch
          ? encodeURIComponent(state.listSearch)
          : ''

        const response = await HTTP.get(
          `Campaign/paging/${countryCode ? `${countryCode}/` : ''}${
            state.listSearch ? `search/${searchTerms}/` : ''
          }${state.listPage}`,
          { params, paramsSerializer }
        )

        commit('ADD_TO_LIST', response.data.datas?.list)
        commit('SET_LIST_PAGING', response.data.datas?.paging)
        commit('SET_LIST_STATUS_COUNT', response.data.datas?.countByStatuses)
      } catch (error) {
        console.error(error)
        // commit('SET_LIST', [])
      } finally {
        if (!initialize) {
          commit('SET_LIST_LOADING_MORE', false)
        }
      }
    },

    async loadMore({ commit, dispatch, state }) {
      await commit('SET_LIST_PAGE', state.listPage + 1)

      dispatch('getListCampaign', { initialize: false })
    },

    toggleListFilter({ commit, state }, { key, value }) {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          commit('REMOVE_LIST_FILTER', key)
        } else {
          commit('SET_LIST_FILTER', { [key]: value })
        }
      } else if (key in state.listFilters && state.listFilters[key] === value) {
        commit('REMOVE_LIST_FILTER', key)
      } else {
        commit('SET_LIST_FILTER', { [key]: value })
      }
    },

    async listSort({ commit }, { key, direction }) {
      if (key === null) {
        await commit('SET_LIST_SORT', null)
        await commit('SET_LIST_SORT_DIRECTION', null)
      } else {
        await commit('SET_LIST_SORT_DIRECTION', direction)
        await commit('SET_LIST_SORT', key)
      }
    },
  },
}

function mapDeals(form) {
  return form?.deals?.map((deal) => ({
    ...deal,
    providers: deal.providers?.map((provider) =>
      provider.id === 72
        ? {
            ...provider,
            identifierReceivedFromProvider:
              provider.identifierReceivedFromProvider.replace('d:', ''),
            identifierSentToProvider: provider.identifierSentToProvider.replace(
              'd:',
              ''
            ),
          }
        : provider
    ),
  }))
}
