import _cloneDeep from 'lodash/cloneDeep'
import HTTP from '@/services/http'
import { parseISO, format } from 'date-fns'
import { formats } from '@/helpers/dateFns'

const SET_DATA = 'SET_DATA'
const GET_CARRIERS_LIST = 'GET_CARRIERS_LIST'
const SET_LOADING_STATE = 'SET_LOADING_STATE'
const CHANGE_FILTERS = 'MAGIC_TOKEN_CAMPAIGN_REPORT_CHANGE_FILTERS'

const initialState = () => ({
  filters: {
    adPlacement: null,
    creativeId: null,
    carrierId: null,
    deviceType: 'All',
    formatId: null,
    genderType: 'Both',
    campaignId: null,
    flightId: null,
    publisherId: null,
    channelId: null,
    budgetId: null,
    IABCategoryId: null,
    uid: null,
    selectedPeriod: {
      start: null,
      end: null,
    },
  },
  MainData: null,
  GeolocalizedDistribution: null,
  GenderAndAgeBreakdown: null,
  DevicesReport: null,
  CarriersReport: null,
  AdPlacementsReport: null,
  ImpressionsOverTimeReport: null,
  FlightsBreakdownReport: null,
  PublishersBreakdownReport: null,
  CreativesBreakdownReport: null,
  RelatedFlightsReport: null,
  RelatedCreativesReport: null,
  PublishersReport: null,
  FormatsReport: null,
  carriersList: [],
  loadingStates: {},
  displayData: {
    currentCampaign: null,
    currentPublisher: null,
    currentFlight: null,
    currentCreative: null,
    currentChannel: null,
    currentBudget: null,
    currentFormat: null,
  },
})

export const magicTokenCampaignReport = {
  state: initialState,

  getters: {
    tokenApiParams: (state) => {
      return {
        startDate: format(
          parseISO(state.filters.selectedPeriod.start),
          formats.ISO.date
        ),
        endDate: format(
          parseISO(state.filters.selectedPeriod.end),
          formats.ISO.date
        ),
        uid: state.filters.uid,
        budgetId: state.filters.budgetId,
        channelId: state.filters.channelId,
        creativeId: state.filters.creativeId,
        flightId: state.filters.flightId,
        formatId: state.filters.formatId,
        publisherId: state.filters.publisherId,
        carrierId: state.filters.carrierId,
        deviceType: state.filters.deviceType,
        genderType: state.filters.genderType,
        adPlacement: state.filters.adPlacement,
        mapCode: state.filters.mapCode,
        IABCategoryId: state.filters.IABCategoryId,
      }
    },
  },

  mutations: {
    [SET_DATA](state, { dataName, data }) {
      state[dataName] = data
    },

    [GET_CARRIERS_LIST](state, payload) {
      state.carriersList = payload
    },

    [SET_LOADING_STATE](state, { propName, value }) {
      const ls = {}

      ls[propName] = value || false
      state.loadingStates = Object.assign({}, state.loadingStates, ls)
    },

    [CHANGE_FILTERS](state, filters) {
      state.filters = _cloneDeep({ ...state.filters, ...filters })

      if (!state.filters.flightId && state.displayData.currentFlight) {
        state.displayData.currentFlight = null
      }
      if (!state.filters.budgetId && state.displayData.currentBudget) {
        state.displayData.currentBudget = null
      }
      if (!state.filters.creativeId && state.displayData.currentCreative) {
        state.displayData.currentCreative = null
      }
      if (!state.filters.channelId && state.displayData.currentChannel) {
        state.displayData.currentChannel = null
      }
      if (!state.filters.formatId && state.displayData.currentFormat) {
        state.displayData.currentFormat = null
      }
      if (!state.filters.publisherId && state.displayData.currentPublisher) {
        state.displayData.currentPublisher = null
      }
    },
  },

  actions: {
    async getTokenReportData({ commit, getters }, { type, endpoint }) {
      commit(SET_LOADING_STATE, { propName: endpoint, value: true })
      try {
        const params = getters.tokenApiParams
        const response = await HTTP.get(`Share/${type}${endpoint}`, { params })

        commit(SET_DATA, { dataName: endpoint, data: response.data.datas })
      } catch (error) {
        console.error(error)
      } finally {
        commit(SET_LOADING_STATE, { propName: endpoint, value: false })
      }
    },

    async getMagicTokenCarriersList({ commit }, { uid }) {
      commit(SET_LOADING_STATE, { propName: 'getCarriersList', value: true })
      commit(GET_CARRIERS_LIST, null)
      try {
        const response = await HTTP.get(`Share/Carriers/${uid}`)

        commit(GET_CARRIERS_LIST, response.data.datas)
      } catch (error) {
        console.error(error)
      } finally {
        commit(SET_LOADING_STATE, { propName: 'getCarriersList', value: false })
      }
    },

    changeMagicTokenCampaignReportFilters({ commit }, filters) {
      commit(CHANGE_FILTERS, filters)
    },
  },
}
