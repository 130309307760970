import HTTP from '@/services/http'
import _isEqual from 'lodash/isEqual'

export default {
  namespaced: true,

  state: {
    flightId: null,
    flightDates: {
      start: null,
      end: null,
    },
    isDirty: {
      global: false,
    },
    preset: null,
    rules: [],
    weekSchedule: [],
  },

  getters: {
    presetRules: (state) => {
      if (!state.preset) {
        return []
      }

      if (state.preset !== 'Custom') {
        return [
          {
            startDay: state.flightDates.start,
            endDay: state.flightDates.end,
            isAllDay: true,
            isIncluded: true,
            id: 'preset',
            ...state.weekSchedule.reduce((days, day) => {
              days[day.dayOfWeek.toLowerCase()] = true

              return days
            }, {}),
          },
        ]
      }

      return state.weekSchedule.map((rule) => {
        const day = rule.dayOfWeek.toLowerCase()

        return {
          ...rule,
          startDay: state.flightDates.start,
          endDay: state.flightDates.start,
          [day]: true,
          isIncluded: true,
          id: day,
        }
      })
    },
  },

  mutations: {
    RESET_STATE(state) {
      state.flightId = null
      state.flightDates = {
        start: null,
        end: null,
      }
      state.preset = null
      state.rules = []
      state.weekSchedule = []
    },
    ADD_RULE(state, rule) {
      state.rules.push(rule)
    },
    EDIT_RULE(state, rule) {
      const index = state.rules.findIndex((r) => r.id === rule.id)

      if (index >= 0) {
        const newRules = [...state.rules]

        newRules[index] = rule

        state.rules = newRules
      }
    },
    DELETE_RULE(state, rule) {
      state.rules = state.rules.filter((r) => !_isEqual(rule, r))
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FLIGHT_ID(state, flightId) {
      state.flightId = flightId
    },
    SET_FLIGHT_DATES(state, { start, end }) {
      state.flightDates = { start, end }
    },
    SET_RULES(state, rules) {
      state.rules = rules
    },
    SET_PRESET(state, preset) {
      state.preset = preset
    },
    SET_WEEK_SCHEDULE(state, weekSchedule) {
      state.weekSchedule = weekSchedule
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    async fetchRules({ commit, state }, { id, force = false }) {
      if (!force && state.flightId === id && state.rules.length) {
        return Promise.resolve()
      }

      try {
        const response = await HTTP.get(`FlightRule/list/${id}`)

        commit('SET_RULES', response.data.datas)
        commit('SET_FLIGHT_ID', id)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchPreset({ commit, state }, { id, force = false }) {
      if (!force && state.flightId === id && state.preset) {
        return Promise.resolve()
      }

      try {
        const response = await HTTP.get(
          `FlightSchedule/GetPreset?flightId=${id}`
        )

        commit('SET_PRESET', response.data.datas.flightWeekScheduleType)
        commit('SET_FLIGHT_ID', id)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchWeekSchedule({ commit, state }, { id, force = false }) {
      if (!force && state.flightId === id && state.weekSchedule.length) {
        return Promise.resolve()
      }

      try {
        const response = await HTTP.get(
          `FlightSchedule/WeekSchedule?flightId=${id}`
        )

        commit('SET_WEEK_SCHEDULE', response.data.datas.weekSchedule)
        commit('SET_FLIGHT_ID', id)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }) {
      try {
        const request = {
          flightId: state.flightId,
          flightWeekScheduleType: state.preset,
          flightRules: state.rules,
        }

        if (state.preset === 'Custom') {
          request.weekSchedule = state.weekSchedule
        }

        const response = await HTTP.put(`FlightSchedule/FullEdit`, request)

        commit('SET_DIRTY', { isDirty: false, key: 'global' })
        commit('SET_RULES', response.data.datas.flightRules)
        commit('SET_WEEK_SCHEDULE', response.data.datas.weekSchedule)

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    discardChanges({ commit }) {
      commit('SET_DIRTY', { isDirty: false, key: 'global' })
    },

    setPreset({ commit, state }, preset) {
      if (!state.isDirty.global) {
        commit('SET_DIRTY', { isDirty: true, key: 'global' })
      }

      commit('SET_PRESET', preset)

      if (preset === 'Custom') {
        commit('SET_WEEK_SCHEDULE', [])
      }
    },

    setWeekSchedule({ commit, state }, weekSchedule) {
      if (!state.isDirty.global) {
        commit('SET_DIRTY', { isDirty: true, key: 'global' })
      }

      commit('SET_WEEK_SCHEDULE', weekSchedule)

      if (state.preset !== 'Custom') {
        commit('SET_PRESET', 'Custom')
      }
    },

    setFlightDates({ commit }, { start, end }) {
      commit('SET_FLIGHT_DATES', { start, end })
    },

    addRule({ commit, state }, rule) {
      if (!state.isDirty.global) {
        commit('SET_DIRTY', { isDirty: true, key: 'global' })
      }

      commit('ADD_RULE', {
        ...rule,
        id: Symbol(),
        isActive: true,
      })
    },

    editRule({ commit, state }, rule) {
      if (!state.isDirty.global) {
        commit('SET_DIRTY', { isDirty: true, key: 'global' })
      }

      commit('EDIT_RULE', rule)
    },

    deleteRule({ commit, state }, rule) {
      if (!state.isDirty.global) {
        commit('SET_DIRTY', { isDirty: true, key: 'global' })
      }

      commit('DELETE_RULE', rule)
    },
  },
}
