<script setup lang="ts">
import type { PropType } from 'vue'
import { nextTick, reactive, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import Tooltip from '@/components/Tooltip.vue'

const emit = defineEmits<{ update: [string | null] }>()

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String as PropType<string | null>,
    default: '',
  },
})

const editForm = reactive({
  title: props.title,
})
const isEditing = ref(false)
const input = ref<HTMLElement | null>(null)
const handleEdit = (): void => {
  isEditing.value = true

  nextTick(() => {
    input.value?.focus()
  })
}

const handleCancel = (): void => {
  editForm.title = props.title
  isEditing.value = false
  emit('update', null)
}

const handleSubmit = async (): Promise<void> => {
  emit('update', editForm.title)

  isEditing.value = false
}
</script>

<template>
  <div>
    <div v-if="props.disabled">
      <div class="tw-group tw-flex tw-h-12 tw-cursor-pointer tw-items-center">
        <div class="tw-w-full tw-truncate tw-text-sm">
          <Tooltip
            tag="span"
            :title="props.title"
          >
            {{ props.title }}
          </Tooltip>
        </div>
      </div>
    </div>
    <div v-else-if="!isEditing">
      <div
        class="tw-group tw-flex tw-h-12 tw-cursor-pointer tw-items-center"
        @click="handleEdit"
      >
        <div class="tw-w-(full-2) tw-truncate tw-text-sm">
          <Tooltip :title="props.title">
            {{ props.title }}
          </Tooltip>
        </div>
        <button
          class="tw-ml-auto tw-hidden tw-w-8 tw-text-gray-600 group-hover:tw-inline-block"
        >
          <Icon name="edit" />
        </button>
      </div>
    </div>
    <form
      v-else
      class="tw-flex tw-h-12 tw-items-center tw-rounded-md tw-bg-gray-500 tw-bg-opacity-25 tw-pl-3"
      @submit.prevent="handleSubmit"
    >
      <input
        ref="input"
        v-model="editForm.title"
        type="text"
        maxlength="80"
        class="tw-mr-1 tw-w-full tw-bg-transparent tw-text-sm tw-outline-none"
        @keyup.esc="handleCancel"
      />
      <button
        type="submit"
        class="tw-mr-1 tw-rounded tw-bg-white tw-p-3 tw-text-gray-600 tw-outline-none hover:tw-text-primary"
      >
        <Icon name="check" />
      </button>
    </form>
  </div>
</template>
