import _cloneDeep from 'lodash/cloneDeep'
import HTTP from '@/services/http'
import router from '@/router/index.ts'
import { baseUrl } from '@/helpers/url'

export default {
  namespaced: true,

  state: {
    accessToken: localStorage.getItem('accessToken'),
    authenticated: false,
    authenticating: true,
    claims: [],
    functions: [],
    refreshing: false,
    refreshToken: localStorage.getItem('refreshToken'),
    subFunctions: [],
    user: null,
    gcuAccepted: false,
  },

  getters: {
    currentOrganization: (state) => {
      return (
        (state.user &&
          state.user.organizationList &&
          state.user.organizationList.find(
            (organization) => organization.id === state.user.organizationId
          )) ||
        null
      )
    },

    currentUser: (state) => {
      return state.user ?? null
    },
    defaultRoute: (state) => {
      if (
        state.functions.some((functionName) => functionName.includes('Admin'))
      ) {
        return { name: 'People', meta: { force: true } }
      }

      if (
        state.functions.some((functionName) => functionName.includes('Manager'))
      ) {
        return { name: 'DashBoard', meta: { force: true } }
      }

      if (
        state.functions.some((functionName) =>
          functionName.includes('Trafficker')
        )
      ) {
        return { name: 'Campaigns', meta: { force: true } }
      }
      // PASSPORTV2-9086 hide billing
      // if (
      //   state.functions.some((functionName) => functionName.includes('Billing'))
      // ) {
      //   return { name: 'Billing', meta: { force: true } }
      // }

      if (
        state.functions.some((functionName) => functionName.includes('Biz'))
      ) {
        return { name: 'DashBoard', meta: { force: true } }
      }

      if (
        state.functions.some((functionName) =>
          functionName.includes('Publisher')
        )
      ) {
        return { name: 'Inventory', meta: { force: true } }
      }

      return { name: 'DashBoard', meta: { force: true } }
    },
    isLoggedIn: (state) => {
      return (
        state.authenticated === true &&
        !state.authenticating &&
        Boolean(state.user)
      )
    },
    isInSimplifiedOrganization: (state, getters) => {
      return (
        getters.currentOrganization &&
        getters.currentOrganization.campaignFlag === 'SingleFlight'
      )
    },
    organizationId: (state) => {
      return state.user && state.user.organizationId
    },
  },

  mutations: {
    RESET_STATE(state) {
      state.accessToken = null
      state.refreshToken = null
      state.authenticated = false
      state.authenticating = false
      state.refreshing = false
      state.claims = []
      state.functions = []
      state.subFunctions = []
      state.user = null

      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      // localStorage.clear()
    },
    SET_AUTHENTICATING(state, authenticating) {
      state.authenticating = authenticating
    },
    SET_AUTHENTICATED(state, authenticated) {
      state.authenticated = authenticated
    },
    SET_REFRESHING(state, refreshing) {
      state.refreshing = refreshing
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        HTTP.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      } else {
        localStorage.removeItem('accessToken')
        HTTP.defaults.headers.common['Authorization'] = null
      }
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
      } else {
        localStorage.removeItem('refreshToken')
      }
    },
    SET_CLAIMS(state, claims) {
      state.claims = claims
    },
    SET_FUNCTIONS(state, functions) {
      state.functions = functions
    },
    SET_SUB_FUNCTIONS(state, subFunctions) {
      state.subFunctions = subFunctions
    },
    SET_USER(state, user) {
      state.user = _cloneDeep(user)
    },
    SET_USER_COUNTRY(state, countryCode) {
      if (state.user) {
        state.user.countryCode = countryCode
      }
    },
    SET_GCU_ACCEPTED(state, bool) {
      state.gcuAccepted = bool
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async login({ commit }, credentials) {
      try {
        const response = await HTTP.post('Account/login', credentials, {
          headers: {
            Authorization: null,
          },
          withoutError: true,
          executeInBackground: true,
        })

        commit('SET_ACCESS_TOKEN', response.data.datas.accessToken)

        if (credentials.remember) {
          commit('SET_REFRESH_TOKEN', response.data.datas.refreshToken)
        }

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_ACCESS_TOKEN', null)
        commit('SET_REFRESH_TOKEN', null)

        return Promise.reject(error)
      }
    },

    async refresh({ commit, state }) {
      commit('SET_REFRESHING', true)

      try {
        const response = await HTTP.post(
          'Account/login',
          {
            refreshToken: state.refreshToken,
            grantType: 'RefreshToken',
          },
          {
            headers: {
              Authorization: null,
            },
            withoutError: true,
            executeInBackground: true,
          }
        )

        commit('SET_ACCESS_TOKEN', response.data.datas.accessToken)
        commit('SET_REFRESH_TOKEN', response.data.datas.refreshToken)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_ACCESS_TOKEN', null)
        commit('SET_REFRESH_TOKEN', null)

        return Promise.reject(error)
      } finally {
        commit('SET_REFRESHING', false)
      }
    },

    async logout({ commit }) {
      commit('RESET_STATE')
    },

    async setup({ commit, dispatch }) {
      try {
        commit('SET_AUTHENTICATING', true)

        await dispatch('fetch')

        const promises = [
          await dispatch('fetchFunctions'),
          dispatch('fetchSubFunctions'),
          dispatch('fetchClaims'),
          dispatch('organization/listCountries', null, { root: true }),
        ]

        await Promise.all(promises)

        commit('SET_AUTHENTICATED', true)

        return Promise.resolve()
      } catch (error) {
        console.error('error', error)
        commit('SET_AUTHENTICATED', false)

        return Promise.reject(error)
      } finally {
        commit('SET_AUTHENTICATING', false)
      }
    },

    async sync({ dispatch, state, getters }) {
      try {
        const currentUser = getters.currentUser

        await dispatch('fetch', { background: false })

        const promises = []

        if (
          !currentUser ||
          currentUser.organizationId !== state.user.organizationId
        ) {
          promises.push(dispatch('fetchFunctions'))
          promises.push(dispatch('fetchSubFunctions'))
          promises.push(dispatch('fetchClaims'))
          promises.push(
            dispatch('organization/listCountries', null, { root: true })
          )
        }

        await Promise.all(promises)

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetch({ commit, dispatch, state }, { background = false } = {}) {
      try {
        const response = await HTTP.get('Account/self', {
          withoutError: true,
          executeInBackground: true,
        })

        if (background) {
          if (
            ['id', 'organizationId', 'countryCode', 'currencyCode'].some(
              (key) => state.user[key] !== response.data.datas[key]
            )
          ) {
            commit('SET_USER', response.data.datas)
          }
        } else {
          commit('SET_USER', response.data.datas)
        }

        dispatch('organization/getOrganizationInfos', null, { root: true })

        dispatch(
          'notifications/setCountUnread',
          response.data.datas.unreadNotificationsCount,
          { root: true }
        )
        dispatch('organization/setTheme', null, { root: true })

        commit(
          'SET_GCU_ACCEPTED',
          response.data.datas.gcu.conditionsOfUse.every(
            ({ acceptationDate }) => acceptationDate !== null
          )
        )
        return Promise.resolve(response)
      } catch (error) {
        commit('SET_USER', null)
        console.error(error)
        return Promise.reject(error)
      }
    },

    async fetchClaims({ commit, state }, { force = false } = {}) {
      if (!force && state.claims.length > 0) {
        return Promise.resolve()
      }

      try {
        const response = await HTTP.get('Account/self/claims', {
          withoutError: true,
          executeInBackground: true,
        })

        commit(
          'SET_CLAIMS',
          Object.values(response.data.datas).reduce((claims, groupClaims) => {
            claims = [...claims, ...groupClaims]

            return claims
          }, [])
        )

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_CLAIMS', [])

        return Promise.reject(error)
      }
    },

    async fetchConfig({ commit }) {
      try {
        const response = await HTTP.get('UserCurrentConfig/self', {
          withoutError: true,
          executeInBackground: true,
        })

        commit('SET_CONFIG', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_CONFIG', null)

        return Promise.reject(error)
      }
    },

    async fetchFunctions({ commit }) {
      try {
        const response = await HTTP.get('Account/self/functions', {
          withoutError: true,
          executeInBackground: true,
        })

        commit('SET_FUNCTIONS', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_FUNCTIONS', [])

        return Promise.reject(error)
      }
    },

    async fetchSubFunctions({ commit }) {
      try {
        const response = await HTTP.get('Account/self/subfunctions', {
          withoutError: true,
          executeInBackground: true,
        })

        commit('SET_SUB_FUNCTIONS', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_SUB_FUNCTIONS', [])

        return Promise.reject(error)
      }
    },

    async sendPasswordResetLink(_, { email }) {
      try {
        const response = await HTTP.post('Account/lost-password', {
          email,
          baseUrl: `${baseUrl}${
            router.resolve({ name: 'ResetPassword' }).href
          }/`,
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async agreedLegals({ commit }, form) {
      const response = await HTTP.put('gcu', form)

      commit('SET_GCU_ACCEPTED', true)

      return response
    },

    async resetPassword(_, { password, token }) {
      try {
        const response = await HTTP.post('Account/reset-password', {
          newPassword: password,
          lostPasswordTokenUid: token,
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async setPassword(_, { password, token }) {
      try {
        const response = await HTTP.post('Account/set-password', {
          newPassword: password,
          setPasswordTokenUid: token,
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    setToken({ commit }, { accessToken = null, refreshToken = null }) {
      commit('SET_ACCESS_TOKEN', accessToken)
      commit('SET_REFRESH_TOKEN', refreshToken)
    },

    setCountry({ commit }, { countryCode }) {
      commit('SET_USER_COUNTRY', countryCode.toUpperCase())
    },
  },
}
