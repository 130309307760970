import { intlFormatCurrency, numberToI18n } from '@/helpers/i18n.js'
import store from '@/store/index'

const formatCurrency = (
  value: number | null,
  currencyCode: string | null = null,
  defaultValue = 0
): string | null | undefined => {
  if (currencyCode === null) {
    currencyCode = store.state.auth.user?.currencyCode || 'EUR'
  }

  if (value === null || isNaN(value)) {
    value = defaultValue
  }

  return intlFormatCurrency(
    value,
    store.state.auth.user?.userPreference?.numberI18n || 'EU',
    currencyCode
  )
}

const formatNumber = (value = 0, fractionDigits = 0): string => {
  value = Number(value)
  fractionDigits = Number(fractionDigits)

  if (value === null || isNaN(value)) {
    return '0'
  }
  if (fractionDigits === null || isNaN(fractionDigits)) {
    fractionDigits = 0
  }

  return numberToI18n(
    value,
    store.state.auth.user?.userPreference?.numberI18n || 'EU',
    {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }
  )
}

const isNumber = (value: number): boolean => !isNaN(value) && isFinite(value)

export { formatCurrency, formatNumber, isNumber }
