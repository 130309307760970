<template>
  <div class="modal">
    <div
      v-if="popupVisibility"
      role="dialog"
      class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center"
      style="z-index: 9999"
    >
      <transition
        enter-active-class="tw-transition-opacity tw-duration-200 tw-ease-in-out"
        enter-from-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-transition-opacity tw-duration-200 tw-ease-in-out"
        leave-from-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
      >
        <div
          v-if="transition"
          class="tw-absolute tw--z-1 tw-h-full tw-w-full tw-bg-black tw-bg-opacity-25"
          @click="hidePopup"
        />
      </transition>
      <transition
        enter-active-class="tw-transition tw-duration-200 tw-ease-in-out tw-transform"
        enter-from-class="tw-opacity-0 tw-scale-90"
        enter-to-class="tw-opacity-100 tw-scale-100"
        leave-active-class="tw-transition tw-duration-200 tw-ease-in-out tw-transform"
        leave-from-class="tw-opacity-100 tw-scale-100"
        leave-to-class="tw-opacity-0 tw-scale-90"
      >
        <div
          v-if="transition"
          class="tw-rounded tw-bg-white"
        >
          <component
            :is="component"
            v-bind="popupProps"
            @close="hidePopup"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  data() {
    return {
      component: null,
      escapeHandler: null,
      transition: false,
    }
  },
  computed: {
    popupCanExit() {
      return this.$store.state.popup.canExit
    },
    popupName() {
      return this.$store.state.popup.name
    },
    popupProps() {
      return this.$store.state.popup.props
    },
    popupVisibility() {
      return this.$store.state.popup.visibility
    },
  },
  watch: {
    async popupVisibility(visibility) {
      if (!visibility) {
        this.component = null
        return
      }
      setTimeout(() => {
        this.transition = true
        this.component = this.popupName
      }, 200)
    },
  },
  mounted() {
    this.escapeHandler = (e) => {
      if (e.keyCode === 27) {
        this.hidePopup
      }
    }

    document.addEventListener('keydown', this.escapeHandler)
  },
  unmounted() {
    document.removeEventListener('keydown', this.escapeHandler)
  },
  methods: {
    hidePopup() {
      if (!this.popupCanExit) return

      this.transition = false
      setTimeout(() => {
        this.$store.dispatch('popup/hide')
      }, 200)
    },
  },
}
</script>
