import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueGridLayout from 'vue-grid-layout'
import { createMetaManager } from 'vue-meta'

import Cache from '@/services/cache.js'
import pluginBugsnag from '@/plugins/bugsnag.js'
import pluginFilters from '@/plugins/filters.js'
import pluginHelpers from '@/plugins/helpers.js'
import pluginHotjar from '@/plugins/hotjar.js'
import pluginPopups from '@/plugins/popups.js'
import pluginTooltip from '@/plugins/tooltip.js'
import router from '@/router/index'
import store from '@/store/index'
import VCalendar from 'v-calendar'

import '@/assets/css/tailwind.css'
import '@/assets/css/theme.css'

import '@/plugins/interceptors.js'

import App from '@/App.vue'

Cache.sync()

Promise.all([store.dispatch('auth/setup')]).finally(() => {
  const app = createApp(App)
  const pinia = createPinia()

  app.use(pinia)
  app.use(pluginFilters)
  app.use(pluginHelpers)
  app.use(pluginBugsnag)
  app.use(pluginHotjar)
  app.use(pluginPopups)
  app.use(pluginTooltip)
  app.use(router)
  app.use(createMetaManager())
  app.use(store)
  app.use(VueGridLayout)
  app.use(VCalendar, {
    componentPrefix: 'vc',
  })
  app.mount('#app')
})
