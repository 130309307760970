import { searchList, searchListDeep } from '@/helpers/search'
import { sortList, sortObjects } from '@/helpers/sort'

export default {
  methods: {
    searchList,
    searchListDeep,
    sortList,
    sortObjects,
  },
}
