/**
 * This object contains all the types of widgets available on the Dashboard
 * It has 2 levels :
 *    1) Information about the widget (card width, card height, enabled actions, API endpoint)
 *    2) Options for the API calls and the customization of the widget itself
 *        (graph mode, list mode, country, title, etc...)
 */
const defaultCountry = '00'
const defaultCurrency = 'USD'
const countryLocationNames = {
  '00': 'Top Locations',
  BE: 'Top Provinces',
  CA: 'Top States',
  DE: 'Top Regions',
  ES: 'Top Provincias',
  FR: 'Top Départements',
  GB: 'Top Counties',
  IT: 'Top Regions',
  NL: 'Top Regions',
  PT: 'Top Regions',
  US: 'Top States',
}

export default {
  ImpressionsSold: {
    w: 1,
    h: 2,
    options: {
      title: 'Impressions Sold',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/ImpressionsSold',
  },
  AverageCpm: {
    w: 1,
    h: 2,
    options: {
      title: 'Average CPM',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/AverageCpm',
  },
  Addressability: {
    w: 1,
    h: 1,
    options: {
      title: 'Addressability',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      hideCurrencyFilter: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/Addressability',
  },
  Audibility: {
    w: 1,
    h: 1,
    options: {
      title: 'Audibility',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      hideCurrencyFilter: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/Audibility',
  },
  LtrVsCtr: {
    w: 1,
    h: 2,
    options: {
      title: 'LTR vs CTR',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      hideCurrencyFilter: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/LtrVsCtr',
  },
  //? PASSPORTV2-5303
  // InternalVsExternalFlight: {
  //   w: 1,
  //   h: 2,
  //   options: {
  //     title: 'Internal Vs External Flight',
  //     country: defaultCountry,
  //     currency: defaultCurrency,
  //     isDefault: true,
  //     hideCurrencyFilter: true
  //   },
  //   actions: ['Delete', 'Duplicate', 'Edit'],
  //   endpoint: '/Widget/InternalVsExternalFlight'
  // },
  PercentageImpressionPerDays: {
    w: 1,
    h: 2,
    options: {
      title: 'Percentage Impressions Per Day',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      hideCurrencyFilter: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/PercentageImpressionPerDays',
  },
  PeriodRevenue: {
    w: 1,
    h: 2,
    options: {
      title: 'Period Revenue',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/revenue',
  },
  GlobalRevenue: {
    w: 1,
    h: 2,
    options: {
      title: 'Global Revenue',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/revenue',
  },
  GlobalRevenueByCountries: {
    w: 1,
    h: 2,
    options: {
      title: 'Global Revenue By Countries',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      mode: 'graph',
      unit: 'percent',
    },
    actions: [
      'Delete',
      'SelectGraphMode',
      'SelectListMode',
      'Duplicate',
      'Edit',
    ],
    endpoint: '/Widget/GlobalRevenueByCountries',
  },
  TopDepartments: {
    w: 2,
    h: 4,
    options: {
      title: Object.keys(countryLocationNames).includes(
        defaultCountry.toUpperCase()
      )
        ? countryLocationNames[defaultCountry.toUpperCase()]
        : 'Top Locations',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      hideCurrencyFilter: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/TopDepartments',
  },
  FillRate: {
    w: 1,
    h: 1,
    options: {
      title: 'Publishers Fill Rate',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      hideCurrencyFilter: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/FillRate',
  },
  RenderRate: {
    w: 1,
    h: 1,
    options: {
      title: 'Publishers Render Rate',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      hideCurrencyFilter: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/RenderRate',
  },
  //? PASSPORTV2-5303
  // TopCampaignManagers: {
  //   w: 1,
  //   h: 2,
  //   options: {
  //     title: 'Top Campaign Managers',
  //     country: defaultCountry,
  //     currency: defaultCurrency,
  //     isDefault: true,
  //     hideCurrencyFilter: true
  //   },
  //   actions: ['Delete', 'Duplicate', 'Edit'],
  //   endpoint: '/Widget/TopCampaignManagers'
  // },
  DevicesBreakout: {
    w: 1,
    h: 2,
    options: {
      title: 'Devices Breakout',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      mode: 'graph',
      hideCurrencyFilter: true,
    },
    actions: [
      'Delete',
      'SelectGraphMode',
      'SelectListMode',
      'Duplicate',
      'Edit',
    ],
    endpoint: '/Widget/DevicesBreakout',
  },
  FormatsBreakout: {
    w: 1,
    h: 2,
    options: {
      title: 'Formats Breakout',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      mode: 'graph',
      hideCurrencyFilter: true,
    },
    actions: [
      'Delete',
      'SelectGraphMode',
      'SelectListMode',
      'Duplicate',
      'Edit',
    ],
    endpoint: '/Widget/FormatsBreakout',
  },
  TopAdvertisers: {
    w: 1,
    h: 2,
    options: {
      title: 'Top Advertisers',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/TopAdvertisers',
  },
  TopAgencies: {
    w: 1,
    h: 2,
    options: {
      title: 'Top Agencies',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
    },
    actions: ['Delete', 'Duplicate', 'Edit'],
    endpoint: '/Widget/TopAgencies',
  },
  TopStations: {
    w: 1,
    h: 2,
    options: {
      title: 'Top Stations',
      country: defaultCountry,
      currency: defaultCurrency,
      isDefault: true,
      mode: 'graph',
      unit: 'allocations',
    },
    actions: [
      'Delete',
      'SelectGraphMode',
      'SelectListMode',
      'Duplicate',
      'Edit',
    ],
    endpoint: [
      '/Widget/TopChannelsByImpressions',
      '/Widget/TopChannelsByAllocations',
      '/Widget/TopChannelsByRevenue',
    ],
  },
}
