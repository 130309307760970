<script setup lang="ts">
import PassportButton from '@/components/PassportButton.vue'
import { type PropType, reactive } from 'vue'
import { type RouteLocationNormalized, useRouter } from 'vue-router'
import { handleError } from '@/helpers/handleError'

const emit = defineEmits<{ (e: 'close'): void }>()

const props = defineProps({
  routeTo: {
    type: Object as PropType<RouteLocationNormalized | null>,
    default: null,
  },
  apply: {
    type: Function as PropType<() => Promise<void>>,
    required: true,
  },
  discard: {
    type: Function as PropType<() => Promise<void>>,
    default: null,
  },
})

const router = useRouter()

const buttons = reactive({
  apply: {
    isLoading: false,
    shouldRetry: false,
  },
  discard: {
    isLoading: false,
    shouldRetry: false,
  },
})

const state = reactive({
  error: '',
})

const handleApplyChanges = async (): Promise<void> => {
  buttons.apply.isLoading = true
  try {
    await props.apply()

    return handleNavigate()
  } catch (error) {
    buttons.apply.shouldRetry = true

    state.error = handleError(error)
  } finally {
    buttons.apply.isLoading = false
  }
}
const handleDiscardChanges = async (): Promise<void> => {
  buttons.discard.isLoading = true
  try {
    if (props.discard) {
      await props.discard()
    }

    return handleNavigate()
  } catch (error) {
    buttons.discard.shouldRetry = true

    state.error = handleError(error)
  } finally {
    buttons.discard.isLoading = false
  }
}
const handleNavigate = async (): Promise<void> => {
  if (props.routeTo) {
    await router.push(props.routeTo)
  }

  emit('close')
}
</script>

<template>
  <div class="tw-w-modal-lg tw-divide-y tw-divide-border-light">
    <div class="tw-p-6">
      <h2 class="tw-text-center tw-text-xl tw-font-bold tw-text-black">
        Some changes are not saved
      </h2>
    </div>
    <div class="tw-p-6 tw-text-center tw-leading-loose">
      Some of your changes have not been saved.
      <br />
      Would you like to save them before leaving?
    </div>
    <div
      v-if="state.error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ state.error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        :disabled="buttons.apply.isLoading || buttons.discard.isLoading"
        variant="basic"
        label="Cancel"
        @@click="emit('close')"
      />
      <div class="tw-flex tw-gap-x-4">
        <PassportButton
          :busy="buttons.discard.isLoading"
          :disabled="buttons.apply.isLoading"
          variant="stroke"
          :label="
            buttons.discard.shouldRetry
              ? 'Retry discard changes'
              : 'Continue without Saving'
          "
          @@click="handleDiscardChanges"
        />
        <PassportButton
          :busy="buttons.apply.isLoading"
          :disabled="buttons.discard.isLoading"
          variant="primary"
          :label="
            buttons.apply.shouldRetry
              ? 'Retry Save & Continue'
              : 'Save & Continue'
          "
          @@click="handleApplyChanges"
        />
      </div>
    </div>
  </div>
</template>
