<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-center tw-text-xl tw-font-bold tw-text-black">
        Save as Bundle
      </h2>
    </div>
    <form
      id="bundle-save-form"
      class="tw-h-auto tw-space-y-6 tw-border-b tw-border-border-light tw-p-6"
      @submit.prevent="handleSubmit"
    >
      <TextInput
        v-model="bundelName"
        label="name"
        required
      />
      <label
        class="tw-flex tw-justify-between"
        @click="handleShare"
      >
        <b>Share this bundle</b>
        <SwitchInput v-model="bundelShared" />
      </label>
    </form>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        :disabled="isLoading"
        @@click="$emit('close')"
      />
      <PassportButton
        :busy="isLoading"
        :label="shouldRetry ? 'Retry' : 'Save'"
        variant="primary"
        type="button"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import SwitchInput from '@/components/Input/SwitchInput.vue'
import TextInput from '@/components/Input/TextInput.vue'
import PassportButton from '@/components/PassportButton.vue'

export default {
  name: 'PopupBundleSave',

  components: {
    PassportButton,
    TextInput,
    SwitchInput,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    resolved: {
      type: Function,
      default: null,
    },
  },

  emits: ['close'],
  data() {
    return {
      error: null,
      isLoading: false,
      bundelShared: false,
      shouldRetry: false,
      bundelName: '',
    }
  },
  methods: {
    handleShare() {
      this.bundelShared = !this.bundelShared
    },
    async handleSubmit() {
      try {
        this.isLoading = true

        const bundle = {
          targetingFilterId: this.item.targetingFilterId,
          name: this.bundelName,
          isShared: this.bundelShared,
          countryCode: this.item.countryCode,
          languageCode: 'fr',
        }

        await this.$store.dispatch('bundles/create', bundle)

        this.$emit('close')

        this.$store.dispatch('addToast', {
          type: 'info',
          value: 'Bundle successfully created',
          delay: 6000,
        })
      } catch (error) {
        this.shouldRetry = true

        if (error.response?.data?.errors?.length) {
          this.error = error.response.data.errors[0].message
        } else if (error.message) {
          this.error = error.message
        } else {
          this.error = 'An error occurred. Please try again later.'
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
