import authenticateInterceptor from '@/interceptors/authenticate.js'
import autoSaveInterceptor from '@/interceptors/autoSave.js'
import cancelRequestInterceptor from '@/interceptors/cancelRequest'
import errorsInterceptor from '@/interceptors/errors.js'
import loaderInterceptor from '@/interceptors/loader.js'
import refreshTokenInterceptor from '@/interceptors/refreshToken.js'

authenticateInterceptor()
errorsInterceptor()
cancelRequestInterceptor()
refreshTokenInterceptor()
autoSaveInterceptor()
loaderInterceptor()
