export enum EnumDateInputModes {
  DATE = 'date',
  DATE_TIME = 'dateTime',
  TIME = 'time',
}

export enum EnumWeekdays {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}
