import { canSeeElk, elkCampaignUrl } from '@/helpers/elk'
import campaignEntity from '@/entity/campaign.js'

const entity = {
  ...campaignEntity,
  absoluteKey: 'CampaignWithFlight',
  module: 'campaignWithFlight',
  statusKey: 'flightStatusType',
  statusList: ['Draft', 'Pending', 'Archived', 'Paused', 'Converted'],
  list: {
    ...campaignEntity.list,
    module: 'campaignWithFlight',
    statusKey: 'flightStatusType',
    headers: [
      {
        key: 'gap-1',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-2',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Status',
        key: 'flightStatusType',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderStatusFromStore',
        componentList: 'ListCellCampaignWithFlight',
        size: '60px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-col-span-3 tw-rounded-tl',
        classHeader: 'tw-col-span-3',
      },
      {
        label: 'Pacing',
        key: 'pacingOrProgression',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPacingOrProgression',
        componentList: 'ListCellPacingProgression',
        size: 'minmax(90px, min-content)',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEntityName',
        size: 'minmax(100px, 1fr)',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24',
      },
      {
        label: 'Start date',
        key: 'startDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'End date',
        key: 'endDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Delivered',
        key: 'deliveredImpressions',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Ordered',
        key: 'orderedImpressions',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Gross CPM',
        key: 'grossCpm',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellCurrency',
        size: '120px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Budget Spread',
        key: 'budgetSpreadType',
        pages: [3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellBudgetSpread',
        size: '140px',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-pl-4 tw-pr-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Creatives',
        key: 'creativesCount',
        pages: [1, 2, 3],
        heading: null,
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellCreatives',
        size: '60px',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-border-l',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-col-span-3 tw-border-r tw-rounded-r',
        classHeader: 'tw-col-span-4',
      },
      {
        key: 'gap-3',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-4',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-mt-4 tw-border-t tw-h-28 tablet:tw-h-24 tw-hidden',
        classHeader: 'tw-hidden',
      },
    ],
    filtersCallbacks: {
      ...campaignEntity.list.filtersCallbacks,
      status(item, selectedStatus) {
        return (
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.flightStatusType)
        )
      },
    },
    rowOptions: (item) => {
      const options = [
        {
          key: 'copyId',
          label: `Copy Work ID (${item.workId})`,
          iconLabel: `${item.workId}`,
          text: item.workId,
          icon: 'copy',
        },
        {
          key: 'show-report',
          label: 'Show Report',
          to: { name: 'CampaignReport', params: { campaign: item.id } },
          icon: 'report',
        },
        ...(canSeeElk()
          ? [
              {
                key: 'kibana',
                label: 'Go To Kibana',
                href: elkCampaignUrl(item),
                icon: 'kibana',
                attrs: {
                  target: '_blank',
                },
              },
            ]
          : []),
        ...(['Ready', 'Running', 'Warning'].includes(item.campaignStatusType)
          ? [
              {
                key: 'pause',
                label: 'Pause',
                icon: 'pause',
              },
            ]
          : []),
        ...(['Paused'].includes(item.campaignStatusType)
          ? [
              {
                key: 'resume',
                label: 'Resume',
                icon: 'play',
              },
            ]
          : []),
        {
          key: 'duplicate',
          label: 'Duplicate',
          icon: 'copy',
        },
        ...(['Draft', 'Paused', 'Ready'].includes(item.campaignStatusType)
          ? [
              {
                key: 'delete',
                label: 'Delete',
                icon: 'trash',
              },
            ]
          : []),
        ...(!['Archived', 'Draft'].includes(item.campaignStatusType)
          ? [
              {
                key: 'archive',
                label: 'Archive',
                icon: 'archive',
              },
            ]
          : []),
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
        },
      ]

      return options
    },
  },
  moreActions: (item) => {
    if (!item) {
      return []
    }

    const actions = [
      {
        key: 'show-report',
        label: 'Show Report',
        to: { name: 'CampaignReport', params: { campaign: item.id } },
        icon: 'report',
      },
      {
        key: 'export',
        label: 'Export Setup XLS',
        icon: 'download',
      },
      ...(canSeeElk()
        ? [
            {
              key: 'kibana',
              label: 'Go To Kibana',
              href: elkCampaignUrl(item),
              icon: 'kibana',
              attrs: {
                target: '_blank',
              },
            },
          ]
        : []),
      {
        key: 'divider-2',
        divider: true,
      },
      {
        key: 'duplicate',
        label: `Duplicate`,
        icon: 'copy',
      },
      ...(['Ready', 'Running', 'Warning'].includes(item.campaignStatusType)
        ? [
            {
              key: 'pause',
              label: `Pause`,
              icon: 'pause',
            },
          ]
        : []),
      ...(['Paused'].includes(item.campaignStatusType)
        ? [
            {
              key: 'resume',
              label: `Resume`,
              icon: 'play',
            },
          ]
        : []),
      ...(!['Archived', 'Draft'].includes(item.campaignStatusType)
        ? [
            {
              key: 'archive',
              label: `Archive`,
              icon: 'archive',
            },
          ]
        : []),
      ...(['Draft', 'Paused', 'Ready'].includes(item.campaignStatusType)
        ? [
            {
              key: 'divider-2',
              divider: true,
            },
            {
              key: 'delete',
              label: `Delete`,
              icon: 'trash',
              class: 'hover:tw-text-red',
            },
          ]
        : []),
    ]

    return actions
  },
  // validateDates(form) {
  //   if (!form) {
  //     return
  //   }

  //   if (!form.startDate && !form.endDate) {
  //     return
  //   }

  //   // Only validate dates on Drafts
  //   if (!['Draft'].includes(form[entity.statusKey])) {
  //     return
  //   }

  //   const endDate = endOfDay(parseISO(form.endDate))
  //   const startDate = subMinutes(endOfDay(parseISO(form.startDate)), 1)

  //   if (isBefore(endDate, startDate)) {
  //     const tempDate = form.endDate

  //     entity.handleStoreInput(entity.module, 'endDate', form.startDate)
  //     entity.handleStoreInput(entity.module, 'startDate', tempDate)

  //     return
  //   }

  //   if (isBefore(startDate, new Date())) {
  //     entity.handleStoreInput(
  //       entity.module,
  //       'startDate',
  //       formatISO(addHours(new Date(), 1))
  //     )

  //     return
  //   }

  //   if (
  //     getHours(endDate) === 0 &&
  //     getMinutes(endDate) === 0 &&
  //     getSeconds(endDate) === 0
  //   ) {
  //     entity.handleStoreInput(
  //       entity.module,
  //       'endDate',
  //       formatISO(endOfDay(endDate))
  //     )
  //   }
  // },
}

export default entity
