import { formatCurrency, formatNumber } from '@/helpers/number'
import { isDateInPast } from '@/helpers/date'

export default {
  absoluteKey: 'CampaignBudgetGrouping',
  module: 'campaignBudgetGrouping',
  parentModule: 'campaign',
  key: 'CampaignBudgetGrouping',
  permissionKey: 'Campaign',
  productType: 'Flight',
  route: {
    index: 'Campaigns',
    create: 'CreateCampaignBudgetGrouping',
    edit: 'EditCampaignBudgetGrouping',
  },
  param: 'campaignBudget',
  singular: 'Budget Grouping',
  plural: 'Budget Groupings',
  statusKey: 'budgetGroupingStatusType',
  parentSingular: 'Campaign',
  parentPlural: 'Campaigns',
  statusList: ['Draft', 'Pending', 'Archived', 'Paused', 'Converted'],
  mutationsToWatch: [],
  list: {
    filters: [],
    loading: true,
    pacingOrProgression: 'pacing',
    search: '',
    shouldRetry: false,
    sortKey: '',
    sortOrder: 'asc',
    statusKey: 'budgetGroupingStatusType',
    headers: [
      {
        key: 'gap-1',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-24 tablet:tw-h-20 tw-bg-alto-light tw-border-l tw-border-border',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-2',
        pages: [1, 2, 3],
        componentList: 'ListCellToggle',
        heading: null,
        size: '1rem',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-rounded-tl',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Status',
        key: 'budgetGroupingStatusType',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderStatus',
        componentList: 'ListCellTypeIcon',
        icon: 'budget-grouping',
        size: '90px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-justify-end tw-pr-4',
        classHeader: 'tw-col-span-2',
      },
      {
        label: 'Pacing',
        key: 'pacingOrProgression',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPacingOrProgression',
        componentList: 'ListCellPacingProgression',
        view: 'pacing',
        size: 'minmax(90px, min-content)',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEntityName',
        size: 'minmax(100px, 1fr)',
        classCell: 'tw-h-24 tablet:tw-h-20',
      },
      {
        label: 'Start date',
        key: 'startDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'End date',
        key: 'endDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Delivered',
        key: 'deliveredImpressions',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Ordered',
        key: 'impressionsGoal',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Gross CPM',
        key: 'grossCpm',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellCurrency',
        size: '120px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Budget Spread',
        key: 'budgetSpreadType',
        pages: [3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellBudgetSpread',
        size: '140px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-pl-4 tw-pr-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Creatives',
        key: 'creativesCount',
        pages: [1, 2, 3],
        heading: null,
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEmpty',
        size: '60px',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-border-l',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell:
          'tw-h-24 tablet:tw-h-20 tw-col-span-2 tw-border-r tw-rounded-tr',
        classHeader: 'tw-col-span-3',
      },
      {
        key: 'gap-3',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell: 'tw-h-24 tablet:tw-h-20 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        key: 'gap-4',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-24 tablet:tw-h-20 tw-bg-alto-light tw-border-r tw-border-border',
        classHeader: 'tw-hidden',
      },
    ],
    filtersCallbacks: {
      status(item, selectedStatus) {
        return (
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.budgetGroupingStatusType)
        )
      },
      deliveredImpressions(item, { min, max }) {
        return (
          (min !== null ? item.deliveredImpressions >= min : true) &&
          (max !== null ? item.deliveredImpressions <= max : true)
        )
      },
      impressionsGoal(item, { min, max }) {
        return (
          (min !== null ? item.impressionsGoal >= min : true) &&
          (max !== null ? item.impressionsGoal <= max : true)
        )
      },
      grossCpm(item, { min, max }) {
        return (
          (min !== null ? item.grossCpm >= min : true) &&
          (max !== null ? item.grossCpm <= max : true)
        )
      },
    },
    rowOptions: (item) => {
      if (!item) return []

      const options = [
        {
          key: 'copyWorkId',
          label: `Copy Work ID (${item.workId})`,
          iconLabel: `${item.workId}`,
          text: item.workId,
          icon: 'copy',
        },
      ]

      if (!isDateInPast(item.parentEndDate, item.parentTimeZoneId)) {
        options.push({
          key: 'plane',
          label: 'Add Flight to budget grouping',
          to: {
            name: 'CreateCampaignBudgetGroupingFlight',
            params: { campaign: item.baseCampaignId, campaignBudget: item.id },
          },
          icon: 'plane',
        })
      }

      options.push(
        {
          key: 'show-report',
          label: 'Show Report',
          to: {
            name: 'CampaignReport',
            params: { campaign: item.baseCampaignId },
            query: { 'filters[budgetGroupingId]': item.id },
          },
          icon: 'report',
        },
        {
          key: 'duplicate',
          label: 'Duplicate',
          icon: 'copy',
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: 'trash',
        },
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
        }
      )

      return options
    },
  },
  moreActions: (item) => {
    if (!item) return []

    const actions = []

    if (!isDateInPast(item.parentEndDate, item.parentTimeZoneId)) {
      actions.push(
        {
          key: 'create-flight',
          label: 'Create New Flight',
          to: {
            name: 'CreateCampaignBudgetGroupingFlight',
            params: { campaign: item.baseCampaignId, campaignBudget: item.id },
          },
          icon: 'plane',
        },
        {
          key: 'divider-1',
          divider: true,
        }
      )
    }

    actions.push(
      {
        key: 'show-report',
        label: 'Show Report',
        icon: 'report',
        to: {
          name: 'CampaignReport',
          params: { campaign: item.baseCampaignId },
          query: { 'filters[budgetGroupingId]': item.id },
        },
      },
      {
        key: 'divider-2',
        divider: true,
      },
      {
        key: 'duplicate',
        label: `Duplicate`,
        icon: 'copy',
      },
      {
        key: 'divider-3',
        divider: true,
      },
      {
        key: 'delete',
        label: `Delete`,
        icon: 'trash',
        class: 'hover:tw-text-red',
      }
    )

    return actions
  },
  getDescription(item) {
    return `<div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Name</div>
        <div class="tw-text-sm">${item.name}</div>
      </div>
      <div class="tw-grid tw-grid-cols-12 tw-gap-6">
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Ordered Impressions</div>
            <div class="tw-text-sm">${formatNumber(item.impressionsGoal)}</div>
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Gross CPM</div>
            <div class="tw-text-sm">${formatCurrency(
              item.grossCpm,
              item.currencyCode
            )}</div>
          </div>
        </div>
      </div>
    </div>`
  },
}
