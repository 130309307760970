import type { ErrorAPI, ErrorAPP, ErrorMessage } from '@/types'

export const handleError = (
  error: ErrorAPP,
  defaultMessage = 'An error occurred. Please try again later.'
): string => {
  if (!error) return defaultMessage
  else if (typeof error === 'string') return error ? error : defaultMessage
  else if (error instanceof Error || 'message' in (error as ErrorMessage)) {
    return (error as Error | ErrorMessage).message
  } else if (Array.isArray((error as ErrorAPI)?.response?.data?.errors)) {
    return (error as ErrorAPI).response.data.errors.reduce(
      (output: string, { message }: ErrorMessage) =>
        (output += ` ${message} &NewLine;`),
      ''
    )
  } else if (Array.isArray(error) && error.every((e) => 'message' in e)) {
    return error.reduce(
      (output: string, { message }: ErrorMessage) =>
        (output += ` ${message} &NewLine;`),
      ''
    )
  }

  return defaultMessage
}
