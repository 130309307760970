<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-justify-start"
  >
    <Tag :type="status.toLowerCase() ?? 'error'">
      {{ status ? status : 'Error' }}
    </Tag>
  </router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Tag from '@/components/Tag.vue'

export default {
  name: 'ListCellStatus',
  extends: ListCellDefault,
  components: {
    Tag,
  },
  computed: {
    status() {
      if (
        this.item.entity === 'Deal' &&
        this.item.dealClientStatusType === 'Paused'
      ) {
        return 'Paused'
      }

      return this.item[this.headerKey]
    },
  },
}
</script>
