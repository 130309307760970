<template>
  <div
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-bg-white tw-text-grey-medium hover:tw-text-blackest"
  >
    <div class="tw-relative tw-text-grey-light">
      <ImageOrPlaceholder
        :url="safeGet(item, headerKey)"
        :name="
          Array.isArray(alt)
            ? alt.map((key) => safeGet(item, key)).join(' ')
            : safeGet(item, alt)
        "
        class="tw-h-8 tw-w-8 tw-flex-shrink-0"
      />
    </div>
  </div>
</template>
<script>
import ImageOrPlaceholder from '@/components/ImageOrPlaceholder.vue'
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellAvatar',
  components: {
    ImageOrPlaceholder,
  },
  extends: ListCellDefault,
  props: {
    alt: {
      type: [Array, String],
      default: '',
    },
  },
}
</script>
