<template>
  <div
    class="form-input-group"
    data-test-role="combo"
  >
    <div :class="{ 'is-label': true, filled: isFilled }">
      <Listbox
        :multiple="multiple"
        :modelValue="modelValue"
        :disabled="disabled"
        @update:modelValue="handleUpdate"
      >
        <ListboxLabel
          as="div"
          class="label"
        >
          {{ label }}
          <sup class="tw-text-red">{{ required ? '*' : '' }}</sup>
        </ListboxLabel>
        <div
          class="input tw-relative tw-w-full"
          @click="toggleOptions"
        >
          <div
            class="tw-w-full tw-overflow-hidden tw-whitespace-nowrap tw-pr-4"
          >
            <div
              v-if="!optionsVisible"
              class="fake-input tw-flex tw-w-full tw-p-0"
            >
              {{
                alwaysShowSearch && !filteredOptions.length
                  ? search
                  : (selectedOption && selectedOption.label) || placeholder
              }}
            </div>
            <input
              v-else
              autofocus
              ref="input"
              type="text"
              class="tw-w-full"
              v-model="search"
              :disabled="disabled"
              :placeholder="
                (selectedOption && selectedOption.label) || placeholder
              "
            />
          </div>
          <div
            class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2"
          >
            <Icon
              name="chevron-down"
              class="tw-text-3xs tw-text-grey"
            />
          </div>
        </div>
        <transition
          enter-active-class="tw-transition tw-duration-100 tw-ease-out"
          enter-from-class="tw-transform tw-scale-95 tw-opacity-0"
          enter-to-class="tw-transform tw-scale-100 tw-opacity-100"
          leave-active-class="tw-transition tw-duration-75 tw-ease-out"
          leave-from-class="tw-transform tw-scale-100 tw-opacity-100"
          leave-to-class="tw-transform tw-scale-95 tw-opacity-0"
        >
          <div
            v-if="optionsVisible"
            class="tw-absolute tw-z-10 tw--mt-px tw-w-full tw-border-t-2 tw-border-primary tw-bg-white tw-pt-1 tw-text-sm tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
          >
            <ListboxOptions
              static
              class="tw-max-h-60 tw-overflow-auto"
            >
              <li
                v-if="options.length === 0"
                class="tw-cursor-pointer tw-px-4 tw-py-2 tw-font-medium"
              >
                No Options
              </li>
              <li
                v-else-if="filteredOptions.length === 0"
                class="tw-cursor-pointer tw-px-4 tw-py-2 tw-font-medium"
              >
                {{ noResultsLabel ?? 'No Options Matching your Search' }}
              </li>
              <ListboxOption
                v-slot="{ active, selected }"
                v-for="option in filteredOptions"
                :key="option"
                :value="option.value"
                :disabled="option.disabled"
                as="template"
              >
                <li
                  :class="[
                    active ? 'tw-text-grey-dark' : '',
                    isSelected(option, selected)
                      ? 'tw-bg-alto-light tw-text-primary'
                      : 'tw-border-transparent',
                    multiple ? 'tw--mt-px' : '',
                    'tw-flex tw-cursor-pointer tw-items-center tw-gap-4 tw-border-b tw-border-t tw-px-4 tw-py-2 tw-font-medium hover:tw-text-grey-dark',
                  ]"
                >
                  <InputSelector
                    v-if="multiple"
                    inert
                    type="checkbox"
                    :model-value="isSelected(option, selected)"
                    :value="true"
                    :label="option.label"
                    :hide-label="false"
                  />

                  <span v-else>{{ option.label }}</span>
                </li>
              </ListboxOption>
            </ListboxOptions>
            <slot
              name="footer"
              v-bind="{ options, filteredOptions, search, close: closeOptions }"
            />
          </div>
        </transition>
      </Listbox>
    </div>
  </div>
  <div />
</template>

<script>
import Icon from '@/components/Icon.vue'
import InputSelector from '@/components/InputSelector.vue'
import {
  Listbox,
  ListboxLabel,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'

export default {
  name: 'SearchInput',
  components: {
    Icon,
    InputSelector,
    Listbox,
    ListboxLabel,
    ListboxOptions,
    ListboxOption,
  },
  props: {
    alwaysShowSearch: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Boolean, Array],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    noResultsLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      defaultPlaceholder: this.placeholder ? this.placeholder : this.label,
      optionsVisible: false,
      search: '',
    }
  },
  computed: {
    filteredOptions() {
      return this.searchList(this.options, 'label', this.search)
    },
    isFilled() {
      if (this.optionsVisible && this.search.length > 0) {
        return true
      }

      if (this.alwaysShowSearch && !this.filteredOptions.length) {
        return this.search.length > 0
      }

      return !this.multiple && this.selectedOption !== null
    },
    selectedOption() {
      return (
        this.options.find((option) => option.value === this.modelValue) ?? null
      )
    },
    selectedOptions() {
      if (!Array.isArray(this.modelValue)) {
        return false
      }

      return this.options.filter((option) =>
        this.modelValue.includes(option.value)
      )
    },
  },
  methods: {
    closeOptions() {
      this.optionsVisible = false
      window.removeEventListener('click', this.handleClickOutside, true)
    },
    handleClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.closeOptions()
      }
    },
    handleUpdate(value) {
      this.$emit('update:modelValue', value)

      if (this.closeOnSelect) {
        this.closeOptions()
      }
    },
    isSelected(option, selected) {
      return this.multiple
        ? Array.isArray(this.modelValue)
          ? this.modelValue.includes(option.value)
          : false
        : selected
    },
    openOptions() {
      this.optionsVisible = true
      window.addEventListener('click', this.handleClickOutside, true)
      this.$nextTick(() => {
        this.$refs.input?.focus()
      })
    },
    toggleOptions() {
      if (this.optionsVisible) {
        this.closeOptions()
      } else {
        this.openOptions()
      }
    },
  },
}
</script>
