<template>
  <div>
    <button
      class="tw-z-10 -tw-ml-px -tw-mt-px tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-overflow-hidden tw-rounded-tl tw-text-sm"
      type="button"
    >
      <div
        class="tw-flex tw-h-full tw-w-4 tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-bg-grey-medium tw-text-white"
      >
        <Icon
          name="chevron-down"
          class="tw-h-2 tw-w-2 tw-rotate-180 tw-transform"
        />
      </div>
    </button>
  </div>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellToggle',
  extends: ListCellDefault,
}
</script>
