<script setup lang="ts">
import { initials } from '@/helpers/strings'
import type { PropType } from 'vue'
import { ref, useAttrs } from 'vue'

const props = defineProps({
  url: {
    type: String as PropType<string | null>,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
})

const attrs = useAttrs()

const imgHasError = ref(false)
const setPlaceholder = (): void => {
  imgHasError.value = true
}
</script>

<template>
  <img
    v-if="props.url && !imgHasError"
    v-bind="attrs"
    :src="props.url"
    class="tw-rounded tw-object-cover tw-shadow"
    :alt="initials(props.name)"
    loading="lazy"
    @error="setPlaceholder"
  />
  <div
    v-else
    v-bind="attrs"
    class="tw-flex tw-items-center tw-justify-center tw-rounded tw-bg-pill-bg tw-text-center tw-text-2xs tw-tracking-tighter tw-shadow"
  >
    {{ initials(props.name) }}
  </div>
</template>
