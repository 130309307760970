<script setup lang="ts">
import { Switch } from '@headlessui/vue'
import { computed } from 'vue'

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const valueProxy = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <div class="tw-h-6 tw-w-11 tw-rounded-full tw-bg-white">
    <Switch
      v-model="valueProxy"
      :disabled="props.disabled"
      :class="{
        'tw-bg-primary': valueProxy == true && props.disabled == false,
        ' tw-bg-primary/40': valueProxy == true && props.disabled == true,
        'tw-bg-black dark:tw-bg-black/40':
          valueProxy == false && props.disabled == false,
        'tw-bg-black/20': valueProxy == false && props.disabled == true,
        'tw-cursor-pointer': props.disabled == false,
        'tw-cursor-not-allowed': props.disabled == true,
      }"
      class="tw-relative tw-inline-flex tw-h-full tw-w-full tw-shrink-0 tw-rounded-full tw-border-2 tw-border-transparent tw-outline-none tw-ring-offset-2 tw-transition-colors tw-duration-200 tw-ease-in-out focus-visible:tw-ring-2 focus-visible:tw-ring-primary focus-visible:tw-ring-opacity-75"
    >
      <span class="tw-sr-only">Use setting</span>
      <span
        aria-hidden="true"
        :class="valueProxy ? 'tw-translate-x-full' : 'tw-translate-x-0'"
        class="tw-pointer-events-none tw-inline-block tw-h-5 tw-w-5 tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out"
      />
    </Switch>
  </div>
</template>
