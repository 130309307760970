import type { RouteLocationRaw } from 'vue-router'
import store from '@/store/index'

export const toRoute = (route: RouteLocationRaw): RouteLocationRaw => {
  if (store && store.getters['auth/organizationId']) {
    if (route && typeof route == 'object') {
      if ('name' in route) {
        if (typeof route.params === 'object') {
          route.params['organization'] = store.getters['auth/organizationId']
        } else {
          route.params = { organization: store.getters['auth/organizationId'] }
        }
      }
    }
  }

  return route
}

export const safeParam = (param: string | string[]): string => {
  if (Array.isArray(param)) return param[0]
  else return param
}
