export const initials = (value = ''): string => {
  return (
    value
      ?.split(' ')
      .slice(0, 2)
      .map((str) => (str ? str.charAt(0) : ''))
      .filter((initial) => !!initial)
      .join(' ') || ''
  )
}
