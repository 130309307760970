import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import Popover from '@/components/Popover.vue'

function tooltipBind(el, binding) {
  if (!binding.value) return

  let options = {
    content: binding.value,
    placement: binding.arg ?? 'auto',
  }

  if (
    typeof binding.value === 'object' &&
    binding.value !== null &&
    !Array.isArray(binding.value)
  ) {
    const {
      content = null,
      placement = options.placement,
      ...otherOptions
    } = binding.value

    options = {
      content,
      placement,
      ...otherOptions,
    }
  } else if (binding.modifiers) {
    options.placement = Object.keys(binding.modifiers)[0] || options.placement
  }

  if (!options.content) {
    if (el._tippy) {
      el._tippy.destroy()
    }
    return
  }

  if (el._tippy) {
    el._tippy.setProps(options)
  } else {
    tippy(el, options)
  }
}

export default (app) => {
  app.component('Popover', Popover)
  app.directive('close-popper', {})
  app.directive('tooltip', {
    mounted: tooltipBind,
    updated: tooltipBind,
    beforeUnmount: (el) => {
      if (el._tippy) {
        el._tippy.destroy()
      }
    },
  })
}
