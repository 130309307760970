<script setup lang="ts">
import { watch } from 'vue'

const emit = defineEmits<{
  (e: 'toggle', value: boolean): void
}>()

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
})

watch(
  () => props.isOpen,
  (isOpen) => {
    emit('toggle', isOpen)
  },
  { immediate: true }
)
</script>

<template>
  <input
    type="hidden"
    :value="props.isOpen"
  />
</template>
