<script setup lang="ts">
import { computed, defineAsyncComponent, type Component } from 'vue'

import { useRoute } from 'vue-router'
import AdBlockerDetector from '@/components/AdBlockerDetector.vue'
import LayoutAuthenticated from '@/layout/Authenticated.vue'
import LayoutGuest from '@/layout/Guest.vue'
import ToastsList from '@/components/ToastsList/index.vue'

const DebugBar = defineAsyncComponent(() => import('@/components/DebugBar.vue'))

const route = useRoute()

const buildInfos = 'Mon, 26 Aug 2024 12:21:54 GMT'

const layout = computed<Component | null>(() => {
  switch (route.meta.layout) {
    case 'LayoutAuthenticated':
      return LayoutAuthenticated
    case 'LayoutGuest':
      return LayoutGuest
    default:
      return null
  }
})

const showDebugBar = computed(
  () =>
    import.meta.env.VITE_SHOW_DEBUG_BAR === true ||
    import.meta.env.VITE_SHOW_DEBUG_BAR === 'true'
)
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <div
    class="tw-pointer-events-none tw-fixed tw-bottom-0 tw-right-0 tw-z-20 tw-rounded-tl tw-bg-surface-3 tw-px-2 tw-py-1 tw-text-xs tw-text-text-1 print:tw-hidden"
  >
    {{ buildInfos }}
  </div>

  <AdBlockerDetector />

  <ToastsList />

  <DebugBar v-if="showDebugBar" />

  <component
    v-if="layout"
    :is="layout"
  >
    <router-view />
  </component>
  <template v-else>
    <router-view />
  </template>
</template>

<style lang="scss">
@import 'sass/main.scss';
</style>
