<script setup lang="ts">
import PassportButton from '@/components/PassportButton.vue'
import type { PropType } from 'vue'
import { onMounted, reactive, ref } from 'vue'
import { handleError } from '@/helpers/handleError'
import type { RouteLocationNormalized } from 'vue-router'
import { useRouter } from 'vue-router'

const emit = defineEmits<{ close: [] }>()

const props = defineProps({
  routeTo: {
    type: Object as PropType<RouteLocationNormalized | null>,
    default: null,
  },
  apply: {
    type: Function as PropType<() => Promise<void>>,
    required: true,
  },
  discard: {
    type: Function as PropType<() => Promise<void>>,
    default: null,
  },
  error: {
    type: String,
    default: null,
  },
})

const router = useRouter()

const errorMessage = ref('')

const buttons = reactive({
  apply: {
    isLoading: false,
    shouldRetry: false,
  },
  discard: {
    isLoading: false,
    shouldRetry: false,
  },
})

onMounted(() => {
  errorMessage.value = props.error
})

const handleApplyChanges = async () => {
  buttons.apply.isLoading = true
  try {
    if (props.apply) {
      await props.apply()
    }

    return handleNavigate()
  } catch (error) {
    buttons.apply.shouldRetry = true

    errorMessage.value = handleError(error)
  } finally {
    buttons.apply.isLoading = false
  }
}
const handleDiscardChanges = async (): Promise<void> => {
  buttons.discard.isLoading = true
  try {
    if (props.routeTo) {
      router.push(props.routeTo)
    }

    if (props.discard) {
      props.discard()
    }

    emit('close')
  } catch (error) {
    console.error(error)
    buttons.discard.shouldRetry = true

    errorMessage.value = handleError(error)
  } finally {
    buttons.discard.isLoading = false
  }
}

const handleNavigate = async (): Promise<void> => {
  emit('close')

  if (props.routeTo) {
    router.push(props.routeTo)
  }
}
</script>

<template>
  <div class="tw-w-modal-lg tw-divide-y tw-divide-border-light">
    <div class="tw-p-6">
      <h2 class="tw-text-center tw-text-xl tw-font-bold tw-text-black">
        An error occurred
      </h2>
    </div>
    <div class="tw-p-6 tw-leading-loose">
      Some of your changes have not been saved.
      <br />
      Do you want to save them before continuing?
    </div>
    <div
      v-if="errorMessage"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ errorMessage }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        :disabled="buttons.apply.isLoading || buttons.discard.isLoading"
        variant="basic"
        @@click="emit('close')"
      />
      <div class="tw-flex tw-space-x-4">
        <PassportButton
          type="button"
          :label="
            buttons.discard.shouldRetry
              ? 'Retry discard changes'
              : 'Discard and continue'
          "
          :busy="buttons.apply.isLoading || buttons.discard.isLoading"
          variant="stroke"
          @@click="handleDiscardChanges"
        />
        <PassportButton
          type="button"
          variant="primary"
          :busy="buttons.apply.isLoading || buttons.discard.isLoading"
          label="Retry Saving Changes"
          @@click="handleApplyChanges"
        />
      </div>
    </div>
  </div>
</template>
