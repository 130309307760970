import _cloneDeep from 'lodash/cloneDeep'
import HTTP from '@/services/http'
import { sortList } from '@/helpers/sort'

export default {
  namespaced: true,

  state: {
    list: [],
    listAdops: [],
  },

  getters: {
    options(state) {
      return sortList(
        state.list.map((saleUser) => ({
          value: saleUser.id,
          label: `${saleUser.firstName} ${saleUser.lastName}`,
        })),
        'label',
        'asc'
      )
    },
  },

  mutations: {
    RESET_STATE(state) {
      state.list = []
    },
    SET_LIST(state, users) {
      state.list = _cloneDeep(users)
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async list({ commit, state }, { force = false } = {}) {
      if (!force && state.list.length) return

      try {
        const response = await HTTP.get('User/sales-users-list')

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },
  },
}
