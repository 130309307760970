import HTTP from '@/services/http'
import type { RootState } from '@/store'
import type {
  APIPagination,
  APIResponsePagination,
  NetworkPublishers,
  TargetingNetworkChannel,
  TargetingNetworkFormat,
  TargetingNetworkGroupChannels,
  TargetingNetworkPublisher,
} from '@/types'
import _cloneDeep from 'lodash/cloneDeep'
import { type Module } from 'vuex'

export interface StateTargetingNetwork {
  listChannels: TargetingNetworkChannel[]
  listNetworkPublishers: NetworkPublishers[]
  listGroups: TargetingNetworkGroupChannels[]
  listGroupChannels: TargetingNetworkChannel[] /*Map<
    TargetingNetworkGroupChannels['groupChannelId'],
    TargetingNetworkChannel[]
  >*/
  listFormats: TargetingNetworkFormat[]
  listPublishers: TargetingNetworkPublisher[]
  listSearch: string
  listGroupChannelsLoading: boolean
  listNetworkPublishersLoading: boolean
  pagingFormats: APIPagination
  pagingGroups: APIPagination
  pagingChannels: APIPagination
  pagingGroupsChannels: APIPagination
  pagingGroupChannels: APIPagination
  pagingNetworkPublishers: APIPagination
  pagingPublishers: APIPagination
}

const defaultPagination = {
  index: 1,
  skip: 0,
  size: 25,
  total: 0,
}

const defaultState: StateTargetingNetwork = {
  listNetworkPublishers: [],
  listGroups: [],
  listChannels: [],
  listGroupChannels: [], // new Map(),
  listGroupChannelsLoading: false,
  listNetworkPublishersLoading: false,

  listFormats: [],
  listPublishers: [],
  listSearch: '',

  pagingGroups: _cloneDeep(defaultPagination),
  pagingChannels: _cloneDeep(defaultPagination),
  pagingGroupsChannels: _cloneDeep(defaultPagination),
  pagingGroupChannels: _cloneDeep(defaultPagination),
  pagingNetworkPublishers: _cloneDeep(defaultPagination),
  pagingFormats: _cloneDeep(defaultPagination),
  pagingPublishers: _cloneDeep(defaultPagination),
}

const storeNetwork: Module<StateTargetingNetwork, RootState> = {
  namespaced: true,

  state: _cloneDeep(defaultState),

  mutations: {
    ADD_GROUPS(state, groups: TargetingNetworkGroupChannels[]) {
      state.listGroups = state.listGroups.concat(groups)
    },
    ADD_CHANNELS(state, channels: TargetingNetworkChannel[]) {
      state.listChannels = state.listChannels.concat(channels)
    },
    ADD_GROUP_CHANNELS(state, channels: TargetingNetworkChannel[]) {
      state.listGroupChannels = state.listGroupChannels.concat(channels)
    },
    ADD_NETWORK_PUBLISHERS(state, networkPublishers: NetworkPublishers[]) {
      state.listNetworkPublishers =
        state.listNetworkPublishers.concat(networkPublishers)
    },
    ADD_FORMATS(state, formats: TargetingNetworkFormat[]) {
      state.listFormats = state.listFormats.concat(formats)
    },
    ADD_PUBLISHERS(state, publishers: TargetingNetworkPublisher[]) {
      state.listPublishers = state.listPublishers.concat(publishers)
    },
    SET_GROUPS(state, groups: TargetingNetworkGroupChannels[]) {
      state.listGroups = groups
    },
    SET_CHANNELS(state, channels: TargetingNetworkChannel[]) {
      state.listChannels = channels
    },
    SET_GROUP_CHANNELS(state, channels: TargetingNetworkChannel[]) {
      state.listGroupChannels = channels
    },
    SET_NETWORK_PUBLISHERS(state, networkPublishers: NetworkPublishers[]) {
      state.listNetworkPublishers = networkPublishers
    },
    SET_FORMATS(state, formats: TargetingNetworkFormat[]) {
      state.listFormats = formats
    },
    SET_LIST_SEARCH(state, search: string) {
      state.listSearch = search
    },
    SET_PUBLISHERS(state, publishers: TargetingNetworkPublisher[]) {
      state.listPublishers = publishers
    },
    SET_PAGINATION_CHANNELS(state, pagination: APIPagination) {
      state.pagingChannels = pagination
    },
    SET_PAGINATION_GROUP_CHANNELS(state, pagination: APIPagination) {
      state.pagingGroupChannels = pagination
    },
    SET_PAGINATION_GROUPS(state, pagination: APIPagination) {
      state.pagingGroups = pagination
    },
    SET_PAGINATION_GROUPS_CHANNELS(state, pagination: APIPagination) {
      state.pagingGroupsChannels = pagination
    },
    SET_PAGINATION_NETWORK_PUBLISHERS(state, pagination: APIPagination) {
      state.pagingNetworkPublishers = pagination
    },
    SET_PAGINATION_FORMATS(state, pagination: APIPagination) {
      state.pagingFormats = pagination
    },
    SET_PAGINATION_PUBLISHERS(state, pagination: APIPagination) {
      state.pagingPublishers = pagination
    },
    SET_GROUP_CHANNELS_LOADING(state, loading: boolean) {
      state.listGroupChannelsLoading = loading
    },
    SET_NETWORK_PUBLISHERS_LOADING(state, loading: boolean) {
      state.listNetworkPublishersLoading = loading
    },
    RESET_STATE(state) {
      Object.assign(state, _cloneDeep(defaultState))
    },
  },
  actions: {
    async fetchChannels(
      { commit, state, rootGetters, rootState },
      { addressableInventoryOnly, flightType, loadMore = false }
    ) {
      const params = {
        isAdressableInventory: addressableInventoryOnly,
        flightType,
        search: state.listSearch || undefined,
        productType: rootState.targetingFilter.passportProductType,
        organizationId: rootGetters['auth/currentOrganization']?.id,
        page: state.pagingChannels.index,
        pageSize: state.pagingChannels.size,
      }

      const response = await HTTP.get<
        APIResponsePagination<TargetingNetworkChannel[]>
      >(`TargetingListing/network/channels`, { params })

      if (loadMore) {
        commit('ADD_CHANNELS', response.data.datas.list)
      } else {
        commit('SET_CHANNELS', response.data.datas.list)
      }
      commit('SET_PAGINATION_CHANNELS', response.data.datas.paging)
    },

    async fetchNetworkPublishers(
      { commit, state, rootGetters, rootState },
      { addressableInventoryOnly, flightType, publisherId, loadMore = false }
    ) {
      commit('SET_NETWORK_PUBLISHERS_LOADING', true)

      const params = {
        isAdressableInventory: addressableInventoryOnly,
        flightType,
        search: state.listSearch || undefined,
        publisherIds: publisherId,
        productType: rootState.targetingFilter.passportProductType,
        organizationId: rootGetters['auth/currentOrganization']?.id,
        page: loadMore
          ? state.pagingNetworkPublishers.index
          : defaultPagination.index,
        pageSize: state.pagingNetworkPublishers.size,
      }

      const response = await HTTP.get<
        APIResponsePagination<TargetingNetworkChannel[]>
      >(`TargetingListing/network/publisher-groups`, { params })

      if (loadMore) {
        commit('ADD_NETWORK_PUBLISHERS', response.data.datas.list)
      } else {
        commit('SET_NETWORK_PUBLISHERS', response.data.datas.list)
      }
      commit('SET_PAGINATION_NETWORK_PUBLISHERS', response.data.datas.paging)
      commit('SET_NETWORK_PUBLISHERS_LOADING', false)
    },

    async fetchGroupChannels(
      { commit, state, rootGetters, rootState },
      {
        addressableInventoryOnly,
        flightType,
        groupId,
        publisherId,
        loadMore = false,
      }
    ) {
      commit('SET_GROUP_CHANNELS_LOADING', true)

      const params = {
        isAdressableInventory: addressableInventoryOnly,
        flightType,
        search: state.listSearch || undefined,
        groupIds: groupId,
        publisherIds: publisherId,
        productType: rootState.targetingFilter.passportProductType,
        organizationId: rootGetters['auth/currentOrganization']?.id,
        page: loadMore
          ? state.pagingGroupChannels.index
          : defaultPagination.index,
        pageSize: state.pagingGroupChannels.size,
      }

      const response = await HTTP.get<
        APIResponsePagination<TargetingNetworkChannel[]>
      >(`TargetingListing/network/channels`, { params })

      if (loadMore) {
        commit('ADD_GROUP_CHANNELS', response.data.datas.list)
      } else {
        commit('SET_GROUP_CHANNELS', response.data.datas.list)
      }
      commit('SET_PAGINATION_GROUP_CHANNELS', response.data.datas.paging)
      commit('SET_GROUP_CHANNELS_LOADING', false)
    },

    async fetchGroupsChannels(
      { commit, state, rootGetters, rootState },
      { addressableInventoryOnly, flightType, publisherId, loadMore = false }
    ) {
      const params = {
        isAdressableInventory: addressableInventoryOnly,
        flightType,
        search: state.listSearch || undefined,
        organizationId: rootGetters['auth/currentOrganization']?.id,
        publisherIds: publisherId,
        productType: rootState.targetingFilter.passportProductType,
        page: loadMore ? state.pagingGroups.index : defaultPagination.index,
        pageSize: state.pagingGroups.size,
      }

      const response = await HTTP.get<
        APIResponsePagination<TargetingNetworkGroupChannels[]>
      >(`TargetingListing/network/groups`, { params })

      if (loadMore) {
        commit('ADD_GROUPS', response.data.datas.list)
      } else {
        commit('SET_GROUPS', response.data.datas.list)
      }
      commit('SET_PAGINATION_GROUPS', response.data.datas.paging)
    },

    async fetchFormats(
      { commit, state, rootGetters, rootState },
      { addressableInventoryOnly, flightType }
    ) {
      const params = {
        isAdressableInventory: addressableInventoryOnly,
        flightType,
        search: state.listSearch || undefined,
        organizationId: rootGetters['auth/currentOrganization']?.id,
        productType: rootState.targetingFilter.passportProductType,
      }

      const response = await HTTP.get<
        APIResponsePagination<TargetingNetworkFormat[]>
      >(`TargetingListing/network/formats`, { params })

      commit('SET_FORMATS', response.data.datas.list)
      commit('SET_PAGINATION_FORMATS', response.data.datas.paging)
    },

    async fetchPublishers(
      { commit, state, rootGetters, rootState },
      { addressableInventoryOnly, flightType, groupId, loadMore = false }
    ) {
      const params = {
        isAdressableInventory: addressableInventoryOnly,
        flightType,
        search: state.listSearch || undefined,
        productType: rootState.targetingFilter.passportProductType,
        organizationId: rootGetters['auth/currentOrganization']?.id,
        page: state.pagingPublishers.index,
        pageSize: state.pagingPublishers.size,
      }

      if (groupId !== null) {
        Object.assign(params, { groupIds: groupId })
      }

      const response = await HTTP.get<
        APIResponsePagination<TargetingNetworkPublisher[]>
      >(`TargetingListing/network/publishers`, { params })

      if (loadMore) {
        commit('ADD_PUBLISHERS', response.data.datas.list)
      } else {
        commit('SET_PUBLISHERS', response.data.datas.list)
      }
      commit('SET_PAGINATION_PUBLISHERS', response.data.datas.paging)
    },

    loadMore(
      { commit, dispatch, state },
      { type, addressableInventoryOnly, flightType, ...params }
    ) {
      switch (type) {
        case 'channels':
          commit(
            'SET_PAGINATION_CHANNELS',
            Object.assign({}, state.pagingChannels, {
              index: state.pagingChannels.index + 1,
            })
          )

          dispatch('fetchChannels', {
            addressableInventoryOnly,
            flightType,
            loadMore: true,
            ...params,
          })
          break
        case 'groupChannels':
          commit(
            'SET_PAGINATION_GROUP_CHANNELS',
            Object.assign({}, state.pagingGroupChannels, {
              index: state.pagingGroupChannels.index + 1,
            })
          )

          dispatch('fetchGroupChannels', {
            addressableInventoryOnly,
            flightType,
            loadMore: true,
            ...params,
          })
          break
        case 'groups':
          commit(
            'SET_PAGINATION_GROUPS',
            Object.assign({}, state.pagingGroups, {
              index: state.pagingGroups.index + 1,
            })
          )

          dispatch('fetchGroupsChannels', {
            addressableInventoryOnly,
            flightType,
            loadMore: true,
            ...params,
          })
          break
        case 'publisherChannels':
          commit(
            'SET_PAGINATION_NETWORK_PUBLISHERS',
            Object.assign({}, state.pagingNetworkPublishers, {
              index: state.pagingNetworkPublishers.index + 1,
            })
          )

          dispatch('fetchNetworkPublishers', {
            addressableInventoryOnly,
            flightType,
            loadMore: true,
            ...params,
          })
          break
        case 'formats':
          // commit(
          //   'SET_PAGINATION_FORMATS',
          //   Object.assign({}, state.pagingFormats, {
          //     index: state.pagingFormats.index + 1,
          //   })
          // )

          // dispatch('fetchFormats', {
          //   addressableInventoryOnly,
          //   flightType,
          //   loadMore: true,
          //   ...params,
          // })
          break
        case 'publishers':
          commit(
            'SET_PAGINATION_PUBLISHERS',
            Object.assign({}, state.pagingPublishers, {
              index: state.pagingPublishers.index + 1,
            })
          )

          dispatch('fetchPublishers', {
            addressableInventoryOnly,
            flightType,
            loadMore: true,
            ...params,
          })
          break
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE')
    },
  },
}

export default storeNetwork
