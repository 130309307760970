<template>
  <TextInput
    v-if="readonly"
    v-bind="$parentAttrs"
    readonly
    :model-value="selectedOption"
    :label="label"
  />
  <div
    v-else
    class="form-input-group"
    v-bind="$parentAttrs"
    data-test-role="dropdown"
  >
    <div :class="{ 'is-label': true, filled: readonly || selectedOption }">
      <Listbox
        :model-value="modelValue"
        :disabled="disabled"
        :multiple="isMultiple"
        @update:model-value="$emit('update:modelValue', $event)"
      >
        <ListboxLabel
          as="div"
          v-bind="$childAttrs"
          :aria-selected="selectedOption || placeholder"
          class="label"
        >
          {{ label }}
          <sup class="tw-text-red">{{ required ? '*' : '' }}</sup>
          <InfoTips
            v-if="infoTips"
            :info="infoTips"
            class="tw-z-50 tw-ml-1 tw-align-text-top"
          />
        </ListboxLabel>
        <ListboxButton class="input tw-relative tw-w-full">
          <div
            v-bind="$childAttrs"
            class="tw-overflow-hidden tw-whitespace-nowrap tw-pr-4"
          >
            <Tooltip :title="selectedOption || placeholder">
              {{ selectedOption || placeholder }}
            </Tooltip>
          </div>
          <div
            class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2"
          >
            <Icon
              name="chevron-down"
              class="tw-text-3xs tw-text-grey"
            />
          </div>
        </ListboxButton>
        <transition
          enter-active-class="tw-transition tw-duration-100 tw-ease-out"
          enter-from-class="tw-transform tw-scale-95 tw-opacity-0"
          enter-to-class="tw-transform tw-scale-100 tw-opacity-100"
          leave-active-class="tw-transition tw-duration-75 tw-ease-out"
          leave-from-class="tw-transform tw-scale-100 tw-opacity-100"
          leave-to-class="tw-transform tw-scale-95 tw-opacity-0"
        >
          <ListboxOptions
            class="tw-absolute tw-z-10 tw--mt-px tw-max-h-60 tw-w-full tw-overflow-auto tw-border-t-2 tw-border-primary tw-bg-white tw-py-1 tw-text-sm tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
          >
            <ListboxOption
              v-for="option in options"
              v-slot="{ active, selected }"
              :key="option"
              :value="option.value"
              :disabled="option.disabled"
            >
              <li
                :class="[
                  active ? 'tw-text-grey-dark' : '',
                  selected
                    ? 'tw-border-b tw-border-t tw-bg-alto-light tw-text-primary'
                    : '',
                  'tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-px-3 tw-py-2 tw-font-medium',
                ]"
              >
                <InputSelector
                  v-if="isMultiple"
                  inert
                  inverted
                  :value="true"
                  type="checkbox"
                  :model-value="selected"
                  :label="option.label"
                />
                <span v-else>{{ option.label }}</span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </Listbox>
    </div>
  </div>
</template>

<script>
import InputSelector from '@/components/InputSelector.vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import attrsMixin from '@/mixins/attrs.js'
import TextInput from '@/components/Input/TextInput.vue'
import Icon from '@/components/Icon.vue'
import InfoTips from '@/components/InfoTips.vue'
import Tooltip from '@/components/Tooltip.vue'

/*eslint-disable */
export default {
  name: 'SelectInput',
  components: {
    Icon,
    InputSelector,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    TextInput,
    InfoTips,
    Tooltip,
  },
  mixins: [attrsMixin],
  props: {
    label: {
      type: String,
      default: null,
    },
    infoTips: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Boolean, Array, null],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      defaultPlaceholder: this.placeholder ? this.placeholder : this.label,
    }
  },
  computed: {
    selectedOption() {
      if (this.isMultiple) {
        return this.modelValue
          .map((value) => {
            const option = this.options.find((option) => option.value === value)
            return option?.label || null
          })
          .join(', ')
      }

      const option = this.options.find(
        (option) => option.value === this.modelValue
      )
      return option?.label || null
    },
  },
}
</script>
