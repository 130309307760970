export enum ColorCodes {
  ERROR = '#FD6E61',
  WARNING = '#F5A623',
  RUNNING = '#84BD4A',
  DELIVERING = '#84BD4A',
  PAUSED = '#3A99D8',
  ARCHIVED = '#6E7075',
  DRAFT = 'transparent',
}

export const ColorMapping = {
  0: ColorCodes.ERROR,
  95: ColorCodes.WARNING,
  98: ColorCodes.RUNNING,
  102: ColorCodes.WARNING,
  105: ColorCodes.ERROR,
}
