import Cache from '@/services/cache'
import HTTP from '@/services/http'
import store from '@/store/index'
import { handleError } from '@/helpers/handleError'

const createHttpErrorInterceptor = function () {
  HTTP.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error || !error.response) {
        return Promise.reject(error)
      }

      if (error.response?.status >= 500 && error.response?.status < 600) {
        return store.dispatch(
          'addError',
          handleError(
            error?.response?.data?.errors?.[0]?.message,
            `Request failed with status code ${error.response?.status}, please contact support`
          )
        )
      }

      if (
        error.response?.status === 400 &&
        error.config?.withoutError !== true
      ) {
        // show error on bad request
        if (error?.response?.data?.errors?.length > 1) {
          store.dispatch(
            'addErrors',
            error.response.data.errors.map((e) => e.message)
          )
        } else {
          store.dispatch('addError', error.response.data.errors[0].message)
        }
      }

      if (
        error.response?.data?.errors?.some(
          (error) => error.messageCode === 'NEW_SELECTED_CHANNEL_IS_NOT_ENABLED'
        )
      ) {
        Cache.clear()
      }

      return Promise.reject(error)
    }
  )
}

export default createHttpErrorInterceptor
