<template>
  <div
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-bg-white tw-text-grey-medium hover:tw-text-blackest"
  >
    <div class="tw-relative tw-text-grey-light">
      <Icon
        :name="icon"
        class="tw-flex-shrink-0"
      />
    </div>
  </div>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'ListCellTypeIcon',
  extends: ListCellDefault,
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
