import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/:organization?/inventory',
    name: 'Inventory',
    component: () => import('@/views/Inventory.vue'),
    meta: {
      title: 'Passport | Inventory',
      layout: 'LayoutAuthenticated',
      requiresAuth: true,
      inMaintenance: true,
    },
  },
] as RouteRecordRaw[]
