import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'agency/create',
    component: () => import('@/views/people/agency/create.vue'),
    meta: {
      title: 'Passport | Advertiser Creation',
      layout: 'LayoutAuthenticated',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'CreateAgency',
        component: () => import('@/views/people/agency/tabs/info.vue'),
      },
    ],
  },
  {
    path: 'agency/:agency',
    component: () => import('@/views/people/agency/edit.vue'),
    meta: {
      title: 'Passport | Agency',
      layout: 'LayoutAuthenticated',
      redirectPath: '/agency',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'EditAgency',
        redirect: { name: 'EditAgencyInfo' },
      },
      {
        path: 'info',
        name: 'EditAgencyInfo',
        component: () => import('@/views/people/agency/tabs/info.vue'),
        meta: {
          title: 'Passport | Agency Details',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'contacts',
        name: 'EditAgencyContacts',
        component: () => import('@/views/people/agency/tabs/contacts.vue'),
        meta: {
          title: 'Passport | Agency Contacts',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'campaigns',
        name: 'EditAgencyCampaigns',
        component: () => import('@/views/people/agency/tabs/campaigns.vue'),
        meta: {
          title: 'Passport | Agency Campaigns',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
    ],
  },
] as RouteRecordRaw[]
