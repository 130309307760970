export * from '@/constants/optionsI18n'

export enum EnumAdPlacements {
  PREROLL = 'Preroll',
  MIDROLL = 'Midroll',
  POSTROLL = 'Postroll',
}
export const optionsAdPlacements = [
  { value: EnumAdPlacements.PREROLL, label: 'Preroll' },
  { value: EnumAdPlacements.MIDROLL, label: 'Midroll' },
  { value: EnumAdPlacements.POSTROLL, label: 'Postroll' },
  // { value: 'Both', label: 'Preroll + Midroll' },
]

export enum EnumAuctionTypes {
  FIRST_PRICE = 'FirstPrice',
  SECOND_PRICE = 'SecondPrice',
}
export const optionsAuctionTypes = [
  { value: EnumAuctionTypes.FIRST_PRICE, label: '1st price' },
  { value: EnumAuctionTypes.SECOND_PRICE, label: '2nd price' },
]

export enum EnumBillingTypes {
  ORDER = 'Order',
  DELIVER = 'Deliver',
}
export const optionsBillingTypes = [
  { value: EnumBillingTypes.ORDER, label: 'Ordered' },
  { value: EnumBillingTypes.DELIVER, label: 'Delivered' },
]

export enum EnumBillingCalendarTypes {
  STANDARD = 'Standard',
  BROADCAST = 'Broadcast',
}
export const optionsBillingCalendarTypes = [
  { value: EnumBillingCalendarTypes.STANDARD, label: 'Standard' },
  { value: EnumBillingCalendarTypes.BROADCAST, label: 'Broadcast' },
]

export enum EnumBudgetSpreadTypes {
  EVENLY = 'Evenly',
  AS_FAST_AS_POSSIBLE = 'AsFastAsPossible',
  FRONT_LOAD = 'FrontLoad',
}
export const optionsBudgetSpreadTypes = [
  { value: EnumBudgetSpreadTypes.EVENLY, label: 'Evenly' },
  {
    value: EnumBudgetSpreadTypes.AS_FAST_AS_POSSIBLE,
    label: 'As Fast As Possible',
  },
  { value: EnumBudgetSpreadTypes.FRONT_LOAD, label: 'Front Load' },
]

export const optionsBudgetTypes = [
  { value: 'FixedPrice', label: 'Fixed Price' },
  { value: 'RTB', label: 'Real Time Bidding (RTB)' },
]

export enum EnumCompanionTypes {
  WITHOUT = 'Without',
  WHEN_AVAILABLE = 'WhenAvailable',
  ALWAYS = 'Always',
}
export const optionsCompanionTypes = [
  { value: EnumCompanionTypes.WITHOUT, label: 'Without' },
  { value: EnumCompanionTypes.WHEN_AVAILABLE, label: 'WhenAvailable' },
  { value: EnumCompanionTypes.ALWAYS, label: 'Always' },
]

export enum EnumCurrencies {
  EURO = 'EUR',
  DOLLAR = 'USD',
  CANADIAN = 'CAD',
  CHF = 'CHF',
  UK = 'GBP',
  AUD = 'AUD',
  SEK = 'SEK',
  NZD = 'NZD',
  NOK = 'NOK',
  DKK = 'DKK',
  CLP = 'CLP',
  ARS = 'ARS',
  PEN = 'PEN',
  COP = 'COP',
  GTQ = 'GTQ',
  IDN = 'IDN',
  INR = 'INR',
  LKR = 'LKR',
  MAD = 'MAD',
  MYR = 'MYR',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  VND = 'VND',
}
export const optionsCurrencies = [
  { value: EnumCurrencies.EURO, label: 'Euro €' },
  { value: EnumCurrencies.DOLLAR, label: '$ US Dollar' },
  { value: EnumCurrencies.CANADIAN, label: '$ Canadian Dollar' },
  { value: EnumCurrencies.UK, label: 'Pound Sterling £' },
  { value: EnumCurrencies.SEK, label: 'kr Swedish Krones' },
  { value: EnumCurrencies.AUD, label: '$ Australian Dollar' },
  { value: EnumCurrencies.NZD, label: '$ New Zealand Dollar' },
  { value: EnumCurrencies.NOK, label: 'kr Norwegian Krone' },
  { value: EnumCurrencies.DKK, label: 'kr Danish Krone' },
  { value: EnumCurrencies.CLP, label: '$ Chilean Peso' },
  { value: EnumCurrencies.ARS, label: '$ Argentine Peso' },
  { value: EnumCurrencies.PEN, label: 'S/ Peruvian Sol' },
  { value: EnumCurrencies.COP, label: '$ Colombian Peso' },
  { value: EnumCurrencies.GTQ, label: 'Q Guatemalan Quetzal' },
  { value: EnumCurrencies.IDN, label: 'Rp Indonesian Rupiah' },
  { value: EnumCurrencies.INR, label: 'Rs Indian Rupee' },
  { value: EnumCurrencies.LKR, label: 'Rs Sri Lankan Rupee' },
  { value: EnumCurrencies.MAD, label: 'DH Moroccan Dirham' },
  { value: EnumCurrencies.MYR, label: 'RM Ringgit' },
  { value: EnumCurrencies.PHP, label: '₱ Peso Philippin' },
  { value: EnumCurrencies.SGD, label: '$ Singapore Dollar' },
  { value: EnumCurrencies.THB, label: '฿ Baht' },
  { value: EnumCurrencies.TRY, label: '₺ Turkish Lira' },
  { value: EnumCurrencies.VND, label: '₫ Dong' },
  { value: EnumCurrencies.CHF, label: 'Swiss Franc' },
]

export enum EnumCampaignTypes {
  STANDARD = 1,
  AUTO_PROMO = 2,
  PSA = 3,
  FILLERS = 4,
  VIDEO = 5,
  OUT_OF_NETWORK = 6,
  PG_AUDIO = 7,
  PG_VIDEO = 8,
  BACK_FILL = 9,
  PMP_AUDIO = 10,
  PMP_VIDEO = 11,
}

export enum EnumDealTypes {
  EXCLUSIVE = 1,
  PRIVATE = 2,
  PREFERRED = 3,
  PROGRAMMATIC_GUARANTEED = 4,
  PROGRAMMATIC_GUARANTEED_CAMPAIGN = 5,
  OPEN_MARKET = 6,
  MAPPING_DEAL = 7,
  PMP_GUARANTEED_CAMPAIGN = 8,
}
export const optionsDealTypes = [
  { value: EnumDealTypes.EXCLUSIVE, label: 'Exclusive' },
  { value: EnumDealTypes.PRIVATE, label: 'Private' },
  { value: EnumDealTypes.PREFERRED, label: 'Preferred' },
  {
    value: EnumDealTypes.PROGRAMMATIC_GUARANTEED,
    label: 'Programmatic Guaranteed',
  },
  {
    value: EnumDealTypes.OPEN_MARKET,
    label: 'Open Market',
  },
  {
    value: EnumDealTypes.PROGRAMMATIC_GUARANTEED_CAMPAIGN,
    label: 'Programmatic Guaranteed Campaign',
  },
  {
    value: EnumDealTypes.MAPPING_DEAL,
    label: 'Mapping Deal',
  },
  {
    value: EnumDealTypes.PMP_GUARANTEED_CAMPAIGN,
    label: 'Pmp Guaranteed Campaign',
  },
]

export const optionsFlightTypes = [
  { value: 1, label: 'AudioEverywhere' },
  { value: 2, label: 'AudioSegmented' },
  { value: 3, label: 'PodcastEverywhere' },
  { value: 4, label: 'PodcastSegmented' },
  { value: 5, label: 'VideoEverywhere' },
  { value: 6, label: 'VideoSegmented' },
  { value: 7, label: 'OutOfNetwork' },
  { value: 9, label: 'PodcastStreaming' },
  { value: 10, label: 'GameStreamingSegmented' },
  { value: 11, label: 'PodcastDownloadEverywhere' },
  { value: 12, label: 'PodcastDownloadSegmented' },
  { value: 13, label: 'VidcastDownloadEverywhere' },
  { value: 14, label: 'VidcastDownloadSegmented' },
  { value: 15, label: 'GameEverywhere' },
  { value: 16, label: 'GameSegmented' },
  { value: 17, label: 'PodcastStreamingEverywhere' },
  { value: 18, label: 'PodcastStreamingSegmented' },
  { value: 19, label: 'RadioStreamingEverywhere' },
  { value: 20, label: 'RadioStreamingSegmented' },
  { value: 21, label: 'Non-Pubable' },
]

export enum EnumHardcostType {
  AMOUNT = 'Amount',
  PERCENT = 'Percent',
  CPM = 'CPM',
}
export const optionsHardCostTypes = [
  { value: EnumHardcostType.AMOUNT, label: 'Amount' },
  { value: EnumHardcostType.PERCENT, label: 'Percentage' },
  { value: EnumHardcostType.CPM, label: 'CPM' },
]

export enum EnumLanguageModeType {
  BROWSER_APP_LANGUAGE_ZIP_AS_FALLBACK = 1,
  BROWSER_APP_LANGUAGE = 2,
  ZIPS = 3,
  FORCE_LANGUAGE = 4,
  CHANNEL_LANGUAGE = 5,
}
export const optionsLanguageModeTypes = [
  {
    value: EnumLanguageModeType.BROWSER_APP_LANGUAGE_ZIP_AS_FALLBACK,
    label: 'BrowserAppLanguageZipAsFallback',
  },
  {
    value: EnumLanguageModeType.BROWSER_APP_LANGUAGE,
    label: 'BrowserAppLanguage',
  },
  { value: EnumLanguageModeType.ZIPS, label: 'Zips' },
  { value: EnumLanguageModeType.FORCE_LANGUAGE, label: 'ForceLanguage' },
  { value: EnumLanguageModeType.CHANNEL_LANGUAGE, label: 'ChannelLanguage' },
]

export const optionsNetOrGross = [
  { value: true, label: 'Net' },
  { value: false, label: 'Gross' },
]

//! not used
export const optionsPlacementTypes = [
  { value: 'Internal', label: 'Internal' },
  { value: 'External', label: 'External' },
]

export enum EnumPlacementProductTypes {
  ROS_BANNER = 'RosBanner',
  SKIN = 'Skin',
}
//! not used
export const optionsPlacementTypesProduct = [
  { value: 'RosBanner', label: EnumPlacementProductTypes.ROS_BANNER },
  { value: 'Skin', label: EnumPlacementProductTypes.SKIN },
]

export enum EnumSequentialTypes {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}
export const optionsSequentialTypes = [
  { value: EnumSequentialTypes.FIRST, label: 'First' },
  { value: EnumSequentialTypes.SECOND, label: 'Second' },
  { value: EnumSequentialTypes.THIRD, label: 'Third' },
]
