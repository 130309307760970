import { h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { RouterView } from 'vue-router'

export default [
  {
    path: '/:organization?/deals',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'Deals',
        component: () => import('@/views/deal/index.vue'),
        redirect: { name: 'ListDeals' },
        meta: {
          title: 'Passport | List of Deals',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'ListDeals',
            component: () => import('@/views/deal/list.vue'),
          },
          {
            path: 'default',
            name: 'DefaultSettingsDeal',
            component: () => import('@/components/Deals/DefaultSettings.vue'),
            meta: {
              title: 'Passport | Deal Default Settings',
              layout: 'LayoutAuthenticated',
              redirectPath: '/deals',
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'create',
        name: 'CreateDeal',
        component: () => import('@/views/deal/edit.vue'),
        meta: {
          title: 'Passport | Create Deal',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'edit/:deal/:tab?',
        name: 'EditDeal',
        component: () => import('@/views/deal/edit.vue'),
        meta: {
          title: 'Passport | Edit Deal',
          layout: 'LayoutAuthenticated',
          redirectPath: '/deals',
          requiresAuth: true,
        },
        props: (route) => ({ tabSelected: route.params.tab || 'info' }),
      },
    ],
  },
] as RouteRecordRaw[]
