import _cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import { baseUrl } from '@/helpers/url'

const getDefaultState = () => ({
  current: null,
  form: null,
  notificationsSettings: [],
  notificationsTitles: null,
})

export default {
  namespaced: true,

  state: getDefaultState,

  mutations: {
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_NOTIFICATIONS_SETTINGS(state, settings) {
      state.notificationsSettings = settings.slice()
    },
    SET_NOTIFICATIONS_TITLES(state, titles) {
      state.notificationsTitles = titles
    },
    TOGGLE_NOTIFICATION_SETTING(state, settingsUpdated) {
      const notificationsSettings = state.notificationsSettings.slice()
      const [notificationSettingUpdated] = settingsUpdated.slice()

      const settingIndex = state.notificationsSettings.findIndex(
        ({ notificationType }) =>
          notificationType === notificationSettingUpdated.notificationType
      )

      notificationsSettings[settingIndex] = notificationSettingUpdated
      state.notificationsSettings = notificationsSettings
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const response = await HTTP.get('Account/self')

        commit('SET_CURRENT', response.data.datas)

        const {
          currencyCode,
          firstName,
          lastName,
          userPreference,
          completePicturePath,
        } = response.data.datas
        const form = {
          firstName,
          lastName,
          picture: null,
          completePicturePath,
          numberI18n: userPreference.numberI18n,
          dateI18n: userPreference.dateI18n,
          currencyCode,
        }

        commit('SET_FORM', form)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchNotificationsInfos({ commit }) {
      try {
        const baseUrl = import.meta.env.VITE_TARGETSPOT_API_BASE_URL.replace(
          '/api/v1',
          ''
        )
        const response = await axios.get(
          `${baseUrl}/strings/notifications.json`
        )

        commit('SET_NOTIFICATIONS_TITLES', response.data.NotificationTitles)
        return Promise.resolve(response.data.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchNotificationsSettings({ commit }) {
      try {
        const response = await HTTP.get('UserNotifications')

        commit('SET_NOTIFICATIONS_SETTINGS', response.data.datas.data)

        return Promise.resolve(response.data.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async toggleNotificationSetting({ commit }, setting) {
      try {
        const response = await HTTP.put('UserNotifications/single', setting)

        commit('TOGGLE_NOTIFICATION_SETTING', response.data.datas.data)

        return Promise.resolve(response.data.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async update({ commit, state, rootGetters, rootState }) {
      try {
        const bodyFormData = new FormData()

        bodyFormData.append('id', state.current.id)
        for (const key in state.form) {
          if (
            state.form[key] !== null &&
            !['completePicturePath', 'currencyCode'].includes(key)
          ) {
            bodyFormData.append(`${key}`, state.form[key])
          }
        }

        const response = await HTTP.put('User', bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        await HTTP.put('UserCurrentConfig/self', {
          currencyCode: state.form.currencyCode,
          countryCode: rootState.auth.user.countryCode,
          organizationId: rootGetters['auth/currentOrganization']?.id,
        })

        commit('SET_CURRENT', response.data.datas)

        // Update auth user
        commit(
          'auth/SET_USER',
          {
            ...rootState.auth.user,
            currencyCode: state.form.currencyCode,
            firstName: state.form.firstName,
            lastName: state.form.lastName,
            numberI18n: state.form.numberI18n,
            dateI18n: state.form.dateI18n,
          },
          { root: true }
        )

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateEmail({ state }, { newEmail, password }) {
      try {
        const form = {
          baseUrl: `${baseUrl}/confirm-email/`,
          newEmail,
          password,
          userId: state.current.id,
        }

        const response = await HTTP.post('Account/send-change-email-mail', form)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updatePassword({ state }, { password }) {
      try {
        const form = {
          ...state.form,
          password,
        }

        const response = await HTTP.put('Account/self/Profile', form)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    handleInput({ commit }, { path, value, valueKey = 'form' }) {
      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
