const initialState = () => ({
  canExit: true,
  visibility: false,
  name: null,
  props: null,
})

export default {
  namespaced: true,

  state: initialState,

  mutations: {
    SET_VISIBILITY(state, visibility) {
      state.visibility = visibility
    },
    SET_COMPONENT(state, { name = null, props = null, canExit = true } = {}) {
      state.canExit = canExit
      state.name = name
      state.props = props
    },
  },

  actions: {
    hide({ commit }) {
      commit('SET_COMPONENT', { name: null, props: null })
      commit('SET_VISIBILITY', false)
    },
    show({ commit }, { name = null, props = null, canExit = true }) {
      commit('SET_COMPONENT', { name, props, canExit })
      commit('SET_VISIBILITY', true)
    },
  },
}
