import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig.js'
import { searchList } from '@/helpers/search'
import { distinctList } from '@/helpers/list'

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
    listChildren: {},
    listIO: [],
  },

  mutations: {
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
      state.listIO = []
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_LIST_CHILDREN(state, { id, list }) {
      const listChildren = _cloneDeep(state.listChildren)

      listChildren[id] = list

      state.listChildren = listChildren
    },
    SET_LIST_IO(state, list) {
      state.listIO = list
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async fetchConfigView(
      { commit, dispatch, state, rootGetters },
      { campaignType = null } = {}
    ) {
      try {
        const response = await HTTP.get(
          `CampaignOrder/Configview/${campaignType ? campaignType : ''}`
        )
        const parsedConfig = parseConfig(
          response.data.CampaignXml,
          'CampaignTypeId'
        )

        commit('SET_CONFIG_VIEW', parsedConfig)

        const productTypes = Array.isArray(
          parsedConfig.fields.CampaignType.DataList.DataList
        )
          ? parsedConfig.fields.CampaignType.DataList.DataList
          : [parsedConfig.fields.CampaignType.DataList.DataList]

        if (campaignType === null) {
          if (
            productTypes.findIndex(
              (productType) => +productType.Id === +parsedConfig.CampaignTypeId
            ) >= 0
          ) {
            await dispatch('handleInput', {
              path: 'campaignType',
              value: +parsedConfig.CampaignTypeId,
              valueKey: 'form',
            })
          } else {
            const firstProductType = productTypes[0].Id

            await dispatch('fetchConfigView', {
              campaignType: +firstProductType,
            }).then(() => {
              dispatch('handleInput', {
                path: 'campaignType',
                value: +firstProductType,
                valueKey: 'form',
              })
            })
          }
        } else if (
          productTypes.findIndex(
            (productType) => +productType.Id === +campaignType
          ) < 0 &&
          productTypes.length > 0
        ) {
          const firstProductType = productTypes[0].Id

          await dispatch('handleInput', {
            path: 'campaignType',
            value: +firstProductType,
            valueKey: 'form',
          })
        }

        if (!state.form?.id) {
          if (parsedConfig.fields.CampaignTraffickingFee.DefaultValue) {
            await dispatch('handleInput', {
              path: 'traffickingFeePercentage',
              value: parsedConfig.fields.CampaignTraffickingFee.DefaultValue,
              valueKey: 'form',
            })
          }

          const optionsSalesUsersIds = rootGetters['saleUsers/options'].map(
            (user) => user.value
          )
          const currentUserId = rootGetters['auth/currentUser']?.id

          if (optionsSalesUsersIds.includes(currentUserId)) {
            dispatch('handleInput', {
              path: 'campaignSaleUsersList.0',
              value: { userId: currentUserId, split: 100 },
              valueKey: 'form',
            })
          }
        }

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit, state }, { id, force = true }) {
      if (!force && state.current && state.current.id === id) {
        if (!state.form || state.form.id !== id) {
          // commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(state.current)
      }

      try {
        const response = await HTTP.get(`CampaignOrder/${id}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        if (!state.form || state.form.id !== id) {
          commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`CampaignOrder/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post('CampaignOrder', state.form)

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { options } = {}) {
      try {
        const response = await HTTP.put('CampaignOrder', state.form, options)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async duplicate(context, { id }) {
      try {
        const response = await HTTP.post(`CampaignOrder/duplicate`, {
          campaignOrderId: id,
          isFullDuplicate: true,
        })

        return Promise.resolve(response?.data?.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async archive(context, { id }) {
      try {
        const response = await HTTP.get(`CampaignOrder/Status`, {
          params: {
            campaignOrderId: id,
            status: 'Archived',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async export(_, { id, type }) {
      try {
        const url =
          type === 'PDF'
            ? `CampaignOrder/export/${id}`
            : `CampaignOrder/exportexcel/${id}`
        const response = await HTTP.get(url, {
          executeInBackground: true,
          ...(type === 'XLS' ? { responseType: 'blob' } : {}),
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async convertBatch(_, { flightOrderIds = [], budgetGroupIds = [] }) {
      try {
        const response = await HTTP.post('Order/convert/batch', {
          budgetGroupIds,
          flightOrderIds,
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, rootState }) {
      try {
        const countryCode = rootState.auth.user?.countryCode?.toLowerCase()
        const response = await HTTP.get(
          countryCode
            ? `CampaignOrder/list/${countryCode}`
            : 'CampaignOrder/list'
        )

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    async search({ commit, rootState }, { search, fields }) {
      try {
        const countryCode = rootState.auth.user?.countryCode?.toLowerCase()
        const response = await HTTP.get(
          countryCode
            ? `CampaignOrder/list/${countryCode}`
            : 'CampaignOrder/list'
        )

        const searchTerms = search.split(' ').map((searchTerm) => {
          return searchTerm
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        })

        const matchedFlights = response.data.datas.filter(({ flightIds }) => {
          return flightIds?.some((flightId) =>
            searchTerms.some((term) => flightId.toString().includes(term))
          )
        })

        response.data.datas = distinctList([
          ...searchList(response.data.datas, fields, search),
          ...matchedFlights,
        ])

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    async listChildren({ commit }, { id }) {
      try {
        const response = await HTTP.get(`FlightOrder/full/${id}`)

        return Promise.resolve(response.data.datas)
      } catch (error) {
        commit('SET_LIST_CHILDREN', [])

        return Promise.reject(error)
      }
    },

    async listIO({ commit }, { id }) {
      try {
        const response = await HTTP.get(`CampaignOrder/io/${id}`)

        commit('SET_LIST_IO', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST_IO', [])

        return Promise.reject(error)
      }
    },

    async downloadIO(_, { id, ioName }) {
      try {
        const response = await HTTP.post(
          `CampaignOrder/io/Download`,
          {
            CampaignOrderId: id,
            ioName,
          },
          {
            responseType: 'arraybuffer',
          }
        )

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async deleteIO(_, { id, ioName }) {
      try {
        const response = await HTTP.delete(`CampaignOrder/io`, {
          params: {
            CampaignOrderId: id,
            ioName,
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    async setDefaultForm({ commit, rootState }) {
      const form = await import('@/forms/order.js')
      const defaultForm = _cloneDeep(form.default)

      commit('SET_FORM', {
        ...defaultForm,
        countryCode: rootState.auth.user?.countryCode || null,
        currencyCode: rootState.organization.defaultCurrency || null,
      })
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
