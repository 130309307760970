import axios from 'axios'
import HTTP from '@/services/http'
import store from '@/store/index'

const createHttpCancelRequestInterceptor = function () {
  HTTP.interceptors.request.use((config) => {
    if (
      !['Account/', 'Organization/'].some(
        (path) => config.url && config.url.includes(path)
      )
    ) {
      try {
        const source = axios.CancelToken.source()

        store.dispatch('requests/addCancelToken', source)
        config.cancelToken = source.token
      } catch (error) {
        console.error(error)
      }
    }

    return config
  })

  HTTP.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isCancel(error)) {
        store.dispatch('addAutoSaveNotification', false)
        store.commit('loader/FINISH_LOADING')
      }

      return Promise.reject(error)
    }
  )
}

export default createHttpCancelRequestInterceptor
