import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig'

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: {
      campaignId: null,
      from: new Date(),
      to: new Date(),
      groupBy: null,
    },
    isPrinting: false,
  },

  mutations: {
    RESET_STATE(state) {
      state.form = {
        campaignId: null,
        from: new Date(),
        to: new Date(),
        groupBy: null,
      }
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_IS_PRINTING(state, isPrinting) {
      state.isPrinting = isPrinting
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    handleInput({ commit }, { path, value, valueKey = 'form' }) {
      commit('HANDLE_INPUT', { path, value, valueKey })
    },

    print({ commit }, isPrinting) {
      commit('SET_IS_PRINTING', isPrinting)
    },

    async fetch({ commit }, { uid }) {
      try {
        const response = await HTTP.get(`Share/CampaignReport/${uid}`)

        commit('SET_CURRENT', response.data.datas)

        Promise.resolve(response)
      } catch (error) {
        Promise.reject(error)
      }
    },

    async downloadReport(_, { id, params, filters }) {
      params = {
        ...params,
        filters,
      }

      try {
        const response = await HTTP.get(`campaign/${id}/xls`, {
          executeInBackground: true,
          responseType: 'blob',
          params,
        })

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async downloadReportPdf(_, { html, uid, isMagicToken }) {
      try {
        const response = await HTTP.post(
          isMagicToken ? `Pdf/${uid}` : `Pdf`,
          html,
          {
            executeInBackground: true,
            headers: { 'Content-Type': 'application/xml' },
          }
        )

        return Promise.resolve(response?.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchConfigView({ commit }, { campaignId }) {
      try {
        const response = await HTTP.get(
          `campaign/Configview/CampaignReport/${campaignId}`
        )
        const parsedConfig = parseConfig(
          response.data.CampaignReportXml,
          'CampaignReportTypeId'
        )

        commit('SET_CONFIG_VIEW', parsedConfig)

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },
  },
}
