import HTTP from '@/services/http'
import store from '@/store/index'

const createHttpAutoSaveInterceptor = function () {
  let pendingRequests = 0

  HTTP.interceptors.request.use((config) => {
    if (config.executeInBackground === true) {
      return config
    }

    if (
      ['PUT', 'POST'].includes(config.method.toUpperCase()) &&
      !['Account/login'].includes(config.url)
    ) {
      pendingRequests++

      store.dispatch('addAutoSaveNotification', true)
    }

    return config
  })

  HTTP.interceptors.response.use(
    (response) => {
      if (!response || response.config.executeInBackground === true) {
        return Promise.resolve(response)
      }

      if (
        ['PUT', 'POST'].includes(response.config.method.toUpperCase()) &&
        !['Account/login'].includes(response.config.url)
      ) {
        pendingRequests--
      }

      if (pendingRequests <= 0) {
        store.dispatch('addAutoSaveNotification', false)
      }

      return Promise.resolve(response)
    },
    (error) => {
      if (!error.response) {
        return Promise.reject(error)
      }

      if (
        ['PUT', 'POST'].includes(error.response.config.method.toUpperCase()) &&
        !['Account/login'].includes(error.response.config.url)
      ) {
        pendingRequests--
      }

      if (pendingRequests <= 0) {
        store.dispatch('addAutoSaveNotification', false)
      }

      return Promise.reject(error)
    }
  )
}

export default createHttpAutoSaveInterceptor
