import store from '@/store/index'
import { format, formatDistanceToNow, isValid, parse, parseISO } from 'date-fns'
import { formats } from '@/helpers/dateFns'
import { isBefore, isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

const isEUDateFormat = store.state.auth.user?.userPreference?.dateI18n === 'EU'

const parseAndFormat = (
  value: string | Date,
  dateFormat: null | string = null,
  parseFormat: null | string = null
): string | null => {
  if (!value) {
    return null
  }

  if (dateFormat === null) {
    dateFormat = isEUDateFormat ? formats.EU.date : formats.US.date
  }

  if (parseFormat) {
    if (typeof value !== 'string') return null

    return format(parse(value, parseFormat, new Date()), dateFormat)
  } else {
    if (value instanceof Date) {
      return format(value, dateFormat)
    }

    return format(parseISO(value), dateFormat)
  }
}

const formatDate = (
  value: string | Date,
  parseFormat = null
): string | null => {
  const dateFormat = isEUDateFormat ? formats.EU.date : formats.US.date

  return parseAndFormat(value, dateFormat, parseFormat)
}

const formatDateWithDay = (
  value: string | Date,
  parseFormat = null
): string | null => {
  const dateFormat = isEUDateFormat
    ? `iiiiii. ${formats.EU.date}`
    : `iiiiii. ${formats.US.date}`

  return parseAndFormat(value, dateFormat, parseFormat)
}

const formatTime = (
  value: string | Date,
  parseFormat = formats.ISO.time
): string | null => {
  const timeFormat = isEUDateFormat ? formats.EU.time : formats.US.time

  return parseAndFormat(value, timeFormat, parseFormat)
}

const formatDateWithTime = (value: string | Date): string | null => {
  return parseAndFormat(value, 'PPPppp')
}

const timeAgo = (value: string): string | null => {
  if (!value) {
    return null
  }

  return formatDistanceToNow(new Date(value), { addSuffix: true })
}

const areDateRangesOverlap = (
  range1: { start: Date; end: Date },
  range2: { start: Date; end: Date }
): boolean =>
  isBefore(range1.start, range2.end) && isAfter(range1.end, range2.start)

const isDateInPast = (endDate: string, timeZoneId?: string): boolean => {
  if (!endDate) return false

  const parsedEndDate = parseISO(endDate)
  let dateNow = new Date()

  if (!isValid(parsedEndDate)) return true

  if (timeZoneId) {
    const dateNowTz = utcToZonedTime(new Date(), timeZoneId)

    dateNow = isValid(dateNowTz) ? dateNowTz : dateNow
  }

  return isBefore(parsedEndDate, dateNow)
}

export {
  isDateInPast,
  formatDate,
  formatDateWithDay,
  formatDateWithTime,
  formatTime,
  parseAndFormat,
  timeAgo,
  areDateRangesOverlap,
}
