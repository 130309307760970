export const formats = {
  EU: {
    date: 'dd/MM/yyyy',
    time: 'HH:mm',
  },
  ISO: {
    date: 'yyyy-MM-dd',
    dateWithTime: "yyyy-MM-dd'T'HH:mm:ss",
    time: 'HH:mm:ss',
  },
  US: {
    date: 'MM/dd/yyyy',
    time: 'p',
  },
}
