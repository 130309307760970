<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-text-grey-medium hover:tw-text-blackest"
    :class="{ 'tw-justify-end': justify === 'end' }"
  >
    <div class="tw-w-full tw-truncate tw-text-sm">
      <Tooltip
        dynamic
        tag="span"
        :title="budgetSpread"
      >
        {{ budgetSpread }}
      </Tooltip>
    </div>
  </router-link>
</template>
<script>
import { optionsBudgetSpreadTypes } from '@/constants/options'
import ListCellDefault from '@/components/list/cell/default.vue'
import Tooltip from '@/components/Tooltip.vue'

export default {
  name: 'ListCellBudgetSpread',
  extends: ListCellDefault,
  components: {
    Tooltip,
  },
  computed: {
    budgetSpread() {
      const budgetSpreadId = this.safeGet(this.item, this.headerKey)
      const budgetSpreadType = optionsBudgetSpreadTypes.find(
        ({ value }) => value === budgetSpreadId
      )

      return (budgetSpreadType && budgetSpreadType.value) || '—'
    },
  },
}
</script>
