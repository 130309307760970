import HTTP from '@/services/http'

const getDefaultState = () => ({
  list: [],
  currentIndex: 0,
  countUnread: 0,
  paging: {
    index: 0,
    size: 10,
    skip: 0,
    total: 0,
  },
})

export default {
  namespaced: true,

  state: getDefaultState,

  mutations: {
    RESET_STATE(state) {
      ;(state.list = []),
        (state.currentIndex = 0),
        (state.paging = {
          index: 0,
          size: 10,
          skip: 0,
          total: 0,
        })
    },
    ADD_TO_LIST(state, list) {
      state.list.push(...list)
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_PAGING(state, paging) {
      Object.assign(state.paging, paging)
    },
    SET_COUNT_UNREAD(state, count) {
      state.countUnread = count
    },
    UPDATE_NOTIFICATIONS(state, notifications) {
      const stateNotifications = [...state.list]

      notifications.forEach((notification) => {
        const notificationIndex = state.list.findIndex(
          ({ id }) => id === notification?.id
        )

        if (notificationIndex >= 0) {
          stateNotifications[notificationIndex] = notification
        }
      })

      state.list = [...stateNotifications]
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    async getCountUnread({ commit }) {
      try {
        const response = await HTTP.get('notifications/count/unread', {
          executeInBackground: true,
        })

        commit('SET_COUNT_UNREAD', response.data.datas)
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async list({ commit, dispatch }, { index = 0 } = {}) {
      try {
        const response = await HTTP.get(`notifications/list/${index}`, {
          executeInBackground: true,
        })
        const { list = [], paging } = response.data.datas

        commit('SET_PAGING', paging)

        if (index === 0) {
          commit('SET_LIST', list)
        } else {
          commit('ADD_TO_LIST', list)
        }

        const notificationsUnseen = list
          .filter(({ seenAt }) => !seenAt)
          .map(({ id }) => id)

        if (notificationsUnseen.length) {
          dispatch('setStatus', {
            notificationIds: notificationsUnseen,
            status: 'seen',
          })
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async search({ commit, dispatch }, { search = '', index = 0 } = {}) {
      try {
        const response = await HTTP.get(
          `notifications/search/${search.trim().normalize('NFC')}/${index}`
        )
        const { list = [], paging } = response.data.datas

        commit('SET_PAGING', paging)

        if (index === 0) {
          commit('SET_LIST', list)
        } else {
          commit('ADD_TO_LIST', list)
        }

        const notificationsUnseen = list
          .filter(({ seenAt }) => !seenAt)
          .map(({ id }) => id)

        if (notificationsUnseen.length) {
          dispatch('setStatus', {
            notificationIds: notificationsUnseen,
            status: 'seen',
          })
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async setStatus({ commit, state }, { status, notificationIds }) {
      try {
        const response = await HTTP.put(
          `notifications/${status}`,
          notificationIds,
          { executeInBackground: true }
        )

        if (status === 'dismissed') {
          commit(
            'SET_LIST',
            state.list.filter(({ id }) => !notificationIds.includes(id))
          )
        } else {
          commit('UPDATE_NOTIFICATIONS', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async setAllStatus({ commit }, { status }) {
      try {
        const response = await HTTP.put(`notifications/${status}/all`, null, {
          executeInBackground: true,
        })

        if (status === 'dismissed') {
          commit('SET_LIST', [])
        } else {
          commit('UPDATE_NOTIFICATIONS', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async setStatusUnread({ commit }, { id }) {
      try {
        const response = await HTTP.put(`notifications/unread/${id}`, null, {
          executeInBackground: true,
        })

        commit('UPDATE_NOTIFICATIONS', [response.data.datas])
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setCountUnread({ commit }, count) {
      commit('SET_COUNT_UNREAD', count)
    },
  },
}
