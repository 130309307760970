const entity = {
  module: 'creativeLibrary',
  singular: 'Creative',
  list: {
    page: 1,
    loading: true,
    search: '',
    shouldRetry: false,
    sortKey: 'name',
    sortOrder: 'asc',
    pagination: {
      perPage: import.meta.env.DEV ? 10 : 50,
      page: 1,
    },
    player: {
      id: null,
      isPlaying: false,
      progression: 0,
      source: null,
    },
    headers: [
      {
        key: 'gap-1',
        pages: [1, 2],
        size: '30px',
        heading: false,
        // classCell: 'tw-mt-4 tw-hidden',
        classHeader: 'tw-hidden',
      },
      {
        label: '',
        key: 'id',
        pages: [1, 2],
        heading: false,
        componentList: 'ListCellCreativePlay',
        classCell:
          'tw-mt-4 tw-border-t tw-h-12 tw-border-l tw-border-r tw-border-border tw-rounded-l tw-justify-center tw-text-md',
        classHeader: '',
        size: '50px',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellCreativeName',
        size: 'minmax(100px, 1fr)',
        classCell: ' tw-px-2 tw-mt-4 tw-border-t tw-h-12',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Advertiser',
        key: 'advertiserName',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: 'minmax(100px, 1fr)',
        classCell: 'tw-px-2 tw-mt-4 tw-border-t tw-h-12',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Uploaded Date',
        key: 'createdDate',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: 'minmax(150px, 0.5fr)',
        classCell: 'tw-px-2 tw-mt-4 tw-border-t tw-h-12',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Duration',
        key: 'duration',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: 'minmax(80px, 0.5fr)',
        classCell: 'tw-px-2 tw-mt-4 tw-border-t tw-h-12',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-12 tw-border-r tw-border-border tw-rounded-r',
        classHeader: '',
      },
    ],
    rowOptions: () => {
      const options = [
        {
          key: 'delete',
          label: 'Delete',
          icon: 'trash',
        },
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
        },
      ]

      return options
    },
  },
}

export default entity
