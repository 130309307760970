<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Do you really want to delete this
        {{ entity.singular.toLowerCase() }}?
      </h2>
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        :busy="isLoading"
        variant="primary"
        :label="
          shouldRetry ? 'Retry' : `Delete ${entity.singular.toLowerCase()}`
        "
        type="button"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>
<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  components: { PassportButton },
  name: 'PopupCreativeLibraryDelete',
  props: {
    callback: {
      type: Function,
      required: false,
      default: () => ({}),
    },
    entity: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'refreshData'],
  data() {
    return {
      error: null,
      isLoading: false,
      shouldRetry: false,
    }
  },
  methods: {
    async handleSubmit() {
      this.error = null
      this.isLoading = true
      this.shouldRetry = false

      try {
        await this.$store.dispatch(`${this.entity.module}/delete`, {
          id: this.item.id,
        })

        this.$store.dispatch('addToast', {
          type: 'info',
          value: `${this.entity.singular} ${this.item.id} successfully deleted`,
        })

        this.$emit('refreshData')
        this.$emit('close')
      } catch (error) {
        this.shouldRetry = true
        this.error = this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
