<template>
  <div class="tw-w-modal-lg tw-divide-y tw-divide-border-light">
    <div class="tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Set Start Date for the Campaign
      </h2>
    </div>
    <div class="tw-p-6">
      <p v-if="isCreating">Please put a start date for the campaign</p>
      <p v-else>The campaign date has passed, please enter a new one</p>
      <form
        id="campaignWithFlightApplyBundle"
        @submit.prevent="handleSubmit"
      >
        <DateInput
          :with-button-apply="true"
          :model-value="form.newStartDate"
          class="tw-w-1/2"
          label="New Start Date"
          required
          :min-date="minDate"
          :mode="EnumDateInputModes.DATE_TIME"
          @update:model-value="handleUpdateStartDate"
        />
      </form>
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        :busy="isLoading"
        variant="primary"
        :label="error ? 'Retry' : 'Set'"
        type="submit"
        form="campaignWithFlightApplyBundle"
      />
    </div>
  </div>
</template>

<script>
import {
  addHours,
  parseISO,
  formatISO,
  addDays,
  set as setDateValue,
} from 'date-fns'
import { EnumDateInputModes } from '@/constants/components'

import DateInput from '@/components/Input/DateInput.vue'
import PassportButton from '@/components/PassportButton.vue'

export default {
  name: 'PopupCampaignWithFlightApplyBundle',
  components: {
    DateInput,
    PassportButton,
  },
  props: {
    bundleSelected: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    isCreating: {
      type: Boolean,
      required: true,
    },
    resolve: {
      type: Function,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      error: null,
      EnumDateInputModes,
      form: {
        newStartDate: null,
      },
      isLoading: false,
    }
  },
  computed: {
    minDate() {
      return formatISO(
        addHours(setDateValue(new Date(), { minutes: 0, seconds: 0 }), 1)
      )
    },
  },
  methods: {
    handleUpdateStartDate(start) {
      if (new Date(start) < new Date()) {
        start = formatISO(
          addHours(setDateValue(new Date(), { minutes: 0, seconds: 0 }), 1)
        )
      }

      this.form.newStartDate = start
    },
    async handleSubmit() {
      this.error = null
      this.isLoading = true

      try {
        const periodInDays = Number(this.bundleSelected.period.split('.')[0])
        const newEndDate = addDays(
          parseISO(this.form.newStartDate),
          periodInDays
        )

        this.$store.dispatch(`${this.entity.module}/handleInput`, {
          path: 'metaBundleId',
          value: this.bundleSelected.id,
        })
        this.$store.dispatch(`${this.entity.module}/handleInput`, {
          path: 'budget.impressionsGoal',
          value: this.bundleSelected.impressionsGoal,
        })
        this.$store.dispatch(`${this.entity.module}/handleInput`, {
          path: 'budget.grossCpm',
          value: this.bundleSelected.cpm,
        })
        this.$store.dispatch(`${this.entity.module}/handleInput`, {
          path: 'budget.flightAmount',
          value: this.bundleSelected.budget,
        })

        this.$store.dispatch(`${this.entity.module}/handleInput`, {
          path: 'startDate',
          value: this.form.newStartDate,
        })
        this.$store.dispatch(`${this.entity.module}/handleInput`, {
          path: 'endDate',
          value: formatISO(newEndDate),
        })

        await this.resolve()

        this.$emit('close')
      } catch (error) {
        this.error = this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
