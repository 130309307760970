<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const autoSaveNotification = computed(
  () => store.state.toasts.autoSaveNotification
)

const autoSaveStatus = computed(() => store.state.toasts.autoSaveStatus)
</script>

<template>
  <span
    v-show="autoSaveNotification"
    class="tw-fixed tw-bottom-10 tw-right-32 tw-z-50 tw-rounded tw-px-6 tw-py-2 tw-text-sm tw-text-white"
    :class="autoSaveStatus === 'error' ? 'tw-bg-red' : 'tw-bg-black'"
  >
    {{ autoSaveStatus !== 'error' ? 'Saving ...' : 'Something is wrong' }}
  </span>
</template>
