function decorateSegments(decoratedSegments, segment, index, allSegments) {
  if (allSegments.length === 0) {
    return []
  }

  if (index === 0) {
    if (allSegments.length > 1) {
      decoratedSegments.push({
        ContentType: 'Operator',
        Value: 'StartBrace',
      })
    }
  } else if (index !== 0) {
    decoratedSegments.push({
      ContentType: 'Operator',
      Value: 'Or',
    })
  }

  decoratedSegments.push({
    ContentType: 'Dmp',
    Value: segment,
  })

  if (index + 1 === allSegments.length) {
    if (allSegments.length > 1) {
      decoratedSegments.push({
        ContentType: 'Operator',
        Value: 'EndBrace',
      })
    }
  }

  return decoratedSegments
}

export default (groups) => {
  if (groups.length === 0) {
    return null
  }

  try {
    const transformedGroups = groups.reduce(
      (transformedGroups, group, groupIndex) => {
        const includedGroupSegments = group.Values.filter(
          ({ IsIncluded }) => IsIncluded
        ).reduce(decorateSegments, [])

        const excludedGroupSegments = group.Values.filter(
          ({ IsIncluded }) => !IsIncluded
        ).reduce(decorateSegments, [])

        const Values = []

        if (includedGroupSegments.length > 0) {
          Values.push(
            ...includedGroupSegments.map((segment) => {
              return {
                ...segment,
                IsIncluded: true,
              }
            })
          )
        }

        if (excludedGroupSegments.length > 0) {
          if (Values.length > 0) {
            Values.push({
              ContentType: 'Operator',
              Value: 'And',
            })
            Values.push({
              ContentType: 'Operator',
              Value: 'Not',
            })
          }
          Values.push(
            ...excludedGroupSegments.map((segment) => {
              return {
                ...segment,
                IsIncluded: false,
              }
            })
          )
        }

        if (Values.length > 0) {
          if (groupIndex !== 0) {
            transformedGroups.push({
              ContentType: 'Operator',
              Value: 'Or',
            })
          }

          transformedGroups.push({
            ContentType: 'Group',
            IsIncluded: true,
            SellPrice: group.SellPrice,
            Values: Values.map((Value, index) => {
              return {
                ...Value,
                Order: index + 1,
              }
            }),
          })
        }

        return transformedGroups
      },
      []
    )

    return JSON.stringify({
      IsAdvanced: false,
      Values: transformedGroups,
    })
  } catch (error) {
    console.error(error)
  }
}
