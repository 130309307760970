const sort = localStorage.getItem('user-sort')

import { userCan, userHasFunction } from '@/helpers/auth'

export default {
  module: 'user',
  key: 'PeopleUsers',
  route: {
    index: { name: 'UserList' },
    edit: 'EditUser',
    create: 'CreateUser',
  },
  param: 'user',
  plural: 'Users',
  singular: 'User',
  list: {
    filters: [],
    loading: true,
    page: 1,
    pagination: {
      perPage: import.meta.env.DEV ? 10 : 50,
      page: 1,
    },
    search: '',
    shouldRetry: false,
    sortKey: sort ? JSON.parse(sort).key : '',
    sortOrder: sort ? JSON.parse(sort).order : 'asc',
    headers: [
      {
        label: '',
        key: 'completePicturePath',
        pages: [1, 2],
        heading: null,
        componentList: 'ListCellAvatar',
        alt: ['firstName', 'lastName'],
        size: '70px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-l tw-border-border tw-rounded-l tw-justify-center tw-text-lg',
        classHeader: '',
      },
      {
        label: 'First Name',
        key: 'firstName',
        pages: [1, 2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: 'minmax(100px, 1fr)',
        classCell:
          'tw-mt-4 tw-px-2 tw-border-l tw-border-t tw-h-20 tablet:tw-h-16 tw-border-border',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Last Name',
        key: 'lastName',
        pages: [1, 2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: 'minmax(100px, 1fr)',
        classCell:
          'tw-mt-4 tw-px-2 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-border',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Functions',
        key: 'functions',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellList',
        separator: ', ',
        size: 'minmax(200px, 2fr)',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2 tw-text-left tw-line-clamp-3 tablet:tw-line-clamp-2 tw-pt-2.5',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Email',
        key: 'email',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: '200px',
        classCell: 'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Phone',
        key: 'phone',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: '150px',
        classCell: 'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-r tw-border-border tw-rounded-r',
        classHeader: '',
      },
    ],
    rowOptions: (item) => {
      return [
        item.email &&
        !item.isSetPassword &&
        (userCan('ResetUserPassword') || userCan('ResetCustomerPassword'))
          ? {
              key: 'resetPassword',
              label: 'Reset Password',
              icon: 'padlock',
            }
          : null,
        (userCan('DeleteUser') && !item.isUserAboveConnectedUser) ||
        (userHasFunction('PeopleManager') &&
          ['AgencyBuyer'].every((role) => item.functions.includes(role)))
          ? {
              key: 'delete',
              label: 'Delete',
              icon: 'trash',
            }
          : null,
        (userCan('EditUser') && !item.isUserAboveConnectedUser) ||
        (userHasFunction('PeopleManager') &&
          ['AgencyBuyer'].every((role) => item.functions.includes(role)))
          ? {
              key: 'edit',
              label: 'Edit',
              icon: 'edit',
            }
          : null,
      ].filter((option) => option)
    },
  },
  moreActions: (item, { canRemoveEntity, canResetPassword }) => {
    return [
      canResetPassword
        ? {
            key: 'resetPassword',
            label: 'Reset Password',
            icon: 'padlock',
          }
        : null,
      canRemoveEntity
        ? {
            key: 'delete',
            label: `Delete`,
            icon: 'trash',
            class: 'hover:tw-text-red',
          }
        : null,
    ].filter((action) => action)
  },
  getDescription(item) {
    return `
    <div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Name</div>
        <div class="tw-text-sm">${item.firstName} ${item.lastName}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Email</div>
        <div class="tw-text-sm">${item.email || '-'}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Phone</div>
        <div class="tw-text-sm">${item.phone || '-'}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Functions</div>
        <div class="tw-text-sm tw-truncate">${item.functions}</div>
      </div>
    </div>`
  },
}
