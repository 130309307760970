import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/:organization?/profile',
    name: 'Profile',
    component: () => import('@/views/profile/profile.vue'),
    meta: {
      title: 'Passport | Profile',
      layout: 'LayoutAuthenticated',
      requiresAuth: true,
    },
  },
] as RouteRecordRaw[]
