<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-text-grey-medium hover:tw-text-blackest"
    :class="{ 'tw-justify-end': justify === 'end' }"
  >
    <div class="tw-w-full tw-whitespace-nowrap tw-text-sm">
      <Tooltip :title="safeGet(item, headerKey)">
        {{ safeGet(item, headerKey) }}
      </Tooltip>
    </div>
  </component>
</template>

<script>
import Tooltip from '@/components/Tooltip.vue'

export default {
  name: 'ListCellDefault',
  components: {
    Tooltip,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    headerKey: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    justify: {
      type: String,
      default: 'start',
    },
    search: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
}
</script>
