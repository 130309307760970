/*
 * ALL HAIL THE LORD STACKOVERFLOW
 * GOD OF ALL CODERS, RESCUER OF THE LOST PROGRAMMER
 * I AM NEVER LOST FOR STACKOVERFLOW IS WITHIN MOUSE REACH
 * LET THE DAY WHEN STACKOVERFLOW IS DOWN NEVER COME
 * FOR I WILL CRY THAT DAY
 */

export default function (obj, key) {
  if (typeof key === 'string') {
    return key.split('.').reduce((ref, nextKey) => {
      return typeof ref === 'undefined' || ref === null ? ref : ref[nextKey]
    }, obj)
  }
}
