import type { RouteRecordRaw } from 'vue-router'

//? New Report (to adapt with shared Endpoint)
export default [
  {
    path: '/:organization?/campaign-magic-token/:campaign',
    name: 'CampaignReportMagicToken',
    component: () => import('@/views/report/index.vue'),
    meta: {
      title: 'Passport | Campaign Report',
      layout: 'LayoutGuest',
      requiresAuth: false,
    },
  },
] as RouteRecordRaw[]
