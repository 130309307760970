import type { RouteRecordRaw } from 'vue-router'
import store from '@/store/index'
import { toRoute } from '@/helpers/route'

export default [
  {
    path: '/:organization?/billing',
    name: 'Billing',
    // PASSPORTV2-9086 hide billing
    // component: () => import('@/views/billing/simple.vue'),
    redirect: () => {
      if (store.state.auth.authenticated) {
        return toRoute(store.getters['auth/defaultRoute'])
      }

      return toRoute({ name: 'DashBoard' })
    },
    meta: {
      title: 'Passport | Billing',
      layout: 'LayoutAuthenticated',
      requireAuth: true,
    },
  },
] as RouteRecordRaw[]
