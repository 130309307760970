import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import { baseUrl } from '@/helpers/url'
import { searchList } from '@/helpers/search'

const getDefaultState = () => ({
  form: null,
  current: null,
  list: [],
  listBuyers: [],
  listBuyersId: null,
  listCampaigns: [],
  listCampaignsId: null,
  listContacts: [],
  listContactsId: null,
})

export default {
  namespaced: true,

  state: getDefaultState,

  mutations: {
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_LIST_BUYERS(state, listBuyers) {
      state.listBuyers = listBuyers
    },
    SET_LIST_BUYERS_ID(state, listBuyersId) {
      state.listBuyersId = listBuyersId
    },
    SET_LIST_CAMPAIGNS(state, listCampaigns) {
      state.listCampaigns = listCampaigns
    },
    SET_LIST_CAMPAIGNS_ID(state, listCampaignsId) {
      state.listCampaignsId = listCampaignsId
    },
    SET_LIST_CONTACTS(state, listContacts) {
      state.listContacts = listContacts
    },
    SET_LIST_CONTACTS_ID(state, listContactsId) {
      state.listContactsId = listContactsId
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async delete(_, { id }) {
      try {
        const response = await HTTP.delete(`Advertiser/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetch({ commit, state }, { id, force = true, reload = false }) {
      try {
        if (!force && state.current.id === id) {
          return Promise.resolve(state.current)
        }
        const response = await HTTP.get(`Advertiser/${id}`)

        commit('SET_CURRENT', response.data.datas)

        if (reload || !state.form || state.form.id !== id) {
          commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async handleInput({ commit }, { path, value, valueKey = 'form' }) {
      await commit('HANDLE_INPUT', { path, value, valueKey })
    },

    async list({ commit, state }, { force = false } = {}) {
      if (!force && state.list.length) {
        return state.list
      }

      try {
        const response = await HTTP.get('Advertiser/list')

        commit('SET_LIST', response.data.datas)

        return response
      } catch (e) {
        commit('SET_LIST', [])
      }
    },

    async listBuyers({ commit, state }, { id, force = false } = {}) {
      if (id === null) {
        commit('SET_LIST_BUYERS_ID', id)
        commit('SET_LIST_BUYERS', [])
        return state.listBuyers
      }

      if (!force && id === state.listBuyersId && state.listBuyers.length) {
        return state.listBuyers
      }

      try {
        const response = await HTTP.get(`Advertiser/${id}/users`)

        commit('SET_LIST_BUYERS_ID', id)
        commit('SET_LIST_BUYERS', response.data.datas)

        return response
      } catch (e) {
        commit('SET_LIST_BUYERS', [])
        commit('SET_LIST_BUYERS_ID', null)
      }
    },

    async listCampaigns({ commit, state }, { id, force = false } = {}) {
      if (id === null) {
        commit('SET_LIST_CAMPAIGNS_ID', id)
        commit('SET_LIST_CAMPAIGNS', [])
        return state.listCampaigns
      }

      if (
        !force &&
        id === state.listCampaignsId &&
        state.listCampaigns.length
      ) {
        return state.listCampaigns
      }

      try {
        const response = await HTTP.get(`Advertiser/${id}/campaigns`)

        commit('SET_LIST_CAMPAIGNS_ID', id)
        commit('SET_LIST_CAMPAIGNS', response.data.datas)
        return response
      } catch (e) {
        commit('SET_LIST_CAMPAIGNS', [])
        commit('SET_LIST_CAMPAIGNS_ID', null)
      }
    },

    async listContacts({ commit, state }, { id, force = false } = {}) {
      if (id === null) {
        commit('SET_LIST_CONTACTS_ID', id)
        commit('SET_LIST_CONTACTS', [])
        return state.listBuyers
      }

      if (!force && id === state.listBuyersId && state.listBuyers.length) {
        return state.listBuyers
      }

      try {
        const response = await HTTP.get(`Advertiser/${id}/users`)

        commit('SET_LIST_CONTACTS_ID', id)
        commit('SET_LIST_CONTACTS', response.data.datas)

        return response
      } catch (e) {
        commit('SET_LIST_CONTACTS', [])
        commit('SET_LIST_CONTACTS_ID', null)
      }
    },

    async create({ state }, data) {
      try {
        const formData = new FormData()

        if (data) {
          const form = await import('@/forms/advertiser.js')
          const defaultForm = {
            ..._cloneDeep(form.default),
            ...data,
          }

          Object.keys(defaultForm).forEach((key) => {
            formData.append(key, defaultForm[key])
          })
        } else {
          for (const key in state.form) {
            if (state.form[key] !== '' && !['completeLogoPath'].includes(key)) {
              formData.append(`${key}`, state.form[key])
            }
          }
        }

        const response = await HTTP.post('Advertiser', formData)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async search({ commit }, { search, fields }) {
      try {
        const response = await HTTP.get('Advertiser/list')

        response.data.datas = searchList(response.data.datas, fields, search)

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])
        return Promise.reject(error)
      }
    },

    async createUser(context, { id, data }) {
      const userForm = await import('@/forms/user.js')
      const userDefaultForm = _cloneDeep(userForm.default)

      const form = {
        ...userDefaultForm,
        ...data,
        advertiserId: id,
        baseUrl: `${baseUrl}/set-password/`,
      }

      try {
        const response = await HTTP.post('UserAdvertiser', form, {
          withoutError: true,
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ state }, options) {
      try {
        console
        const formData = new FormData()

        for (const key in state.form) {
          if (state.form[key] !== null && !['completeLogoPath'].includes(key)) {
            formData.append(`${key}`, state.form[key])
          }
        }
        const response = await HTTP.put('Advertiser', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          ...options,
        })

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async setDefaultForm({ commit, rootState }) {
      const form = await import('@/forms/advertiser.js')
      const defaultForm = _cloneDeep(form.default)

      commit('SET_FORM', {
        ...defaultForm,
        countryCode: rootState.auth.user?.countryCode,
      })
    },
  },
}
