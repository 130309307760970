<template>
  <template
    v-for="{
      pages = [],
      componentList,
      classCell = null,
      key,
      ...header
    } in entity.headers"
    :key="key"
  >
    <component
      :is="componentList"
      v-if="componentList"
      v-bind="{
        ...header,
        headerKey: key,
        ...([
          'Campaign',
          'Order',
          'MagicTokenCampaignReport',
          'MagicTokenForecast',
        ].includes(item.entity) && { showChildren }),
      }"
      :entity="entity"
      :item="item"
      :to="toRoute(routerLink)"
      :selected-items="selectedItems"
      :search="search"
      :class="[
        'tw-border-b tw-border-border',
        [
          'Campaign',
          'CampaignBudgetGrouping',
          'ControlBudgetGrouping',
          'ControlFlight',
          'Order',
          'OrderBudgetGrouping',
          'ControlOrderBudgetGrouping',
          'ControlOrderFlight',
          'Forecast',
          'Agency',
          'Advertiser',
          'User',
          'Deal',
          'MagicTokenCampaignReport',
          'MagicTokenForecast',
        ].includes(item.entity)
          ? 'tw-border-t'
          : 'tw-border-t-0',
        pages.includes(page) ? '' : 'tw-hidden tablet:tw-flex',
        classCell,
        highlightedItems.includes(item.id) ? 's' : '',
      ]"
      @click="
        $emit('click', {
          ...$event,
          refresh: () => fetchChildren({ refresh: true }),
        })
      "
      @select="$emit('select', $event)"
    />
    <div
      v-else
      :class="classCell"
    ></div>
  </template>
  <template v-if="['Campaign', 'Order'].includes(item.entity)">
    <template v-if="showChildren">
      <div
        class="tw-col-span-full tw-h-14 tw-items-center tw-justify-center tw-border-l tw-border-r tw-border-border tw-bg-alto-light tw-pt-4"
        :class="
          showChildren && isFetching && children === null
            ? 'tw-flex'
            : 'tw-hidden'
        "
      >
        <Icon
          name="passport"
          class="preserve-3d tw-animate-flip-x tw-text-3xl tw-text-primary"
        />
      </div>
      <CampaignListingRow
        v-for="notBudgetGrouping in sortList(
          (showChildren && children && children.notBudgetGrouping) || [],
          entity.sortKey,
          entity.sortOrder
        )"
        :key="notBudgetGrouping.id"
        :search="search"
        :entity="{
          ...entity,
          statusKey: flightEntity.statusKey,
          headers: flightEntity.list.headers,
          rowOptions: flightEntity.list.rowOptions,
        }"
        :highlighted-items="highlightedItems"
        :page="entity.page"
        :item="{
          ...notBudgetGrouping,
          ...notBudgetGrouping.flights[0],
          currencyCode: item.currencyCode,
          entity: `${item.entity}Flight`,
          ...(item.campaignFlag ? { campaignFlag: item.campaignFlag } : {}),
        }"
        @click="
          $emit('click', {
            ...$event,
            refresh: () => fetchChildren({ refresh: true }),
          })
        "
      />
      <div
        class="tw-col-span-full tw-h-4 tw-border-l tw-border-r tw-border-border tw-bg-alto-light"
        :class="
          showChildren && children && children.budgetGrouping.length
            ? 'tw-flex'
            : 'tw-hidden'
        "
      ></div>
      <template
        v-for="(budgetGrouping, index) in sortList(
          (showChildren && children && children.budgetGrouping) || [],
          entity.sortKey,
          entity.sortOrder
        )"
        :key="`budget-grouping-${budgetGrouping.id}`"
      >
        <div
          v-if="index > 0"
          class="tw-col-span-full tw-h-4 tw-border-l tw-border-r tw-border-border tw-bg-alto-light"
        ></div>
        <CampaignListingRow
          :entity="{
            ...entity.list,
            statusKey: budgetGroupingEntity.statusKey,
            headers: budgetGroupingEntity.list.headers,
            rowOptions: budgetGroupingEntity.list.rowOptions,
          }"
          :page="entity.page"
          :highlighted-items="highlightedItems"
          :selected-items="selectedItems"
          :search="search"
          :item="{
            ...budgetGrouping,
            currencyCode: item.currencyCode,
            entity: `${item.entity}BudgetGrouping`,
            ...(item.campaignFlag ? { campaignFlag: item.campaignFlag } : {}),
            parentEndDate: item.endDate,
            parentTimeZoneId: item.timezones?.[0]?.timeZoneId,
          }"
          @select="$emit('select', $event)"
          @click="
            $emit('click', {
              ...$event,
              refresh: () => fetchChildren({ refresh: true }),
            })
          "
        />
      </template>
      <div
        class="tw-col-span-full tw-items-center tw-justify-center tw-border-l tw-border-r tw-border-border tw-bg-alto-light tw-pt-4 tw-text-center tw-text-sm tw-text-grey"
        :class="shouldRetry ? 'tw-flex' : 'tw-hidden'"
      >
        An error occurred while loading this section.
        <button
          class="tw-ml-2 tw-font-medium tw-text-black tw-underline"
          @click="() => fetchChildren({ refresh: true })"
        >
          Retry.
        </button>
      </div>
      <div
        class="tw-col-span-full tw-items-center tw-justify-center tw-border-l tw-border-r tw-border-border tw-bg-alto-light tw-pt-4 tw-text-center tw-text-sm tw-text-grey"
        :class="
          showChildren &&
          !isFetching &&
          children &&
          children.budgetGrouping.length === 0 &&
          children.notBudgetGrouping.length === 0
            ? 'tw-flex'
            : 'tw-hidden'
        "
      >
        No Flights or Budget Groupings found
      </div>
      <div
        class="tw-col-span-full tw-h-4 tw-rounded-b tw-border tw-border-t-0 tw-border-border tw-bg-alto-light"
        :class="showChildren ? 'tw-flex' : 'tw-hidden'"
      ></div>
    </template>
  </template>
  <template
    v-if="
      [
        'CampaignBudgetGrouping',
        'ControlBudgetGrouping',
        'OrderBudgetGrouping',
        'ControlOrderBudgetGrouping',
      ].includes(item.entity)
    "
  >
    <template v-if="filteredFlights.length">
      <CampaignListingRow
        v-for="flight in filteredFlights"
        :key="flight.id"
        :entity="{
          ...entity.list,
          statusKey: flightEntity.statusKey,
          headers: flightEntity.list.headers,
          rowOptions: flightEntity.list.rowOptions,
        }"
        :search="search"
        :page="page"
        :highlighted-items="highlightedItems"
        :selected-items="selectedItems"
        :item="{
          ...flight,
          currencyCode: item.currencyCode,
          entity: `${item.entity}Flight`,
          ...(item.campaignFlag ? { campaignFlag: item.campaignFlag } : {}),
        }"
        @click="$emit('click', { ...$event, refresh: fetchChildren })"
        @select="$emit('select', $event)"
      />
      <template
        v-if="
          ['CampaignBudgetGrouping', 'OrderBudgetGrouping'].includes(
            item.entity
          )
        "
      >
        <div class="tw-h-4 tw-border-l tw-border-border tw-bg-alto-light"></div>
        <div
          class="tw-col-span-(full-2) tw-h-4 tw-rounded-b tw-border tw-border-t-0 tw-border-border tw-bg-pill-bg"
        ></div>
        <div class="tw-h-4 tw-border-r tw-border-border tw-bg-alto-light"></div>
      </template>
      <template
        v-if="
          ['ControlBudgetGrouping', 'ControlOrderBudgetGrouping'].includes(
            item.entity
          )
        "
      >
        <div
          class="tw-col-span-full tw-h-4 tw-rounded-b tw-border tw-border-t-0 tw-border-border tw-bg-alto-light"
          :class="
            ['ControlOrderBudgetGrouping'].includes(item.entity)
              ? 'tw-col-start-2'
              : ''
          "
        ></div>
      </template>
    </template>
    <template v-else-if="item.flights && item.flights.length === 0">
      <div
        class="tw-h-10 tw-border-l tw-border-border tw-bg-alto-light"
        :class="
          ['ControlOrderBudgetGrouping'].includes(item.entity)
            ? 'tw-col-start-2'
            : ''
        "
      ></div>
      <div
        class="tw-col-span-(full-2) tw-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-b tw-border tw-border-t-0 tw-border-border tw-bg-pill-bg tw-text-sm"
        :class="
          ['ControlOrderBudgetGrouping'].includes(item.entity)
            ? 'tw-col-start-3'
            : ''
        "
      >
        No Flights for this Budget Grouping
      </div>
      <div class="tw-h-10 tw-border-r tw-border-border tw-bg-alto-light"></div>
      <template
        v-if="
          ['ControlBudgetGrouping', 'ControlOrderBudgetGrouping'].includes(
            item.entity
          )
        "
      >
        <div
          class="tw-col-span-full tw-h-4 tw-rounded-b tw-border tw-border-t-0 tw-border-border tw-bg-alto-light"
          :class="
            ['ControlOrderBudgetGrouping'].includes(item.entity)
              ? 'tw-col-start-2'
              : ''
          "
        ></div>
      </template>
    </template>
  </template>
  <template
    v-if="
      ['MagicTokenCampaignReport', 'MagicTokenForecast'].includes(item.entity)
    "
  >
    <template
      v-if="
        showChildren &&
        item.magicTokenCampaignReportResponses &&
        item.magicTokenCampaignReportResponses.length
      "
    >
      <CampaignListingRow
        v-for="magicToken in item.magicTokenCampaignReportResponses"
        :key="magicToken.id"
        :entity="{
          ...entity.list,
          headers: flightEntity.list.headers,
          rowOptions: flightEntity.list.rowOptions,
        }"
        :page="page"
        :item="{
          ...magicToken,
          entity: `${item.entity}Row`,
          parentName: item.name,
        }"
        @click="$emit('click', $event)"
      />
    </template>
    <template
      v-else-if="
        showChildren &&
        item.magicTokenFlightForecastResponses &&
        item.magicTokenFlightForecastResponses.length
      "
    >
      <CampaignListingRow
        v-for="magicToken in item.magicTokenFlightForecastResponses"
        :key="magicToken.id"
        :entity="{
          ...entity.list,
          headers: flightEntity.list.headers,
          rowOptions: flightEntity.list.rowOptions,
        }"
        :page="page"
        :item="{
          ...magicToken,
          entity: `${item.entity}Row`,
          parentName: item.name,
        }"
        @click="$emit('click', $event)"
      />
    </template>
    <div
      class="tw-col-span-full tw-h-4 tw-rounded-b tw-border tw-border-t-0 tw-border-border tw-bg-alto-light"
      :class="showChildren ? 'tw-flex' : 'tw-hidden'"
    ></div>
  </template>
</template>

<script>
import campaignBudgetGroupingEntity from '@/entity/campaign-budget-grouping.js'
import campaignBudgetGroupingFlightEntity from '@/entity/campaign-budget-grouping-flight.js'
import campaignFlightEntity from '@/entity/campaign-flight.js'
import controlBudgetGroupingFlightEntity from '@/entity/control-budget-grouping-flight.js'
import controlOrderBudgetGroupingFlightEntity from '@/entity/control-order-budget-grouping-flight.js'
import magicTokenForecastRow from '@/entity/magic-token-forecast-row.js'
import magicTokenCampaignReportRow from '@/entity/magic-token-campaign-report-row.js'
import orderBudgetGroupingEntity from '@/entity/order-budget-grouping.js'
import orderBudgetGroupingFlightEntity from '@/entity/order-budget-grouping-flight.js'
import orderFlightEntity from '@/entity/order-flight.js'

import Icon from '@/components/Icon.vue'
import ListCellActions from '@/components/list/cell/actions.vue'
import ListCellAvatar from '@/components/list/cell/avatar.vue'
import ListCellBudgetSpread from '@/components/list/cell/budget-spread.vue'
import ListCellCampaignToggle from '@/components/list/cell/campaign-toggle.vue'
import ListCellCampaignWithFlight from '@/components/list/cell/campaign-with-flight.vue'
import ListCellCreativeName from '@/components/list/cell/creative-name.vue'
import ListCellCreativePlay from '@/components/list/cell/creative-play.vue'
import ListCellCreatives from '@/components/list/cell/creatives.vue'
import ListCellCurrency from '@/components/list/cell/currency.vue'
import ListCellDate from '@/components/list/cell/date.vue'
import ListCellDealName from '@/components/list/cell/deal-name.vue'
import ListCellDealSwitch from '@/components/list/cell/deal-switch.vue'
import ListCellDefault from '@/components/list/cell/default.vue'
import ListCellEmpty from '@/components/list/cell/empty.vue'
import ListCellEntityName from '@/components/list/cell/entity-name.vue'
import ListCellList from '@/components/list/cell/list.vue'
import ListCellMagicTokenDate from '@/components/list/cell/magic-token-date.vue'
import ListCellMagicTokenToggle from '@/components/list/cell/magic-token-toggle.vue'
import ListCellMagicTokenVisibility from '@/components/list/cell/magic-token-visibility.vue'
import ListCellNumber from '@/components/list/cell/number.vue'
import ListCellPacingProgression from '@/components/list/cell/pacing-progression.vue'
import ListCellSelect from '@/components/list/cell/select.vue'
import ListCellStatus from '@/components/list/cell/status.vue'
import ListCellToggle from '@/components/list/cell/toggle.vue'
import ListCellTypeIcon from '@/components/list/cell/type-icon.vue'

export default {
  name: 'CampaignListingRow',
  components: {
    Icon,
    ListCellActions,
    ListCellAvatar,
    ListCellCampaignToggle,
    ListCellCampaignWithFlight,
    ListCellCreatives,
    ListCellCreativeName,
    ListCellCreativePlay,
    ListCellCurrency,
    ListCellBudgetSpread,
    ListCellDate,
    ListCellDealName,
    ListCellDealSwitch,
    ListCellDefault,
    ListCellEmpty,
    ListCellList,
    ListCellMagicTokenDate,
    ListCellMagicTokenToggle,
    ListCellMagicTokenVisibility,
    ListCellNumber,
    ListCellEntityName,
    ListCellPacingProgression,
    ListCellSelect,
    ListCellStatus,
    ListCellToggle,
    ListCellTypeIcon,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    highlightedItems: {
      type: Array,
      default: () => [],
    },
    openedItems: {
      type: Array,
      default: () => [],
    },
    searchedItems: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: null,
    },
    selectedItems: {
      type: [Array, Object],
      default: () => [],
    },
  },
  emits: ['click', 'select', 'toggle'],
  data() {
    let budgetGroupingEntity = null
    let flightEntity = null

    switch (this.item.entity) {
      case 'Campaign':
        budgetGroupingEntity = campaignBudgetGroupingEntity
        flightEntity = campaignFlightEntity
        break
      case 'CampaignBudgetGrouping':
        flightEntity = campaignBudgetGroupingFlightEntity
        break
      case 'ControlBudgetGrouping':
        flightEntity = controlBudgetGroupingFlightEntity
        break
      case 'Order':
        budgetGroupingEntity = orderBudgetGroupingEntity
        flightEntity = orderFlightEntity
        break
      case 'OrderBudgetGrouping':
        flightEntity = orderBudgetGroupingFlightEntity
        break
      case 'ControlOrderBudgetGrouping':
        flightEntity = controlOrderBudgetGroupingFlightEntity
        break
      case 'MagicTokenCampaignReport':
        flightEntity = magicTokenCampaignReportRow
        break
      case 'MagicTokenForecast':
        flightEntity = magicTokenForecastRow
        break
    }

    return {
      budgetGroupingEntity,
      flightEntity,
      children: null,
      isFetching: true,
      isHighlighted: false,
      shouldRetry: false,
    }
  },
  computed: {
    filteredFlights() {
      if (!this.item.flights || !this.item.flights.length) {
        return []
      }

      if (!this.item.entity.includes('Control')) {
        return this.sortList(
          this.item.flights,
          this.entity.sortKey,
          this.entity.sortOrder
        )
      }

      let items = this.item.flights.slice()

      this.entity.filters.map(({ key, value }) => {
        if (Object.keys(this.entity.filtersCallbacks).includes(key)) {
          items = items.filter((item) =>
            this.entity.filtersCallbacks[key](item, value)
          )
        }
      })

      return this.sortList(items, this.entity.sortKey, this.entity.sortOrder)
    },
    routerLink() {
      switch (this.item.entity) {
        case 'Campaign':
          return { name: 'EditCampaign', params: { campaign: this.item.id } }
        case 'CampaignFlight':
        case 'ControlFlight':
          return {
            name: 'EditCampaignFlight',
            params: {
              campaign: this.item.campaignId,
              campaignFlight: this.item.flights[0].id,
            },
          }
        case 'CampaignBudgetGrouping':
        case 'ControlBudgetGrouping':
          return {
            name: 'EditCampaignBudgetGrouping',
            params: {
              campaign: this.item.baseCampaignId,
              campaignBudget: this.item.id,
            },
          }
        case 'CampaignBudgetGroupingFlight':
        case 'ControlBudgetGroupingFlight':
          return {
            name: 'EditCampaignBudgetGroupingFlight',
            params: {
              campaign: this.item.campaignId,
              campaignBudget: this.item.budgetId,
              campaignFlight: this.item.id,
            },
          }
        case 'Forecast':
          return { name: 'EditForecast', params: { forecast: this.item.id } }
        case 'Order':
          return { name: 'EditOrder', params: { order: this.item.id } }
        case 'OrderFlight':
        case 'ControlOrderFlight':
          return {
            name: 'EditOrderFlight',
            params: {
              order: this.item.campaignOrderId,
              orderFlight: this.item.flights[0].id,
            },
          }
        case 'OrderBudgetGrouping':
        case 'ControlOrderBudgetGrouping':
          return {
            name: 'EditOrderBudgetGrouping',
            params: {
              order: this.item.baseCampaignId,
              orderBudget: this.item.id,
            },
          }
        case 'OrderBudgetGroupingFlight':
        case 'ControlOrderBudgetGroupingFlight':
          return {
            name: 'EditOrderBudgetGroupingFlight',
            params: {
              order: this.item.campaignOrderId,
              orderBudget: this.item.budgetId,
              orderFlight: this.item.id,
            },
          }
        case 'Deal':
          return { name: 'EditDeal', params: { deal: this.item.id } }
        case 'Agency':
          return { name: 'EditAgency', params: { agency: this.item.id } }
        case 'Advertiser':
          return {
            name: 'EditAdvertiser',
            params: { advertiser: this.item.id },
          }
        case 'User':
          return { name: 'EditUser', params: { user: this.item.id } }
        case 'MagicTokenCampaignReport':
          return {
            name: 'CampaignReport',
            params: {
              campaign:
                this.item.magicTokenCampaignReportResponses[0].campaignId,
            },
          }
        case 'MagicTokenForecast':
          return {
            name: 'EditForecast',
            params: { forecast: this.item.id },
          }
        case 'MagicTokenCampaignReportRow':
          if (
            this.item.isDisabled ||
            new Date(this.item.expiryDate) < new Date()
          )
            return {}
          return {
            name: 'CampaignReportMagicToken',
            params: { campaign: this.item.uid },
          }
        case 'MagicTokenForecastRow':
          if (
            this.item.isDisabled ||
            new Date(this.item.expiryDate) < new Date()
          )
            return {}
          return { name: 'ForecastMagicToken', params: { uid: this.item.uid } }
      }

      return {}
    },
    showChildren() {
      return this.openedItems.includes(this.item.id)
    },
  },
  watch: {
    showChildren: {
      handler(value) {
        if (value && this.children === null) {
          this.fetchChildren()
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchChildren({ refresh = false } = {}) {
      if (
        ['MagicTokenCampaignReport', 'MagicTokenForecast'].includes(
          this.item.entity
        )
      )
        return
      else if (!refresh && ['Campaign'].includes(this.item.entity)) {
        this.children = {
          budgetGrouping: this.item.budgetGrouping.slice(),
          notBudgetGrouping: this.item.notBudgetGrouping.slice(),
        }

        return
      }

      this.isFetching = true
      this.shouldRetry = false

      this.$store
        .dispatch(`${this.item.entity.toLowerCase()}/listChildren`, {
          id: this.item.id,
        })
        .then((data) => {
          if (['Campaign'].includes(this.item.entity)) {
            this.children = data
          } else if (['Order'].includes(this.item.entity)) {
            this.children = {
              budgetGrouping: data.budgetGroupings.map(
                ({ flightOrderList = [], ...budgetGrouping }) => {
                  return {
                    ...budgetGrouping,
                    flights: flightOrderList,
                  }
                }
              ),
              notBudgetGrouping: data.notBudgetGroupings.map(
                (notBudgetGrouping) => {
                  return {
                    ...notBudgetGrouping,
                    flights: [notBudgetGrouping],
                  }
                }
              ),
            }
          }
        })
        .catch(() => (this.shouldRetry = true))
        .finally(() => (this.isFetching = false))
    },
  },
}
</script>
