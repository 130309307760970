<template>
  <div class="tw-select-none">
    <!-- eslint-disable vue/no-unused-vars -->
    <template
      v-for="{
        componentHeader,
        key,
        classHeader = null,
        classCell = null,
        heading = true,
        ...header
      } in entity.headers"
      :key="key"
    >
      <component
        v-bind="{
          ...header,
          classHeader,
          headerKey: key,
          ...(['ListHeaderStatus', 'ListHeaderStatusFromStore'].includes(
            componentHeader
          ) && { items }),
        }"
        :is="componentHeader"
        v-if="heading"
        :entity="entity"
        class="tw-sticky tw-top-16 tw-z-40 tw-h-10 tw-bg-alto-light tw-text-xs tw-font-medium"
        :class="[
          header.pages.includes(entity.page) ? '' : 'tw-hidden tablet:tw-flex',
          classHeader,
        ]"
        @change-status="handleApplyStatus"
        @apply-pacing="handleApplyPacing"
        @clear-filter="handleClearFilter"
        @filter="handleFilter"
        @page="handleChangePage($event)"
        @sort="handleSort"
      />
      <div
        v-else
        class="tw-sticky tw-top-16 tw-z-40 tw-h-10 tw-bg-alto-light tw-text-xs tw-font-medium"
        :class="[
          header.pages.includes(entity.page) ? '' : 'tw-hidden tablet:tw-flex',
          classHeader,
        ]"
      />
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const ListHeaderDefault = defineAsyncComponent(() =>
  import('@/components/list/header/default.vue')
)
const ListHeaderLabel = defineAsyncComponent(() =>
  import('@/components/list/header/label.vue')
)
const ListHeaderRange = defineAsyncComponent(() =>
  import('@/components/list/header/range.vue')
)
const ListHeaderPacingOrProgression = defineAsyncComponent(() =>
  import('@/components/list/header/pacing-or-progression.vue')
)
const ListHeaderPagination = defineAsyncComponent(() =>
  import('@/components/list/header/pagination.vue')
)
const ListHeaderStatus = defineAsyncComponent(() =>
  import('@/components/list/header/status.vue')
)
const ListHeaderStatusFromStore = defineAsyncComponent(() =>
  import('@/components/list/header/status-from-store.vue')
)

export default {
  components: {
    ListHeaderDefault,
    ListHeaderLabel,
    ListHeaderRange,
    ListHeaderPacingOrProgression,
    ListHeaderPagination,
    ListHeaderStatus,
    ListHeaderStatusFromStore,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    showStatusFilter: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['changePacing', 'changeStatus', 'clearFilter', 'filter', 'sort'],
  methods: {
    handleApplyStatus(selected) {
      this.$emit('changeStatus', selected)
    },
    handleApplyPacing(selected) {
      this.$emit('changePacing', selected)

      if (['pacing', 'progression'].includes(this.entity.sortKey)) {
        this.$emit('sort', { key: selected, direction: this.entity.sortOrder })
      }
    },
    handleChangePage(event) {
      // eslint-disable-next-line vue/no-mutating-props
      this.entity.page = event
    },
    handleSort({ key, direction = null }) {
      this.$emit('sort', { key, direction })
    },
    handleFilter(filter) {
      this.$emit('filter', filter)
    },
    handleClearFilter(key) {
      this.$emit('clearFilter', key)
    },
  },
}
</script>
