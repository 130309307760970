export function distinctList<T extends { [k: string]: unknown }>(
  list: T[],
  distinctKey: keyof T = 'id'
): T[] {
  return list.filter((item, index, currentList) => {
    return !currentList.slice(++index).some((nextItem) => {
      if (!(distinctKey in item) || !(distinctKey in nextItem)) return false

      return item[distinctKey] === nextItem[distinctKey]
    })
  })
}
