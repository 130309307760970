// import store from '@/store/index'
import { useStore } from 'vuex'
import { isAfter, parseISO } from 'date-fns'

export const canSeeElk = (): boolean => {
  const store = useStore()

  if (!store?.state?.auth?.subFunctions?.length) {
    return false
  }

  const targetspotOrganizations = [1, 2, 4, 5, 6, 7, 8, 9, 10, 13, 14]

  if (
    !store.state.auth.user ||
    !store.getters['auth/currentOrganization'] ||
    !targetspotOrganizations.includes(
      store.getters['auth/currentOrganization'].id
    )
  ) {
    return false
  }

  return ['AdTrafficker', 'AdTraffickerSenior', 'MasterAdtrafficker'].some(
    (subFunction) => store.state.auth.subFunctions.includes(subFunction)
  )
}

export const elkCampaignUrl = ({
  id,
  startDate,
  endDate,
}: {
  id: number
  startDate: string
  endDate: string
}): string => {
  const paramsStartDate = parseISO(startDate).toISOString()
  const paramsEndDate = endDate
    ? isAfter(parseISO(endDate), new Date())
      ? new Date().toISOString()
      : parseISO(endDate).toISOString()
    : new Date().toISOString()

  return `https://checkts-elk.targetspot.com/app/discover#/?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${paramsStartDate}',to:'${paramsEndDate}'))&_a=(columns:!(_source),index:'3addd4c0-f092-11eb-bc18-e35482fb30e6',interval:auto,query:(language:kuery,query:'campaignid:${id}'),sort:!(!('@timestamp',desc)))`
}

export const elkFlightUrl = ({
  id,
  startDate,
  endDate,
}: {
  id: number
  startDate: string
  endDate: string
}): string => {
  const paramsStartDate = parseISO(startDate).toISOString()
  const paramsEndDate = endDate
    ? isAfter(parseISO(endDate), new Date())
      ? new Date().toISOString()
      : parseISO(endDate).toISOString()
    : new Date().toISOString()

  return `https://checkts-elk.targetspot.com/app/discover#/?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${paramsStartDate}',to:'${paramsEndDate}'))&_a=(columns:!(_source),index:'3addd4c0-f092-11eb-bc18-e35482fb30e6',interval:auto,query:(language:kuery,query:'flightid:${id}'),sort:!(!('@timestamp',desc)))`
}
