import { formatCurrency, formatNumber } from '@/helpers/number'
import {
  formatDate,
  formatDateWithDay,
  formatTime,
  parseAndFormat,
  timeAgo,
} from '@/helpers/date'
import { initials } from '@/helpers/strings'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import { sortArray } from '@/helpers/sort'

export default (app) => {
  app.config.globalProperties.$filters = {
    camelCase,
    date: formatDate,
    dateWithDay: formatDateWithDay,
    formatCurrency,
    formatNumber,
    initials,
    parseAndFormat,
    snakeCase,
    sortArray,
    time: formatTime,
    timeAgo: timeAgo,
  }
}
