import campaignBudgetGroupingFlightEntity from './campaign-budget-grouping-flight'

const entity = {
  ...campaignBudgetGroupingFlightEntity,
  absoluteKey: 'ControlBudgetGroupingFlight',
  list: {
    ...campaignBudgetGroupingFlightEntity.list,
    headers: [
      {
        key: 'gap-2',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-20 tablet:tw-h-18 tw-bg-alto-light tw-border-l tw-border-border',
        classHeader: 'tw-hidden',
      },
      {
        label: 'Status',
        key: 'flightStatusType',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderStatus',
        componentList: 'ListCellTypeIcon',
        icon: 'plane',
        size: 'calc(90px - 1rem)',
        classCell:
          'tw-h-20 tablet:tw-h-18 tw-border-l tw-border-border tw-justify-end',
        classHeader: '',
      },
      {
        label: 'Pacing',
        key: 'pacingOrProgression',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPacingOrProgression',
        componentList: 'ListCellEmpty',
        size: 'minmax(90px, min-content)',
        classCell: 'tw-h-20 tablet:tw-h-18',
      },
      {
        label: 'Name',
        key: 'name',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellEntityName',
        size: 'minmax(100px, 1fr)',
        classCell: 'tw-h-20 tablet:tw-h-18',
      },
      {
        label: 'Start date',
        key: 'startDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'End date',
        key: 'endDate',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Delivered',
        key: 'deliveredImpressions',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Ordered',
        key: 'orderedImpressions',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellEmpty',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Gross CPM',
        key: 'grossCpm',
        pages: [2],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellEmpty',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Bonus Impressions',
        key: 'budget.bonusImpressions',
        pages: [3],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '120px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Budget Spread',
        key: 'budgetSpreadType',
        pages: [3],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellBudgetSpread',
        size: '140px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Creatives',
        key: 'creativesCount',
        pages: [1, 2, 3],
        heading: null,
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellCreatives',
        size: '60px',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-border-l',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2, 3],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: 'calc(80px - 1rem)',
        classCell: 'tw-h-20 tablet:tw-h-18 tw-border-r tw-border-border',
        classHeader: 'tw-col-span-2',
      },
      {
        key: 'gap-3',
        pages: [1, 2, 3],
        heading: null,
        size: '1rem',
        classCell:
          'tw-h-20 tablet:tw-h-18 tw-bg-alto-light tw-border-r tw-border-border',
        classHeader: 'tw-hidden',
      },
    ],
  },
}

export default entity
