<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { PopoverButton } from '@headlessui/vue'
import { optionsCurrencies } from '@/constants/options'
import Cache from '@/services/cache.js'
import Icon from '@/components/Icon.vue'
import ImageOrPlaceholder from '@/components/ImageOrPlaceholder.vue'
import Notifications from '@/components/Notifications.vue'
import MenuItems from '@/components/Menu.vue'
import AzerionLogo from '@/components/AzerionLogo.vue'
import { computed, nextTick, ref } from 'vue'
import { userCan } from '@/helpers/auth'
import { useStore } from 'vuex'
import { toRoute } from '@/helpers/route'
import { sortList } from '@/helpers/sort'
import { useRoute, useRouter } from 'vue-router'
import type { Organization } from '@/types'

const route = useRoute()
const router = useRouter()
const store = useStore()

const search = ref('')
const searchInputOrganizations = ref<HTMLElement | null>(null)

const allAuthorizedNavItems = computed(() =>
  navItems.value.filter((item) => userCan(item.claim))
)

// const authorizedNavItems = computed(() =>
//   allAuthorizedNavItems.value.slice(0, 5)
// )

const restMenu = computed(() => allAuthorizedNavItems.value.slice(5))

const currentCurrency = computed(() =>
  optionsCurrencies.find(
    (currency) => currency.value === user.value.currencyCode
  )
)

const currentOrganization = computed(
  () => store.getters['auth/currentOrganization']
)

const defaultUserRoute = computed(() => {
  return toRoute(store.getters['auth/defaultRoute'])
})

const filteredOrganizationItems = computed(() => {
  let _organizationList = organizationList.value

  if (search.value !== '') {
    const searchTerms = search.value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')

    _organizationList = organizationList.value.filter((item) => {
      return searchTerms.every((term) =>
        item.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(term)
      )
    })
  }

  if (_organizationList.length <= 0) {
    return []
  }

  return sortList(_organizationList, 'name', 'asc').map((organization) => {
    return {
      ...organization,
      key: organization.id,
      label: organization.name,
      selected: organization.id === currentOrganization.value?.id,
      to: {
        name: 'SwitchOrganization',
        params: {
          orgFrom: currentOrganization.value?.id,
          orgTo: organization.id,
        },
        query: {
          redirect: `/${organization.id}${redirectPath.value}`,
        },
      },
    }
  })
})

const redirectPath = computed(() => {
  let { redirectPath } = route.meta

  if (!redirectPath) {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { organization = null, ...routeParams } = route.params

    try {
      const redirectRoute = router.resolve({
        ...route,
        params: routeParams,
      })

      redirectPath = redirectRoute.path
    } catch (error) {
      return route.path
    }
  }

  return redirectPath
})

const organizationList = computed<Organization[]>(
  () => user.value?.organizationList.slice() || []
)
const navItems = computed(() => {
  if (!currentOrganization.value?.id) return []

  return [
    {
      label: 'Dashboard',
      key: 'dashboard',
      to: { name: 'DashBoard' },
      claim: 'MenuDashboard',
    },
    {
      label: 'Forecasts',
      key: 'forecasts',
      to: { name: 'Forecasts' },
      claim: 'MenuForecast',
    },
    {
      label: 'Orders',
      key: 'orders',
      to: { name: 'Orders' },
      claim: 'MenuOrder',
    },
    {
      label: 'Campaigns',
      key: 'campaigns',
      to: { name: 'Campaigns' },
      claim: 'MenuCampaign',
    },
    {
      label:
        currentOrganization.value?.organizationType?.name === 'Publisher'
          ? 'Meta SSP'
          : 'SSP',
      to: { name: 'Deals' },
      key: 'deals',
      claim: 'MenuProgrammatic',
    },
    // PASSPORTV2-9086 hide billing
    // {
    //   label: 'Billing',
    //   to: { name: 'Billing' },
    //   claim: 'MenuBilling',
    // },
    {
      label: 'Inventory',
      key: 'inventory',
      to: { name: 'Inventory' },
      claim: 'MenuInventory',
    },
    {
      label: 'Magic Tokens',
      key: 'magic-tokens',
      to: { name: 'MagicTokens' },
      claim: 'MenuMagicToken',
    },
    {
      label: 'People',
      key: 'people',
      to: { name: 'People' },
      claim: 'MenuPeople',
    },
  ]
})
const user = computed(() => store.state.auth.user)
const handleChangeOpen = (value: boolean): void => {
  nextTick(() => {
    if (value) {
      setTimeout(() => {
        searchInputOrganizations.value?.focus()
      }, 100)
    }
  })
}
const clearCache = async (): Promise<void> => {
  await Cache.clear()
}
const signOut = async (): Promise<void> => {
  await store.dispatch('auth/logout')

  router.push({
    name: 'Login',
    query: { redirect: router.currentRoute.value.path },
  })
}
</script>

<template>
  <header
    class="tw-fixed tw-top-0 tw-flex tw-h-16 tw-w-full tw-select-none tw-items-center tw-bg-black tw-px-4 tablet:tw-px-12"
    style="z-index: 2000"
  >
    <ul class="tw-mr-4 tw-flex tw-items-center tw-space-x-4 md:tw-space-x-6">
      <li>
        <router-link
          :to="defaultUserRoute"
          class="tw-flex tw-items-center tw-justify-center"
        >
          <AzerionLogo class="tw-h-7" />
        </router-link>
      </li>
      <li
        v-for="(item, index) in allAuthorizedNavItems"
        :key="index"
        class="tw-items-center tw-text-grey"
        :class="index < 5 ? 'tw-hidden lg:tw-flex' : 'tw-hidden xl:tw-flex'"
      >
        <router-link
          :to="toRoute(item.to)"
          class="tw-whitespace-nowrap tw-text-xsm tw-font-medium tw-uppercase hover:tw-text-white"
          active-class="tw-text-white"
          data-test-role="menu-item"
        >
          {{ item.label }}
        </router-link>
      </li>
      <li
        v-if="restMenu.length"
        class="tw-hidden lg:tw-flex xl:tw-hidden"
      >
        <MenuItems
          v-slot="{ open }"
          :items="restMenu"
        >
          <div
            class="tw-flex tw-h-12 tw-cursor-pointer tw-select-none tw-items-center tw-whitespace-nowrap tw-text-xsm hover:tw-text-white"
            :class="open ? 'tw-text-white' : 'tw-text-grey'"
          >
            <span
              class="tw-mr-2 tw-whitespace-nowrap tw-text-xsm tw-font-medium tw-uppercase"
            >
              Menu
            </span>
            <Icon
              name="chevron-down"
              class="tw-text-2xs"
            />
          </div>
        </MenuItems>
      </li>
      <li class="lg:tw-hidden">
        <MenuItems
          :items="allAuthorizedNavItems"
          position="bottom-left"
        >
          <div
            class="tw-flex tw-h-12 tw-cursor-pointer tw-select-none tw-items-center tw-whitespace-nowrap tw-text-xsm tw-text-grey hover:tw-text-white"
          >
            <span
              class="tw-mr-2 tw-whitespace-nowrap tw-text-xsm tw-font-medium tw-uppercase hover:tw-text-white"
            >
              Menu
            </span>
            <Icon
              name="chevron-down"
              class="tw-text-2xs"
            />
          </div>
        </MenuItems>
      </li>
    </ul>
    <ul class="tw-ml-auto tw-flex tw-items-center">
      <li class="tw-mr-2">
        <Notifications />
      </li>
      <li class="tw-mr-4 md:tw-mr-6">
        <MenuItems
          :items="filteredOrganizationItems"
          data-test-role="select-organization"
          @toggle="handleChangeOpen"
        >
          <template #default>
            <div
              class="tw-flex tw-h-12 tw-cursor-pointer tw-select-none tw-items-center tw-whitespace-nowrap tw-text-xsm tw-text-grey hover:tw-text-white"
            >
              <span class="tw-mr-2">
                {{
                  (currentOrganization && currentOrganization.name) ||
                  'Select an organization'
                }}
              </span>
              <Icon
                name="chevron-down"
                class="tw-text-2xs"
              />
            </div>
          </template>
          <template #prepend>
            <div class="tw-border-b tw-border-border-light tw-p-4">
              <div class="tw-truncate tw-text-sm tw-font-medium">
                Switch Organization
              </div>
            </div>
            <div
              class="tw-flex tw-items-center tw-border-b tw-border-border-light tw-p-2"
            >
              <Icon
                name="search"
                class="tw-mx-2 tw-w-3"
              />
              <input
                ref="searchInputOrganizations"
                v-model="search"
                class="input tw-w-full tw-p-1 tw-text-sm tw-outline-none"
                placeholder="Search organizations"
                type="text"
                @keydown.stop
              />
            </div>
          </template>
        </MenuItems>
      </li>

      <li class="mr-2">
        <Popover
          position="bottom-right"
          data-test-role="my-profile"
        >
          <div
            class="tw-flex tw-h-12 tw-select-none tw-items-center tw-justify-center"
          >
            <ImageOrPlaceholder
              v-if="user"
              :url="user.completePicturePath"
              :name="user.firstName + ' ' + user.lastName"
              class="tw-h-8 tw-w-8 tw-cursor-pointer"
            />
            <div
              v-else
              class="tw-h-8 tw-w-8"
            />
          </div>
          <template #popover="{ close }">
            <div
              v-if="user"
              class="tw-w-64 tw-divide-y tw-divide-border-light"
            >
              <div class="tw-p-4">
                <div
                  class="tw-truncate tw-text-lg tw-font-bold tw-leading-none"
                >
                  {{ user.firstName + ' ' + user.lastName }}
                </div>
                <div
                  class="tw-truncate tw-text-sm tw-font-normal tw-text-grey-medium"
                >
                  {{ user.email }}
                </div>
                <div
                  class="tw-mt-2 tw-truncate tw-text-xs tw-font-normal tw-text-grey-medium"
                >
                  {{ currentCurrency?.value ?? user.currencyCode }}
                </div>
              </div>
              <ul class="tw-w-full tw-py-1">
                <li class="tw-flex tw-w-full">
                  <PopoverButton
                    as="template"
                    class="tw-w-full tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-text-grey-medium hover:tw-bg-alto-light"
                  >
                    <router-link :to="toRoute({ name: 'Profile' })">
                      View / Edit Profile
                    </router-link>
                  </PopoverButton>
                </li>
                <li class="tw-flex tw-w-full">
                  <PopoverButton
                    as="button"
                    type="button"
                    class="tw-flex tw-w-full tw-items-center tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-text-grey-medium hover:tw-bg-alto-light"
                    @click="
                      () => {
                        clearCache()
                        close()
                      }
                    "
                  >
                    Clear Cache
                  </PopoverButton>
                </li>
                <li class="tw-flex tw-w-full">
                  <PopoverButton
                    as="button"
                    class="tw-flex tw-w-full tw-items-center tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-text-grey-medium hover:tw-bg-alto-light"
                    type="button"
                    @click="
                      () => {
                        signOut()
                        close()
                      }
                    "
                  >
                    <div>Sign Out</div>
                    <Icon
                      name="sign-out"
                      class="tw-ml-auto"
                    />
                  </PopoverButton>
                </li>
              </ul>
              <div
                class="tw-flex tw-justify-evenly tw-px-4 tw-py-3 tw-text-xs tw-text-grey-dark"
              >
                <PopoverButton as="template">
                  <router-link
                    :to="toRoute({ name: 'PrivacyPolicy' })"
                    class="hover:tw-text-black"
                  >
                    Privacy Policy
                  </router-link>
                </PopoverButton>
                <span>&#8226;</span>
                <PopoverButton>
                  <router-link
                    :to="toRoute({ name: 'TermsAndConditions' })"
                    class="hover:tw-text-black"
                  >
                    Terms of Service
                  </router-link>
                </PopoverButton>
              </div>
            </div>
          </template>
        </Popover>
      </li>
    </ul>
  </header>
</template>
