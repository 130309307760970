<template>
  <div v-if="rowOptions.length === 0"></div>
  <div
    v-else
    data-test-role="row-actions"
  >
    <div
      class="tw-group tw-relative tw-hidden tw-h-full tablet:tw-flex"
      @mouseenter="isOpen = true"
      @mouseleave="isOpen = false"
    >
      <button
        class="tw-flex tw-h-full tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-justify-start tw-pl-4 tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
        type="button"
        @click.stop="handleToggle"
      >
        <Icon
          name="dots"
          class="tw-text-xl"
        />
      </button>
      <transition
        enter-active-class="tw-transition tw-duration-100 tw-ease-in"
        enter-from-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-transition tw-duration-100 tw-ease-out"
        leave-from-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
      >
        <div
          v-show="isOpen"
          class="tw-absolute tw-left-0 tw-h-full tw--translate-x-full tw-transform"
        >
          <ul
            class="tw-flex tw-h-full tw-items-center tw-justify-end tw-outline-none"
          >
            <!-- Gradient -->
            <li
              class="tw-flex tw-h-full tw-w-96 tw-items-center tw-justify-center tw-bg-gradient-to-r tw-from-transparent tw-to-white"
            ></li>
            <li
              v-for="option in rowOptions"
              :key="`${option.key}`"
              class="tw-flex tw-h-full tw-items-center tw-bg-white"
            >
              <component
                :is="option.to ? 'RouterLink' : option.href ? 'a' : 'button'"
                class="tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-uppercase tw-text-grey-medium hover:tw-text-black"
                v-tooltip.top="option.label"
                :class="option.class"
                :aria-label="option.label"
                v-bind="
                  option.to
                    ? { to: toRoute(option.to), ...(option.attrs || {}) }
                    : option.href
                    ? { href: option.href, ...(option.attrs || {}) }
                    : {
                        onClick: () =>
                          $emit('click', { item, key: option.key }),
                      }
                "
              >
                <span v-if="option.iconLabel">{{ option.iconLabel }}</span>
                <Icon
                  v-else-if="option.icon"
                  :name="option.icon"
                  class="tw-text-lg"
                />
              </component>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <div class="tw-relative tw-h-full tablet:tw-hidden">
      <PopoverMenu
        :items="rowOptions.slice().reverse()"
        class="menu-actions tw-h-full tw-w-full"
        @click="handleClick"
      >
        <template #button>
          <MenuButton
            class="tw-flex tw-h-full tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-pl-4 tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
            type="button"
          >
            <Icon
              name="dots"
              class="tw-text-xl"
            />
          </MenuButton>
        </template>
      </PopoverMenu>
    </div>
  </div>
</template>
<script>
import { MenuButton } from '@headlessui/vue'
import ListCellDefault from '@/components/list/cell/default.vue'
import Icon from '@/components/Icon.vue'
import PopoverMenu from '@/components/Menu.vue'

export default {
  name: 'ListCellActions',
  emits: ['click'],
  extends: ListCellDefault,
  components: {
    Icon,
    PopoverMenu,
    MenuButton,
  },
  props: {
    showChildren: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      rowOptions: this.entity.rowOptions(this.item),
    }
  },
  methods: {
    handleToggle() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.$refs?.popover?.focus?.()
        })
      }
    },
    handleFocusOut() {
      // Force Timeout otherwise item is hidden as soon as we focus into something else
      setTimeout(() => {
        this.isOpen = false
      }, 200)
    },
    handleClick(props) {
      this.$emit('click', { item: this.item, key: props.key })
    },
  },
}
</script>
