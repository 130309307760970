import { createStore, type StoreOptions } from 'vuex'
import type { Organization, Toast } from '@/types'
/**
  Stores to refactor & namespace
*/
import { dashboard } from '@/store/dashboard.js'
import { modal } from '@/store/modal.js'
import magicTokens from '@/store/magic-tokens.js'
import { magicTokenCampaignReport } from '@/store/magic-token-campaign-report.js'

import advertiser from '@/store/advertiser.js'
import agency from '@/store/agency.js'
import auth from '@/store/auth.js'
import bundles from '@/store/bundles.js'
import campaign from '@/store/campaign'
import campaignBudgetGrouping from '@/store/campaign-budget-grouping.js'
import campaignCreative from '@/store/campaign-creative.js'
import campaignFlight from '@/store/campaign-flight.js'
import campaignWithFlight from '@/store/campaign-with-flight.js'
import creative from '@/store/creative.js'
import creativeCompanion from '@/store/creative-companion.js'
import creativeLibrary from '@/store/creative-library.js'
import deal from '@/store/deal.js'
import debug from '@/store/debug.js'
import forecast from '@/store/forecast.js'
import inventory from '@/store/inventory.js'
import loader from '@/store/loader.js'
import magicToken from '@/store/magic-token.js'
import notifications from '@/store/notifications.js'
import order from '@/store/order.js'
import orderBudgetGrouping from '@/store/order-budget-grouping.js'
import orderFlight from '@/store/order-flight.js'
import organization from '@/store/organization.js'
import popup from '@/store/popup.js'
import profile from '@/store/profile.js'
import report from '@/store/report.js'
import requests from '@/store/requests.js'
import saleUsers from '@/store/sale-users.js'
import schedule from '@/store/schedule.js'
import targetingFilter from '@/store/targeting-filter.js'
import type { StateTargetingNetwork } from '@/store/targeting-network'
import targetingNetwork from '@/store/targeting-network'
import toasts from '@/store/toasts.js'
import user from '@/store/user.js'

const config = {
  modules: {
    advertiser,
    agency,
    auth,
    bundles,
    campaign,
    campaignBudgetGrouping,
    campaignCreative,
    campaignFlight,
    campaignWithFlight,
    creative,
    creativeCompanion,
    creativeLibrary,
    deal,
    debug,
    forecast,
    inventory,
    loader,
    magicToken,
    order,
    orderBudgetGrouping,
    orderFlight,
    organization,
    popup,
    profile,
    report,
    requests,
    saleUsers,
    schedule,
    targetingFilter,
    targetingNetwork,
    toasts,
    user,

    notifications,
    dashboard: dashboard as unknown as StoreOptions<unknown>,
    modal,
    magicTokens,
    magicTokenCampaignReport,
  },
  strict: import.meta.env.DEV ? true : false,
  namespace: true,
}

export type RootState = {
  auth: {
    authenticating: boolean
    authenticated: boolean
    user: {
      currencyCode: string
      organizationId: number
      organizationList: Organization[]
      userPreference?: {
        numberI18n: string
        dateI18n: string
      }
    }
    functions: string[]
    claims: string[]
    gcuAccepted: boolean
  }
  targetingFilter: {
    passportProductType: number
  } & StateTargetingNetwork
  bundles: {
    list: Array<{
      name: string
      id: number
      isShared: boolean
      countryCode: string
    }>
  }
  toasts: {
    list: Toast[]
  }
}

const store = createStore<RootState>(config)

export default store
