<template>
  <div class="tw-w-modal-lg tw-divide-y tw-divide-border-light">
    <div class="tw-p-6">
      <h2 class="tw-text-center tw-text-xl tw-font-bold tw-text-black">
        Convert Forecast to Order
      </h2>
    </div>
    <div class="tw-p-6">
      <div class="tw-mb-8 tw-px-8">
        <ButtonsInput
          v-model="form.actualTab"
          :options="optionsButtons"
        />
      </div>
      <form
        v-if="form.actualTab === 'selectOrder'"
        id="forecastFormConversion-selectOrder"
        class="tw-p-6"
        @submit.prevent="handleSubmit"
      >
        <h3 class="tw-text-md tw-mb-2 tw-font-bold tw-text-black">
          Choose on wich order you want to convert this forecast
        </h3>
        <SearchInput
          label="Order List"
          required
          :model-value="form.campaignOrderId"
          :options="orderList"
          :popover="{ position: 'bottom' }"
          @update:model-value="handleSelected"
        />
      </form>
      <form
        v-else-if="form.actualTab === 'createOrder'"
        :id="`forecastFormConversion-createOrder`"
        class="tw-p-6"
        @submit.prevent="handleSubmit"
      >
        <h3 class="tw-text-md tw-mb-2 tw-font-bold tw-text-black">
          Order isn't listed? Create one
        </h3>
        <div class="tw-flex tw-items-center">
          <TextInput
            v-model="form.name"
            label="Name"
            required
            class="tw-mr-1 tw-bg-transparent tw-text-sm tw-outline-none"
          />
        </div>
      </form>
    </div>
    <div
      v-if="isError"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        variant="primary"
        :busy="isLoading"
        :form="`forecastFormConversion-${form.actualTab}`"
        type="submit"
        :label="
          shouldRetry
            ? 'Retry'
            : form.actualTab == 'selectOrder'
            ? 'Convert to Order'
            : 'Create Order and convert'
        "
      />
    </div>
  </div>
</template>

<script>
import ButtonsInput from '@/components/Input/ButtonsInput.vue'
import PassportButton from '@/components/PassportButton.vue'
import SearchInput from '@/components/Input/SearchInput.vue'
import TextInput from '@/components/Input/TextInput.vue'

export default {
  name: 'PopupForecastConfirmConversion',
  components: {
    ButtonsInput,
    PassportButton,
    SearchInput,
    TextInput,
  },
  props: {
    forecast: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      isLoading: false,
      isError: false,
      shouldRetry: false,

      error: '',

      optionsButtons: [
        { value: 'selectOrder', label: 'Select Order' },
        { value: 'createOrder', label: 'Create Order' },
      ],

      form: {
        name: null,
        campaignOrderId: null,
        actualTab: 'selectOrder',
      },
    }
  },
  computed: {
    orderList() {
      return this.$store.state.order.list
        .map((order) => ({ label: order.name, value: order.id }))
        .sort((a, b) => ('' + a.label).localeCompare(b.label))
    },
  },
  async mounted() {
    await this.$store.dispatch('order/list')
  },
  methods: {
    handleSelected(id) {
      this.form.campaignOrderId = id
      this.isEditing = false
    },
    async handleSubmit() {
      this.isLoading = true
      const data = { id: this.forecast.id }

      if (this.form.actualTab === 'createOrder') {
        data.newOrder = {
          name: this.form.name,
          startDate: this.forecast.startDate,
          endDate: this.forecast.endDate,
          currencyCode: this.forecast.currencyCode.toUpperCase(),
          campaignAmount: this.forecast.budget.flightAmount,
          countryCode: this.forecast.countryCode,
          channelTypes: this.forecast.channelTypes,
        }
      } else {
        data.campaignOrderId = this.form.campaignOrderId
      }

      try {
        const response = await this.$store.dispatch(
          'forecast/confirmConversion',
          data
        )

        this.$router.push(
          this.toRoute({
            name: 'EditOrder',
            params: { order: response.data.datas.campaignOrderId },
          })
        )

        this.$store.dispatch('addToast', {
          type: 'info',
          value: `Forecast successfully converted`,
        })

        this.$emit('close')
      } catch (error) {
        this.handleError(error)

        this.isError = true
        this.shouldRetry = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
