import _cloneDeep from 'lodash/cloneDeep'
import HTTP from '@/services/http'

export default {
  namespaced: true,

  state: () => ({
    current: null,
    flightId: null,
    form: null,
    list: [],
  }),

  mutations: {
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_FLIGHT_ID(state, flightId) {
      state.flightId = flightId
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    async list({ commit, state }, { id, force = false }) {
      if (!force && state.flightId === id && state.list.length) {
        return Promise.resolve()
      }

      try {
        const response = await HTTP.get(`Creative/list/${id}`)

        commit('SET_LIST', response.data.datas)
        commit('SET_FLIGHT_ID', id)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ state }) {
      try {
        const request = {
          flightId: state.flightId,
          flightWeekScheduleType: state.preset,
          flightRules: state.rules,
        }

        if (state.preset === 'Custom') {
          request.weekSchedule = state.weekSchedule
        }

        const response = await HTTP.put(`FlightSchedule/FullEdit`, request)

        // commit('SET_RULES', response.data.datas.flightRules)
        // commit('SET_WEEK_SCHEDULE', response.data.datas.weekSchedule)

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },
  },
}
