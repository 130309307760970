<script setup lang="ts">
import PassportButton from '@/components/PassportButton.vue'
import TextInput from '@/components/Input/TextInput.vue'
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits<{ close: [] }>()

const store = useStore()

const error = ref('')
const form = reactive({
  email: '',
})
const isLoading = ref(false)
const isError = ref(false)
const shouldRetry = ref(false)

const handleSubmit = async (): Promise<void> => {
  try {
    isLoading.value = true
    shouldRetry.value = false

    const baseUrl = `${window.location.origin}/reset-password/`

    await store.dispatch('resetPassword', {
      email: form.email,
      baseUrl,
    })

    emit('close')
  } catch (error) {
    shouldRetry.value = true
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="tw-w-modal tw-divide-y tw-divide-border-light">
    <div class="tw-p-6">
      <h2 class="tw-text-center tw-text-xl tw-font-bold tw-text-black">
        Reset your password
      </h2>
    </div>
    <form
      id="forgotPassword"
      class="tw-p-6"
      @submit.prevent="handleSubmit"
    >
      <TextInput
        v-model="form.email"
        label="Enter your email"
        required
        type="email"
      />
    </form>
    <div
      v-if="isError"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="emit('close')"
      />
      <PassportButton
        :busy="isLoading"
        form="forgotPassword"
        :label="shouldRetry ? 'Retry' : 'Submit'"
        type="submit"
        variant="primary"
      />
    </div>
  </div>
</template>
