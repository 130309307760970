export default {
  namespaced: true,

  state: () => ({
    loading: 0,
  }),

  mutations: {
    START_LOADING(state) {
      state.loading++
    },
    FINISH_LOADING(state) {
      state.loading--
    },
  },
}
