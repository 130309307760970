<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Edit name of this {{ entity.singular.toLowerCase() }}
      </h2>
    </div>
    <form
      id="creative-library-edit"
      class="tw-p-6"
      @submit.prevent="handleSubmit"
    >
      <TextInput
        v-model="form.name"
        label="name"
      />
    </form>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-border-t tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        variant="primary"
        :busy="isLoading"
        :label="shouldRetry ? 'Retry' : `Edit ${entity.singular.toLowerCase()}`"
        type="submit"
        form="creative-library-edit"
      />
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue'
import TextInput from '@/components/Input/TextInput.vue'

export default {
  name: 'PopupCreativeLibraryEdit',
  components: {
    PassportButton,
    TextInput,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'refreshData'],
  data() {
    return {
      isLoading: false,
      shouldRetry: false,
      error: null,

      form: {
        name: '',
      },
    }
  },
  mounted() {
    Object.assign(this.form, this.item)
  },
  methods: {
    async handleSubmit() {
      try {
        this.isLoading = true
        this.shouldRetry = false
        this.error = null

        await this.$store.dispatch('creativeLibrary/update', {
          id: this.item.id,
          payload: this.form,
        })

        this.$emit('refreshData')
        this.$emit('close')
      } catch (error) {
        this.shouldRetry = true
        this.error = this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
