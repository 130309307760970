import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: ':campaign/flights/create',
    name: 'CreateCampaignFlight',
    component: () => import('@/views/campaign-flight/edit.vue'),
    meta: {
      title: 'Passport | Create Campaign Flight',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
  },
  {
    path: ':campaign/flights/:campaignFlight/creatives/:campaignCreative',
    name: 'EditCampaignFlightCreative',
    component: () => import('@/views/campaign-flight/edit.vue'),
    meta: {
      title: 'Passport | Edit Campaign Flight Creative',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
    props: () => ({ tabSelected: 'creatives' }),
  },
  {
    path: ':campaign/flights/:campaignFlight/:tab?',
    name: 'EditCampaignFlight',
    component: () => import('@/views/campaign-flight/edit.vue'),
    meta: {
      title: 'Passport | Edit Campaign Flight',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
] as RouteRecordRaw[]
