<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Do you really want to archive this {{ entity.singular.toLowerCase() }}?
      </h2>
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        variant="basic"
        label="Cancel"
        @@click="$emit('close')"
      />
      <PassportButton
        variant="primary"
        :busy="isLoading"
        type="button"
        :label="shouldRetry ? 'Retry' : 'Archive'"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>
<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  components: { PassportButton },
  name: 'PopupEntityArchive',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    resolved: {
      type: Function,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      isLoading: false,
      shouldRetry: false,
    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      this.shouldRetry = false

      try {
        await this.$store.dispatch(`${this.entity.module}/archive`, {
          id: this.item.id,
        })

        await this.$store.dispatch(`${this.entity.module}/fetch`, {
          id: this.item.id,
          reload: true,
        })
        this.$store.dispatch('addToast', {
          type: 'info',
          value: `${this.entity.singular} ${
            this.item.id || this.item.id
          } successfully archived`,
        })

        if (typeof this.resolved === 'function') {
          await this.resolved()
        }

        this.$emit('close')
      } catch (error) {
        this.shouldRetry = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
