import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { handleError } from '@/helpers/handleError'
import Http from '@/services/http'

interface DealId {
  name: string
  validFrom: string
  validTo: string
  used: boolean
}

export const useStoreCampaignDeals = defineStore('campaignDeals', () => {
  const stateDealIds = reactive<{
    loading: boolean
    error: string | null
    currentOverlapping: boolean
  }>({
    loading: true,
    error: null,
    currentOverlapping: false,
  })
  const listDealsId = ref<DealId[]>([])

  const fetchDealsIds = async ({
    countryCode,
    campaignId,
  }: {
    countryCode: string
    campaignId: null | number
  }): Promise<void> => {
    try {
      stateDealIds.loading = true
      stateDealIds.error = null

      const response = await Http.get(`deal/list/${countryCode}/${campaignId}`)

      listDealsId.value = response.data.datas
    } catch (error) {
      stateDealIds.error = handleError(error)
    } finally {
      stateDealIds.loading = false
    }
  }

  return { listDealsId, stateDealIds, fetchDealsIds }
})
