export default {
  namespaced: true,

  state: {
    components: [],
  },

  mutations: {
    ADD_COMPONENT(state, component) {
      state.components.push(component._uid)
    },
    REMOVE_COMPONENT(state, component) {
      state.components.splice(state.components.indexOf(component._uid), 1)
    },
    RESET_STATE(state) {
      state.components = []
    },
  },

  actions: {
    beforeUnmount({ commit }, component) {
      commit('REMOVE_COMPONENT', component)
    },
    beforeMount({ commit }, component) {
      commit('ADD_COMPONENT', component)
    },
    resetState({ commit }) {
      commit('RESET_STATE')
    },
  },
}
