<template>
  <div
    class="tw-col-span-full tw-flex tw-justify-center tw-py-2"
    :class="{ 'tw-hidden': pagination.perPage * pagination.page > count }"
  >
    <PassportButton
      variant="stroke"
      :busy="loading"
      :label="buttonLabel"
      :disabled="loading"
      :size="size"
      :class="buttonClass"
      @@click="$emit('nextpage')"
    />
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  name: 'LoadMore',
  components: {
    PassportButton,
  },
  props: {
    buttonClass: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: 'Load More',
    },
    count: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'base',
    },
  },
  emits: ['nextpage'],
}
</script>
