<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-text-grey-medium hover:tw-text-blackest"
  >
    <div class="tw-truncate">
      <div class="tw-text-sm tw-font-medium">
        <template v-if="safeGet(item, headerKey)">
          {{ $filters.dateWithDay(safeGet(item, headerKey)) }}
        </template>
        <template v-else>-</template>
      </div>
      <div
        v-if="
          safeGet(item, headerKey) && isDate(parseISO(safeGet(item, headerKey)))
        "
        class="tw-text-xsm tw-text-grey-light"
      >
        <template
          v-if="
            headerKey === 'endDate' &&
            ['Campaign', 'Order'].includes(item.entity)
          "
        >
          {{ $filters.timeAgo(endOfDay(parseISO(safeGet(item, headerKey)))) }}
        </template>
        <template v-else>
          {{ $filters.timeAgo(safeGet(item, headerKey)) }}
        </template>
      </div>
    </div>
  </router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import { isDate, endOfDay, parseISO } from 'date-fns'

export default {
  name: 'ListCellDate',
  extends: ListCellDefault,

  data() {
    return {
      isDate,
      endOfDay,
      parseISO,
    }
  },
}
</script>
