<template>
  <Popup
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Change your email address
      </h2>
    </div>

    <form
      id="email-form"
      class="tw-px-10 tw-pb-8 tw-pt-4"
      @submit.prevent="handleSubmit"
    >
      <div class="tw-space-y-4">
        <TextInput
          v-model="form.newEmail"
          required
          label="New email address"
          type="email"
        />
        <TextInput
          v-model="form.password"
          required
          type="password"
          label="Your current Password"
        />
      </div>
      <div
        v-show="errorMessage !== null"
        class="tw-mt-4 tw-text-red"
      >
        {{ errorMessage }}
      </div>
    </form>
    <div
      class="tw-mt-auto tw-flex tw-items-center tw-justify-between tw-border-t tw-p-6"
    >
      <PassportButton
        variant="basic"
        label="Cancel"
        @@click="handleClose"
      />
      <PassportButton
        variant="primary"
        type="submit"
        form="email-form"
        label="Edit email"
      />
    </div>
  </Popup>
</template>
<script>
import PassportButton from '@/components/PassportButton.vue'
import Popup from '@/components/Popup.vue'
import TextInput from '@/components/Input/TextInput.vue'

export default {
  name: 'PopupEmailChange',

  components: {
    PassportButton,
    Popup,
    TextInput,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'update:modelValue'],
  data() {
    return {
      errorMessage: null,
      form: {
        newEmail: '',
        password: '',
      },
    }
  },
  methods: {
    async handleSubmit() {
      this.errorMessage = null

      try {
        await this.$store.dispatch('user/updateEmail', this.form)

        this.$store.dispatch('addToast', {
          type: 'info',
          value: `An email has been sent to user`,
        })
        this.$emit('close')
      } catch (error) {
        try {
          this.errorMessage = error.response.data.errors[0].message
        } catch (error) {
          this.errorMessage = 'Please check your password.'
        }
      }
    },
    handleClose() {
      this.errorMessage = null
      this.form.newEmail = ''
      this.form.password = ''

      this.$emit('close')
    },
  },
}
</script>
