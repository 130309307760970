<template>
  <div>
    <button
      class="tw-group tw-z-10 -tw-ml-px -tw-mt-px tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-overflow-hidden tw-rounded tw-text-sm"
      :class="showChildren ? 'tw-rounded-bl-none' : ''"
      type="button"
      @click.stop="$emit('click', { item: item, key: 'toggle' })"
    >
      <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-bg-completed">
        <div
          class="tw-flex tw-h-full tw-w-4 tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-bg-overlay-light tw-text-white group-hover:tw-bg-overlay"
        >
          <Icon
            name="chevron-down"
            class="tw-h-2 tw-w-2 tw-transform"
            :class="showChildren ? 'tw-rotate-180' : ''"
          />
        </div>
        <div
          class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-evenly tw-text-white group-hover:tw-bg-overlay-light"
        >
          <template v-if="'magicTokenFlightForecastResponses' in item">
            <div class="tw-text-right tw-tabular-nums tw-leading-none">
              {{ item.magicTokenFlightForecastResponses.length }}
            </div>
            <Icon
              name="stats"
              class="tw-h-4 tw-w-4 tw-flex-shrink-0"
            />
          </template>
          <template v-else-if="'magicTokenCampaignReportResponses' in item">
            <div class="tw-text-right tw-tabular-nums tw-leading-none">
              {{ item.magicTokenCampaignReportResponses.length }}
            </div>
            <Icon
              name="report"
              class="tw-h-4 tw-w-4 tw-flex-shrink-0"
            />
          </template>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'ListCellMagicTokenToggle',
  emits: ['click'],
  extends: ListCellDefault,
  components: {
    Icon,
  },
  props: {
    showChildren: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
