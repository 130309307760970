import { sortList } from '@/helpers/sort'
import targetingJsonTransformer from '@/helpers/targetingJsonTransformer.js'
import targetingJsonTransformerDmp from '@/helpers/targetingJsonTransformerDmp.js'
import HTTP from '@/services/http'
import { useStoreForecastEstimates } from '@/stores'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import { v4 as uuid } from 'uuid'

const GeoElementTypes = ['States', 'Department', 'City', 'Zip']

const getDefaultState = () => ({
  addressableInventoryOnly: false,
  carriers: [],
  countChannels: 0,
  countSegments: 0,
  countZips: 0,
  devices: [],
  form: null,
  isDirty: {
    dmps: false,
    form: false,
    kvps: false,
    ips: false,
    locations: false,
    networks: false,
  },
  targetingFilter: null,
  mapColors: [],
  mapTargets: [],
  locationsAdvancedResults: [],
  locationsIsAdvanced: false,
  locationsIsValid: true,
  locationsList: {
    1: [],
    2: [],
    3: [],
    4: [],
  },
  locationsResults: [],
  networksAdvancedResults: [],
  networksIsAdvanced: false,
  networksIsValid: true,
  networksList: {
    channels: [],
    groupChannels: [],
    formats: [],
    publishers: [],
  },
  networksResults: [],
  dmpsList: [],
  dmpsResults: [],
  dmpsAdvancedResults: [],
  kvpsResults: [],
  kvpsKeys: [],
  ipsResults: [],
})

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    RESET_CURRENT(state) {
      state.targetingFilter = null
      state.form = null
      state.locationsAdvancedResults = []
      state.locationsIsAdvanced = false
      state.locationsIsValid = true
      state.locationsResults = []
      state.networksAdvancedResults = []
      state.networksIsAdvanced = false
      state.networksIsValid = true
      state.networksResults = []
      state.dmpsResults = []
      state.dmpsAdvancedResults = []
      state.ipsResults = []
      state.countChannels = 0
      state.countSegments = 0
      state.countZips = 0
      state.isDirty = {
        dmps: false,
        kvps: false,
        ips: false,
        form: false,
        locations: false,
        networks: false,
      }
    },
    SET_CARRIERS(state, carriers) {
      state.carriers = carriers
    },
    SET_DEVICES(state, devices) {
      state.devices = devices
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_DIRTY_ALL(state, isDirty) {
      for (const key in state.isDirty) {
        state.isDirty[key] = isDirty
      }
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_COUNT_CHANNELS(state, count) {
      state.countChannels = count
    },
    SET_COUNT_SEGMENTS(state, count) {
      state.countSegments = count
    },
    SET_COUNT_ZIPS(state, count) {
      state.countZips = count
    },
    SET_LIST_DMPS(state, dmpsList) {
      state.dmpsList = _cloneDeep(dmpsList).map((dataProvider) => {
        return {
          ...dataProvider,
          segments: dataProvider.segments,
        }
      })
    },
    SET_RESULTS_DMPS(state, targetingFilterDmpsJson) {
      function handleSetKeys(item) {
        switch (item.DmpElementType) {
          case 'Dmp':
            item.key = `Dmp-${item.dataProviderId}`
            break
          case 'Segment':
            item.key = `Segment-${item.dataProviderId}-${item.segmentId}`
            break
        }
      }

      try {
        const parsedValue = JSON.parse(targetingFilterDmpsJson)
        const dmpsResults =
          parsedValue?.Values.reduce((dmpsResults, group, groupIndex) => {
            if (group.ContentType === 'Operator') {
              return dmpsResults
            }

            const dataProviderId = group.Values.reduce(
              (dataProviderId, value) => {
                if (value.Value.dataProviderId) {
                  return value.Value.dataProviderId
                }

                return dataProviderId
              },
              `index-${groupIndex}`
            )

            dmpsResults.push({
              ...group,
              SellPrice: group.SellPrice ?? 0,
              Currency: group.Currency ?? 'USD',
              dataProviderId: dataProviderId,
              Values: group.Values.reduce((groupValues, value) => {
                if (value.ContentType === 'Operator') {
                  return groupValues
                }

                groupValues.push(value.Value)

                return groupValues
              }, []).map((value) => {
                return {
                  ...value,
                  key: value.key ?? handleSetKeys(value),
                }
              }),
            })

            return dmpsResults
          }, []) ?? []

        state.dmpsResults = dmpsResults
      } catch (error) {
        console.error(error)
        state.dmpsResults = []
      }
    },
    SET_LIST_LOCATIONS(state, locationsList) {
      state.locationsList = locationsList
    },
    SET_RESULTS_LOCATIONS(state, targetingFilterLocationJson) {
      try {
        const parsedValue = JSON.parse(targetingFilterLocationJson)
        const locationsResults = []

        parsedValue.Values.forEach((group) => {
          if (group.ContentType === 'Operator') {
            return group
          }

          group.Values.forEach((groupValue) => {
            if (groupValue.ContentType === 'Operator') {
              return
            }

            const item = groupValue.Value

            switch (item.GeoElementType) {
              case 'File':
                item.IsIncluded =
                  typeof item.IsIncluded !== 'undefined'
                    ? item.IsIncluded
                    : true
                break
              case 'States':
              case 'Department':
              case 'City':
              case 'Zip':
              default:
                item.typeId = 'geoItemId'
                item.geoItemId = Object.keys(item).includes('GeoItemId')
                  ? item.GeoItemId
                  : item.geoItemId
                item.id = item.geoItemId
                item.countryLevel = GeoElementTypes.indexOf(item.GeoElementType)
                break
            }

            // Set default Radius to enable watchers
            if (['Zip', 'Gps'].includes(item.GeoElementType)) {
              item.Radius = item.Radius || 0
            }

            locationsResults.push(item)
          })
        })

        // Use unique key
        state.locationsResults = sortList(
          _cloneDeep(locationsResults).map((item) => {
            return {
              ...item,
              key: item.geoItemId || item.gpsId || item.Path,
            }
          }),
          'Name',
          'asc'
        )

        // Force correct & unique Order values, even for old targetings
        const locationsAdvancedResults = sortList(
          parsedValue.Values,
          'Order',
          'asc'
        ).reduce((locationsAdvancedResults, item, index) => {
          item.Order = index + 1
          item.Uid = item.Uid || uuid()

          if ('Values' in (item || {}) && Array.isArray(item.Values)) {
            item.Values = sortList(item.Values, 'Order', 'asc').reduce(
              (itemValues, Value, index) => {
                Value.Order = index + 1

                itemValues.push(Value)

                return itemValues
              },
              []
            )
          }

          locationsAdvancedResults.push(item)

          return locationsAdvancedResults
        }, [])

        state.locationsResults = sortList(
          _cloneDeep(locationsResults),
          'Name',
          'asc'
        )
        state.locationsAdvancedResults = _cloneDeep(locationsAdvancedResults)
        state.locationsIsAdvanced = parsedValue.IsAdvanced ?? false
      } catch (error) {
        state.locationsResults = []
        state.locationsAdvancedResults = []
        state.locationsIsAdvanced = false
      }
    },
    SET_VALIDITY_LOCATIONS(state, validity) {
      state.locationsIsValid = validity
    },
    SET_LIST_NETWORKS(state, networksList) {
      state.networksList = networksList
    },
    SET_VALIDITY_NETWORKS(state, validity) {
      state.networksIsValid = validity
    },
    SET_RESULTS_NETWORKS(state, targetingFilterNetworkJson) {
      try {
        const parsedValue = JSON.parse(targetingFilterNetworkJson)
        const networksResults = []

        parsedValue.Values.forEach((group) => {
          if (group.ContentType === 'Operator') {
            return group
          }

          group.Values.forEach((groupValue) => {
            if (groupValue.ContentType === 'Operator') {
              return
            }

            const item = groupValue.Value

            switch (item.NetworkElementType) {
              case 'Channel':
                item.key = `Channel-${item.ChannelId || item.channelId}`
                break
              case 'PublisherChannel':
                item.key = `PublisherChannel-${
                  item.PublisherId || item.publisherId
                }`
                break
              case 'GroupChannel':
                item.key = `GroupChannel-${
                  item.GroupChannelId || item.groupChannelId
                }`
                break
              case 'Publisher':
                item.key = `Publisher-${item.PublisherId || item.publisherId}`
                break
              case 'MusicStyle':
                if (item.musicStyleId || item.MusicStyleId) {
                  item.key = `${item.NetworkElementType}-${
                    item.contentFormatTypeId
                  }-${item.musicStyleId || item.MusicStyleId}`
                } else if (item.formatId || item.FormatId) {
                  item.key = `${item.NetworkElementType}-${
                    item.contentFormatTypeId
                  }-${item.formatId || item.FormatId}`
                } else {
                  item.key = `${item.NetworkElementType}-${item.contentFormatTypeId}`
                }
                break
              case 'File':
                item.key = `${item.NetworkElementType}-${item.Path}`
                break
              default:
                return
            }

            networksResults.push(item)
          })
        })

        // Force correct & unique Order values, even for old targetings
        const networksAdvancedResults = sortList(
          parsedValue.Values,
          'Order',
          'asc'
        ).reduce((networksAdvancedResults, item, index) => {
          item.Order = index + 1
          item.Uid = item.Uid || uuid()

          if ('Values' in (item || {}) && Array.isArray(item.Values)) {
            item.Values = sortList(item.Values, 'Order', 'asc').reduce(
              (itemValues, Value, index) => {
                Value.Order = index + 1

                itemValues.push(Value)

                return itemValues
              },
              []
            )
          }

          networksAdvancedResults.push(item)

          return networksAdvancedResults
        }, [])

        state.networksResults = sortList(networksResults, 'Name', 'asc')
        state.networksAdvancedResults = networksAdvancedResults
        state.networksIsAdvanced = parsedValue.IsAdvanced ?? false
      } catch (error) {
        state.networksResults = []
        state.networksAdvancedResults = []
        state.networksIsAdvanced = false
      }
    },
    SET_RESULTS_KVPS(state, targetingFilterKeyValuePairsJson) {
      try {
        const parsedValue = JSON.parse(targetingFilterKeyValuePairsJson)
        const kvpsResults = []

        parsedValue.Values.forEach((group) => {
          if (group.ContentType === 'Operator') {
            return group
          }

          group.Values.forEach((groupValue) => {
            if (groupValue.ContentType === 'Operator') {
              return
            }

            const item = groupValue.Value

            kvpsResults.push(item)
          })
        })

        state.kvpsResults = kvpsResults
      } catch (error) {
        state.kvpsResults = []
      }
    },
    SET_KVPS_KEYS(state, keys) {
      state.kvpsKeys = keys
    },
    ADD_KVP(state, item) {
      state.kvpsResults.push(item)
    },
    TOGGLE_KVP(state, { include, item }) {
      const index = state.kvpsResults.findIndex(
        (value) => value.Value === item.Value && value.Key === item.Key
      )

      if (index >= 0) {
        state.kvpsResults[index].IsIncluded = include
      }
    },
    REMOVE_KVP(state, item) {
      const index = state.kvpsResults.findIndex(
        (value) => value.Value === item.Value && value.Key === item.Key
      )

      if (index >= 0) {
        state.kvpsResults.splice(index, 1)
      }
    },
    REMOVE_ALL_KVPS(state) {
      state.kvpsResults = []
    },
    TOGGLE_ALL_KVPS(state, { include }) {
      state.kvpsResults.forEach((item) => {
        item.IsIncluded = include
      })
    },
    SET_RESULTS_IPS(state, { results }) {
      state.ipsResults = results
    },
    SET_TARGETING_FILTER(state, targetingFilter) {
      state.targetingFilter = targetingFilter
    },
    TOGGLE_CARRIER(state, carrier) {
      const newCarriers = state.targetingFilter.targetingFilterCarriers.slice()

      const carrierIndex = newCarriers.findIndex(
        (c) => c.carrierId === carrier.carrierId
      )
      const isIncluded = !newCarriers[carrierIndex].isIncluded

      if (carrierIndex < 0) {
        return
      }

      state.form.targetingFilterCarriers = newCarriers.map((c) => {
        if (
          c.carrierId === carrier.carrierId ||
          c.parentId === carrier.carrierId
        ) {
          c.isIncluded = isIncluded
        }

        return c
      })
    },
    TOGGLE_DEVICE(state, device) {
      let newDevices = state.targetingFilter.targetingFilterDevices.slice()

      const deviceIndex = newDevices.findIndex((d) => d.deviceId === device.id)
      const isIncluded = !newDevices[deviceIndex].isIncluded

      if (deviceIndex < 0) {
        return
      }

      newDevices = newDevices.map((d) => {
        if (d.deviceId === device.id || d.parentId === device.id) {
          d.isIncluded = isIncluded
        }

        return d
      })

      // If all children are included, include the parent
      if (isIncluded && device.parentId) {
        if (
          newDevices
            .filter((d) => d.parentId === device.parentId)
            .every((child) => child.isIncluded)
        ) {
          newDevices = newDevices.map((d) => {
            if (d.deviceId === device.parentId) {
              d.isIncluded = true
            }

            return d
          })
        }
      }

      state.form.targetingFilterDevices = newDevices
    },
    TOGGLE_LOCATION(state, { include, item }) {
      const index = state.locationsResults.findIndex(
        (value) => value[item.typeId] === item.id
      )

      if (index >= 0) {
        state.locationsResults[index].IsIncluded = include
      }
    },
    TOGGLE_ALL_LOCATIONS(state, { include }) {
      state.locationsResults.map((value) => {
        value.IsIncluded = include
      })
    },
    ADD_LOCATION(state, item) {
      state.locationsResults.push(item)
    },
    REMOVE_LOCATION(state, item) {
      let index

      if (item.GeoElementType === 'File') {
        index = state.locationsResults.findIndex(
          (value) => value.Path === item.Path
        )
      } else {
        index = state.locationsResults.findIndex(
          (value) => value[item.typeId] === item.id
        )
      }

      if (index >= 0) {
        state.locationsResults.splice(index, 1)
      }
    },
    REMOVE_ALL_LOCATIONS(state) {
      state.locationsResults = []
    },
    SET_LOCATION_RADIUS(state, { item, radius }) {
      const index = state.locationsResults.findIndex(
        (value) => value[item.typeId] === item.id
      )

      if (index >= 0) {
        state.locationsResults[index].Radius = radius
      }
    },
    SET_MAP_COLORS(state, mapColors) {
      state.mapColors = mapColors
    },
    SET_MAP_TARGETS(state, mapTargets) {
      state.mapTargets = mapTargets
    },
    TOGGLE_NETWORK(state, { include, item }) {
      const index = state.networksResults.findIndex(
        (value) => value.key === item.key
      )

      if (index >= 0) {
        state.networksResults[index].IsIncluded = include
      }
    },
    TOGGLE_ALL_NETWORKS(state, { include }) {
      state.networksResults.map((value) => {
        value.IsIncluded = include
      })
    },
    ADD_NETWORK(state, item) {
      state.networksResults.push(item)
    },
    REMOVE_NETWORK(state, item) {
      const index = state.networksResults.findIndex(
        (value) => value.key === item.key
      )

      if (index >= 0) {
        state.networksResults.splice(index, 1)
      }
    },
    REMOVE_ALL_NETWORKS(state) {
      state.networksResults = []
      state.networksAdvancedResults = []
    },
    REMOVE_IAB_CATEGORY_BLOCKING(state, item) {
      const index = state.networksResults.findIndex(
        (value) => value.key === item.key
      )

      if (index >= 0) {
        state.networksResults.splice(index, 1)
      }
    },
  },

  actions: {
    setForm({ commit }, form) {
      commit('SET_FORM', form)
    },

    toggleCarrier({ commit, state }, carrier) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: 'form' })
      }

      commit('TOGGLE_CARRIER', carrier)
    },

    toggleDevice({ commit, state }, device) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: 'form' })
      }

      commit('TOGGLE_DEVICE', device)
    },

    async fetch({ commit }, { id, type }) {
      try {
        const response = await HTTP.get(
          `TargetingFilter/${id}?passportProductType=${type}`
        )

        commit('SET_TARGETING_FILTER', response.data.datas)
        commit('SET_FORM', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    resetState({ commit }) {
      commit('RESET_STATE')
    },

    resetCurrent({ commit }) {
      commit('RESET_CURRENT')
    },

    async update({ state, commit }) {
      try {
        const response = await HTTP.put('TargetingFilter', state.form)

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.dataCostsCpm = response.data.datas.dataCostsCpm

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchCarriers({ commit, state }, { force = false } = {}) {
      if (!force && state.carriers.length) {
        return state.carriers
      }

      try {
        const response = await HTTP.get('Carrier/getlist')

        commit('SET_CARRIERS', response.data.datas)

        return state.carriers
      } catch (error) {
        console.error(error)
      }
    },

    async fetchDevices({ commit, state }, { force = false } = {}) {
      if (!force && state.devices.length) {
        return state.devices
      }

      try {
        const response = await HTTP.get('Device/getlist')

        commit('SET_DEVICES', response.data.datas)

        return state.devices
      } catch (error) {
        console.error(error)
      }
    },

    async fetchMap({ commit, state }) {
      try {
        const response = await HTTP.get(
          `TargetingFilter/${state.targetingFilter.id}/LocationMap?passportProductType=${state.form.passportProductType}`,
          {
            executeInBackground: true,
          }
        )

        commit('SET_MAP_TARGETS', response.data.datas.locationMapTarget)
        commit('SET_MAP_COLORS', response.data.datas.locationMapColor)

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchLocations({ commit, state }) {
      return HTTP.get(
        `TargetingFilter/${state.targetingFilter.id}/Locations?passportProductType=${state.form.passportProductType}`,
        {
          executeInBackground: true,
        }
      ).then((response) => {
        if (!response?.data?.datas) return

        commit(
          'SET_RESULTS_LOCATIONS',
          response.data.datas.targetingFilterLocationJson
        )
        commit('SET_COUNT_ZIPS', response.data.datas.countZipInclude)
        commit('SET_VALIDITY_LOCATIONS', response.data.datas.isValidTargeting)
        commit('SET_DIRTY', { isDirty: false, key: 'locations' })
      })
    },

    async updateLocations(
      { commit, state },
      { advanced = false, locationsResults = null, background = false } = {}
    ) {
      const request = {
        targetingFilterId: state.form.id,
        targetingFilterLocationJson:
          advanced && Array.isArray(locationsResults)
            ? locationsResults.length
              ? JSON.stringify({
                  IsAdvanced: true,
                  Values: locationsResults, // Make sure all items and their children have correct order
                })
              : null
            : targetingJsonTransformer(state.locationsResults, 'Geo'),
        isAdvanced: locationsResults ? true : false,
        passportProductType: state.form.passportProductType,
      }

      return await HTTP.put(`TargetingFilter/Location`, request, {
        executeInBackground: true,
      }).then((response) => {
        if (!response?.data?.datas) return

        if (!background) {
          commit('SET_VALIDITY_LOCATIONS', response.data.datas.isValidTargeting)
          commit(
            'SET_RESULTS_LOCATIONS',
            response.data.datas.targetingFilterLocationJson
          )
        }

        commit('SET_COUNT_ZIPS', response.data.datas.countZipInclude)
        commit('SET_DIRTY', { isDirty: false, key: 'locations' })
      })
    },

    async bulkImportLocations({ commit, state }, file) {
      const bodyFormData = new FormData()

      bodyFormData.append('File', file)
      bodyFormData.append('TargetingFilterId', state.form.id)
      bodyFormData.append('PassportProductType', state.form.passportProductType)

      return HTTP.put('TargetingFilter/Location/Import', bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((response) => {
        if (!response?.data?.datas) return

        // Locations probably won't change until whe change the country
        // commit('SET_LIST_LOCATIONS', response.data.datas.locationGeoItems)
        commit(
          'SET_RESULTS_LOCATIONS',
          response.data.datas.targetingFilterLocationJson
        )
        commit('SET_COUNT_ZIPS', response.data.datas.countZipInclude)
        commit('SET_VALIDITY_LOCATIONS', response.data.datas.isValidTargeting)
        commit('SET_DIRTY', { isDirty: false, key: 'locations' })
      })
    },

    toggleLocation({ commit, state }, { include, item }) {
      if (!state.isDirty.locations) {
        commit('SET_DIRTY', { isDirty: true, key: 'locations' })
      }

      commit('TOGGLE_LOCATION', { include, item })
    },

    toggleAllLocations({ commit, state }, { include }) {
      if (!state.isDirty.locations) {
        commit('SET_DIRTY', { isDirty: true, key: 'locations' })
      }

      commit('TOGGLE_ALL_LOCATIONS', { include })
    },

    addLocation({ commit, state }, item) {
      if (!state.isDirty.locations) {
        commit('SET_DIRTY', { isDirty: true, key: 'locations' })
      }

      commit('ADD_LOCATION', item)
    },

    removeLocation({ commit, state }, item) {
      if (!state.isDirty.locations) {
        commit('SET_DIRTY', { isDirty: true, key: 'locations' })
      }

      commit('REMOVE_LOCATION', item)
    },

    removeAllLocations({ commit, state }) {
      if (!state.isDirty.locations) {
        commit('SET_DIRTY', { isDirty: true, key: 'locations' })
      }

      commit('REMOVE_ALL_LOCATIONS')
    },

    setLocationRadius({ commit, state }, { item, radius }) {
      if (!state.isDirty.locations) {
        commit('SET_DIRTY', { isDirty: true, key: 'locations' })
      }

      commit('SET_LOCATION_RADIUS', { item, radius })
    },

    async fetchNetworks(
      { commit, state },
      { addressableInventoryOnly = false } = {}
    ) {
      return HTTP.get(`TargetingFilter/${state.targetingFilter.id}/Networks`, {
        params: {
          passportProductType: state.form.passportProductType,
          addressableInventoryOnly,
        },
        executeInBackground: true,
      })
        .then((response) => {
          if (!response?.data?.datas) return
          // commit('SET_LIST_NETWORKS', {
          //   channels: response.data.datas.networkChannels,
          //   groupChannels: response.data.datas.networkGroupChannels,
          //   formats: response.data.datas.networkMusicStyles,
          //   publishers: response.data.datas.networkPublishers,
          // })
          commit(
            'SET_RESULTS_NETWORKS',
            response.data.datas.targetingFilterNetworkJson
          )
          commit('SET_COUNT_CHANNELS', response.data.datas.countChannelIncludes)
          commit('SET_VALIDITY_NETWORKS', response.data.datas.isValidTargeting)
          commit('SET_DIRTY', { isDirty: false, key: 'networks' })
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async updateNetworks(
      { commit, state },
      { advanced = false, networksResults = null, background = false } = {}
    ) {
      const request = {
        targetingFilterId: state.form.id,
        targetingFilternetworkJson:
          advanced && Array.isArray(networksResults)
            ? networksResults.length
              ? JSON.stringify({
                  IsAdvanced: true,
                  Values: networksResults, // Make sure all items and their children have correct order
                })
              : null
            : targetingJsonTransformer(state.networksResults, 'Network'),
        isAdvanced: networksResults ? true : false,
        passportProductType: state.form.passportProductType,
      }

      return await HTTP.put(`TargetingFilter/network`, request, {
        executeInBackground: true,
      }).then((response) => {
        if (!response?.data?.datas) return

        if (!background) {
          // Locations probably won't change until whe change the country
          // commit('SET_LIST_NETWORKS', {
          //   channels: response.data.datas.networkChannels,
          //   groupChannels: response.data.datas.networkGroupChannels,
          //   formats: response.data.datas.networkMusicStyles,
          //   publishers: response.data.datas.networkPublishers,
          // })
          commit('SET_VALIDITY_NETWORKS', response.data.datas.isValidTargeting)
          commit(
            'SET_RESULTS_NETWORKS',
            response.data.datas.targetingFilterNetworkJson
          )
        }

        commit('SET_COUNT_CHANNELS', response.data.datas.countChannelIncludes)
        commit('SET_DIRTY', { isDirty: false, key: 'networks' })
      })
    },

    toggleNetwork({ commit, state }, { include, item }) {
      if (!state.isDirty.networks) {
        commit('SET_DIRTY', { isDirty: true, key: 'networks' })
      }

      commit('TOGGLE_NETWORK', { include, item })
    },

    toggleAllNetworks({ commit, state }, { include }) {
      if (!state.isDirty.networks) {
        commit('SET_DIRTY', { isDirty: true, key: 'networks' })
      }

      commit('TOGGLE_ALL_NETWORKS', { include })
    },

    addNetwork({ commit, state }, item) {
      if (!state.isDirty.networks) {
        commit('SET_DIRTY', { isDirty: true, key: 'networks' })
      }

      commit('ADD_NETWORK', item)
    },

    removeNetwork({ commit, state }, item) {
      if (!state.isDirty.networks) {
        commit('SET_DIRTY', { isDirty: true, key: 'networks' })
      }

      commit('REMOVE_NETWORK', item)
    },

    removeAllNetworks({ commit, state }) {
      if (!state.isDirty.networks) {
        commit('SET_DIRTY', { isDirty: true, key: 'networks' })
      }

      commit('REMOVE_ALL_NETWORKS')
    },

    exportNetworks({ state }) {
      return HTTP.get(
        `TargetingFilter/${state.targetingFilter.id}/Networks/export`,
        { executeInBackground: true }
      )
    },

    async fetchDmps({ commit, state }) {
      return HTTP.get(
        `TargetingFilter/${state.targetingFilter.id}/Dmp?passportProductType=${state.form.passportProductType}`,
        {
          executeInBackground: true,
        }
      ).then((response) => {
        if (!response?.data?.datas) return

        // commit('SET_LIST_DMPS', bject.values(response.data.datas.dataProviderItems))
        commit('SET_RESULTS_DMPS', response.data.datas.targetingFilterDmpJson)
        commit('SET_COUNT_SEGMENTS', response.data.datas.countSegmentInclude)
        commit('SET_DIRTY', { isDirty: false, key: 'dmps' })
      })
    },

    async updateDmps({ commit, state, dispatch }, { dmpsResults }) {
      const request = {
        targetingFilterId: state.form.id,
        targetingFilterDmpJson: Array.isArray(dmpsResults)
          ? targetingJsonTransformerDmp(dmpsResults)
          : null,
        isAdvanced: false,
        passportProductType: state.form.passportProductType,
      }

      return await HTTP.put(`TargetingFilter/Dmp`, request, {
        executeInBackground: true,
      }).then((response) => {
        function checkIfZipsIncluded() {
          const { log } = response?.data?.datas ?? {}

          if (!log) return false
          else if (
            !('Targeting' in log) ||
            !('Location' in log) ||
            !('GeoSegments' in log)
          )
            return false
          else if (log.GeoSegments.count == 0) return false
          else
            return (
              log.Location.geoItemCount > 0 &&
              log.Targeting.geoItemIntersect == 0
            )
        }

        const noneZipsIncluded = checkIfZipsIncluded()

        if (noneZipsIncluded)
          dispatch(
            'addToast',
            {
              type: 'warning',
              value:
                'There is 0 zip included in the current targeting, please check the DMP segments.',
            },
            { root: true }
          )

        if (!response?.data?.datas) return

        // Dmps probably won't change until whe change the country
        // commit('SET_LIST_DMPS', response.data.datas.dataProviderItems)
        commit('SET_RESULTS_DMPS', response.data.datas.targetingFilterDmpJson)
        commit('SET_COUNT_SEGMENTS', response.data.datas.countSegmentInclude)
        commit('SET_DIRTY', { isDirty: false, key: 'dmps' })
      })
    },

    async fetchKvps({ commit, state }) {
      return HTTP.get(
        `TargetingFilter/${state.targetingFilter.id}/KeyValuePairs?passportProductType=${state.form.passportProductType}`,
        {
          executeInBackground: true,
        }
      ).then((response) => {
        if (!response?.data?.datas) return

        commit('SET_KVPS_KEYS', response.data.datas.knownKeys)
        commit(
          'SET_RESULTS_KVPS',
          response.data.datas.targetingFilterKeyValuePairsJson
        )
        commit('SET_DIRTY', { isDirty: false, key: 'kvps' })
      })
    },

    async updateKvps({ commit, state }) {
      const request = {
        targetingFilterId: state.form.id,
        targetingFilterKeyValuePairsJson: targetingJsonTransformer(
          state.kvpsResults,
          'KeyValuePairs'
        ),
        isAdvanced: false,
        passportProductType: state.form.passportProductType,
      }

      return await HTTP.put(`TargetingFilter/KeyValuePairs`, request, {
        executeInBackground: true,
      }).then((response) => {
        if (!response?.data?.datas) return

        commit(
          'SET_RESULTS_KVPS',
          response.data.datas.targetingFilterKeyValuePairsJson
        )
        commit('SET_DIRTY', { isDirty: false, key: 'kvps' })
      })
    },

    addKvp({ commit, state }, item) {
      if (!state.isDirty.kvps) {
        commit('SET_DIRTY', { isDirty: true, key: 'kvps' })
      }

      commit('ADD_KVP', item)
    },

    removeKvp({ commit, state }, item) {
      if (!state.isDirty.kvps) {
        commit('SET_DIRTY', { isDirty: true, key: 'kvps' })
      }

      commit('REMOVE_KVP', item)
    },

    removeAllKvps({ commit, state }) {
      if (!state.isDirty.kvps) {
        commit('SET_DIRTY', { isDirty: true, key: 'kvps' })
      }

      commit('REMOVE_ALL_KVPS')
    },

    exportKvps({ state }) {
      return HTTP.get(
        `TargetingFilter/${state.targetingFilter.id}/KeyValuePairs/export`,
        {
          executeInBackground: true,
          responseType: 'blob',
        }
      )
    },

    toggleKvp({ commit, state }, item) {
      if (!state.isDirty.kvps) {
        commit('SET_DIRTY', { isDirty: true, key: 'kvps' })
      }

      commit('TOGGLE_KVP', item)
    },

    toggleAllKvps({ commit, state }, { include }) {
      if (!state.isDirty.kvps) {
        commit('SET_DIRTY', { isDirty: true, key: 'kvps' })
      }

      commit('TOGGLE_ALL_KVPS', { include })
    },

    async addAdvertiserDomain(
      { dispatch },
      { id, domain, advertiserDomainListingType }
    ) {
      try {
        const response = await HTTP.put('DealSetting/advertiser', {
          dealId: id,
          advertiserDomain: domain,
          advertiserDomainListingType,
        })

        dispatch('deal/fetch', { id }, { root: true })

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async removeAdvertiserDomain(
      { dispatch },
      { id, domain, advertiserDomainListingType }
    ) {
      try {
        const response = await HTTP.delete('DealSetting/advertiser', {
          data: {
            dealId: id,
            advertiserDomain: domain,
            advertiserDomainListingType,
          },
        })

        dispatch('deal/fetch', { id }, { root: true })

        Promise.resolve(response)
      } catch (error) {
        console.error(error)
        Promise.reject(error)
      }
    },

    async changeListingTypeAdvertiserDomain(
      { dispatch },
      { id, domains, advertiserDomainListingType }
    ) {
      try {
        const response = await HTTP.put('DealSetting/advertisers', {
          dealId: id,
          advertiserDomains: domains,
          advertiserDomainListingType,
        })

        dispatch('deal/fetch', { id }, { root: true })
        Promise.resolve(response)
      } catch (error) {
        console.error(error)
        Promise.reject(error)
      }
    },

    async removeAllAdvertiserDomains(
      { dispatch },
      { id, advertiserDomainListingType }
    ) {
      try {
        const response = await HTTP.put('DealSetting/advertisers', {
          dealId: id,
          advertiserDomains: [],
          advertiserDomainListingType,
        })

        dispatch('deal/fetch', { id }, { root: true })
        Promise.resolve(response)
      } catch (error) {
        console.error(error)
        Promise.resolve(error)
      }
    },

    async addIABCategoryBlocking({ dispatch }, { id, iabCategory }) {
      try {
        const response = await HTTP.put('DealSetting/category-blocking', {
          dealId: id,
          data: iabCategory.id,
        })

        dispatch('deal/fetch', { id }, { root: true })

        Promise.resolve(response)
      } catch (error) {
        console.error(error)
        Promise.reject(error)
      }
    },

    async removeIABCategoryBlocking({ dispatch }, { id, iabCategory }) {
      try {
        const response = await HTTP.delete('DealSetting/category-blocking', {
          data: { dealId: id, data: iabCategory },
        })

        dispatch('deal/fetch', { id }, { root: true })

        Promise.resolve(response)
      } catch (error) {
        console.error(error)
        Promise.reject(error)
      }
    },

    async removeAllIABCategoriesBlocking({ dispatch }, { id }) {
      try {
        const response = await HTTP.put('DealSetting/category-blockings', {
          dealId: id,
          data: [],
        })

        dispatch('deal/fetch', { id }, { root: true })

        Promise.resolve(response)
      } catch (error) {
        console.error(error)
        Promise.reject(error)
      }
    },

    fetchIps({ commit, state }) {
      return HTTP.get(
        `TargetingFilter/${state.targetingFilter.id}/Ips?passportProductType=${state.form.passportProductType}`,
        {
          executeInBackground: true,
        }
      ).then((response) => {
        if (!response?.data?.datas) return

        commit('SET_RESULTS_IPS', { results: response.data.datas })
        commit('SET_DIRTY', { isDirty: false, key: 'ips' })
      })
    },

    async updateIps({ commit, state }, { ipsResults }) {
      const request = {
        targetingFilterId: state.form.id,
        TargetingFilterIps: ipsResults,
        passportProductType: state.form.passportProductType,
      }

      return await HTTP.put(`TargetingFilter/Ips`, request, {
        executeInBackground: true,
      }).then((response) => {
        if (!response?.data?.datas) return

        commit('SET_RESULTS_IPS', { results: response.data.datas })
        commit('SET_DIRTY', { isDirty: false, key: 'ips' })
      })
    },

    applyChanges({ commit }) {
      commit('SET_DIRTY_ALL', false)
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY_ALL', false)
      commit('SET_FORM', state.current)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },

    setDirty({ commit, state }, key) {
      if (!state.isDirty[key]) {
        commit('SET_DIRTY', { isDirty: true, key })
      }
    },
  },
  getters: {
    isSomeDirty(state) {
      return Object.values(state.isDirty).some((element) => element)
    },
  },
}
