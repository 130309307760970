import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import _get from 'lodash/get'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig.js'
import { searchList } from '@/helpers/search'
import { useStoreForecastEstimates } from '@/stores'

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
  },

  mutations: {
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_BUDGET(state, budget) {
      state.current.budget = budget
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      state.current = _cloneDeep(current)
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async fetchConfigView({ commit }, { campaignId } = {}) {
      try {
        const response = await HTTP.get(
          `Flight/Configview/Campaign/${campaignId}`
        )
        const parsedConfig = parseConfig(
          response.data.FlightXml,
          'FlightTypeId'
        )

        commit('SET_CONFIG_VIEW', parsedConfig)

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit, state }, { id, reload = false }) {
      try {
        const response = await HTTP.get(`Flight/${id}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        if (reload || !state.form) {
          commit('SET_FORM', response.data.datas)
        }

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.dataCostsCpm =
          response.data.datas.budget.dataCostsCpm

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`Flight/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post('Flight', state.form)

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { options } = {}) {
      try {
        const dataForm = state.form
        const response = await HTTP.put('Flight', dataForm, options)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.dataCostsCpm =
          response.data.datas.budget.dataCostsCpm

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updateDataCost({ commit, state }, { value }) {
      try {
        const response = await HTTP.put('Flight/UpdateDataCostsCpm', {
          flightId: state.current.id,
          dataCostsCpm: value,
        })

        commit('SET_FORM', {
          ...state.form,
          budget: {
            ...state.form.budget,
            ecpm: response.data.datas.budget.ecpm,
            dataCostsCpm: response.data.datas.budget.dataCostsCpm,
          },
        })
        commit('SET_CURRENT', {
          ...state.current,
          budget: {
            ...state.current.budget,
            ecpm: response.data.datas.budget.ecpm,
            dataCostsCpm: response.data.datas.budget.dataCostsCpm,
          },
        })

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.dataCostsCpm =
          response.data.datas.budget.dataCostsCpm

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async duplicate(context, { id, startDate, endDate }) {
      try {
        const response = await HTTP.post(`Flight/duplicate`, {
          flightId: id,
          startDate,
          endDate,
        })

        return Promise.resolve(response?.data?.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async activate(context, { id }) {
      try {
        const response = await HTTP.get('Flight/Status', {
          params: {
            flightId: id,
            status: 'Ready',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async archive(context, { id }) {
      try {
        const response = await HTTP.get(`Flight/Status`, {
          params: {
            flightId: id,
            status: 'Archived',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async pause(context, { id }) {
      try {
        const response = await HTTP.get(`Flight/Status`, {
          params: {
            flightId: id,
            status: 'Paused',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async restart(context, { id }) {
      try {
        const response = await HTTP.get(`Flight/Status`, {
          params: {
            flightId: id,
            status: 'Draft',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async resume(context, { id }) {
      try {
        const response = await HTTP.get(`Flight/Status`, {
          params: {
            flightId: id,
            status: 'Ready',
          },
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, state }, { force = true, id }) {
      if (!force && state.list.length) {
        return Promise.resolve(state.list)
      }

      try {
        const response = await HTTP.get(`Flight/list`, {
          params: {
            campaignId: id,
          },
        })

        // Flatten the flights that are not parts of budget groupings
        const list = response.data.datas.notBudgetGrouping.map(
          (notBudgetGrouping) => {
            return {
              ...notBudgetGrouping,
              ...notBudgetGrouping.flights[0],
            }
          }
        )

        commit('SET_LIST', list)
        commit(
          'campaignBudgetGrouping/SET_LIST',
          response.data.datas.budgetGrouping,
          { root: true }
        )

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    async search({ dispatch }, { search }) {
      try {
        const response = await dispatch('list')

        response.data.datas = searchList(response.data.datas, 'name', search)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    async setDefaultForm({ commit, state, rootState }, type = 'flight') {
      const languageCodesList = _get(
        state.configView,
        'fields.FlightLanguageCode.DataList.DataList' || []
      )

      const channelsTypesList = _cloneDeep(
        _get(state.configView, 'fields.ChannelTypes.DataList.DataList') || []
      )

      const languageCode = Array.isArray(languageCodesList)
        ? languageCodesList[0]?.Language?.toUpperCase()
        : languageCodesList?.Language?.toUpperCase()

      if (type === 'flight') {
        const form = await import('@/forms/campaign-flight.js')
        const defaultForm = _cloneDeep(form.default)

        commit('SET_FORM', {
          ...defaultForm,
          budget: {
            ...defaultForm.budget,
            hardCostsCurrencyCode: rootState.campaign.current.currencyCode,
          },
          campaignId: rootState.campaign.current.id,
          countryCode: rootState.campaign.current.countryCode,
          currencyCode: rootState.campaign.current.currencyCode,
          languageCode,
          channelTypes: channelsTypesList
            .filter(({ IsDefault }) => IsDefault)
            .map(({ Id }) => Id),
        })
      }

      if (type === 'budget-grouping-flight') {
        const form = await import('@/forms/campaign-budget-grouping-flight.js')
        const defaultForm = _cloneDeep(form.default)

        commit('SET_FORM', {
          ...defaultForm,
          budgetId: rootState.campaignBudgetGrouping.current.id,
          campaignId: rootState.campaign.current.id,
          languageCode,
          channelTypes: channelsTypesList
            .filter(({ IsDefault }) => IsDefault)
            .map(({ Id }) => Id),
        })
      }
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
