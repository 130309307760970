import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import { sortList } from '@/helpers/sort'

const breadcrumbMapping = {
  adPlacementList: 'AdPlacement',
  adPlacementIds: 'AdPlacement',
  budgetGroupIds: 'Group',
  campaignTypeId: 'CampaignType',
  carrierIds: 'Carrier',
  carrierList: 'Carrier',
  channelId: 'Channel',
  channelTypeId: 'ChannelType',
  channelTypeIds: 'ChannelType',
  countryCodeList: 'Country',
  countryCodes: 'Country',
  creativeIds: 'Creative',
  deviceIds: 'Device',
  deviceList: 'Device',
  flightTypeIds: 'FlightType',
  flightIds: 'Flight',
  genderId: 'Gender',
  groupIds: 'Group',
  organizationId: 'Organization',
  productType: 'ProductType',
  publisherGroupId: 'Group',
  publisherId: 'Publisher',
  publisherIds: 'Publisher',
}

export default {
  namespaced: true,

  state: {
    form: {
      adPlacementList: [],
      adTraffickingId: null,
      campaignTypeId: null,
      carrierList: [],
      channelId: null,
      channelTypeId: null,
      countryCodeList: [],
      deviceList: [],
      from: new Date(),
      genderId: null,
      organizationId: [],
      productType: null,
      publisherGroupId: null,
      publisherId: null,
      to: new Date(),
    },
    listCampaignTypes: [],
    listChannelTypes: [],
    listCountries: [],
    listOrganizations: [],
    listProductTypes: [],
    listPublishers: [],
  },

  getters: {
    menuCampaignTypes(state) {
      return sortList(
        state.listCampaignTypes.map((campaignType) => {
          return {
            key: campaignType.id,
            label: campaignType.name,
            ratio: campaignType.ratio,
            formKey: 'campaignTypeId',
          }
        }),
        'label',
        'asc'
      )
    },
    menuChannelTypes(state) {
      return sortList(
        state.listChannelTypes.map((channelType) => {
          return {
            key: channelType.id,
            label: channelType.name,
            ratio: channelType.ratio,
            formKey: 'channelTypeId',
          }
        }),
        'label',
        'asc'
      )
    },
    menuCountryCodes(state) {
      return sortList(
        state.listCountries.map((country) => {
          return {
            key: country.id,
            label: country.name,
            ratio: country.ratio,
            formKey: 'countryCodeList',
          }
        }),
        'label',
        'asc'
      )
    },
    menuOrganizations(state) {
      return sortList(
        state.listOrganizations.map((organization) => {
          return {
            key: organization.id,
            label: organization.name,
            ratio: organization.ratio,
            formKey: 'organizationId',
          }
        }),
        'label',
        'asc'
      )
    },
    menuProductTypes(state) {
      return sortList(
        state.listProductTypes.map((productType) => {
          return {
            key: productType.id,
            label: productType.name,
            ratio: productType.ratio,
            formKey: 'productType',
          }
        }),
        'label',
        'asc'
      )
    },
    menuPublishers(state) {
      return sortList(
        state.listPublishers.map((publisher) => {
          return {
            value: publisher.id,
            label: publisher.name,
            formKey: 'publisherId',
            type: 'publisher',
          }
        }),
        'label',
        'asc'
      )
    },
  },

  mutations: {
    RESET_STATE(state) {
      state.form = {
        adPlacementList: [],
        adTraffickingId: null,
        budgetGroupId: null,
        campaignTypeId: null,
        carrierList: [],
        channelId: null,
        channelTypeId: null,
        countryCodeList: [],
        deviceList: [],
        from: new Date(),
        genderId: null,
        organizationId: [],
        productType: null,
        publisherGroupId: null,
        publisherId: null,
        to: new Date(),
      }
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST_CAMPAIGN_TYPES(state, listCampaignTypes) {
      state.listCampaignTypes = listCampaignTypes
    },
    SET_LIST_CHANNEL_TYPES(state, listChannelTypes) {
      state.listChannelTypes = listChannelTypes
    },
    SET_LIST_COUNTRIES(state, listCountries) {
      state.listCountries = listCountries
    },
    SET_LIST_ORGANIZATIONS(state, listOrganizations) {
      state.listOrganizations = listOrganizations
    },
    SET_LIST_PRODUCT_TYPES(state, listProductTypes) {
      state.listProductTypes = listProductTypes
    },
    SET_LIST_PUBLISHERS(state, listPublishers) {
      state.listPublishers = listPublishers
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    async listPublishers({ commit }) {
      try {
        const response = await HTTP.get('inventory/listing/publisher')

        commit('SET_LIST_PUBLISHERS', response.data.publisherList)

        return response
      } catch (e) {
        commit('SET_LIST_PUBLISHERS', [])
      }
    },

    setListCampaignTypes({ commit }, campaignTypes) {
      commit('SET_LIST_CAMPAIGN_TYPES', campaignTypes)
    },

    setListChannelTypes({ commit }, channelTypes) {
      commit('SET_LIST_CHANNEL_TYPES', channelTypes)
    },

    setListCountries({ commit }, countries) {
      commit('SET_LIST_COUNTRIES', countries)
    },

    setListOrganizations({ commit }, organizations) {
      commit('SET_LIST_ORGANIZATIONS', organizations)
    },

    setListProductTypes({ commit }, productTypes) {
      commit('SET_LIST_PRODUCT_TYPES', productTypes)
    },

    fetchBreadcrumb(_, { key, value }) {
      if (key === 'productType') {
        return Promise.resolve({ key, value, label: value })
      }
      if (key === 'genders' || key === 'genderIds') {
        return Promise.resolve({
          key,
          value,
          label: value === 'Female' || value === 'F' ? 'Women' : 'Men',
        })
      }
      if (key === 'genderId') {
        return Promise.resolve({
          key,
          value,
          label: value === 3 ? 'Women' : 'Men',
        })
      }
      if (key === 'adTraffickingId') {
        return Promise.resolve({
          key,
          value,
          label: value === 0 ? 'Direct' : 'Programmatic',
        })
      }
      if (key === 'campaignTypeId' && value === -1) {
        return Promise.resolve({ key, value, label: 'Unknown' })
      }

      if (!Object.keys(breadcrumbMapping).includes(key)) {
        if (!Array.isArray(value)) {
          return Promise.resolve({ key, value, label: value })
        } else {
          const promises = []

          value.map((val) => {
            promises.push(Promise.resolve({ key, value: val, label: val }))
          })

          return Promise.all(promises)
        }
      }

      if (!Array.isArray(value)) {
        return HTTP.get(`labels/${breadcrumbMapping[key]}/${value}`).then(
          (response) => {
            return { key, value, label: response.data.name }
          }
        )
      } else {
        const promises = []

        value.map((val) => {
          promises.push(
            HTTP.get(`labels/${breadcrumbMapping[key]}/${val}`).then(
              (response) => {
                return { key, value: val, label: response.data.name }
              }
            )
          )
        })

        return Promise.all(promises)
      }
    },

    async download(_, { params, type = 'simple' }) {
      switch (type) {
        case 'simple': {
          const response = await HTTP.post(`inventory/export/${type}`, params, {
            executeInBackground: true,
            responseType: 'blob',
          })

          return Promise.resolve(response.data)
        }
        case 'advanced': {
          const response = await HTTP.post(
            `inventory/daily/export/${type}`,
            params
          )

          return Promise.resolve(response.data)
        }
      }
    },

    handleInput({ commit }, { path, value, valueKey = 'form' }) {
      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
