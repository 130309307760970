import { searchList } from '@/helpers/search'
import { EnumDealTypes, EnumCampaignTypes } from '@/constants/options'
import HTTP from '@/services/http'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _set from 'lodash/set'

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
    partners: [],
  },

  mutations: {
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
      state.partners = []
    },
    RESET_CURRENT(state) {
      state.current = null
      state.form = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      if (!form) {
        state.form = form

        return
      }

      const { providersResponse = [], ...cleanForm } = form

      if (state.form) {
        Object.assign(state.form, cleanForm)
      } else {
        state.form = form
      }

      if (form) {
        let mappedProviderResponse = providersResponse

        mappedProviderResponse = mappedProviderResponse?.map((pr) =>
          pr.id === 72
            ? {
                ...pr,
                identifierReceivedFromProvider:
                  pr.identifierReceivedFromProvider.replace('d:', ''),
                identifierSentToProvider: pr.identifierSentToProvider.replace(
                  'd:',
                  ''
                ),
              }
            : pr
        )

        state.form.providers = [...mappedProviderResponse].map(
          ({
            id,
            identifierReceivedFromProvider,
            identifierSentToProvider,
            ...provider
          }) => ({
            identifierReceivedFromProvider:
              identifierReceivedFromProvider || form.name,
            identifierSentToProvider: identifierSentToProvider || form.name,
            providerId: id,
            ...provider,
          })
        )
      }
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_PARTNERS(state, partners) {
      state.partners = partners
    },
    TOGGLE_PARTNER(state, partner) {
      const partnerIndex = state.form.providers.findIndex(
        (provider) => provider.providerId === partner.id
      )

      if (partnerIndex >= 0) {
        state.form.providers.splice(partnerIndex, 1)
      } else {
        state.form.providers.push({
          providerId: partner.id,
          identifierSentToProvider: state.form.name,
          identifierReceivedFromProvider: state.form.name,
        })
      }
    },
    UPDATE_PARTNER(state, partner) {
      const partnerIndex = state.form.providers.findIndex(
        ({ providerId }) => providerId === partner.providerId
      )

      if (partnerIndex >= 0) {
        state.form.providers[partnerIndex] = partner
      }
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async fetchConfigView({ commit }) {
      try {
        const { data: configView } = await HTTP.get(`DealSetting/ConfigView`)

        commit('SET_CONFIG_VIEW', { sections: { ...configView.Sections } })

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit, state }, { id, force = true, reload = false }) {
      try {
        if (!force && state.current.id === id) {
          return Promise.resolve(state.current)
        }
        const response = await HTTP.get(`DealSetting/${id}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        if (reload || !state.form || state.form.id !== id) {
          commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`DealSetting/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post('DealSetting', state.form)

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async createForCampaign(
      { state, commit, dispatch, rootGetters },
      { campaign }
    ) {
      try {
        await dispatch('fetchConfigView')

        const currentOrganization = rootGetters['auth/currentOrganization']
        const isInPublisherOrganization =
          currentOrganization?.organizationType?.name !== 'Saleshouse'

        const form = await import('@/forms/deal.js')
        const defaultForm = isInPublisherOrganization
          ? _cloneDeep(form.metaSSP)
          : _cloneDeep(form.SSP)

        const channelsTypesList = _cloneDeep(
          _get(
            state.configView,
            'sections.DealInfo.Fields.ChannelTypes.Rules.DataList'
          ) || []
        )

        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { impressionsGoal, adPlacement, budgetSpreadTypes, ...dataForm } =
          state.form

        dataForm.channelTypes = channelsTypesList
          .filter(({ IsDefault }) => IsDefault)
          .map(({ Id }) => Id)

        const pmpCampaignTypes = [
          EnumCampaignTypes.PMP_AUDIO,
          EnumCampaignTypes.PMP_VIDEO,
        ]
        const dealType = pmpCampaignTypes.includes(campaign.campaignType)
          ? EnumDealTypes.PMP_GUARANTEED_CAMPAIGN
          : EnumDealTypes.PROGRAMMATIC_GUARANTEED_CAMPAIGN

        dataForm.countryCode = campaign.countryCode
        dataForm.currencyCode = campaign.currencyCode
        dataForm.impressionsGoal = campaign.orderedImpressions
        dataForm.organizationId = currentOrganization?.id
        dataForm.type = dealType
        // hardcode to "VideoEverywhere" for PG Video campaigns & "AudioEverywhere" for others
        dataForm.validFrom = campaign.startDate
        dataForm.validTo = campaign.endDate

        const response = await HTTP.post(
          'DealSetting',
          Object.assign(defaultForm, dataForm)
        )

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { options } = {}) {
      try {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { providersResponse, ...dataForm } = state.form

        dataForm.providers = updateDealMap(dataForm)

        const response = await HTTP.put('DealSetting', dataForm, options)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updateForCampaign(
      { commit, state, rootGetters },
      { campaign, options } = {}
    ) {
      try {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { impressionsGoal, adPlacement, budgetSpreadTypes, ...dataForm } =
          state.form

        const pmpCampaignTypes = [
          EnumCampaignTypes.PMP_AUDIO,
          EnumCampaignTypes.PMP_VIDEO,
        ]
        const dealType = pmpCampaignTypes.includes(campaign.campaignType)
          ? EnumDealTypes.PMP_GUARANTEED_CAMPAIGN
          : EnumDealTypes.PROGRAMMATIC_GUARANTEED_CAMPAIGN

        dataForm.countryCode = campaign.countryCode
        dataForm.currencyCode = campaign.currencyCode
        dataForm.impressionsGoal = campaign.orderedImpressions
        dataForm.organizationId = rootGetters['auth/currentOrganization']?.id
        dataForm.type = dealType
        // hardcode to "VideoEverywhere" for PG Video campaigns & "AudioEverywhere" for others
        // dataForm.flightType = state.form.flightType
        dataForm.validFrom = campaign.startDate
        dataForm.validTo = campaign.endDate
        dataForm.seatId = campaign.seatId
        dataForm.providers = updateDealMap(dataForm)

        const response = await HTTP.put('DealSetting', dataForm, options)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async activate(context, { id }) {
      try {
        const response = await HTTP.post(`DealSetting/save/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async pause(context, { id }) {
      try {
        const response = await HTTP.post(`DealSetting/pause/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async resume(context, { id }) {
      try {
        const response = await HTTP.post(`DealSetting/resume/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit }) {
      try {
        const response = await HTTP.get(`DealSetting`)

        response.data.datas = response.data.datas.map((deal) => {
          return {
            ...deal,
            sspNames: deal.providersResponse.map((ssp) => ssp.name).join(', '),
          }
        })

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    async search({ commit }, { search, fields }) {
      try {
        const response = await HTTP.get(`DealSetting`)

        response.data.datas = searchList(
          response.data.datas,
          fields,
          search
        ).map((item) => {
          return {
            ...item,
            sspNames: item.providersResponse.map((ssp) => ssp.name).join(', '),
          }
        })

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    async fetchPartners(
      { commit },
      { isOpenMarket = false, campaignType = undefined } = {}
    ) {
      try {
        const response = await HTTP.get('Provider/list/programmatic', {
          params: { isOpenMarket, campaignType },
        })

        commit('SET_PARTNERS', response.data.datas)

        return response
      } catch (e) {
        commit('SET_PARTNERS', [])
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    togglePartner({ commit, state }, partner) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: 'form' })
      }

      commit('TOGGLE_PARTNER', partner)
    },

    updatePartner({ commit, state }, partner) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: 'form' })
      }

      commit('UPDATE_PARTNER', partner)
    },

    async setDefaultForm({ commit, rootGetters, state, rootState }) {
      const currentOrganization = rootGetters['auth/currentOrganization']
      const isInPublisherOrganization =
        currentOrganization?.organizationType?.name !== 'Saleshouse'

      const form = await import('@/forms/deal.js')
      const defaultForm = isInPublisherOrganization
        ? _cloneDeep(form.metaSSP)
        : _cloneDeep(form.SSP)

      const channelsTypesList = _cloneDeep(
        _get(
          state.configView,
          'sections.DealInfo.Fields.ChannelTypes.Rules.DataList'
        ) || []
      )

      commit('SET_FORM', {
        ...defaultForm,
        channelTypes: channelsTypesList
          .filter(({ IsDefault }) => IsDefault)
          .map(({ Id }) => Id),

        currencyCode: rootState.organization.defaultCurrency || null,
      })
    },

    setForm({ commit }, deal) {
      commit('SET_FORM', deal)
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      // let stateValue = _cloneDeep(state[valueKey])

      // commit('SET_FORM', _set(stateValue, path, value))
      commit('HANDLE_INPUT', { valueKey, path, value })
    },
  },
}

function updateDealMap(dataForm) {
  return dataForm?.providers?.map((provider) => {
    if (provider.providerId === 72) {
      let { identifierReceivedFromProvider, identifierSentToProvider } =
        provider

      if (identifierReceivedFromProvider.startsWith('d:'))
        identifierReceivedFromProvider =
          identifierReceivedFromProvider.substring(2)

      if (identifierSentToProvider.startsWith('d:'))
        identifierSentToProvider = identifierSentToProvider.substring(2)

      return {
        ...provider,
        identifierReceivedFromProvider: `d:${identifierReceivedFromProvider}`,
        identifierSentToProvider: `d:${identifierSentToProvider}`,
      }
    }
    return provider
  })
}
