<template>
  <div class="tw-w-modal-lg">
    <div class="tw-border-b tw-border-border-light">
      <h2 class="tw-p-6 tw-text-center tw-text-xl tw-font-bold tw-text-black">
        Manage Bundles
      </h2>
      <div
        class="tw-border-t tw-border-border-light tw-p-2 tw-text-sm tw-font-semibold"
      >
        Shared?
      </div>
    </div>

    <div
      v-if="isFetching"
      class="tw-animate-pulse tw-divide-y tw-border-b tw-border-border-light"
    >
      <div class="tw-flex tw-h-16 tw-items-center tw-px-6">
        <div class="tw-h-4 tw-w-12 tw-rounded tw-bg-grey-light"></div>
        <div class="tw-ml-4 tw-h-4 tw-w-2/6 tw-rounded tw-bg-grey-light"></div>
        <div
          class="tw-ml-auto tw-h-4 tw-w-16 tw-rounded tw-bg-grey-light"
        ></div>
      </div>
      <div class="tw-flex tw-h-16 tw-items-center tw-px-6">
        <div class="tw-h-4 tw-w-12 tw-rounded tw-bg-grey-light"></div>
        <div class="tw-ml-4 tw-h-4 tw-w-2/6 tw-rounded tw-bg-grey-light"></div>
        <div
          class="tw-ml-auto tw-h-4 tw-w-16 tw-rounded tw-bg-grey-light"
        ></div>
      </div>
      <div class="tw-flex tw-h-16 tw-items-center tw-px-6">
        <div class="tw-h-4 tw-w-12 tw-rounded tw-bg-grey-light"></div>
        <div class="tw-ml-4 tw-h-4 tw-w-2/6 tw-rounded tw-bg-grey-light"></div>
        <div
          class="tw-ml-auto tw-h-4 tw-w-16 tw-rounded tw-bg-grey-light"
        ></div>
      </div>
    </div>
    <div
      v-else
      class="tw-max-h-128 tw-divide-y tw-divide-solid tw-divide-gray-300 tw-overflow-auto tw-border-b tw-border-border-light"
    >
      <div
        v-if="form.length"
        class="tw-divide-y"
      >
        <div
          v-for="bundle in form"
          :key="bundle.id"
          class="tw-flex tw-max-w-full tw-space-x-2 tw-py-2"
          :class="
            bundle.removed ? 'tw-text tw-bg-gray-200 tw-line-through' : ''
          "
        >
          <div
            class="tw-flex tw-w-16 tw-flex-shrink-0 tw-items-center tw-justify-center"
          >
            <SwitchInput v-model="bundle.isShared" />
          </div>
          <div class="tw-flex tw-items-center tw-justify-center">
            <Icon
              :name="`flag-${bundle.countryCode}`"
              class="tw-h-6 tw-w-6"
            />
          </div>
          <div
            class="tw-w-full tw-truncate"
            @click="handleEdited(bundle.id)"
          >
            <InlineInput
              :title="bundle.name"
              :disabled="bundle.removed"
              @update="handleEdit($event, bundle.id)"
            />
          </div>
          <div
            class="tw-flex tw-w-16 tw-flex-shrink-0 tw-items-center tw-justify-center"
          >
            <button
              :title="bundle.removed ? 'Keep Bundle' : 'Remove Bundle'"
              class="tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-text-gray-600 tw-outline-none hover:tw-text-gray-800"
              type="button"
              @click="handleRemove(bundle.id)"
            >
              <Icon name="trash" />
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-p-6"
      >
        No Bundles available
      </div>
    </div>

    <div
      v-if="isEditing"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      Please finish editing
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        :busy="isLoading"
        variant="primary"
        type="button"
        :label="shouldRetry ? 'Retry' : 'Save'"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>
<script>
import Icon from '@/components/Icon.vue'
import InlineInput from '@/components/Input/InlineInput.vue'
import PassportButton from '@/components/PassportButton.vue'
import SwitchInput from '@/components/Input/SwitchInput.vue'

export default {
  name: 'PopupBundleManage',

  components: {
    Icon,
    InlineInput,
    PassportButton,
    SwitchInput,
  },

  emits: ['close'],
  data() {
    return {
      error: null,
      form: [],
      isLoading: false,
      shouldRetry: false,
      isFetching: false,
      isEditing: false,
    }
  },
  computed: {
    bundlesList() {
      return this.$store.state.bundles.listMine
    },
  },
  async mounted() {
    this.isFetching = true
    await this.$store.dispatch('bundles/fetch')

    this.form = this.bundlesList.map((bundle) => ({
      ...bundle,
      removed: false,
      edited: false,
    }))
    this.isFetching = false
  },
  methods: {
    handleRemove(bundleId) {
      const bundle = this.form.find((bundle) => bundle.id === bundleId)

      bundle.removed = !bundle.removed
    },
    handleEdited(bundleId) {
      const bundle = this.form.find((bundle) => bundle.id === bundleId)

      bundle.edited = true
    },
    handleEdit(newName, bundleId) {
      const bundle = this.form.find((bundle) => bundle.id === bundleId)

      bundle.edited = false

      if (this.form.every((bundle) => !bundle.edited)) {
        this.isEditing = false
      }
      if (newName) {
        bundle.name = newName
      }
    },
    handleEditCancel(bundleId) {
      const bundle = this.form.find((bundle) => bundle.id === bundleId)

      bundle.edited = false
    },
    async handleSubmit() {
      try {
        if (this.form.every((bundle) => !bundle.edited)) {
          this.isLoading = true
          this.isEditing = false

          for (const bundle of this.form) {
            if (bundle.removed) {
              await this.$store.dispatch('bundles/delete', bundle.id)
            } else {
              await this.$store.dispatch('bundles/update', bundle)
            }
          }

          await this.$store.dispatch('bundles/fetch')

          this.$emit('close')

          this.$store.dispatch('addToast', {
            type: 'info',
            value: 'Bundles successfully updated',
            delay: 6000,
          })
        } else {
          this.isEditing = true
        }
      } catch (error) {
        this.shouldRetry = true

        if (error.response?.data?.errors?.length) {
          this.error = error.response.data.errors[0].message
        } else if (error.message) {
          this.error = error.message
        } else {
          this.error = 'An error occurred. Please try again later.'
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
