import { h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { RouterView } from 'vue-router'

export default [
  {
    path: '/:organization?/forecasts',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'Forecasts',
        component: () => import('@/views/forecast/index.vue'),
        meta: {
          title: 'Passport | List of Forecasts',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'create',
        name: 'CreateForecast',
        component: () => import('@/views/forecast/edit.vue'),
        meta: {
          title: 'Passport | Create Forecast',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'edit/:forecast/:tab?',
        name: 'EditForecast',
        component: () => import('@/views/forecast/edit.vue'),
        meta: {
          title: 'Passport | Edit Forecast',
          layout: 'LayoutAuthenticated',
          redirectPath: '/forecasts',
          requiresAuth: true,
        },
        props: (route) => ({ tabSelected: route.params.tab || 'info' }),
      },
    ],
  },
  {
    path: '/:organization?/forecast-magic-token/:uid/:tab?',
    component: () => import('@/views/forecast/magic-token.vue'),
    name: 'ForecastMagicToken',
    meta: {
      title: 'Passport | Forecast Information',
      layout: 'LayoutAuthenticated',
      requiresAuth: false,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
] as RouteRecordRaw[]
