<template>
  <div>
    <button
      class="tw-group tw-z-10 -tw-ml-px -tw-mt-px tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-overflow-hidden tw-rounded tw-rounded-r-none tw-text-sm"
      :class="[`tw-bg-${(item[entity.statusKey] || 'error').toLowerCase()}`]"
      @click.prevent="handleCopyId"
    >
      <div
        class="tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-p-3 tw-text-sm tw-text-white group-hover:tw-bg-overlay-light"
      >
        <div
          class="tw--mx-1 tw-flex tw-h-full tw-items-center tw-justify-center tw-text-center tw-text-xs tw-leading-snug"
        >
          <template v-if="!isIdCopied">
            Copy
            <br />
            Flight ID
          </template>
          <template v-else>ID Copied!</template>
        </div>
        <div
          class="tw-bottom-0 tw-flex tw-w-full tw-flex-shrink-0 tw-items-center tw-justify-between tw-rounded tw-bg-black tw-bg-opacity-50 tw-px-2 tw-py-1"
          :class="[
            `group-hover:tw-bg-${(
              item[entity.statusKey] || 'error'
            ).toLowerCase()}`,
          ]"
        >
          <Icon
            name="plane"
            class="tw-flex-shrink-0"
          />
          <span class="tw-tabular-nums">
            {{ flightId || 'N/A' }}
          </span>
        </div>
      </div>
    </button>
  </div>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'ListCellCampaignWithFlight',
  extends: ListCellDefault,
  components: {
    Icon,
  },
  data() {
    return {
      isIdCopied: false,
    }
  },
  computed: {
    flightId() {
      return (
        (this.item.flightIds &&
          this.item.flightIds.length &&
          this.item.flightIds[0]) ||
        null
      )
    },
  },
  methods: {
    handleCopyId() {
      this.handleClipboardCopy(this.flightId)
      this.isIdCopied = true

      setTimeout(() => (this.isIdCopied = false), 3000)
    },
  },
}
</script>
