<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Do you really want to duplicate this
        {{ entity.singular.toLowerCase() }}?
      </h2>
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        :label="shouldRetry ? 'Retry' : 'Duplicate'"
        :busy="isLoading"
        type="button"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>
<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  components: { PassportButton },
  name: 'PopupEntityDuplicate',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    resolved: {
      type: Function,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      isLoading: false,
      shouldRetry: false,
    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      this.shouldRetry = false

      try {
        const response = await this.$store.dispatch(
          `${this.entity.module}/duplicate`,
          { id: this.item.id }
        )

        this.$store.dispatch('addToast', {
          type: 'info',
          value: `${this.entity.singular} ${
            this.item.id || this.item.id
          } successfully duplicated`,
        })

        if (typeof this.resolved === 'function') {
          await this.resolved(response)
        }

        this.$emit('close')
      } catch (error) {
        console.error({ error })
        this.shouldRetry = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
