import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/:organization?/dashboard/:id?',
    name: 'DashBoard',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      title: 'Passport | Dashboard',
      layout: 'LayoutAuthenticated',
      requiresAuth: true,
    },
  },
] as RouteRecordRaw[]
