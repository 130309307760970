import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import _get from 'lodash/get'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig.js'
import { searchList } from '@/helpers/search'
import { useStoreForecastEstimates } from '@/stores'

const getDefaultState = () => ({
  configView: null,
  current: null,
  form: null,
  isDirty: {
    form: false,
  },
  list: [],
})

export default {
  namespaced: true,

  state: getDefaultState,

  mutations: {
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_BUDGET(state, budget) {
      state.current.budget = budget
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      if (current && current.saleUsers) {
        current.saleUsers = current.saleUsers.map((saleUser) => {
          if (isNaN(saleUser)) {
            return saleUser.userId
          }

          return saleUser
        })
      }

      state.current = current
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      const newForm = _cloneDeep(form)

      if (newForm && newForm.saleUsers) {
        newForm.saleUsers = newForm.saleUsers.map((saleUser) => {
          if (isNaN(saleUser)) {
            return saleUser.userId
          }

          return saleUser
        })
      }

      state.form = newForm
    },
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    async fetchConfigView({ commit }) {
      try {
        const response = await HTTP.get(`FlightForecast/Configview`)

        const parsedConfig = parseConfig(
          response.data.FlightForecastXml,
          'FlightTypeId'
        )

        commit('SET_CONFIG_VIEW', parsedConfig)

        return Promise.resolve()
      } catch (e) {
        console.error(e)
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit, state }, { id, force = true, reload = false }) {
      try {
        if (!force && state.current.id === id) {
          return Promise.resolve(state.current)
        }
        const response = await HTTP.get(`FlightForecast/${id}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        if (reload || !state.form || state.form.id !== id) {
          commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchMagicToken({ commit }, { uid }) {
      try {
        const response = await HTTP.get(`Share/FlightForecast/${uid}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_FORM', response.data.datas)

        const storeForecastEstimates = useStoreForecastEstimates()

        storeForecastEstimates.setForecast(response.data.datas.tecForecast)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_CURRENT', null)
        commit('SET_FORM', null)

        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`FlightForecast/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post('FlightForecast', {
          ...state.form,
          saleUsers: state.form.saleUsers.map((id) => ({ userId: id })),
        })

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { options } = {}) {
      try {
        const response = await HTTP.put(
          'FlightForecast',
          {
            ...state.form,
            saleUsers: state.form.saleUsers.map((id) => ({ userId: id })),
          },
          options
        )

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async duplicate(context, { id }) {
      try {
        const response = await HTTP.post(`FlightForecast/duplicate`, {
          flightForecastId: id,
        })

        return Promise.resolve(response?.data?.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, rootState }) {
      try {
        const countryCode = rootState.auth.user?.countryCode?.toLowerCase()

        const response = await HTTP.get(
          countryCode
            ? `FlightForecast/list/${countryCode}`
            : 'FlightForecast/list'
        )

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async search({ commit, rootState }, { search, fields }) {
      try {
        const countryCode = rootState.auth.user?.countryCode?.toLowerCase()

        const response = await HTTP.get(
          countryCode
            ? `FlightForecast/list/${countryCode}`
            : 'FlightForecast/list'
        )

        response.data.datas = searchList(response.data.datas, fields, search)
        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async confirmConversion(
      { dispatch, rootState },
      { id, newOrder = null, campaignOrderId = null }
    ) {
      try {
        if (newOrder) {
          await dispatch('order/fetchConfigView', {}, { root: true })
          const configView = rootState.order.configView
          const traffickingFeePercentage = Number(
            configView?.fields?.CampaignTraffickingFee?.DefaultValue || 0
          )

          const form = await import('@/forms/order.js')
          const defaultForm = {
            ..._cloneDeep(form.default),
            ...newOrder,
            traffickingFeePercentage,
          }

          const response = await HTTP.post('CampaignOrder', defaultForm)

          campaignOrderId = response.data.datas.id
        }

        const response = await HTTP.post(`FlightForecast/to-order/${id}`, {
          campaignOrderId,
        })

        return Promise.resolve(response)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    async setDefaultForm({ commit, rootState, state }) {
      const form = await import('@/forms/forecast.js')
      const defaultForm = _cloneDeep(form.default)

      const channelsTypesList = _cloneDeep(
        _get(state.configView, 'fields.ChannelTypes.DataList.DataList') || []
      )

      commit('SET_FORM', {
        ...defaultForm,
        channelTypes: channelsTypesList
          .filter(({ IsDefault }) => IsDefault)
          .map(({ Id }) => Id),
        countryCode: rootState.auth.user?.countryCode || null,
        currencyCode: rootState.organization.defaultCurrency || null,
      })
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
