import store from '@/store/index'
import { formatDate } from '@/helpers/date'

const listFilters = localStorage.getItem('deal-list-filters')
const sort = localStorage.getItem('deal-sort')

const entity = {
  module: 'deal',
  key: 'Deal',
  permissionKey: 'Deal',
  productType: 'Programmatic',
  route: {
    index: 'Deals',
    create: 'CreateDeal',
    edit: 'EditDeal',
  },
  param: 'deal',
  singular: 'Deal',
  statusList: ['Draft', 'Pending', 'Ready'],
  plural: 'Deals',
  statusKey: 'dealStatusType',
  mutationsToWatch: ['deal/TOGGLE_PARTNER', 'deal/UPDATE_PARTNER'],
  list: {
    filters: listFilters ? JSON.parse(listFilters) : [],
    loading: true,
    page: 1,
    pagination: {
      perPage: import.meta.env.DEV ? 10 : 50,
      page: 1,
    },
    search: '',
    shouldRetry: false,
    sortKey: sort ? JSON.parse(sort).key : '',
    sortOrder: sort ? JSON.parse(sort).order : 'asc',
    statusKey: 'dealStatusType',
    headers: [
      {
        label: '',
        key: 'dealClientStatusType',
        pages: [1, 2],
        heading: null,
        componentList: 'ListCellDealSwitch',
        size: '70px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-l tw-border-border tw-rounded-l tw-flex tw-justify-center tw-items-center',
        classHeader: '',
      },
      {
        label: 'Status',
        key: 'dealStatusType',
        pages: [1, 2],
        componentHeader: 'ListHeaderStatus',
        componentList: 'ListCellStatus',
        icon: 'plane',
        size: '120px',
        classCell:
          'tw-px-4 tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-l tw-border-border',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Deal ID',
        key: 'name',
        pages: [1, 2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDealName',
        size: 'minmax(100px, 1fr)',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-border tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        get label() {
          const currentOrganization = store.getters['auth/currentOrganization']
          const isInPublisherOrganization =
            currentOrganization?.organizationType?.name !== 'Saleshouse'

          return isInPublisherOrganization ? 'metaSSP' : 'SSP'
        },
        key: 'sspNames',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: 'minmax(100px, 1fr)',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-border tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Start date',
        key: 'validFrom',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'End date',
        key: 'validTo',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDate',
        size: '150px',
        classCell: 'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-l tw-border-r tw-border-border tw-rounded-r',
        classHeader: '',
      },
    ],
    filtersCallbacks: {
      status(item, selectedStatus) {
        return (
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.dealStatusType)
        )
      },
    },
    rowOptions: (item) => [
      ...(['Draft'].includes(item.dealStatusType)
        ? [
            {
              key: 'delete',
              label: 'Delete',
              icon: 'trash',
            },
          ]
        : []),
      {
        key: 'edit',
        label: 'Edit',
        icon: 'edit',
      },
    ],
  },
  moreActions: (item, claims) => {
    if (!item) {
      return []
    }
    const bundles = store.state.bundles.list.filter(
      (bundle) => bundle.countryCode === item.countryCode
    )

    const actions = [
      // ...(['UnPaused'].includes(item.dealClientStatusType) ? [
      //   {
      //     key: 'pause',
      //     label: `Pause`,
      //     icon: 'pause'
      //   }
      // ] : [
      //   {
      //     key: 'resume',
      //     label: `Resume`,
      //     icon: 'play'
      //   }
      // ]),
      // {
      //   key: 'divider',
      //   divider: true
      // },
      {
        key: 'load-bundle',
        label: 'Load Bundle',
        children: bundles.length
          ? bundles.map((bundle) => {
              return {
                key: 'load-bundle',
                label: bundle.name,
                id: bundle.id,
                targetingFilterId: item.targetingFilterId,
                icon: bundle.isShared ? 'gender-both' : null,
                iconTooltip: 'Shared',
              }
            })
          : [{ key: 'no-bundle', label: 'No Bundle available' }],
      },
      ...(claims.indexOf('CreateBundle') >= 0
        ? [
            {
              key: 'save-bundle',
              label: 'Save As Bundle',
            },
            {
              key: 'manage-bundles',
              label: 'Manage Bundles',
            },
          ]
        : []),
      ...(['Draft'].includes(item.dealStatusType)
        ? [
            {
              key: 'divider-3',
              divider: true,
            },
            {
              key: 'delete',
              label: `Delete`,
              icon: 'trash',
              class: 'hover:tw-text-red',
            },
          ]
        : []),
    ]

    return actions
  },
  handleStoreInput(module, path, value, valueKey = 'form') {
    store.dispatch(`${module}/handleInput`, { path, value, valueKey })
  },
  getDescription(item) {
    return `<div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Name</div>
        <div class="tw-text-sm">${item.name}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">Dates</div>
        <div class="tw-text-sm">${formatDate(item.validFrom)} - ${formatDate(
      item.validTo
    )}</div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-semibold tw-text-sm">SSP</div>
        <div class="tw-text-sm">${item.sspNames || 'None'}</div>
      </div>
    </div>`
  },
}

export default entity
