import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig.js'
import { searchList } from '@/helpers/search'

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
  },

  mutations: {
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async fetchConfigView({ commit }, { campaignId }) {
      try {
        const response = await HTTP.get(
          `BudgetGrouping/Configview/Campaign/${campaignId}`
        )

        commit('SET_CONFIG_VIEW', parseConfig(response.data.BudgetGroupingXml))

        return Promise.resolve()
      } catch (e) {
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit, state }, { id }) {
      try {
        const response = await HTTP.get(`BudgetGrouping/${id}`)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        if (!state.form || state.form.id !== id) {
          commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`BudgetGrouping/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post('BudgetGrouping', state.form)

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { options } = {}) {
      try {
        const response = await HTTP.put('BudgetGrouping', state.form, options)

        commit('SET_CURRENT', response.data.datas)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async duplicate(context, { id }) {
      try {
        const response = await HTTP.post(
          `BudgetGrouping/duplicateForCampaign`,
          { flightBudgetId: id }
        )

        return Promise.resolve(response?.data?.datas)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, state }, { id, force = true }) {
      if (!force && state.list.length && state.list[0].campaignId === id) {
        return Promise.resolve(state.list)
      }

      try {
        const response = await HTTP.get(`BudgetGrouping/List/${id}`)

        commit('SET_LIST', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        commit('SET_LIST', [])

        console.error(error)
        return Promise.reject(error)
      }
    },

    async search({ dispatch }, { search }) {
      try {
        const response = await dispatch('list')

        response.data.datas = searchList(response.data.datas, 'name', search)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    discardChanges({ commit, state }) {
      commit('SET_DIRTY', { isDirty: false, key: 'form' })
      commit('SET_FORM', state.current)
    },

    async setDefaultForm({ commit, rootState }) {
      const form = await import('@/forms/campaign-budget-grouping.js')
      const defaultForm = _cloneDeep(form.default)

      commit('SET_FORM', {
        ...defaultForm,
        baseCampaignId: rootState.campaign.current.id,
        hardCostsCurrencyCode: rootState.campaign.current.currencyCode,
      })
    },

    resetForm({ commit }) {
      commit('SET_FORM', null)
    },

    handleInput({ commit, state }, { path, value, valueKey = 'form' }) {
      if (!state.isDirty.form) {
        commit('SET_DIRTY', { isDirty: true, key: valueKey })
      }

      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
