<template>
  <div class="tw-w-modal-lg">
    <h2
      class="tw-border-b tw-border-border-light tw-p-6 tw-text-center tw-text-xl tw-font-bold tw-text-black"
    >
      Duplicate flight
    </h2>
    <div
      v-if="isFetching"
      class="tw-grid tw-h-32 tw-place-content-center"
    >
      <Icon
        name="passport"
        class="preserve-3d tw-animate-flip-x tw-text-3xl tw-text-primary"
      />
    </div>
    <div
      v-if="!isFetching && !isPast"
      class="tw-space-y-4 tw-p-6"
    >
      <div class="tw-grid tw-grid-cols-12 tw-gap-6">
        <div class="tw-col-span-12">
          <div class="tw-text-sm tw-font-semibold">Name</div>
          <div class="tw-text-sm">
            {{ flight.name }}
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-text-sm tw-font-semibold">Ordered Impressions</div>
          <div class="tw-text-sm">
            {{ $filters.formatNumber(flight.orderedImpressions) }}
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-text-sm tw-font-semibold">Gross CPM</div>
          <div class="tw-text-sm">
            {{ $filters.formatCurrency(flight.grossCpm, flight.currencyCode) }}
          </div>
        </div>
      </div>
      <DateRangeInput
        :split-panels="true"
        :start="form.startDate"
        :end="form.endDate"
        :min-date="minDate"
        :max-date="campaign.endDate"
        :mode="EnumDateInputModes.DATE_TIME"
        @update:start="form.startDate = $event"
        @update:end="form.endDate = $event"
      />
      <div class="tw-flex tw-items-center tw-justify-end">
        <InputSelector
          :model-value="isSameDatesAsCampaign"
          :value="true"
          label="Same dates as Campaign"
          @update:model-value="handleSameDatesAsCampaign"
        />
      </div>
    </div>
    <div
      v-else-if="isPast"
      class="tw-p-6"
    >
      You cannot duplicate a flight for a past campaign.
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div
      class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-border-light tw-p-6"
    >
      <PassportButton
        label="cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <PassportButton
        variant="primary"
        :disabled="!campaign || isPast"
        :busy="isLoading"
        type="button"
        :label="shouldRetry ? 'Retry' : 'Duplicate'"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import DateRangeInput from '@/components/Input/DateRangeInput.vue'
import Icon from '@/components/Icon.vue'
import InputSelector from '@/components/InputSelector.vue'
import PassportButton from '@/components/PassportButton.vue'
import {
  addHours,
  addDays,
  differenceInDays,
  format,
  formatISO,
  isBefore,
  parseISO,
  set as setDateValue,
  setSeconds,
  setMinutes,
} from 'date-fns'
import { formats } from '@/helpers/dateFns'
import { EnumDateInputModes } from '@/constants/components'
import { toRoute } from '@/helpers/route'

export default {
  components: {
    DateRangeInput,
    Icon,
    InputSelector,
    PassportButton,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    flight: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      EnumDateInputModes,
      error: null,
      isLoading: false,
      isFetching: true,
      shouldRetry: false,
      form: {
        startDate: null,
        endDate: null,
      },
      isPast: false,
    }
  },
  computed: {
    campaign() {
      return this.$store.state[this.entity.parentModule].current
    },
    minDate() {
      const dateNow = addHours(
        setDateValue(new Date(), { minutes: 0, seconds: 0 }),
        1
      )

      if (isBefore(dateNow, parseISO(this.campaign.startDate))) {
        return this.campaign.startDate
      }

      return formatISO(dateNow)
    },
    isSameDatesAsCampaign() {
      const campaignStartDate =
        this.campaign.startDate && this.campaign.startDate.slice(0, 10)
      const campaignEndDate =
        this.campaign.endDate && this.campaign.endDate.slice(0, 10)

      if (this.form.startDate) {
        if (this.form.startDate.slice(0, 10) !== campaignStartDate) {
          return false
        }
      }

      if (this.form.endDate) {
        if (
          !campaignEndDate ||
          this.form.endDate.slice(0, 10) !== campaignEndDate
        ) {
          return false
        }
      }

      if (!this.form.startDate && !this.form.endDate) {
        return false
      }

      return true
    },
  },
  async mounted() {
    try {
      this.isFetching = true

      const parentId =
        this.entity.parentModule === 'order'
          ? this.flight.campaignOrderId
          : this.flight.campaignId

      await this.$store.dispatch(`${this.entity.parentModule}/fetch`, {
        id: parentId,
      })

      if (isBefore(parseISO(this.campaign.endDate), new Date())) {
        this.isPast = true
        return
      }

      let startDate = parseISO(this.flight.startDate)
      let endDate = parseISO(this.flight.endDate)
      const daysDifference = differenceInDays(endDate, startDate)

      if (isBefore(startDate, new Date())) {
        startDate = setSeconds(setMinutes(addHours(new Date(), 1), 0), 0)
      }

      endDate = addDays(startDate, daysDifference)

      if (isBefore(parseISO(this.campaign.endDate), endDate)) {
        endDate = parseISO(this.campaign.endDate)
      }

      this.form.startDate = format(startDate, formats.ISO.dateWithTime)
      this.form.endDate = format(
        setDateValue(endDate, { hours: 23, minutes: 59, seconds: 59 }),
        formats.ISO.dateWithTime
      )
    } catch (error) {
      console.error(error)
      this.error = this.handleError(
        error,
        'A problem occurred when retrieving the parent campaign'
      )
    } finally {
      this.isFetching = false
    }
  },
  methods: {
    handleSameDatesAsCampaign() {
      let startDate = parseISO(this.campaign.startDate)
      let endDate = parseISO(this.campaign.endDate)

      if (isBefore(startDate, new Date())) {
        startDate = addHours(
          setDateValue(new Date(), { minutes: 0, seconds: 0 }),
          1
        )
        this.$store.dispatch('addToast', {
          type: 'info',
          value: 'The start date has been pushed to today.',
        })
      }
      if (endDate) {
        endDate = setDateValue(endDate, { minutes: 59, seconds: 59, hours: 23 })
      }

      this.form.startDate = format(startDate, formats.ISO.dateWithTime)
      this.form.endDate = format(endDate, formats.ISO.dateWithTime)
    },
    async handleSubmit() {
      try {
        this.shouldRetry = false
        this.isLoading = true
        this.error = null

        const response = await this.$store.dispatch(
          `${this.entity.module}/duplicate`,
          {
            id: this.flight.id,
            startDate: this.form.startDate
              ? this.form.startDate.slice(0, 19)
              : this.form.startDate,
            endDate: this.form.endDate
              ? this.form.endDate.slice(0, 19)
              : this.form.endDate,
          }
        )

        if (response) {
          switch (this.entity.module) {
            case 'orderFlight':
              await this.$router.push(
                toRoute({
                  name: 'EditOrder',
                  params: {
                    order: response.campaignOrderId,
                    orderFlight: response.id,
                  },
                })
              )
              break
            case 'campaignFlight':
              await this.$router.push(
                toRoute({
                  name: 'EditCampaignFlight',
                  params: {
                    campaign: response.data.campaignId,
                    campaignFlight: response.data.id,
                  },
                })
              )
              break
          }
        }

        this.$store.dispatch('addToast', {
          type: 'info',
          value: `${this.entity.singular} ${this.flight.id} successfully duplicated`,
        })
      } catch (error) {
        console.error(error)
        this.shouldRetry = true
        this.error = this.handleError(error)
      } finally {
        this.isLoading = false
        this.$emit('close')
      }
    },
  },
}
</script>
