export default {
  methods: {
    handleError(error, defaultMessage) {
      let result = ''

      if (error.response?.data?.errors?.length) {
        result = error.response.data.errors[0].message
      } else if (error.message) {
        result = error.message
      } else {
        result = defaultMessage || 'An error occurred. Please try again later.'
      }

      return result
    },
  },
}
