import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'user/create',
    component: () => import('@/views/people/user/create.vue'),
    meta: {
      title: 'Passport | Advertiser Creation',
      layout: 'LayoutAuthenticated',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'CreateUser',
        component: () => import('@/views/people/user/tabs/info.vue'),
      },
    ],
  },
  {
    path: 'user/:user',
    component: () => import('@/views/people/user/edit.vue'),
    meta: {
      title: 'Passport | User',
      layout: 'LayoutAuthenticated',
      redirectPath: '/user',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'EditUser',
        redirect: { name: 'EditUserInfo' },
      },
      {
        path: 'info',
        name: 'EditUserInfo',
        component: () => import('@/views/people/user/tabs/info.vue'),
        meta: {
          title: 'Passport | User Details',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'campaigns',
        name: 'EditUserCampaigns',
        component: () => import('@/views/people/user/tabs/campaigns.vue'),
        meta: {
          title: 'Passport | User Campaigns',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
    ],
  },
] as RouteRecordRaw[]
