<script setup lang="ts">
import PassportButton from '@/components/PassportButton.vue'
import { handleError } from '@/helpers/handleError'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

const emit = defineEmits<{ close: [] }>()

const props = defineProps({
  routeTo: {
    type: Object,
    default: null,
  },
  discard: {
    type: Function,
    default: null,
  },
  entity: {
    type: Object,
    required: true,
  },
})

const router = useRouter()

const error = ref<string | null>(null)

const buttons = reactive({
  discard: {
    isLoading: false,
    shouldRetry: false,
  },
})

const handleDiscardChanges = async (): Promise<void> => {
  buttons.discard.isLoading = true
  try {
    if (props.routeTo) {
      router.push(props.routeTo)
    }

    if (props.discard) {
      props.discard()
    }

    emit('close')
  } catch (e) {
    console.error(error)
    buttons.discard.shouldRetry = true

    error.value = handleError(e)
  } finally {
    buttons.discard.isLoading = false
  }
}
</script>

<template>
  <div class="tw-w-modal-lg tw-divide-y tw-divide-border-light tw-text-center">
    <div class="tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        This {{ entity.parentSingular ? `${entity.parentSingular}-` : ''
        }}{{ entity.singular }} has not been created
      </h2>
    </div>
    <div class="tw-p-6 tw-leading-loose">
      This {{ entity.singular }} has not been submitted, if you leave now, it'll
      be lost.
      <br />
      Are you sure you want to leave ?
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        variant="stroke"
        :busy="buttons.discard.isLoading"
        label="No"
        @@click="emit('close')"
      />
      <PassportButton
        variant="primary"
        :busy="buttons.discard.isLoading"
        label="Yes, Leave"
        @@click="handleDiscardChanges"
      />
    </div>
  </div>
</template>
