<template>
  <div
    class="form-input-group"
    v-bind="$parentAttrs"
  >
    <label
      :class="{
        'with-error': withError,
        filled: !!modelValue || modelValue === 0,
      }"
    >
      <input
        v-bind="$childAttrs"
        class="input read-only:tw-cursor-not-allowed"
        :class="[targetClass, { 'with-error': withError }]"
        :value="
          focused || formattedValue === null ? modelValue : formattedValue
        "
        :placeholder="defaultPlaceholder"
        :required="required"
        :type="type"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
      />

      <div
        v-if="label"
        class="label"
      >
        {{ label }}
        <sup class="tw-text-red">{{ required ? '*' : '' }}</sup>
        <InfoTips
          v-if="infoTips"
          :info="infoTips"
          class="tw-z-50 tw-ml-1 tw-align-text-top"
        />
      </div>
    </label>
  </div>
</template>

<script>
import attrsMixin from '@/mixins/attrs.js'
import _round from 'lodash/round'
import InfoTips from '@/components/InfoTips.vue'

export default {
  name: 'TextInput',
  mixins: [attrsMixin],
  components: {
    InfoTips,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    infoTips: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, null],
      default: null,
    },
    formattedValue: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    precision: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    targetClass: {
      type: [Array, String, Object],
      default: null,
    },
    state: {
      type: String,
      default: 'initial',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      defaultPlaceholder: this.placeholder ? this.placeholder : this.label,
      focused: false,
    }
  },

  computed: {
    withError() {
      return this.state === 'error'
    },
  },
  methods: {
    handleInput(e) {
      if (this.type === 'number') {
        const value =
          (e.target.value &&
            Number.parseFloat(e.target.value.replace(',', '.'))) ||
          0

        this.$emit('update:modelValue', _round(value, this.precision))
      } else {
        this.$emit('update:modelValue', e.target.value)
      }
    },
    handleFocus() {
      this.focused = true
    },
    handleChange() {
      this.focused = false
    },
    handleBlur() {
      this.focused = false
    },
  },
}
</script>
