export default {
  inheritAttrs: false,
  computed: {
    $childAttrs() {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { class: className, style, ...$childAttrs } = this.$attrs

      return $childAttrs
    },
    $parentAttrs() {
      const { class: className, style } = this.$attrs

      return {
        class: className,
        style,
      }
    },
  },
}
