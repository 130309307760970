import HTTP from '@/services/http'
import store from '@/store/index'
import router from '@/router/index.ts'

const createHttpRefreshTokenInterceptor = function () {
  HTTP.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.config || !error.response) {
        return Promise.reject(error)
      }

      if (error.response.status === 401) {
        if (!store.state.auth.refreshToken) {
          return Promise.reject(error)
        }

        const originalRequest = error.config

        if (originalRequest.url.includes('Account/login')) {
          console.debug(
            'Refresh Token Request Failed. Logout & Redirect to Login'
          )

          router.push({
            name: 'Login',
            query: { redirect: router.currentRoute.value.fullPath },
          })

          return Promise.reject(error)
        }

        if (store.state.auth.refreshing) {
          if (['get', 'head', 'options'].includes(error.config.method)) {
            setTimeout(() => {
              console.debug('Refreshing Token, retry request in 500ms')
              HTTP(originalRequest)
            }, 500)
          }
        }

        if (!originalRequest._retry) {
          originalRequest._retry = true

          // HTTP.interceptors.response.eject(responseInterceptor) // Prevent looping into the same interceptor

          try {
            await store.dispatch('auth/refresh')

            originalRequest.headers.Authorization =
              'Bearer ' + store.state.auth.accessToken
            originalRequest.withoutErrors = true

            return HTTP(originalRequest)
          } catch (error) {
            // The refresh Token is invalid
            await store.dispatch('auth/logout')
            store.dispatch('addToast', {
              type: 'error',
              value: 'Your session expired. Please login again.',
            })

            router.push({
              name: 'Login',
              query: { redirect: router.currentRoute.value.fullPath },
            })

            return Promise.reject(error)
          }
        }
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export default createHttpRefreshTokenInterceptor
