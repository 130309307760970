export enum EnumI18nLocation {
  EUROPE = 'EU',
  USA = 'US',
}

export const optionsNumberFormats = [
  { value: EnumI18nLocation.EUROPE, label: 'Europe (100 000,00 €)' },
  { value: EnumI18nLocation.USA, label: 'USA ($ 100,000.00)' },
]

export const optionsDateFormats = [
  { value: EnumI18nLocation.EUROPE, label: 'Europe' },
  { value: EnumI18nLocation.USA, label: 'USA' },
]

export enum EnumLocalesIso {
  EU = 'fr-FR',
  US = 'en-US',
}
