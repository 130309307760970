import type { RouteRecordRaw } from 'vue-router'

import store from '@/store/index'

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/login.vue'),
    meta: {
      title: 'Passport | Log in to Passport',
      requiresGuest: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/auth/login.vue'),
    meta: {
      title: 'Passport | Log out from Passport',
    },
    async beforeEnter(_to, _from, next) {
      await store.dispatch('auth/logout')

      next({ name: 'Login' })
    },
  },
  {
    path: '/reset-password/:token?',
    name: 'ResetPassword',
    component: () => import('@/views/auth/reset-password.vue'),
    meta: {
      title: 'Passport | Reset your password',
      requiresGuest: true,
    },
  },
  {
    path: '/set-password/:token?',
    name: 'SetPassword',
    component: () => import('@/views/auth/set-password.vue'),
    meta: {
      title: 'Passport | Set your password',
      requiresGuest: true,
    },
  },
  {
    path: '/:organization?/switch-organization/:orgFrom/:orgTo',
    name: 'SwitchOrganization',
    component: () => import('@/views/auth/switch-organization.vue'),
    meta: {
      title: 'Passport | Switch Organization',
      layout: 'LayoutGuest',
      requiresAuth: true,
    },
  },
] as RouteRecordRaw[]
