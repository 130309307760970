<script setup lang="ts">
import { ref, watch } from 'vue'

const emit = defineEmits<{ 'update:modelValue': [boolean] }>()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  popupWidth: {
    type: String,
    default: '',
  },
  zIndex: {
    type: Number,
    default: 9999,
  },
})

const transition = ref(false)
const visibility = ref(false)

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      visibility.value = true
      setTimeout(() => {
        transition.value = true
      })
    } else {
      transition.value = false

      setTimeout(() => {
        visibility.value = false
      }, 200)
    }
  },
  { immediate: true }
)

const handleClose = (): void => {
  emit('update:modelValue', false)
}
</script>

<template>
  <Teleport to="body">
    <div
      role="dialog"
      class="tw-modal tw-relative"
      :style="`z-index: ${props.zIndex};`"
    >
      <div
        v-if="visibility"
        class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center"
      >
        <Transition
          enter-active-class="tw-transition-opacity tw-duration-200 tw-ease-in-out"
          enter-from-class="tw-opacity-0"
          enter-to-class="tw-opacity-100"
          leave-active-class="tw-transition-opacity tw-duration-200 tw-ease-in-out"
          leave-from-class="tw-opacity-100"
          leave-to-class="tw-opacity-0"
        >
          <div
            v-if="transition"
            class="tw-absolute tw--z-1 tw-h-full tw-w-full tw-bg-black tw-bg-opacity-25"
            @click="handleClose"
          />
        </Transition>
        <Transition
          enter-active-class="tw-transition tw-duration-200 tw-ease-in-out tw-transform"
          enter-from-class="tw-opacity-0 tw-scale-90"
          enter-to-class="tw-opacity-100 tw-scale-100"
          leave-active-class="tw-transition tw-duration-200 tw-ease-in-out tw-transform"
          leave-from-class="tw-opacity-100 tw-scale-100"
          leave-to-class="tw-opacity-0 tw-scale-90"
        >
          <div
            v-if="transition"
            :class="[props.popupWidth]"
            class="tw-rounded tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5"
          >
            <slot />
          </div>
        </Transition>
      </div>
    </div>
  </Teleport>
</template>
