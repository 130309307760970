import HTTP from '@/services/http'
import store from '@/store/index'

const createHttpLoaderInterceptor = function () {
  HTTP.interceptors.request.use(
    (config) => {
      if (config.executeInBackground !== true) {
        store.commit('loader/START_LOADING')
      }

      return config
    },
    (error) => {
      if (error.config && error.config.executeInBackground !== true) {
        store.commit('loader/FINISH_LOADING')
      }

      return Promise.reject(error)
    }
  )

  HTTP.interceptors.response.use(
    (response) => {
      if (response?.config?.executeInBackground !== true) {
        store.commit('loader/FINISH_LOADING')
      }

      return response
    },
    (error) => {
      if (error.config && error.config.executeInBackground !== true) {
        store.commit('loader/FINISH_LOADING')
      }

      return Promise.reject(error)
    }
  )
}

export default createHttpLoaderInterceptor
