<template>
  <div class="tw-w-modal-xl">
    <div
      class="tw-my-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <Icon
        name="wand"
        class="tw-text-5xl tw-text-alto"
      />
      <h2 class="mt-8 tw-text-xl tw-text-black">
        <template v-if="magicToken">
          Congratulations, the link has been generated
        </template>
        <template v-else>Generate a Campaign Report Magic Token</template>
      </h2>
    </div>

    <div v-if="magicToken">
      <div class="tw-mb-8 tw-px-6">
        <div class="tw-relative tw-flex tw-h-16 tw-max-w-full tw-items-center">
          <input
            ref="copyInput"
            :value="magicTokenLink"
            readonly
            class="tw-flex tw-h-16 tw-w-full tw-rounded-l tw-border tw-border-r-0 tw-border-border-light tw-pl-6 tw-outline-none"
            @focus="handleCopy"
          />
          <button
            type="button"
            class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-r tw-bg-primary hover:tw-bg-opacity-75"
            @click="handleCopy"
          >
            <Icon
              v-if="copied"
              name="check"
              class="tw-text-white"
            />
            <Icon
              v-else
              name="copy"
              class="tw-text-white"
            />
          </button>
        </div>
      </div>

      <div class="tw-mb-8 tw-px-6">
        <p
          v-if="magicToken.expiryDate.includes('9999-12-31')"
          class="tw-mt-4 tw-text-sm tw-text-grey"
        >
          Anyone with this link can view the report.
        </p>
        <p
          v-else
          class="tw-mt-4 tw-text-sm tw-text-grey"
        >
          Anyone with this link can view the campaign report until:
          <span class="tw-font-semibold">
            {{ $filters.dateWithDay(magicToken.expiryDate) }}
          </span>
        </p>
      </div>
    </div>
    <div v-else>
      <div class="tw-mb-4 tw-px-6">
        <div
          class="tw-flex tw-h-12 tw-max-w-full tw-items-center tw-truncate tw-rounded tw-border tw-border-border-light"
        >
          <div
            class="tw-grid tw-h-full tw-w-12 tw-place-content-center tw-border-r tw-border-border-light"
          >
            <Icon
              name="stats"
              class="tw-flex-shrink-0 tw-text-grey-light"
            />
          </div>
          <div class="tw-flex tw-items-center tw-gap-2 tw-truncate tw-px-6">
            <div class="tw-flex-none tw-font-semibold tw-text-grey-light">
              id: {{ item.id }}
            </div>
            <h3 class="tw-truncate tw-font-semibold">
              {{ item.name }}
            </h3>
          </div>
        </div>
      </div>

      <div class="tw-max-h-(screen-24) tw-min-h-96 tw-overflow-y-scroll">
        <div class="tw-mb-8 tw-px-6">
          <h4
            class="tw-text-md tw-border-b tw-border-black tw-font-bold tw-leading-8 tw-text-black"
          >
            Expiration
          </h4>
          <p class="tw-mt-4 tw-text-sm tw-text-grey">
            Date for this link to expire
          </p>
          <div class="tw-flex tw-items-center tw-justify-between tw-pt-5">
            <InputSelector
              v-for="preset of optionsPresets"
              :key="preset.value"
              v-model="form.preset"
              :value="preset.value"
              :label="preset.label"
              name="preset"
            />
            <div class="tw-flex tw-items-center tw-gap-2">
              <InputSelector
                v-model="form.preset"
                value="CUSTOM"
                name="preset"
              />
              <DateInput
                v-model="form.customDate"
                :with-button-apply="true"
                :min-date="new Date().toISOString()"
                label="Expiration on"
                class="-tw-mt-6 tw-w-64"
                @update:model-value="
                  ($event) =>
                    $event ? (form.preset = 'CUSTOM') : (form.preset = null)
                "
              />
            </div>
          </div>
        </div>
        <div class="tw-mb-8 tw-px-6">
          <h4
            class="tw-text-md tw-border-b tw-border-black tw-font-bold tw-leading-8 tw-text-black"
          >
            Type of report
          </h4>
          <p class="tw-mt-4 tw-text-sm tw-text-grey">Type of report to share</p>
          <div
            class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-pl-4 tw-pt-5"
          >
            <InputSelector
              v-model="form.withPublisherData"
              label="Limited report (without publishers report)"
              :value="false"
            />
            <InputSelector
              v-model="form.withPublisherData"
              label="Full report"
              :value="true"
            />
          </div>
        </div>
        <div class="tw-mb-8 tw-px-6">
          <div
            class="tw-text-md tw-flex tw-justify-between tw-border-b tw-border-black tw-font-bold tw-text-black"
          >
            <h4 class="tw-leading-8">Budget Data</h4>
            <div class="tw-mb-1.5 tw-self-end">
              <SwitchInput v-model="form.withBudgetData" />
            </div>
          </div>
          <p class="tw-mt-4 tw-text-sm tw-text-grey">
            Hide/Show Amount, CPM, Discount
          </p>
        </div>
        <div
          v-for="section of listSections"
          :key="section.formKey"
          class="tw-mb-8 tw-px-6"
        >
          <label
            class="tw-text-md tw-flex tw-justify-between tw-border-b tw-border-black tw-font-bold tw-text-black"
          >
            <h4 class="tw-leading-8">{{ section.name }}</h4>
            <div class="tw-mb-1.5 tw-self-end">
              <SwitchInput v-model="form[section.formKey]" />
            </div>
          </label>
          <p class="tw-mt-4 tw-text-sm tw-text-grey">
            Hide/show {{ section.name }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-border-light tw-p-6"
    >
      <PassportButton
        variant="basic"
        label="Cancel"
        size="small"
        @@click="() => $emit('close')"
      />
      <PassportButton
        v-if="magicToken"
        :to="
          toRoute({ name: 'MagicTokens', params: { tab: 'campaign-report' } })
        "
        variant="stroke"
        size="small"
        label="Show Links List"
        @@click="() => $emit('close')"
      />
      <PassportButton
        v-else
        :busy="isLoading"
        variant="primary"
        :label="shouldRetry ? 'Retry' : 'Generate Link'"
        type="button"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>
<script>
import { addDays, format } from 'date-fns'
import { formats } from '@/helpers/dateFns'

import DateInput from '@/components/Input/DateInput.vue'
import Icon from '@/components/Icon.vue'
import InputSelector from '@/components/InputSelector.vue'
import PassportButton from '@/components/PassportButton.vue'
import SwitchInput from '@/components/Input/SwitchInput.vue'

export default {
  name: 'PopupMagicTokenCampaignReport',
  components: {
    DateInput,
    Icon,
    InputSelector,
    PassportButton,
    SwitchInput,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      copied: false,
      form: {
        customDate: null,
        preset: null,
        withAdPlacementData: true,
        withBudgetData: true,
        withCarrierData: true,
        withChannelData: true,
        withCreativeData: true,
        withDeviceData: true,
        withFlightTypeData: true,
        withFormatData: true,
        withGenderReport: true,
        withGeoData: true,
        withImpressionData: true,
        withKVPData: true,
        withPublisherData: true,
        withRelatedFlightAndBudgetGroupingData: true,
      },
      magicToken: null,
      isLoading: false,
      optionsPresets: [
        { value: null, label: 'Never' },
        { value: 7, label: '7 days' },
        { value: 30, label: '30 days' },
      ],
      shouldRetry: false,
      step: 'settings',
    }
  },
  computed: {
    magicTokenLink() {
      if (!this.magicToken) {
        return null
      }
      return (
        window.location.origin +
        this.$router.resolve(
          this.toRoute({
            name: 'CampaignReportMagicToken',
            params: { campaign: this.magicToken.uid },
          })
        ).href
      )
    },
    listSections() {
      return [
        { formKey: 'withAdPlacementData', name: 'Ad Placement data' },
        // { formKey: 'withBudgetData', name: 'Budget' },
        { formKey: 'withCarrierData', name: 'Carrier data' },
        { formKey: 'withChannelData', name: 'Channels Types data' },
        { formKey: 'withCreativeData', name: 'Creatives data' },
        { formKey: 'withDeviceData', name: 'Devices data' },
        // { formKey: 'withFlightTypeData', name: 'Flight Types data' },
        { formKey: 'withFormatData', name: 'Format data' },
        { formKey: 'withImpressionData', name: 'Impressions data' },
        { formKey: 'withGenderReport', name: 'Gender/Age report' },
        { formKey: 'withGeoData', name: 'Geo-localized Distribution data' },
        { formKey: 'withKVPData', name: 'KVP data' },
        { formKey: 'withPublisherData', name: 'Publisher data' },
        {
          formKey: 'withRelatedFlightAndBudgetGroupingData',
          name: 'Related Flights and Budget Groupings',
        },
      ]
    },
  },
  methods: {
    handleCopy() {
      this.$refs.copyInput?.select()
      document.execCommand('copy')
      this.copied = true
      this.$refs.copyInput?.blur()

      setTimeout(() => {
        this.copied = false
      }, 2000)
    },
    async handleSubmit() {
      this.isLoading = true
      this.shouldRetry = false

      let expiryDate = null

      if (this.form.preset === 7) {
        expiryDate = format(addDays(new Date(), 7), formats.ISO.date)
      } else if (this.form.preset === 30) {
        expiryDate = format(addDays(new Date(), 30), formats.ISO.date)
      } else if (this.form.customDate) {
        expiryDate = this.form.customDate
      } else {
        expiryDate = '9999-12-31'
      }

      try {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { customDate, preset, ...configSections } = this.form
        const payload = {
          expiryDate,
          campaignId: this.item.id,
          ...configSections,
        }

        const response = await this.$store.dispatch(
          'magicToken/createCampaignReportMagicToken',
          { payload }
        )

        this.magicToken = response.data.datas
      } catch (error) {
        this.shouldRetry = true
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
