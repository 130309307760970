export default (values, contentType) => {
  if (values.length === 0) {
    return null
  }

  const transformedForm = []
  const includedItems = values.filter((item) => item.IsIncluded === true)
  const excludedItems = values.filter((item) => item.IsIncluded === false)

  if (includedItems.length) {
    transformedForm.push({
      Order: transformedForm.length + 1,
      ContentType: 'Group',
      IsIncluded: true,
      Values: includedItems.reduce((Values, item, index) => {
        if (index > 0) {
          Values.push({
            Order: Values.length + 1,
            ContentType: 'Operator',
            Value: 'Or',
          })
        }

        Values.push({
          Order: Values.length + 1,
          ContentType: contentType,
          Value: item,
        })

        return Values
      }, []),
    })
  }

  if (excludedItems.length) {
    if (transformedForm.length) {
      transformedForm.push({
        Order: transformedForm.length + 1,
        ContentType: 'Operator',
        Value: 'And',
      })
    }

    transformedForm.push({
      Order: transformedForm.length + 1,
      ContentType: 'Operator',
      Value: 'Not',
    })

    transformedForm.push({
      Order: transformedForm.length + 1,
      ContentType: 'Group',
      IsIncluded: false,
      Values: excludedItems.reduce((Values, item, index) => {
        if (index > 0) {
          Values.push({
            Order: Values.length + 1,
            ContentType: 'Operator',
            Value: 'Or',
          })
        }

        Values.push({
          Order: Values.length + 1,
          ContentType: contentType,
          Value: item,
        })

        return Values
      }, []),
    })
  }

  return JSON.stringify({
    IsAdvanced: false,
    Values: transformedForm,
  })
}
