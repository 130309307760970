<template>
  <Popover class="tw-relative">
    <PopoverButton
      class="tw-relative tw-flex tw-h-12 tw-w-10 tw-select-none tw-items-center tw-justify-center tw-text-xl tw-text-grey hover:tw-text-white"
      @click="fetchData"
    >
      <Icon
        name="bell"
        class="tw-text-2xl"
      />
      <span
        v-if="notifications.countUnread"
        class="tw-absolute tw-right-0 tw-top-0.5 tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-red tw-text-2xs tw-font-medium tw-text-white"
      >
        {{ notifications.countUnread }}
      </span>
    </PopoverButton>
    <transition
      enter-active-class="tw-transition tw-duration-200 tw-ease-out"
      enter-from-class="tw-translate-y-1 tw-opacity-0"
      enter-to-class="tw-translate-y-0 tw-opacity-100"
      leave-active-class="tw-transition tw-duration-150 tw-ease-in"
      leave-from-class="tw-translate-y-0 tw-opacity-100"
      leave-to-class="tw-translate-y-1 tw-opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="tw-absolute tw-right-0 tw-z-50 tw-origin-top-right tw-transform tw-rounded-md tw-bg-white tw-leading-tight tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
      >
        <div class="tw-w-128">
          <div
            class="tw-flex tw-items-end tw-justify-between tw-border-b tw-border-border-light tw-p-4"
          >
            <div class="tw-text-sm tw-font-medium">Notifications</div>
            <button
              v-if="notifications.countUnread"
              class="tw-text-xs tw-text-blue hover:tw-underline"
              :disabled="isLoading"
              @click="handleMarkAllAsRead"
            >
              Mark all as read
            </button>
          </div>
          <div
            class="tw-flex tw-items-center tw-border-b tw-border-border-light tw-p-2"
          >
            <Icon
              name="search"
              class="tw-mx-2 tw-w-3"
            />
            <input
              v-model="search"
              autofocus
              class="input tw-w-full tw-p-1 tw-text-xs tw-outline-none"
              placeholder="Search Notifications"
              type="search"
              @input="handleSearch"
            />
          </div>
          <ComponentFetchable :is-loading="isLoading">
            <div
              class="tw-h-96 tw-max-h-96 tw-overflow-auto tw-overscroll-contain"
              :class="isLoading ? 'tw-bg-alto-light' : ''"
            >
              <div
                v-if="!isLoading && !shouldRetry && items.length === 0"
                class="tw-flex tw-h-full tw-items-center tw-justify-center tw-text-center tw-text-sm tw-text-grey"
              >
                No notifications found
              </div>
              <div class="tw-divide-y tw-divide-border">
                <div
                  v-for="notification in items"
                  :key="notification.id"
                  class="tw-group tw-flex tw-justify-between tw-text-sm hover:tw-bg-alto-light"
                >
                  <div
                    class="tw-relative tw-flex tw-h-auto tw-flex-none tw-items-center tw-justify-center tw-border-l-3 tw-pl-3 tw-pr-2 tw-text-lg"
                    :class="{
                      'tw-border-warning tw-text-warning':
                        notification.level === 'Warn',
                      'tw-border-red tw-text-red':
                        notification.level === 'Error',
                      'tw-border-primary tw-text-primary':
                        notification.level === 'Info',
                    }"
                  >
                    <Icon :name="getIconName(notification)" />
                  </div>
                  <div
                    class="tw-break-word tw-flex-auto tw-px-2 tw-py-2 tw-text-xsm"
                  >
                    <div
                      class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1 tw-font-bold"
                    >
                      <span v-if="notification.title">
                        {{ notification.title }}
                      </span>
                    </div>
                    <template
                      v-for="(node, index) in getNotificationsInfos(
                        notification
                      )"
                      :key="index"
                    >
                      <span
                        v-if="'link' in node && node.link"
                        v-tooltip="{
                          content: node.title,
                          theme: 'break-words',
                        }"
                        class="tw-clear-both tw-cursor-pointer tw-font-semibold tw-text-blue hover:tw-underline"
                        @click="
                          () => {
                            handleClickLink(node, notification)
                            close()
                          }
                        "
                      >
                        {{ node.text }}
                      </span>
                      <span
                        v-else
                        class="tw-clear-both"
                      >
                        {{ node.text }}
                      </span>
                    </template>
                    <br />
                    <span
                      v-tooltip="
                        `${
                          notification.created &&
                          formatDateWithTime(notification.created)
                        }`
                      "
                      class="tw-cursor-default tw-text-2xs tw-text-grey"
                    >
                      {{
                        notification.created &&
                        $filters.timeAgo(notification.created)
                      }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-flex-none tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-p-3"
                  >
                    <button
                      v-tooltip="`Dismiss`"
                      class="tw--m-2 tw-p-2 tw-text-grey-medium hover:tw-text-blackest"
                      :disabled="isLoading"
                      @click="handleDismiss(notification)"
                    >
                      <Icon
                        class="tw-text-sm"
                        name="delete"
                      />
                    </button>
                    <button
                      v-tooltip="
                        `Mark as ${notification.readAt ? 'unread' : 'read'}`
                      "
                      class="tw-h-5 tw-w-5 tw-rounded-full tw-p-1.5 group-hover:tw-bg-alto"
                      :disabled="isLoading"
                      @click="toggleReadStatus(notification)"
                    >
                      <div
                        v-if="!notification.readAt"
                        class="tw-h-full tw-w-full tw-rounded-full tw-bg-blue"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="shouldRetry"
                class="tw-flex tw-w-full tw-items-center tw-justify-center tw-text-center tw-text-sm tw-text-grey"
                :class="
                  items?.length
                    ? 'tw-h-16 tw-border-t tw-border-border'
                    : 'tw-h-full'
                "
              >
                An error occurred while loading
                {{ items?.length ? 'more notifications' : 'notifications' }}
                <button
                  class="tw-ml-2 tw-font-medium tw-text-black tw-underline"
                  @click="fetchData({ index: notifications.paging.index + 1 })"
                >
                  Retry.
                </button>
              </div>
              <LoadMore
                v-if="items.length && !shouldRetry"
                class="tw-border-t tw-border-border"
                :loading="isLoading"
                :count="notifications.paging.total"
                :pagination="{
                  perPage: notifications.paging.size,
                  page: notifications.paging.index + 1,
                }"
                @nextpage="fetchData({ index: notifications.paging.index + 1 })"
              />
            </div>
          </ComponentFetchable>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import _debounce from 'lodash/debounce'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import LoadMore from '@/components/list/load-more.vue'
import { formatDateWithTime } from '@/helpers/date'
import ComponentFetchable from '@/components/ComponentFetchable.vue'
import Icon from '@/components/Icon.vue'

export default {
  components: {
    ComponentFetchable,
    Icon,
    LoadMore,
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  data() {
    return {
      formatDateWithTime,
      isLoading: true,
      isVisible: false,
      search: '',
      shouldRetry: false,
    }
  },
  computed: {
    items() {
      return this.$store.state.notifications.list
    },
    notifications() {
      return this.$store.state.notifications
    },
  },
  methods: {
    _resetState() {
      this.$store.dispatch(`notifications/resetState`)
    },
    async handleClickLink(node, notification) {
      this.$store.dispatch(`notifications/setStatus`, {
        status: 'read',
        notificationIds: [notification.id],
      })

      await this.$router.push(node.link)
    },
    async handleDismiss({ id }) {
      await this.$store.dispatch(`notifications/setStatus`, {
        status: 'dismissed',
        notificationIds: [id],
      })
    },
    async handleMarkAllAsRead() {
      await this.$store.dispatch(`notifications/setAllStatus`, {
        status: 'read',
      })
      this.getCountUnread()
    },
    async toggleReadStatus(notification) {
      const { id } = notification

      if (notification.readAt) {
        await this.$store.dispatch(`notifications/setStatusUnread`, { id })
      } else {
        await this.$store.dispatch(`notifications/setStatus`, {
          status: 'read',
          notificationIds: [id],
        })
      }

      this.getCountUnread()
    },
    async fetchData({ index = 0 } = {}) {
      try {
        this.isLoading = true
        this.shouldRetry = false

        if (this.search.length > 2) {
          this.$store.dispatch(`notifications/search`, {
            search: this.search,
            index,
          })
        } else {
          await this.$store.dispatch(`notifications/list`, { index })
        }
      } catch (error) {
        this.shouldRetry = true
      } finally {
        this.isLoading = false
      }
    },
    getNotificationsInfos({ description, data = {} }) {
      if (!description) return

      const keys = [...description.matchAll(/:(\w)+.(\w)*:/g)]

      if (!keys.length) return [{ text: description }]

      const results = []

      keys.forEach(([key], index) => {
        const dataKey = key.replaceAll(':', '').trim()
        const [text, endText] = description.split(key)

        description = endText

        if (!data || !data[dataKey]) {
          results.push({ text }, { text: key })
        } else {
          results.push(
            { text },
            ...[
              this.getLink(data, dataKey)
                ? {
                    text: `"${
                      data[dataKey]?.label.trim().slice(0, 15) || 'unknown'
                    }${data[dataKey]?.label.length > 15 ? '...' : ''}"`,
                    title: data[dataKey]?.label.trim() || '',
                    link: this.getLink(data, dataKey),
                  }
                : {
                    text: `"${
                      data[dataKey]?.label.trim().slice(0, 15) || 'unknown'
                    }${data[dataKey]?.label.length > 15 ? '...' : ''}"`,
                  },
            ]
          )
        }

        if (keys.length === index + 1) {
          results.push({ text: endText })
        }
      })

      return results
    },
    getCountUnread: _debounce(function () {
      this.$store.dispatch(`notifications/getCountUnread`)
    }, 300),
    getLink(data, dataKey) {
      try {
        const { type, id } = data[dataKey]
        const { organization } = data

        if (!organization || isNaN(id) || !type) return

        switch (type) {
          case 'FORECAST': {
            return {
              name: 'EditForecast',
              params: {
                forecast: id,
                organization: organization.id,
              },
            }
          }

          case 'DEAL': {
            return {
              name: 'EditDeal',
              params: {
                deal: id,
                organization: organization.id,
              },
            }
          }

          case 'CAMPAIGN': {
            return {
              params: {
                campaign: id,
                organization: organization.id,
              },
            }
          }

          case 'CAMPAIGN_FLIGHT': {
            const { campaign } = data

            if (!campaign) return

            return {
              name: 'EditCampaignFlight',
              params: {
                campaign: campaign.id,
                campaignFlight: id,
                organization: organization.id,
              },
            }
          }

          case 'FLIGHT_ORDER': {
            const { campaignOrder } = data

            if (!campaignOrder) return

            return {
              name: 'EditOrderFlight',
              params: {
                order: campaignOrder.id,
                orderFlight: id,
                organization: organization.id,
              },
            }
          }

          case 'FLIGHT_REVISION': {
            const { campaignOrder, flightOrder, flightRevision } = data

            if (!campaignOrder || !flightRevision) return

            return {
              name: 'OrderFlightRevision',
              params: {
                order: campaignOrder.id,
                flightId: flightOrder.id,
                organization: organization.id,
                revisionId: flightRevision.id,
              },
            }
          }

          case 'CAMPAIGN_REPORT': {
            return {
              name: 'CampaignReport',
              params: {
                campaign: id,
                organization: organization.id,
              },
            }
          }

          case 'CAMPAIGN_FLIGHT_REPORT': {
            const { campaign } = data

            if (!campaign) return

            return {
              name: 'CampaignReport',
              params: {
                campaign: campaign.id,
                organization: organization.id,
              },
              query: {
                'filters[flightIds]': id,
              },
            }
          }

          case 'CAMPAIGN_ORDER': {
            return {
              name: 'EditOrder',
              params: {
                order: id,
                organization: organization.id,
              },
            }
          }

          case 'USER': {
            return {
              name: 'EditUser',
              params: {
                organization: organization.id,
                user: id,
              },
            }
          }
        }
      } catch (error) {
        return
      }
    },
    getIconName(notification) {
      const label = notification.data?.icon?.label

      switch (label) {
        case 'CAMPAIGN_FLIGHT':
        case 'FLIGHT_ORDER':
          return 'plane'
        case 'FLIGHT_REVISION':
          return 'revision'
        default:
          return 'megaphone'
      }
    },
    handleSearch: _debounce(function () {
      this.fetchData({ index: 0, initializing: true })
    }, 500),
  },
}
</script>
