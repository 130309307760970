import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'advertiser/create',
    component: () => import('@/views/people/advertiser/create.vue'),
    meta: {
      title: 'Passport | Advertiser Creation',
      layout: 'LayoutAuthenticated',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'CreateAdvertiser',
        component: () => import('@/views/people/advertiser/tabs/info.vue'),
      },
    ],
  },
  {
    path: 'advertiser/:advertiser',
    component: () => import('@/views/people/advertiser/edit.vue'),
    meta: {
      title: 'Passport | Advertiser',
      layout: 'LayoutAuthenticated',
      redirectPath: '/advertiser',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'EditAdvertiser',
        redirect: { name: 'EditAdvertiserInfo' },
      },
      {
        path: 'info',
        name: 'EditAdvertiserInfo',
        component: () => import('@/views/people/advertiser/tabs/info.vue'),
        meta: {
          title: 'Passport | Advertiser Details',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'contacts',
        name: 'EditAdvertiserContacts',
        component: () => import('@/views/people/advertiser/tabs/contacts.vue'),
        meta: {
          title: 'Passport | Advertiser Contacts',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
      {
        path: 'campaigns',
        name: 'EditAdvertiserCampaigns',
        component: () => import('@/views/people/advertiser/tabs/campaigns.vue'),
        meta: {
          title: 'Passport | Advertiser Campaigns',
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },
      },
    ],
  },
] as RouteRecordRaw[]
