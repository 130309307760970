<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-text-grey-medium hover:tw-text-blackest"
    :class="{ 'tw-justify-end': justify === 'end' }"
  ></router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellEmpty',
  extends: ListCellDefault,
}
</script>
