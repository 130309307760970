<template>
  <div class="tw-w-modal">
    <div class="tw-border-b tw-border-border-light tw-p-6">
      <h2 class="tw-text-xl tw-font-bold tw-text-black">
        Do you really want to apply this bundle?
      </h2>
    </div>

    <div
      class="tw-border-b tw-border-border-light tw-p-4"
      style="max-height: 50vh; overflow: auto"
    >
      <p>
        <b>Bundle:</b>
        {{ item.label }}
      </p>
      <p style="margin: 20px auto">Will be applied to</p>
      <p>
        <b>Target:</b>
        {{ item.targetName }}
      </p>
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        variant="basic"
        label="Cancel"
        @@click="$emit('close')"
      />
      <PassportButton
        :busy="isLoading"
        variant="primary"
        :label="shouldRetry ? 'Retry' : 'Apply'"
        type="button"
        @@click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue'

export default {
  components: { PassportButton },
  name: 'PopupBundleLoad',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    resolved: {
      type: Function,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      isLoading: false,
      shouldRetry: false,
    }
  },
  methods: {
    async handleSubmit() {
      const bundle = {
        bundleId: this.item.id,
        targetingFilterId: this.item.targetingFilterId,
        passportProductType: this.entity.productType,
        //! bundleType: 'string',
      }

      await this.$store.dispatch('bundles/load', bundle)

      if (typeof this.resolved === 'function') {
        await this.resolved()
      }

      this.$store.dispatch('addToast', {
        type: 'info',
        value: `Bundle "${this.item.label}" successfully applied`,
        delay: 6000,
      })

      this.$emit('close')
    },
  },
}
</script>
