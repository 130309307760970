/*
 **  Notifications shown at the top or bottom of the screen
 *   For example: 'An agency has been created' or 'An user has been successfully deleted'
 *   Can be of type 'error' or anything else
 */
const STANDARD_DELAY = 6000
let counter = 1

const initialState = () => ({
  list: [],
  autoSaveNotification: false,
  autoSaveStatus: '',
})

export default {
  state: initialState,
  mutations: {
    ADD_TOAST(state, { type, value }) {
      state.list.push({
        id: counter++,
        type,
        value,
      })
      // Return the id of the notification
      // So we can remove it later
      return counter - 1
    },

    REMOVE_TOAST(state, id) {
      state.list.splice(
        state.list.findIndex((m) => m.id === id),
        1
      )
      state.autoSaveNotification = false
    },

    // This mutation is supposed to toggle the boolean that display the auto saving popup
    ADD_AUTO_SAVE_NOTIFICATION(state, loading) {
      state.autoSaveStatus = 'info'
      state.autoSaveNotification = loading
    },

    ADD_AUTO_SAVE_NOTIFICATION_STATUS(state, status = 'info') {
      state.autoSaveStatus = status
    },
  },
  actions: {
    addToast({ commit }, { type = 'info', value, delay = STANDARD_DELAY }) {
      const id = commit('ADD_TOAST', {
        type,
        value,
      })

      // Remove notification after 3s
      setTimeout(() => commit('REMOVE_TOAST', id), delay)
    },

    addError({ dispatch }, errorMessage) {
      dispatch('addToast', {
        type: 'error',
        value: errorMessage,
      })
    },

    addErrors({ dispatch }, errorMessages) {
      for (const message of errorMessages) {
        dispatch('addError', message)
      }
    },

    removeToast({ commit }, id) {
      commit('REMOVE_TOAST', id)
    },

    // This action is supposed to toggle the boolean that display the auto saving popup
    addAutoSaveNotification({ commit }, loading) {
      commit('ADD_AUTO_SAVE_NOTIFICATION', loading)
    },
  },
}
