<template>
  <div
    class="tw-w-modal-lg tw-divide-y tw-divide-border-light lg:tw-w-modal-xl"
  >
    <div class="tw-p-6">
      <h2 class="tw-text-center tw-text-xl tw-font-bold tw-text-black">
        Creative Library
      </h2>
    </div>
    <div class="tw-px-6 tw-py-4">
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
        <div
          class="tw-mb-4 tw-ml-8 tw-flex tw-w-1/2 tw-items-center tw-text-grey-light"
        >
          <Icon name="search" />
          <input
            v-model="search"
            placeholder="Search creative..."
            type="text"
            class="tw-h-12 tw-w-full tw-bg-transparent tw-pl-8 tw-text-xl tw-font-extralight tw-text-grey tw-placeholder-alto"
          />
        </div>
        <SelectInput
          label="Sort by Advertiser:"
          class="tw-z-50 tw--mt-8 tw-w-48"
          :model-value="advertiserSelected"
          :options="optionsAdvertisers"
          @update:model-value="handleSelectAdvertiser"
        />
      </div>
      <audio
        ref="player"
        :src="entity.list.player.source"
        class="tw-hidden"
        controls
        autoplay
        @timeupdate="handleProgression"
      />
      <section class="tw-max-h-80 tw-overflow-y-auto tw-overscroll-none">
        <div
          class="grid-style tw-grid tw-border-collapse"
          :class="`page-${entity.list.page}`"
          :style="gridStyle"
        >
          <InputSelector
            v-tooltip="
              `${
                checkedCreatives.length === items.length ? 'Deselect' : 'Select'
              } All`
            "
            :model-value="checkedCreatives.length === items.length"
            :value="true"
            type="checkbox"
            :disabled="!filteredItems.length"
            @update:model-value="handleSelectAll"
          />

          <ListingHeader
            class="tw-contents"
            :class="`page-${entity.list.page}`"
            :entity="entity.list"
            :items="filteredItems"
            @change-status="handleApplyStatus"
            @clear-filter="handleClearFilter"
            @filter="handleFilter"
            @sort="handleSort"
          />
          <div
            v-if="entity.list.loading"
            class="tw-col-span-full tw-flex tw-h-80 tw-items-center tw-justify-center"
          >
            <Icon
              name="passport"
              class="preserve-3d tw-animate-flip-x tw-text-3xl tw-text-primary"
            />
          </div>
          <div
            v-else-if="entity.list.shouldRetry"
            class="tw-col-span-full tw-flex tw-h-96 tw-items-center tw-justify-center tw-text-center tw-text-sm tw-text-grey"
          >
            An error occurred while loading this section.
            <button
              class="tw-ml-2 tw-font-medium tw-text-black tw-underline"
              @click="fetchData"
            >
              Retry.
            </button>
          </div>
          <div
            v-else-if="filteredItems.length === 0"
            class="tw-col-span-full tw-flex tw-h-96 tw-items-center tw-justify-center tw-text-center tw-text-sm tw-text-grey"
          >
            No records found
          </div>
        </div>
        <div v-if="!entity.list.loading">
          <div
            v-for="item in filteredItems"
            :key="item.id"
            class="grid-style tw-relative tw-grid tw-border-collapse"
            :class="`page-${entity.list.page}`"
            :style="gridStyle"
          >
            <div class="tw-absolute tw-mt-2 tw-flex tw-h-full tw-items-center">
              <InputSelector
                v-model="checkedCreatives"
                :value="item.id"
                :label="item.name"
                :hide-label="true"
              />
            </div>
            <ListingRow
              :entity="entity.list"
              :item="{ ...item, entity: 'CreativeLibrary' }"
              :page="entity.list.page"
              @click="handleClick($event)"
            />
            <div
              v-if="entity.list.player.id === item.id"
              class="tw-col-start-2 tw-col-end-6 tw--mt-1 tw-overflow-hidden tw-rounded-b tablet:tw-col-end-8"
            >
              <div
                class="tw-h-1 tw-bg-primary tw-transition-all tw-duration-300 tw-ease-linear"
                :style="`width: ${entity.list.player.progression}%;`"
              />
            </div>
          </div>
          <LoadMore
            :pagination="entity.list.pagination"
            :count="filteredItems.length"
            @nextpage="entity.list.pagination.page++"
          />
        </div>
      </section>
    </div>
    <div
      v-if="error"
      class="tw-border-b tw-border-gray-500 tw-bg-red tw-p-6 tw-text-sm tw-text-white"
    >
      {{ error }}
    </div>
    <div class="tw-flex tw-items-center tw-justify-between tw-p-6">
      <PassportButton
        label="Cancel"
        variant="basic"
        @@click="$emit('close')"
      />
      <div v-tooltip="!checkedCreatives.length ? 'No creative selected' : ''">
        <PassportButton
          :label="shouldRetry ? 'Retry' : 'Import'"
          :busy="isLoading"
          :disabled="!checkedCreatives.length"
          type="button"
          variant="primary"
          @@click="handleImport"
        />
      </div>
    </div>
    <Popup
      v-model="modals.edit.isVisible"
      :z-index="10000"
    >
      <PopupCreativeEdit
        :entity="entity"
        :item="modals.edit.item"
        @close="handleCloseModal(modals.edit)"
        @refresh-data="fetchData"
      />
    </Popup>
    <Popup
      v-model="modals.delete.isVisible"
      :z-index="10000"
    >
      <PopupEntityDelete
        :entity="entity"
        :item="modals.delete.item"
        @close="handleCloseModal(modals.delete)"
        @refresh-data="fetchData"
      />
    </Popup>
  </div>
</template>

<script>
import { sortList } from '@/helpers/sort'
import { searchList } from '@/helpers/search'
import listPageMixin from '@/mixins/listPage'
import entity from '@/entity/creative-library.js'

import Icon from '@/components/Icon.vue'
import InputSelector from '@/components/InputSelector.vue'
import PassportButton from '@/components/PassportButton.vue'
import Popup from '@/components/Popup.vue'
import PopupCreativeEdit from '@/popups/creative-library-edit.vue'
import PopupEntityDelete from '@/popups/creative-library-delete.vue'
import SelectInput from '@/components/Input/SelectInput.vue'

export default {
  name: 'PopupCreativeLibrary',
  components: {
    Icon,
    InputSelector,
    PassportButton,
    Popup,
    PopupCreativeEdit,
    PopupEntityDelete,
    SelectInput,
  },
  mixins: [listPageMixin],
  props: {
    callback: {
      type: Function,
      required: true,
    },
    flight: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      checkedCreatives: [],
      entity,
      error: '',
      isLoading: false,
      modals: {
        edit: {
          isVisible: false,
          item: null,
        },
        delete: {
          isVisible: false,
          item: null,
        },
      },
      search: '',
      shouldRetry: false,
      advertiserSelected: '_',
    }
  },
  computed: {
    items() {
      return this.$store.state.creativeLibrary.list
    },
    filteredItems() {
      const items = this.items.slice()
      let filteredItems = sortList(
        items,
        this.entity.list.sortKey,
        this.entity.list.sortOrder
      )

      filteredItems = searchList(filteredItems, 'name', this.search)

      return filteredItems.slice(
        0,
        this.entity.list.pagination.perPage * this.entity.list.pagination.page
      )
    },
    optionsAdvertisers() {
      return [
        {
          value: '_',
          label: 'None',
        },
        ...this.sortList(
          this.$store.state.advertiser.list.map((item) => {
            return {
              value: item.id,
              label: item.name,
            }
          }),
          'label',
          'asc'
        ),
      ]
    },
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch('advertiser/list', { force: true })

    this.entity.list.pagination.perPage = import.meta.env.DEV ? 10 : 50
    this.entity.list.pagination.page = 1

    this.entity.list.pagination.player = {
      ...this.entity.list.pagination.player,
      id: null,
      isPlaying: false,
      progression: 0,
      source: null,
    }
  },
  methods: {
    handleCloseModal(modal) {
      Object.assign(modal, {
        isVisible: false,
        item: null,
      })
    },
    handleClickDelete(item) {
      Object.assign(this.modals.delete, {
        isVisible: true,
        item,
      })
    },
    handleClickEdit(item) {
      Object.assign(this.modals.edit, {
        isVisible: true,
        item,
      })
    },
    handleClickPlayerPause() {
      this.entity.list.player.isPlaying = false
      this.$refs.player?.pause()
    },
    handleClickPlayerPlay(item) {
      const { player } = this.entity.list

      try {
        if (player.id !== item.id) {
          player.id = item.id
          player.source = `${import.meta.env.VITE_TARGETSPOT_MEDIA_URL}${
            item.filePath
          }`

          this.$refs.player?.load()
        }

        player.isPlaying = true

        setTimeout(() => {
          this.$refs.player?.play()
        }, 0)
      } catch (error) {
        console.error(error)
      }
    },
    handleProgression(event) {
      this.entity.list.player.progression = Math.min(
        (event.target.currentTime / event.target.duration) * 100,
        100
      )

      if (this.entity.list.player.progression >= 100) {
        this.entity.list.player.isPlaying = false
      }
    },
    handleSelectAll(event) {
      if (!event || this.checkedCreatives.length === this.items.length) {
        this.checkedCreatives = []
      } else {
        this.checkedCreatives = this.items.map(({ id }) => id)
      }
    },
    async handleImport() {
      try {
        this.error = null
        this.isLoading = true

        await this.$store.dispatch('creativeLibrary/import', {
          flightId: this.flight.id,
          creativeLibraryIds: this.checkedCreatives,
        })

        this.$store.dispatch('addToast', {
          type: 'info',
          value: `The creatives have been correctly imported from the library, you can now edit them`,
        })
        await this.callback()

        this.$emit('close')
      } catch (error) {
        this.shouldRetry = true
        this.error = this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSelectAdvertiser(event) {
      this.advertiserSelected = event

      this.fetchData()
    },
    async fetchData() {
      const advertiserId =
        this.advertiserSelected === '_' ? null : this.advertiserSelected

      this.entity.list.shouldRetry = false
      this.entity.list.loading = true

      try {
        await this.$store.dispatch('creativeLibrary/list', { advertiserId })
      } catch (error) {
        this.entity.list.shouldRetry = true
        this.error = this.handleError(error)
      } finally {
        this.entity.list.loading = false
      }
    },
  },
}
</script>
