<script setup lang="ts">
import AutoSaveNotifications from '@/components/ToastsList/AutoSaveNotifications.vue'
import HeroFooter from '@/components/Footer.vue'
import HeroHeader from '@/components/Header.vue'
import Popup from '@/components/PopupFromState.vue'
import ScrollTopComponent from '@/components/ScrollTopComponent.vue'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const countryCode = computed(() => store.state.user?.countryCode)

onMounted(() => {
  // set the user language to html lang attribute to have input number with , for european and . for others
  if (countryCode.value) {
    document.getElementsByTagName('html')[0].lang =
      countryCode.value.toLowerCase()
  }
})
</script>

<template>
  <AutoSaveNotifications />

  <ScrollTopComponent />

  <HeroHeader />

  <div class="tw-mt-16 tw-flex tw-flex-1 tw-flex-col">
    <slot />
  </div>

  <HeroFooter class="tw-mt-auto" />

  <Popup />
</template>
