<template>
  <div
    class="tw-flex tw-h-full tw-items-center tw-justify-center tw-border-none"
  >
    <InputSelector
      v-if="isSelectable"
      v-tooltip="
        !userCanSelect
          ? {
              content: 'Only Sales and Ad Traffickers can perform this action',
              placement: 'top',
            }
          : {}
      "
      :model-value="checked"
      :value="true"
      type="checkbox"
      :label="item.name"
      :hide-label="true"
      :disabled="!userCanSelect"
      @update:model-value="() => $emit('select', this.item)"
    />
  </div>
</template>

<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import InputSelector from '@/components/InputSelector.vue'

export default {
  components: {
    InputSelector,
  },

  name: 'ListCellSelect',
  emits: ['select'],
  extends: ListCellDefault,

  props: {
    selectedItems: {
      type: [Array, Object],
      required: true,
    },
  },

  computed: {
    checked() {
      return this.selectedItems.some(
        ({ id, entity }) => id === this.item.id && entity === this.item.entity
      )
    },
    userCanSelect() {
      // const hasRole = [
      //   'Sales',
      //   'SalesAssistant',
      //   'SalesSenior',
      //   'SalesTeamManager',
      //   'AdTrafficker',
      //   'AdTraffickerSenior',
      //   'MasterAdTrafficker',
      // ].some((claim) => this.$store.state.auth.functions.includes(claim))

      return true
    },
    isSelectable() {
      return ['Draft'].includes(this.item[this.entity.statusKey])
    },
  },
}
</script>
