<script setup lang="ts">
import { computed } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import Icon from '@/components/Icon.vue'

type PopoverPanelPosition =
  | 'bottom-left'
  | 'bottom-right'
  | 'top-left'
  | 'top-right'

const props = defineProps({
  ariaLabel: {
    type: String,
    default: null,
  },
  buttonClass: {
    type: [String, Array, Object],
    default: null,
  },
  buttonLabel: {
    type: String,
    default: null,
  },
  customButton: {
    type: Boolean,
    default: false,
  },
  panelClass: {
    type: [String, Array, Object],
    default: null,
  },
  position: {
    type: String as () => PopoverPanelPosition,
    default: 'bottom-right',
  },
})

const positionClass = computed(() => {
  if (['top', 'right'].every((position) => props.position.includes(position))) {
    return 'tw-right-0 tw-origin-bottom-right'
  }
  if (
    ['bottom', 'left'].every((position) => props.position.includes(position))
  ) {
    return 'tw-left-0 tw-origin-top-left'
  }
  if (['top', 'left'].every((position) => props.position.includes(position))) {
    return 'tw-left-0 tw-top-0 tw--translate-y-full tw-origin-bottom-left'
  }

  return 'tw-right-0 tw-origin-top-right'
})
</script>

<template>
  <Popover class="tw-relative">
    <PopoverButton
      v-slot="{ open }"
      :aria-label="props.ariaLabel"
      :title="props.ariaLabel"
      :as="props.customButton ? 'div' : 'button'"
      class="tw-text-grey-medium hover:tw-text-primary focus:tw-text-primary"
      :class="buttonClass"
    >
      <slot v-bind="{ open }">
        <div
          class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-select-none tw-items-center tw-gap-x-1.5 tw-text-xs"
        >
          <div class="tw-truncate tw-font-medium">
            {{ props.buttonLabel }}
          </div>
          <Icon
            name="chevron-down"
            class="tw-text-2xs"
          />
        </div>
      </slot>
    </PopoverButton>

    <transition
      enter-active-class="tw-transition tw-duration-200 tw-ease-out"
      enter-from-class="tw-translate-y-1 tw-opacity-0"
      enter-to-class="tw-translate-y-0 tw-opacity-100"
      leave-active-class="tw-transition tw-duration-150 tw-ease-in"
      leave-from-class="tw-translate-y-0 tw-opacity-100"
      leave-to-class="tw-translate-y-1 tw-opacity-0"
    >
      <PopoverPanel
        v-slot="{ close, open }"
        class="tw-absolute tw-z-50 tw-transform tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
        :class="[props.panelClass, positionClass]"
      >
        <slot
          name="popover"
          v-bind="{ close, open }"
        />
      </PopoverPanel>
    </transition>
  </Popover>
</template>
