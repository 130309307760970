import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: ':order/flights/create',
    name: 'CreateOrderFlight',
    component: () => import('@/views/order-flight/edit.vue'),
    meta: {
      title: 'Passport | Create Order Flight',
      layout: 'LayoutAuthenticated',
      redirectPath: '/orders',
      requiresAuth: true,
    },
  },
  {
    path: ':order/flights/:orderFlight/:tab?',
    name: 'EditOrderFlight',
    component: () => import('@/views/order-flight/edit.vue'),
    meta: {
      title: 'Passport | Edit Order Flight',
      layout: 'LayoutAuthenticated',
      redirectPath: '/orders',
      requiresAuth: true,
    },
  },
] as RouteRecordRaw[]
