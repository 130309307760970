<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-flex-col tw-justify-center tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
  >
    <Tooltip
      :title="item.name"
      class="tw-max-w-full tw-truncate tw-font-medium"
    >
      {{ item.name }}
    </Tooltip>
    <div class="tw-mt-1 tw-flex tw-leading-none">
      <button
        v-tooltip="'Copy ID'"
        class="tw-inline-flex tw-text-xs"
        type="button"
        @click.prevent="handleClipboardCopy(item.id)"
      >
        ID: {{ item.id }}
      </button>
    </div>
  </router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Tooltip from '@/components/Tooltip.vue'

export default {
  name: 'ListCellDealName',
  extends: ListCellDefault,
  components: {
    Tooltip,
  },
}
</script>
