import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: ':campaign/budget-groupings/create/:tab?',
    name: 'CreateCampaignBudgetGrouping',
    component: () => import('@/views/campaign-budget/edit.vue'),
    meta: {
      title: 'Passport | Create Campaign Budget Grouping',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
  {
    path: ':campaign/budget-groupings/:campaignBudget/:tab?',
    name: 'EditCampaignBudgetGrouping',
    component: () => import('@/views/campaign-budget/edit.vue'),
    meta: {
      title: 'Passport | Edit Campaign Budget Grouping',
      layout: 'LayoutAuthenticated',
      redirectPath: '/campaigns',
      requiresAuth: true,
    },
    props: (route) => ({ tabSelected: route.params.tab || 'info' }),
  },
] as RouteRecordRaw[]
