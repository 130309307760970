<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { TransitionRoot } from '@headlessui/vue'
import Icon from '@/components/Icon.vue'

const adBanner = ref<Element | null>(null)
const adBlockDetected = ref(false)

onMounted(() => {
  if (adBanner.value?.getBoundingClientRect().height === 0) {
    adBlockDetected.value = true
  } else {
    adBlockDetected.value = false
  }
})
</script>
<template>
  <TransitionRoot
    :show="adBlockDetected"
    as="template"
    enter="tw-duration-300 tw-ease-out"
    enter-from="tw-opacity-0"
    enter-to="tw-opacity-100"
    leave="tw-duration-200 tw-ease-in"
    leave-from="tw-opacity-100"
    leave-to="tw-opacity-0"
  >
    <div
      class="tw-fixed tw-left-1/2 tw-top-20 tw-z-50 tw-flex tw-min-w-[24rem] tw--translate-x-1/2 tw-transform tw-items-center tw-gap-4 tw-rounded tw-bg-white tw-p-4 tw-px-6 tw-text-grey-dark tw-drop-shadow-lg"
    >
      <div class="tw-inline-block">
        <Icon
          name="shield"
          class="tw-text-2xl tw-text-red"
        />
      </div>

      <p class="tw-leading-none">
        It looks like you're using an
        <strong class="tw-text-red">AdBlocker</strong>
        . Consider deactivating it as it may result in display inconsistencies
      </p>
      <button
        v-tooltip="{ content: 'Dismiss', placement: 'top' }"
        class="tw-float-right tw-rounded-full tw-p-2 tw-ring-primary hover:tw-text-grey focus-visible:tw-ring"
        @click="adBlockDetected = false"
      >
        <span class="tw-sr-only">Hide ad-block warning message</span>
        <Icon
          name="delete"
          class="tw-text-xs"
        />
      </button>
    </div>
  </TransitionRoot>
  <div
    ref="adBanner"
    class="adBanner ad-banner tw-sr-only"
  />
</template>
