const sort = localStorage.getItem('advertiser-sort')

import { userCan } from '@/helpers/auth'

export default {
  module: 'advertiser',
  key: 'PeopleAdvertisers',
  route: {
    index: { name: 'AdvertiserList' },
    edit: 'EditAdvertiserInfo',
    create: 'CreateAdvertiser',
  },
  param: 'advertiser',
  plural: 'Advertisers',
  singular: 'Advertiser',
  list: {
    filters: [],
    loading: true,
    page: 1,
    pagination: {
      perPage: import.meta.env.DEV ? 10 : 50,
      page: 1,
    },
    search: '',
    shouldRetry: false,
    sortKey: sort ? JSON.parse(sort).key : '',
    sortOrder: sort ? JSON.parse(sort).order : 'asc',
    headers: [
      {
        label: '',
        key: 'completeLogoPath',
        pages: [1, 2],
        heading: null,
        componentList: 'ListCellAvatar',
        alt: 'name',
        size: '70px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-l tw-border-border tw-rounded-l tw-justify-center tw-text-lg',
        classHeader: '',
      },
      {
        label: 'Advertiser',
        key: 'name',
        pages: [1, 2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: 'minmax(100px, 1fr)',
        classCell:
          'tw-mt-4 tw-pl-2 tablet:tw-pl-4 tw-border-l tw-border-t tw-h-20 tablet:tw-h-16 tw-border-border',
        classHeader: 'tw-px-2 tablet:tw-pl-4',
      },
      {
        label: 'Agency',
        key: 'agencyName',
        pages: [1],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        suffix: '%',
        size: 'minmax(100px, 1fr)',
        classCell: 'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Flights',
        key: 'campaignsCount',
        pages: [1],
        componentHeader: 'ListHeaderRange',
        componentList: 'ListCellNumber',
        size: '100px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2 tw-justify-end',
        classHeader: 'tw-px-2 tw-justify-end',
      },
      {
        label: 'Email',
        key: 'email',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: '200px',
        classCell: 'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Phone',
        key: 'phone',
        pages: [2],
        componentHeader: 'ListHeaderDefault',
        componentList: 'ListCellDefault',
        size: '150px',
        classCell: 'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-px-2',
        classHeader: 'tw-px-2',
      },
      {
        label: 'Actions',
        key: 'actions',
        pages: [1, 2],
        componentHeader: 'ListHeaderPagination',
        componentList: 'ListCellActions',
        size: '80px',
        classCell:
          'tw-mt-4 tw-border-t tw-h-20 tablet:tw-h-16 tw-border-r tw-border-border tw-rounded-r',
        classHeader: '',
      },
    ],
    filtersCallbacks: {},
    rowOptions: () => [
      ...(userCan('DeleteAdvertiser')
        ? [
            {
              key: 'delete',
              label: 'Delete',
              icon: 'trash',
            },
          ]
        : []),
      ...(userCan('UpdateAdvertiser')
        ? [
            {
              key: 'edit',
              label: 'Edit',
              icon: 'edit',
            },
          ]
        : []),
    ],
  },
  moreActions: (item, { canRemoveEntity, canEditEntity }) => {
    return [
      canEditEntity
        ? {
            key: 'add-user',
            label: 'Create new Contact',
            icon: 'gender-both',
          }
        : null,
      item
        ? {
            key: 'add-campaign',
            label: 'Create new Campaign',
            icon: 'plane',
            to: { name: 'CreateCampaign', query: { advertiserId: item.id } },
          }
        : null,
      {
        key: 'divider-1',
        divider: true,
      },
      canRemoveEntity
        ? {
            key: 'delete',
            label: `Delete`,
            icon: 'trash',
            class: 'hover:tw-text-red',
          }
        : null,
    ].filter((action) => action)
  },

  getDescription(item) {
    return `
    <div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-grid tw-grid-cols-12 tw-gap-6">
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Name</div>
            <div class="tw-text-sm">${item.name}</div>
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Agency Name</div>
            <div class="tw-text-sm">${item.agencyName || '-'}</div>
          </div>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-12 tw-gap-6">
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Email</div>
            <div class="tw-text-sm">${item.email || '-'}</div>
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-flex tw-flex-col">
            <div class="tw-font-semibold tw-text-sm">Phone</div>
            <div class="tw-text-sm">${item.phone || '-'}</div>
          </div>
        </div>
      </div>
    </div>
      `
  },
}
