<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-items-center tw-text-grey-medium hover:tw-text-blackest"
  >
    <div class="tw-truncate">
      <div class="tw-text-sm tw-font-medium">
        <template v-if="!date">-</template>
        <template v-else-if="date.includes('9999-12-31')">Always</template>
        <template v-else>
          <div class="tw-flex tw-space-x-2">
            <div>{{ $filters.dateWithDay(date) }}</div>
            <div
              v-if="isExpired"
              class="tw-text-grey-light"
            >
              (expired)
            </div>
          </div>
        </template>
      </div>
    </div>
  </router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'

export default {
  name: 'ListCellDate',
  extends: ListCellDefault,
  data() {
    const date = this.safeGet(this.item, this.headerKey)
    const isExpired = new Date(date) < new Date()

    return {
      date,
      isExpired,
    }
  },
}
</script>
