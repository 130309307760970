import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import HTTP from '@/services/http'

export default {
  namespaced: true,

  state: {
    current: null,
    form: null,
    list: [],
    parentId: null,
  },

  mutations: {
    RESET_STATE(state) {
      state.current = null
      state.form = null
      state.list = []
      state.parentId = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      _set(state[valueKey], path, value)
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_FORM(state, form) {
      state.form = _cloneDeep(form)
    },
    SET_LIST(state, list) {
      state.list = list
    },
    SET_PARENT_ID(state, parentId) {
      state.parentId = parentId
    },
  },

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async fetch({ commit, state }, { id }) {
      try {
        const response = await HTTP.get(`CreativeCompanion/${id}`)

        commit('SET_CURRENT', response.data.datas)

        if (!state.form) {
          commit('SET_FORM', response.data.datas)
        }

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async delete(context, { id }) {
      try {
        const response = await HTTP.delete(`CreativeCompanion/${id}`)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async create({ state }) {
      try {
        const response = await HTTP.post('CreativeCompanion', state.form)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }) {
      try {
        const response = await HTTP.put('CreativeCompanion', state.form)

        commit('SET_CURRENT', response.data.datas)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async list({ commit, state }, { id, force = true }) {
      if (!force && state.list.length) {
        return Promise.resolve(state.list)
      }

      try {
        const listResponse = await HTTP.get(`CreativeCompanion/${id}/list`)

        const list = listResponse.data.datas

        commit('SET_LIST', list)

        return Promise.resolve({ data: { datas: list } })
      } catch (error) {
        commit('SET_LIST', [])

        console.error(error)
        return Promise.reject(error)
      }
    },

    setParentId({ commit }, { id }) {
      commit('SET_PARENT_ID', id)
    },

    handleInput({ commit }, { path, value, valueKey = 'form' }) {
      commit('HANDLE_INPUT', { path, value, valueKey })
    },
  },
}
