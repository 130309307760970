import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import _get from 'lodash/get'
import { HARDCOST_TYPE_AMOUNT } from '@/constants/HardCostTypes'
import HTTP from '@/services/http'
import parseConfig from '@/helpers/parseConfig.js'
import campaignStore from '@/store/campaign'
import { useStoreForecastEstimates } from '@/stores'

const cleanForm = (form) => {
  const cleanForm = _cloneDeep(form)

  cleanForm.campaignSaleUsersList = cleanForm.campaignSaleUsersList.filter(
    (campaignSaleUser) => campaignSaleUser.userId !== null
  )
  cleanForm.campaignHardCostsList = cleanForm.campaignHardCostsList.filter(
    (campaignHardCost) =>
      [
        campaignHardCost.currencyCode,
        campaignHardCost.hardCostType === HARDCOST_TYPE_AMOUNT
          ? campaignHardCost.amount
          : campaignHardCost.cpm,
        campaignHardCost.name,
      ].every((value) => value !== null)
  )

  return cleanForm
}

export default {
  namespaced: true,

  state: {
    configView: null,
    current: null,
    form: null,
    isDirty: {
      form: false,
    },
    list: [],
    listLoadingMore: false,
    listTotal: 0,
    listPerPage: 15,
    listSearch: '',
    listFilters: {},
    listOrderBy: null,
    listStatusCount: {},
    listOrderByDirection: null,
    listPage: 0,
  },

  mutations: {
    ...campaignStore.mutations,
    RESET_STATE(state) {
      state.configView = null
      state.current = null
      state.form = null
    },
    RESET_CURRENT(state) {
      state.current = null
      state.form = null
    },
    HANDLE_INPUT(state, { path, value, valueKey = 'form' }) {
      if (path === null) {
        state[valueKey] = _cloneDeep(value)
      } else {
        _set(state[valueKey], path, value)
      }
    },
    SET_CONFIG_VIEW(state, payload) {
      state.configView = payload
    },
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_DIRTY(state, { isDirty, key }) {
      state.isDirty[key] = isDirty
    },
    SET_FORM(state, form) {
      state.form = {
        ...form,
      }
    },
    SET_LIST(state, list) {
      state.list = list
    },
  },

  actions: {
    ...campaignStore.actions,

    async fetchConfigView(
      { commit, dispatch, state },
      { campaignType = null } = {}
    ) {
      try {
        const response = await HTTP.get(
          `Campaign/Configview/WithFlight/${
            campaignType ? campaignType : 'NULL'
          }`
        )

        const parsedConfig = parseConfig(
          response.data.CampaignWithFlightXml,
          'CampaignTypeId'
        )

        commit('SET_CONFIG_VIEW', parsedConfig)

        const productTypes = Array.isArray(
          parsedConfig.fields.CampaignType.DataList.DataList
        )
          ? parsedConfig.fields.CampaignType.DataList.DataList
          : [parsedConfig.fields.CampaignType.DataList.DataList]

        if (campaignType === null) {
          if (
            productTypes.findIndex(
              (productType) => +productType.Id === +parsedConfig.CampaignTypeId
            ) >= 0
          ) {
            dispatch('handleInput', {
              path: 'campaignType',
              value: +parsedConfig.CampaignTypeId,
              valueKey: 'form',
            })
          } else {
            const firstProductType = productTypes[0].Id

            return dispatch('fetchConfigView', {
              campaignType: +firstProductType,
            }).then(() => {
              dispatch('handleInput', {
                path: 'campaignType',
                value: +firstProductType,
                valueKey: 'form',
              })
            })
          }
        } else if (
          productTypes.findIndex(
            (productType) => +productType.Id === +campaignType
          ) < 0 &&
          productTypes.length > 0
        ) {
          const firstProductType = productTypes[0].Id

          dispatch('handleInput', {
            path: 'campaignType',
            value: +firstProductType,
            valueKey: 'form',
          })
        }

        if (
          !state.form.id &&
          parsedConfig.fields.CampaignTraffickingFee.DefaultValue
        ) {
          dispatch('handleInput', {
            path: 'traffickingFeePercentage',
            value: parsedConfig.fields.CampaignTraffickingFee.DefaultValue,
            valueKey: 'form',
          })
        }

        if (
          !state.form.id &&
          parsedConfig.fields.CampaignCpm?.ValidationsList?.Validations
        ) {
          const [{ FirstValue: grossCpm } = {}] =
            parsedConfig.fields.CampaignCpm?.ValidationsList?.Validations || []

          dispatch('handleInput', {
            path: 'budget.grossCpm',
            value: Number(grossCpm),
            valueKey: 'form',
          })
        }

        const languages = Array.isArray(
          parsedConfig.fields.FlightLanguageCode.DataList.DataList
        )
          ? parsedConfig.fields.FlightLanguageCode.DataList.DataList
          : [parsedConfig.fields.FlightLanguageCode.DataList.DataList]

        if (state.form.languageCode === null && languages.length) {
          const firstLanguage = languages[0].Language.toUpperCase()

          dispatch('handleInput', {
            path: 'languageCode',
            value: firstLanguage,
            valueKey: 'form',
          })
        }

        if (!state.form?.channelTypes) {
          const channelsTypesList = _cloneDeep(
            _get(state.configView, 'fields.ChannelTypes.DataList.DataList') ||
              []
          )

          dispatch('handleInput', {
            path: 'channelTypes',
            value: channelsTypesList
              .filter(({ IsDefault }) => IsDefault)
              .map(({ Id }) => Id),
            valueKey: 'form',
          })
        }

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve()
      } catch (e) {
        console.error(e)
        commit('SET_CONFIG_VIEW', null)

        return Promise.reject(e)
      }
    },

    async fetch({ commit, state }, { id, reload = false }) {
      try {
        const form = await import('@/forms/campaign-with-flight.js')
        const defaultForm = _cloneDeep(form.default)

        const responseCampaign = await HTTP.get(`Campaign/${id}/flight`)
        const campaign = responseCampaign.data.datas
        const flight = campaign.flights && campaign.flights[0]

        if (!flight) {
          return Promise.reject(
            'No Flight was found for this campaign please contact support'
          )
        }

        const campaignWithFlight = {
          ...defaultForm,
          ...campaign,
          adPlacement: flight.adPlacement,
          budget: flight.budget || defaultForm.budget,
          budgetId: flight.budgetId,
          campaignId: flight.campaignId,
          channelTypes: flight.channelTypes,
          creativeMode: flight.creativeMode,
          dealIds: flight.dealIds || defaultForm.dealIds,
          endDate: flight.endDate,
          flightCategoryIabs:
            flight.flightCategoryIabs || defaultForm.flightCategoryIabs,
          flightCompanionType: flight.flightCompanionType,
          flightCustomCapping:
            flight.flightCustomCapping || defaultForm.flightCustomCapping,
          flightId: flight.id,
          // flightType: flight.flightType,
          hasExplicitContent: flight.hasExplicitContent,
          includeGenericSegments: flight.includeGenericSegments,
          languageCode: flight.languageCode,
          metaBundleId: flight.metaBundleId,
          placementProductType: flight.placementProductType,
          startDate: flight.startDate,
          targetingFilterId: flight.targetingFilterId,
        }

        commit('SET_CURRENT', campaignWithFlight)
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        commit('campaignFlight/SET_CURRENT', flight, { root: true })
        commit('campaignFlight/SET_FORM', flight, { root: true })

        if (reload || !state.form || state.form.id !== id) {
          commit('SET_FORM', campaignWithFlight)
        }

        const storeForecastEstimates = useStoreForecastEstimates()

        await storeForecastEstimates.fetch({
          id: campaignWithFlight.flightId,
          type: 'flight',
        })

        return Promise.resolve(campaignWithFlight)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    async create({ state, commit }) {
      try {
        const response = await HTTP.post(
          'Campaign/flight',
          cleanForm(state.form)
        )

        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update({ commit, state }, { options } = {}) {
      try {
        const response = await HTTP.put(
          'Campaign/flight',
          {
            ...cleanForm(state.form),
            id: state.current.id,
          },
          options
        )

        const campaign = response.data.datas
        const flight = campaign.flights && campaign.flights[0]

        if (!flight) {
          return Promise.reject(
            'No Flight was found for this campaign please contact support'
          )
        }

        const campaignWithFlight = {
          ...campaign,
          adPlacement: flight.adPlacement,
          budget: flight.budget,
          budgetId: flight.budgetId,
          campaignId: flight.campaignId,
          channelTypes: flight.channelTypes,
          creativeMode: flight.creativeMode,
          dealIds: flight.dealIds,
          flightCategoryIabs: flight.flightCategoryIabs,
          flightCompanionType: flight.flightCompanionType,
          flightCustomCapping: flight.flightCustomCapping,
          flightId: flight.id,
          // flightType: flight.flightType,
          hasExplicitContent: flight.hasExplicitContent,
          includeGenericSegments: flight.includeGenericSegments,

          languageCode: flight.languageCode,
          metaBundleId: flight.metaBundleId,
          placementProductType: flight.placementProductType,
          targetingFilterId: flight.targetingFilterId,
        }

        commit('SET_CURRENT', campaignWithFlight)
        commit(
          'SET_FORM',
          Object.assign({}, state.form, { channelTypes: flight.channelTypes })
        )
        commit('SET_DIRTY', { isDirty: false, key: 'form' })

        const storeForecastEstimates = useStoreForecastEstimates()

        await storeForecastEstimates.fetch({
          id: campaignWithFlight.flightId,
          type: 'flight',
        })

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async listChildren({ commit }, { id }) {
      try {
        const response = await HTTP.get(`Flight/list`, {
          params: {
            campaignId: id,
          },
        })

        // commit('SET_LIST_CHILDREN', { id, list: response.data.datas })

        return Promise.resolve(response.data.datas)
      } catch (error) {
        commit('SET_LIST', [])

        return Promise.reject(error)
      }
    },

    async setDefaultForm({ commit, rootState }) {
      const form = await import('@/forms/campaign-with-flight.js')
      const defaultForm = _cloneDeep(form.default)

      commit('SET_FORM', {
        ...defaultForm,
        countryCode: rootState.auth.user?.countryCode || null,
        currencyCode: rootState.organization.defaultCurrency || null,
      })
    },
  },
}
