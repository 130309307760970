<template>
  <div>
    <button
      class="tw-group tw-z-10 -tw-ml-px -tw-mt-px tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-overflow-hidden tw-rounded tw-rounded-r-none tw-text-sm"
      :class="[
        `tw-bg-${(item[entity.statusKey] || 'error').toLowerCase()}`,
        showChildren ? 'tw-rounded-bl-none' : '',
      ]"
      type="button"
      @click.stop="$emit('click', { item: item, key: 'toggle' })"
    >
      <div
        class="tw-flex tw-h-full tw-w-4 tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-bg-overlay-light tw-text-white group-hover:tw-bg-overlay"
      >
        <Icon
          name="chevron-down"
          class="tw-h-2 tw-w-2 tw-transform"
          :class="showChildren ? 'tw-rotate-180' : ''"
        />
      </div>
      <div
        class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-px-4 group-hover:tw-bg-overlay-light"
      >
        <div
          class="tw-flex tw-w-full tw-items-center tw-justify-between tw-text-white"
        >
          <div class="tw-w-full tw-text-right tw-tabular-nums">
            {{ item.budgetGroupingsCount }}
          </div>
          <Icon
            name="budget-grouping"
            class="tw-ml-2 tw-h-4 tw-w-4 tw-flex-shrink-0"
          />
        </div>
        <div
          class="tw-my-2 tw-h-px tw-w-full tw-bg-grey-dark tw-bg-opacity-25"
        ></div>
        <div
          class="tw-flex tw-w-full tw-items-center tw-justify-between tw-text-white"
        >
          <div class="tw-w-full tw-text-right tw-tabular-nums">
            {{ item.flightsCount }}
          </div>
          <Icon
            name="plane"
            class="tw-ml-2 tw-h-4 tw-w-4 tw-flex-shrink-0"
          />
        </div>
      </div>
    </button>
  </div>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'ListCellCampaignToggle',
  emits: ['click'],
  extends: ListCellDefault,
  components: {
    Icon,
  },
  props: {
    showChildren: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
