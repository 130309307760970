<script setup lang="ts">
import type { ListOption } from '@/types'
import type { PropType } from 'vue'

// eslint-disable-next-line no-undef
defineOptions({ inheritAttrs: false })

const emit = defineEmits<{ 'update:modelValue': [ListOption['value']] }>()

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<ListOption[]>,
    default: () => [],
  },
  targetClass: {
    type: [Array, String, Object],
    default: null,
  },
})

const handleClick = (option: ListOption): void => {
  emit('update:modelValue', option.value)
}
</script>

<template>
  <div
    class="tw-mt-2 tw-flex tw-w-full tw-items-center tw-overflow-hidden tw-rounded-full tw-border"
    :class="disabled ? 'tw-pointer-events-none tw-opacity-75' : ''"
  >
    <template
      v-for="option in props.options"
      :key="option.value"
    >
      <button
        type="button"
        class="tw-h-10 tw-flex-1 tw-text-center tw-text-xs tw-font-medium tw-uppercase"
        :disabled="disabled"
        :class="[
          modelValue === option.value
            ? 'tw-bg-primary tw-text-white hover:tw-bg-opacity-75 focus-visible:tw-bg-opacity-75'
            : 'hover:tw-bg-border-light focus-visible:tw-bg-border-light',
          targetClass,
        ]"
        @click="handleClick(option)"
      >
        {{ option.label }}
      </button>
    </template>
  </div>
</template>
