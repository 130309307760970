import { defineStore } from 'pinia'
import { useStore } from 'vuex'
import HTTP from '@/services/http'
import { reactive, ref } from 'vue'
import { handleError } from '@/helpers/handleError'
import type {
  APIResponseForecastBaseFlight,
  APIResponseForecastBG,
} from '@/types/api'
import type { APIResponse } from '@/types'
import downloadBuffer from '@/helpers/downloadBuffer'
import _cloneDeep from 'lodash/cloneDeep'

interface ForecastEstimates {
  estimatedDailyReachMin: number
  estimatedDailyReachMax: number
  estimatedDailyImpressionsMin: number
  estimatedDailyImpressionsMax: number
  gaugeForecast: number
  gaugeAvailable: number
  impressionsAvailable: number
  impressionsForecast: number
  impressionsGoal: number
  uniqueUsersMin: number
  uniqueUsersMax: number
}
const defaultForecastEstimates: ForecastEstimates = {
  estimatedDailyReachMin: 0,
  estimatedDailyReachMax: 0,
  estimatedDailyImpressionsMin: 0,
  estimatedDailyImpressionsMax: 0,
  gaugeForecast: 0,
  gaugeAvailable: 0,
  impressionsAvailable: 0,
  impressionsForecast: 0,
  impressionsGoal: 0,
  uniqueUsersMin: 0,
  uniqueUsersMax: 0,
}

//* Aside Forecast on forms of Deals, Flights, BG, Campaigns, Orders, Forecast
export const useStoreForecastEstimates = defineStore(
  'forecastEstimates',
  () => {
    const store = useStore()

    const dataCostsCpm = ref<number | null>(null)

    const state = reactive<{ loading: boolean; error: string | null }>({
      loading: false,
      error: null,
    })

    const forecastEstimates = ref<typeof defaultForecastEstimates>(
      _cloneDeep(defaultForecastEstimates)
    )

    const setForecast = ({
      estForecast,
      type,
      module,
    }: {
      estForecast: APIResponseForecastBaseFlight | APIResponseForecastBG
      type: 'campaign' | 'bg' | 'flight'
      module: string | null
    }): void => {
      if (!estForecast) return

      switch (type) {
        case 'flight':
          {
            const { budget, ..._forecastEstimates } =
              estForecast as APIResponseForecastBaseFlight

            forecastEstimates.value = Object.assign({}, _forecastEstimates, {
              impressionsForecast: _forecastEstimates.impressionForecast,
              impressionsAvailable: _forecastEstimates.impressionAvailable,
              impressionsGoal: _forecastEstimates.impressionGoal,
              gaugeForecast: _forecastEstimates.forecastGauge,
              gaugeAvailable: _forecastEstimates.availableGauge,
            })

            if (module) {
              store.commit(`${module}/SET_BUDGET`, budget, {
                root: true,
              })
            }
          }
          break
        case 'campaign':
        case 'bg':
          {
            forecastEstimates.value = Object.assign({}, estForecast, {
              impressionsForecast: estForecast.impressionForecast,
              impressionsAvailable: estForecast.impressionAvailable,
              impressionsGoal: estForecast.impressionGoal,
              gaugeForecast: estForecast.forecastGauge,
              gaugeAvailable: estForecast.availableGauge,
            })
          }
          break
      }
    }

    const resetForecast = (): void => {
      forecastEstimates.value = _cloneDeep(defaultForecastEstimates)
    }

    const fetch = async ({
      type = 'flight',
      id,
      module = null,
    }: {
      type: 'flight' | 'bg' | 'campaign'
      id: string
      module: string | null
    }): Promise<void> => {
      try {
        Object.assign(state, { loading: true, error: null })

        switch (type) {
          case 'flight':
            var pathName = 'baseFlight'
            break
          case 'bg':
            var pathName = 'budgetGrouping'
            break
          case 'campaign':
            var pathName = 'campaign'
            break
        }

        const url = `Forecast/${pathName}`

        const response = await HTTP.get<
          APIResponse<{
            data: APIResponseForecastBaseFlight | APIResponseForecastBG
          }>
        >(url, {
          params: { id },
          executeInBackground: true,
        })

        setForecast({ estForecast: response.data.datas.data, type, module })
      } catch (error) {
        state.error = handleError(error)
      } finally {
        state.loading = false
      }
    }

    const exportForecast = async ({ id }: { id: number }): Promise<void> => {
      try {
        const response = await HTTP.get('Forecast/baseFlightExport', {
          responseType: 'blob',
          params: { id },
          executeInBackground: true,
        })

        downloadBuffer(response.data, `forecast-${id}.zip`, 'application/zip')

        Promise.resolve(response)
      } catch (error) {
        Promise.reject(error)
        console.error(error)
      }
    }

    return {
      dataCostsCpm,
      exportForecast,
      fetch,
      forecastEstimates,
      setForecast,
      resetForecast,
      state,
    }
  }
)
