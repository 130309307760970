import { h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { RouterView } from 'vue-router'

import PeopleAdvertiser from '@/router/people/advertiser'
import PeopleAgency from '@/router/people/agency'
import PeopleUser from '@/router/people/user'

export default [
  {
    path: '/:organization?/people',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'People',
        component: () => import('@/views/people/index.vue'),
        redirect: { name: 'AgencyList' },
        meta: {
          layout: 'LayoutAuthenticated',
          requiresAuth: true,
        },

        children: [
          {
            path: 'agency',
            name: 'AgencyList',
            component: () => import('@/views/people/agency/list.vue'),
            meta: {
              title: 'Passport | Agencies',
            },
          },
          {
            path: 'advertiser',
            name: 'AdvertiserList',
            component: () => import('@/views/people/advertiser/list.vue'),
            meta: {
              title: 'Passport | Advertisers',
            },
          },
          {
            path: 'user',
            name: 'UserList',
            component: () => import('@/views/people/user/list.vue'),
            meta: {
              title: 'Passport | Users',
            },
          },
        ],
      },
      ...PeopleAdvertiser,
      ...PeopleAgency,
      ...PeopleUser,
    ],
  },
] as RouteRecordRaw[]
