<!-- eslint-disable vue/no-v-html -->
<template>
  <router-link
    :to="to"
    class="tw-flex tw-w-full tw-max-w-full tw-cursor-pointer tw-flex-col tw-justify-center tw-text-sm tw-text-grey-medium hover:tw-text-blackest"
  >
    <div
      v-if="item.entity === 'CampaignBudgetGrouping'"
      class="tw-text-2xs tw-font-semibold tw-uppercase tw-leading-none tw-text-primary"
    >
      Budget Grouping
    </div>
    <div class="tw-whitespace-nowrap tw-font-medium">
      <Tooltip :title="item.name">
        <span v-html="labelValue" />
      </Tooltip>
    </div>
    <div class="tw-flex tw-flex-wrap tw-items-center tw-leading-none">
      <Tag
        class="sm:tw-mt-1 xxl:tw-mt-1 tablet:tw-mt-px"
        v-if="
          ![
            'CampaignBudgetGrouping',
            'ControlBudgetGrouping',
            'OrderBudgetGrouping',
            'ControlOrderBudgetGrouping',
          ].includes(item.entity)
        "
        :type="item[entity.statusKey].toLowerCase() || 'error'"
      >
        <template v-if="item[entity.statusKey] === 'AppliedRevision'">
          #{{ $filters.formatNumber(item.revisionVersionNumber) }}
        </template>
        {{ item[entity.statusKey] ? item[entity.statusKey] : 'Error' }}
      </Tag>
      <div class="sm:tw-mt-1 xxl:tw-mt-1 tablet:tw-mt-px">
        <button
          class="tw-group tw-inline-flex tw-rounded tw-p-1 tw-text-xs hover:tw-bg-pill-bg hover:tw-text-black"
          type="button"
          @click.prevent="handleCopyId"
        >
          <template v-if="isIdCopied">
            <span
              class="tw--m-1 tw-hidden tw-rounded tw-bg-pill-bg tw-p-1 tw-pl-2 tw-font-medium tw-text-black lg:tw-block"
            >
              ID Copied! {{ item.id }}
            </span>
            <span
              class="tw--m-1 tw-rounded tw-bg-pill-bg tw-p-1 tw-pl-2 tw-font-medium tw-text-black lg:tw-hidden"
            >
              ID Copied!
            </span>
          </template>
          <template v-else-if="item.campaignFlag === 'SingleFlight'">
            <div class="tw-hidden tw-font-medium lg:tw-block">
              Copy Campaign ID:
            </div>
            <div class="tw-font-medium lg:tw-hidden">Copy ID:</div>
            <span
              class="tw--m-1 tw-ml-1 tw-rounded tw-bg-pill-bg tw-p-1 tw-text-black"
            >
              {{ item.id }}
            </span>
          </template>
          <template v-else>
            <div class="tw-font-medium">Copy ID:</div>
            <span
              class="tw--m-1 tw-ml-1 tw-rounded tw-bg-pill-bg tw-p-1 tw-text-black"
            >
              {{ item.id }}
            </span>
          </template>
        </button>
      </div>
    </div>
  </router-link>
</template>
<script>
import ListCellDefault from '@/components/list/cell/default.vue'
import Tag from '@/components/Tag.vue'
import Tooltip from '@/components/Tooltip.vue'

export default {
  name: 'ListCellEntityName',
  extends: ListCellDefault,
  components: {
    Tag,
    Tooltip,
  },
  data() {
    return {
      isIdCopied: false,
    }
  },
  computed: {
    labelValue() {
      if (!this.search) return this.item.name
      return (this.item.name || '')
        .normalize('NFD')
        .replaceAll(
          new RegExp(this.search?.normalize('NFD').replaceAll(' ', '|'), 'gi'),
          '<mark class="tw-bg-primary-light/90">$&</mark>'
        )
    },
  },
  methods: {
    handleCopyId() {
      this.handleClipboardCopy(this.item.id)
      this.isIdCopied = true

      setTimeout(() => (this.isIdCopied = false), 3000)
    },
  },
}
</script>
