<template>
  <svg
    class="tw-z-50"
    enable-background="new 0 0 1000 693.9"
    viewBox="0 0 1000 693.9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#fff">
      <path
        d="m763.8 444.4c1 1.3 1.8 2.2 2.4 3.1 11.9 18.2 19.5 37.9 21.3 59.6 1.6 18.7-1.1 36.9-8.3 54.3-13.7 33.3-37.1 57.8-68.8 74.3-24.5 12.7-50.7 18-78.3 16.4-32.1-1.9-60.7-13-85.5-33.5-2.2-1.8-4.3-3.7-6.3-5.7-37-38-61-82.9-71.6-134.9-3.7-18-5.3-36.3-5.2-54.6 0-25.2 0-50.3 0-75.5 0-15.5-2.7-30.5-8.5-44.9-15.1-37.2-48.5-64.4-88-70.8-64.4-10.5-120.1 31.5-132.9 89.6-2.2 10-2.9 20.1-2.4 30.3.1 2.7.2 5.3.4 7.9-1.3.7-1.6-.3-2.1-.9-5.6-8.1-10.6-16.5-15.1-25.3-11.4-22.1-18.8-45.5-22.1-70.2-6.2-45.2 1.9-87.6 24.6-127.1 28.3-49.1 69.8-82.4 123-101.6 16.4-5.9 33.2-9.9 50.5-11.8 19-2.1 37.9-2 56.8.5 23 3 45 9.3 65.9 19.2 4.5 2.1 8.9 4.5 13.2 7.1 80 48.5 137.7 128.6 158.2 219.9 4.4 19.7 7.3 39.6 8 59.8.3 7.5.5 15 .6 22.5.2 23.3.2 46.7.5 70 0 3.8.2 7.7 1.2 11.4 4 14.8 13.5 24.4 28.5 27.6 14.8 3.2 27.3-1.6 37.1-13.1.4-.5.8-1.1 1.2-1.6s.9-1 1.7-2z"
      />
      <path
        d="m408.2 6c-1.5.1-2.9.3-4.4.3-12.2.3-24.3 1.3-36.3 3.3-33.9 5.6-65.2 17.7-93.9 36.4-33.1 21.6-59.8 49.3-79.3 83.8-13.2 23.4-21.8 48.5-25.8 75.2-2.9 19.4-3.4 38.8-1 58.3 6.8 55.4 30.3 102.7 69 142.6 14.9 15.4 31.8 28.4 50.1 39.6 15.7 9.6 32.8 15.3 51.3 16.7 30.8 2.3 58.3-6.3 82.4-25.6 4.9-4 9.7-8.2 14.6-12.2.8-.7 1.8-1.3 2.6-1.9 1.1.7.9 1.6.9 2.4 0 5.5.1 11 0 16.5-.2 9.7 1.1 19.3 2.6 28.8 4.6 29.3 13.3 57.4 26.4 84 14.3 29.1 33 55.1 55.9 78.1 2.6 2.6 5.1 5.2 7.7 7.8-12.1 10.8-60 32.3-90.5 40.8-50.9 14.2-102.4 16.7-154.4 7.5-53.9-9.5-102.9-30.8-146.7-63.6-135.3-101.5-175.5-280.5-105.7-426.8 35.5-74.5 91.2-129.4 165.5-165.1 71-34.1 148.4-39.1 209-26.9z"
      />
      <path
        d="m613.8 665c4 .7 7.7 1.5 11.5 2.1 27.5 4.5 54.5 2.2 80.8-7.2 41.7-15 72.9-42.2 93.2-81.6 10.1-19.6 15-40.6 15.2-62.6s-4.8-43-14.2-62.8c-9.4-19.9-22.5-37-38.6-51.8-17.5-16-28.9-35.6-34.2-58.7-14.1-61.3 22.5-120.9 81.5-137.4 40.7-11.3 83.7.3 112.9 29.8 37.9 38.4 62.4 83.9 73.1 136.8 3.4 16.7 5.2 33.6 5 50.6-.2 9.3 0 18.6-.9 28-2.2 22.3-7 44.1-14.6 65.2-30.5 84.7-101.6 148.7-189 170.2-26.6 6.5-53.6 8.8-80.9 7.1-33.8-2.1-66-10.4-96.7-24.8-1.4-.7-3.1-1-4.1-2.9z"
      />
    </g>
  </svg>
</template>
