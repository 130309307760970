<script setup lang="ts">
import { computed, type PropType, reactive, ref, watch } from 'vue'
import {
  isAfter,
  parseISO,
  format,
  isValid,
  isBefore,
  formatISO,
} from 'date-fns'
import { formats } from '@/helpers/dateFns'
import 'v-calendar/dist/style.css'
import { EnumDateInputModes } from '@/constants/components'
import DateInput from '@/components/Input/DateInput.vue'
import TextInput from '@/components/Input/TextInput.vue'
import { OnClickOutside } from '@vueuse/components'
import DatePicker from '@/components/DatePicker.vue'
import { Popover, PopoverPanel } from '@headlessui/vue'
import type { DatePickerValue } from '@/types'
import { useStoreDate } from '@/stores'
import PassportButton from '@/components/PassportButton.vue'
import Icon from '@/components/Icon.vue'

const emit = defineEmits<{
  (e: 'update:start', startDate: string | null): void
  (e: 'update:end', endDate: string | null): void
  (e: '@focus'): void
  (e: '@blur'): void
}>()

const props = defineProps({
  currentStartDate: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  minDate: {
    type: String,
    default: null,
  },
  maxDate: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String as PropType<EnumDateInputModes>,
    default: EnumDateInputModes.DATE,
  },
  start: {
    type: [String, null] as PropType<string | null>,
    required: true,
  },
  startNow: {
    type: Boolean,
    default: false,
  },
  end: {
    type: [String, null] as PropType<string | null>,
    required: true,
  },
  sameDatesAsParent: {
    type: Boolean,
    default: false,
  },
  splitPanels: {
    type: Boolean,
    default: false,
  },
  targetClass: {
    type: [Array, Object, String],
    default: null,
  },
  trigger: {
    type: String as PropType<'click' | 'focus' | 'hover' | 'hidden'>,
    default: 'click',
  },
  valueFormat: {
    type: String,
    default: formats.ISO.dateWithTime,
  },
  withButtonApply: {
    type: Boolean,
    default: false,
  },
})

const storeDate = useStoreDate()

const isOpen = ref<boolean>(false)

const attributes = {
  highlight: {
    start: {
      color: 'white',
      class: 'tw-bg-primary',
      contentClass: 'tw-text-white',
    },
    base: {
      color: 'white',
      class: 'tw-bg-primary tw-opacity-70',
      contentClass: 'tw-text-white',
    },
    end: {
      color: 'white',
      class: 'tw-bg-white tw-border-primary tw-border-2',
      contentClass: 'tw-text-black',
    },
  },
}

const startDate = ref<string | null>(props.start)
const endDate = ref<string | null>(props.end)
const shadowDates = reactive({ start: props.start, end: props.end })

watch(
  () => props.start,
  (value, oldValue) => {
    if (value !== oldValue) {
      startDate.value = value
    }
  }
)

watch(
  () => props.end,
  (value, oldValue) => {
    if (value !== oldValue) {
      endDate.value = value
    }
  }
)

const startDateProxy = computed<DatePickerValue<'simple'>>({
  get: () =>
    typeof startDate.value === 'string' && isValid(parseISO(startDate.value))
      ? parseISO(startDate.value)
      : null,

  set: (date) => {
    if (!date) startDate.value = null
    else startDate.value = format(date, props.valueFormat)
  },
})

const endDateProxy = computed<DatePickerValue<'simple'>>({
  get: () =>
    typeof endDate.value === 'string' && isValid(parseISO(endDate.value))
      ? parseISO(endDate.value)
      : null,
  set: (date) => {
    if (!date) endDate.value = null
    else endDate.value = format(date, props.valueFormat)
  },
})

const dateRangeProxy = computed<{
  start: DatePickerValue<'simple'>
  end: DatePickerValue<'simple'>
}>({
  get: () => ({
    start: props.start ? parseISO(props.start) : null,
    end: props.end ? parseISO(props.end) : null,
  }),
  set: ({ start, end }) => {
    if (!start || !end) return

    emit('update:start', format(start, props.valueFormat))
    emit('update:end', format(end, props.valueFormat))
  },
})

const isDatesSet = computed(() => {
  return Boolean(props.start && props.end)
})

const state = computed(() => {
  if (props.start === null || props.end === null) return 'initial'

  if (isAfter(parseISO(props.start), parseISO(props.end))) {
    return 'error'
  }

  return 'initial'
})

const computedRangeAttributes = computed(() => {
  if (!isDatesSet.value) return []

  return [
    {
      ...attributes,
      key: 'range',
      dates: shadowDates,
    },
  ]
})

const handleSubmit = (): void => {
  emit('update:start', startDate.value)
  emit('update:end', endDate.value)
}

const handleReset = (): void => {
  startDate.value = props.start
  endDate.value = props.end
}

const handleChangeDates = (range: DatePickerValue): void => {
  if (!range || typeof range !== 'object' || range instanceof Date) return

  startDateProxy.value = range.start
  endDateProxy.value = range.end
}

const showRangePicker = computed(() => {
  if (props.currentStartDate && isValid(parseISO(props.currentStartDate))) {
    if (
      isAfter(
        storeDate.currentDate || new Date(),
        parseISO(props.currentStartDate)
      )
    ) {
      return false
    }
  }

  return props.withButtonApply == true && props.splitPanels == false
})

const maxStartDate = computed<string | null>(() => {
  const _maxDate = parseISO(props.maxDate || '')
  const _endDate = parseISO(props.end || '')

  if (!isValid(_maxDate) && isValid(_endDate)) return formatISO(_endDate)
  if (!isValid(_maxDate) || !isValid(_endDate)) return null
  if (isBefore(_endDate, _maxDate)) return formatISO(_endDate)
  else return formatISO(_maxDate)
})

const minEndDate = computed<string | null>(() => {
  const _minDate = parseISO(props.minDate || '')
  const _startDate = parseISO(props.start || '')

  if (!isValid(_minDate) && isValid(_startDate)) return formatISO(_startDate)
  if (!isValid(_minDate) || !isValid(_startDate)) return null
  if (isBefore(_startDate, _minDate)) return formatISO(_minDate)
  else return formatISO(_startDate)
})
</script>

<template>
  <template v-if="showRangePicker">
    <DatePicker
      v-model="dateRangeProxy"
      trigger="hidden"
      :is-range="true"
      :mode="props.mode"
      :min-date="props.minDate"
      :max-date="props.maxDate"
      :required="required"
      :disabled="props.disabled"
      :value-format="props.valueFormat"
    >
      <template #default="{ inputValue, inputEvents }">
        <OnClickOutside
          class="tw-relative tw-w-full tw-max-w-[800px]"
          @trigger="
            () => {
              isOpen = false
              handleReset()
            }
          "
        >
          <div
            class="tw-flex tw-w-full tw-flex-wrap tw-items-center tw-gap-x-8 tw-gap-y-2"
          >
            <template
              v-for="panel of ['start', 'end']"
              :key="panel"
            >
              <div class="tw-relative tw-flex-1">
                <TextInput
                  class="tw-min-w-48 tw-uppercase"
                  :label="`${panel} ${'Date'}`"
                  :disabled="props.disabled"
                  :model-value="inputValue[panel]"
                  :required="props.required"
                  :state="state"
                  :target-class="props.targetClass"
                  v-on="inputEvents[panel]"
                  @focus="isOpen = true"
                />
                <Icon
                  v-if="props.startNow && panel === 'start'"
                  name="calendar-now"
                  class="tw-absolute tw-bottom-1.5 tw-right-1.5 tw-text-primary"
                />
                <Icon
                  v-else
                  name="calendar"
                  :class="
                    props.sameDatesAsParent
                      ? 'tw-text-primary'
                      : 'tw-text-gray-500'
                  "
                  class="tw-absolute tw-bottom-1.5 tw-right-1.5"
                />
              </div>
              <div
                v-if="panel === 'start'"
                class="tw-hidden lg:tw-block"
              >
                to
              </div>
            </template>
          </div>

          <Popover>
            <PopoverPanel
              v-if="isOpen === true"
              class="popover-panel tw-absolute tw-top-12 tw-z-20 tw-mt-px tw-w-full tw-min-w-[500px] tw-rounded-b tw-border-t-2 tw-border-t-primary tw-bg-white tw-drop-shadow-lg"
              static
            >
              <slot
                name="popover-header"
                :set-start="(date: Date) => (startDateProxy = date)"
                :set-end="(date: Date) => (endDateProxy = date)"
                :start="startDateProxy"
                :end="endDateProxy"
              />
              <slot>
                <DatePicker
                  :is-range="true"
                  :static="true"
                  :columns="2"
                  :model-value="{ start: startDateProxy, end: endDateProxy }"
                  :min-date="props.minDate"
                  :max-date="props.maxDate"
                  :mode="props.mode"
                  :required="required"
                  :disabled="props.disabled"
                  :value-format="props.valueFormat"
                  @update:model-value="handleChangeDates"
                />
              </slot>
              <div class="tw-flex tw-items-center tw-px-6 tw-py-2">
                <slot
                  name="popover-footer"
                  :set-start="(date: Date) => (startDateProxy = date)"
                  :set-end="(date: Date) => (endDateProxy = date)"
                />
                <PassportButton
                  label="Apply"
                  variant="primary"
                  size="small"
                  class="tw-ml-auto"
                  type="button"
                  @@click="
                    () => {
                      isOpen = false
                      handleSubmit()
                    }
                  "
                />
              </div>
            </PopoverPanel>
          </Popover>
        </OnClickOutside>
      </template>
    </DatePicker>
  </template>
  <div
    v-else
    class="tw-flex tw-flex-wrap tw-gap-x-8 tw-gap-y-2"
  >
    <div
      class="tw-flex-1"
      :class="
        props.splitPanels
          ? props.mode === EnumDateInputModes.TIME
            ? 'tw-w-1'
            : 'tw-min-w-[250px]'
          : ''
      "
    >
      <template v-if="props.mode === EnumDateInputModes.TIME">
        <p class="tw-text-sm tw-text-grey">Start Time</p>
      </template>
      <DateInput
        :label="
          props.mode === EnumDateInputModes.TIME ? 'Start Time' : 'Start Date'
        "
        :attributes="computedRangeAttributes"
        :disabled="props.disabled"
        :min-date="props.minDate"
        :max-date="maxStartDate"
        :model-value="props.start"
        :state="state"
        :mode="props.mode"
        :required="required"
        :target-class="props.targetClass"
        :value-format="props.valueFormat"
        :with-button-apply="props.withButtonApply"
        @update:model-value="emit('update:start', $event)"
        @update:shadow="shadowDates.start = $event"
      />
    </div>
    <div
      class="tw-flex-1"
      :class="
        props.splitPanels
          ? props.mode === EnumDateInputModes.TIME
            ? ''
            : 'tw-min-w-[250px]'
          : ''
      "
    >
      <template v-if="props.mode === EnumDateInputModes.TIME">
        <p class="tw-text-sm tw-text-grey">End Time</p>
      </template>

      <DateInput
        :label="
          props.mode === EnumDateInputModes.TIME ? 'End Time' : 'End Date'
        "
        :attributes="computedRangeAttributes"
        :disabled="props.disabled"
        :min-date="minEndDate"
        :max-date="props.maxDate"
        :model-value="props.end"
        :state="state"
        :mode="props.mode"
        :required="required"
        :target-class="props.targetClass"
        :value-format="props.valueFormat"
        :with-button-apply="props.withButtonApply"
        @update:model-value="emit('update:end', $event)"
        @update:shadow="shadowDates.end = $event"
      />
    </div>
  </div>
</template>
